import {
    AIVQ_TARIFICATION,
    AIVQ_TARIFICATION_SHORT,
    AVQ_TARIFICATION,
    AVQ_TARIFICATION_SHORT
} from "@/components/Util/tarificationActConstants";
import {dateFormat} from "@/components/Util/UtilFunction";
/*const BVIDF = 'BVIDF'*/

export default {
    getEvaluation(state){
        return state.evaluation;
    },
    getActiveNavOptionsActivated(state){
        let navOptions = [];
        navOptions.push("Général");

        let sensTab = Object.values(state.sense);
        for(let i = 0; i < sensTab.length ; i++){
            if(sensTab[i].response !== null && sensTab[i].response !== ''){
                navOptions.push("Organes des sens");
                break;
            }
        }

        let nutritionTab = Object.values(state.nutrition);
        for(let i = 0; i < nutritionTab.length ; i++){
            if(nutritionTab[i].response !== null && nutritionTab[i].response !== ''){
                navOptions.push("Nutrition");
                break;
            }
        }

        let breathingTab = Object.values(state.breathing);
        for(let i = 0; i < breathingTab.length ; i++){
            if(breathingTab[i].response !== null && breathingTab[i].response !== ''){
                navOptions.push("Respiration");
                break;
            }
        }

        let eliminationTab = Object.values(state.elimination);
        for(let i = 0; i < eliminationTab.length ; i++){
            if(eliminationTab[i].response !== null && eliminationTab[i].response !== ''){
                navOptions.push("Élimination");
                break;
            }
        }

        let motorTab = Object.values(state.motor);
        for(let i = 0; i < motorTab.length ; i++){
            if(motorTab[i].response !== null && motorTab[i].response !== ''){
                navOptions.push("Motricité");
                break;
            }
        }

        let skinTab = Object.values(state.skin);
        for(let i = 0; i < skinTab.length ; i++){
            if(skinTab[i].response !== null && skinTab[i].response !== ''){
                navOptions.push("Peau");
                break;
            }
        }

        let painTab = Object.values(state.pain);
        for(let i = 0; i < painTab.length ; i++){
            if(painTab[i].response !== null && painTab[i].response !== ''){
                navOptions.push("Douleur");
                break;
            }
        }

        let brainTab = Object.values(state.brain);
        for(let i = 0; i < brainTab.length ; i++){
            if(brainTab[i].response !== null && brainTab[i].response !== ''){
                navOptions.push("Cognition");
                break;
            }
        }

        let treatmentTab = Object.values(state.treatment);
        for(let i = 0; i < treatmentTab.length ; i++){
            if(treatmentTab[i].response !== null && treatmentTab[i].response !== ''){
                navOptions.push("Traitement");
                break;
            }
        }
        return navOptions;
    },

    getActEvaluationFromId: (state) => (id) => {
        return state.evaluation.evaluationHasActList.find(act => act.id === id)
    },
    getHabitEvaluationFromId: (state) => (id) => {
        return state.evaluation.habits.find(habit => habit.id === id)
    },
    getEstimatedTimeSum: (state) => {
        let sum = 0;
        state.evaluation.evaluationHasActList.forEach((act) => {
            if (act.estimatedTime && act.active /*&& act.caregiver.indexOf(BVIDF) >= 0*/) {
                let minutes = act.estimatedTime * (act.weekDays ? act.weekDays.length : 0);
                sum += minutes;
            }
        })
        return dateFormat(sum, true);
    },
    getValidatedTimeSum: (state) => {
        let sum = 0;
        state.evaluation.evaluationHasActList.forEach((act) => {
            if (act.validatedTimeIntFormat && act.active /*&& act.caregiver.indexOf(BVIDF) >= 0*/) {
                let minutes = act.validatedTimeIntFormat * (act.weekDays ? act.weekDays.length : 0);
                sum += minutes;
            }
        });
        return dateFormat(sum, true);
    },
    getEstimatedTimeSumByPeriod: (state) => (period) =>  {
        let sum = 0;
        state.evaluation.evaluationHasActList.forEach((act) => {
            if (act.estimatedTime && act.active /*&& act.caregiver.indexOf(BVIDF) >= 0*/ && act.period === period) {
                let minutes = act.estimatedTime * (act.weekDays ? act.weekDays.length : 0);
                sum += minutes;
            }
        })
        return dateFormat(sum, true);
    },
    getValidatedTimeSumByPeriod: (state) => (period, singleDay) =>  {
        let sum = 0;
        state.evaluation.evaluationHasActList.forEach((act) => {
            if (act.validatedTimeIntFormat && act.active /*&& act.caregiver.indexOf(BVIDF) >= 0*/ && act.period === period) {
                let minutes = act.validatedTimeIntFormat * (!singleDay && act.weekDays ? act.weekDays.length : (singleDay ? 1 : 0));
                sum += minutes;
            }
        });
        return dateFormat(sum, true);
    },
    //TODO refactor to use tarif of agency
    getSumOfPrestationCost: (state) => (type, prestations) => {
        let cost = 0
        state.evaluation.evaluationHasActList.forEach((act) => {
            if (act && act.active && prestations.length > 0 /*&& act.caregiver.indexOf(BVIDF) >= 0*/) {
                if (act.act.category === 'AVQ') {
                    let prestAivq = prestations.find(prestation =>
                        prestation.name.toUpperCase().indexOf(AVQ_TARIFICATION.toUpperCase()) !== -1
                        || prestation.name.toUpperCase().indexOf(AVQ_TARIFICATION_SHORT.toUpperCase()) !== -1
                    )
                    if (prestAivq) {
                        cost += type === 'estimated' ? act.estimatedTime / 60 * prestAivq.price * (act.weekDays ? act.weekDays.length : 0) : act.validatedTimeIntFormat / 60 * prestAivq.price * (act.weekDays ? act.weekDays.length : 0)
                    }
                } else {
                    let prestAivq = prestations.find(prestation =>
                        prestation.name.toUpperCase().indexOf(AIVQ_TARIFICATION.toUpperCase()) !== -1
                        || prestation.name.toUpperCase().indexOf(AIVQ_TARIFICATION_SHORT.toUpperCase()) !== -1
                    )
                    if (prestAivq) {
                        cost += type === 'estimated' ? act.estimatedTime / 60 * prestAivq.price * (act.weekDays ? act.weekDays.length : 0) : act.validatedTimeIntFormat / 60 * prestAivq.price * (act.weekDays ? act.weekDays.length : 0)
                    }
                }
            }
        })
        return Math.round(cost * 100) / 100
    },
    getGroupedBy: (state) => (key, cate, twice = false) => {
        return state.evaluation.evaluationHasActList.reduce((acts, val) => {
            if (twice) {
                if (val.act.category === cate && val.active) {
                    (acts[val[key]] = acts[val[key]] || []).push(val);
                }
                return acts
            } else {
                if (val.act.category === cate && val.active) {
                    (acts[val[key]] = acts[val[key]] || []).push(val);
                    return acts
                } else {
                    return acts
                }
            }
        }, {});
    },
    getAvqActList: (state, getters) => {
        return getters.getGroupedBy('period', 'AVQ')
    },
    getAvqActListPlace: (state, getters) => {
        return getters.getGroupedBy('place', 'AVQ', true)
    },
    getAivqActList: (state, getters) => {
        return getters.getGroupedBy('period', 'AIVQ')
    },
    getAivqActListPlace: (state, getters) => {
        return getters.getGroupedBy('place', 'AIVQ', true)
    },
    getAivqValidatedTimeSum: (state) => {
        var sum = 0;
        state.evaluation.evaluationHasActList.forEach((act) => {
            if (act.validatedTimeIntFormat && act.active && act.act.category === 'AIVQ' /*&& act.caregiver.indexOf(BVIDF) >= 0*/) {
                sum += act.validatedTimeIntFormat * (act.weekDays ? act.weekDays.length : 0);
            }
        })
        state.evaluation.aivqTimeSum = sum
        return sum;
    },
    getAvqValidatedTimeSum: (state) => {
        var sum = 0;
        state.evaluation.evaluationHasActList.forEach((act) => {
            if (act.validatedTimeIntFormat && act.active && act.act.category === 'AVQ' /*&& act.caregiver.indexOf(BVIDF) >= 0*/) {
                sum += act.validatedTimeIntFormat * (act.weekDays ? act.weekDays.length : 0);
            }
        })
        state.evaluation.avqTimeSum = sum
        return sum;
    },
    getPrestationCost: (state) => (category, prestations) => {
        let cost = 0
        state.evaluation.evaluationHasActList.forEach((act) => {
            if (act && act.active && prestations.length > 0 /*&& act.caregiver.indexOf(BVIDF) >= 0*/) {
                if (category === 'AIVQ') {
                    let prestAct = prestations.find((prestation) => {
                        return prestation.name.toUpperCase().includes(AIVQ_TARIFICATION.toUpperCase())
                    })
                    cost = prestAct.price
                } else {
                    let prestAct = prestations.find((prestation) => {
                        return prestation.name.toUpperCase().includes(AVQ_TARIFICATION.toUpperCase())
                    })
                    cost = prestAct.price
                }
            }
        });
        return Math.round(cost * 100) / 100;
    },
    calculateTime: (state) => {
        let tab = state.evaluation.evaluationHasActList.filter((c) => c.active /*&& c.caregiver.indexOf(BVIDF) >= 0*/);
        let time = 0;
        tab.forEach((c) => {
            time += c.estimatedTime ? c.estimatedTime : 0;
        });
        return time;
    },
    getAggirPercentage: (state) => {
        const aggirPercentage = Math.round((state.evaluation.answerList.length * 100) / 24);
        return aggirPercentage > 100 ? 100 : aggirPercentage;
    },


    //Devis week and sum
    getAivqValidatedTimeWeekSum: (state) => {
        let sum = 0;
        state.evaluation.evaluationHasActList.forEach((act) => {
            const weekToApply = (act.weekDays ? act.weekDays.filter((c) => c !== 'Dim').length : 0);
            if (act.validatedTimeIntFormat && act.active && act.act.category === 'AIVQ' /*&& act.caregiver.indexOf(BVIDF) >= 0*/) {
                sum += act.validatedTimeIntFormat * weekToApply;
            }
        })
        state.evaluation.aivqTimeSum = sum
        return sum;
    },
    getAvqValidatedTimeWeekSum: (state) => {
        let sum = 0;
        state.evaluation.evaluationHasActList.forEach((act) => {
            const weekToApply = (act.weekDays ? act.weekDays.filter((c) => c !== 'Dim').length : 0);
            if (act.validatedTimeIntFormat && act.active && act.act.category === 'AVQ' /*&& act.caregiver.indexOf(BVIDF) >= 0*/) {
                sum += act.validatedTimeIntFormat * weekToApply;
            }
        })
        state.evaluation.avqTimeSum = sum
        return sum;
    },
    getAivqValidatedTimeDFSum: (state) => {
        let sum = 0;
        state.evaluation.evaluationHasActList.forEach((act) => {
            const weekToApply = (act.weekDays ? act.weekDays.filter((c) => c === 'Dim').length : 0);
            if (act.validatedTimeIntFormat && act.active && act.act.category === 'AIVQ' /*&& act.caregiver.indexOf(BVIDF) >= 0*/) {
                sum += act.validatedTimeIntFormat * weekToApply;
            }
        })
        state.evaluation.aivqTimeSum = sum
        return sum;
    },
    getAvqValidatedTimeDFSum: (state) => {
        let sum = 0;
        state.evaluation.evaluationHasActList.forEach((act) => {
            const weekToApply = (act.weekDays ? act.weekDays.filter((c) => c === 'Dim').length : 0);
            if (act.validatedTimeIntFormat && act.active && act.act.category === 'AVQ' /*&& act.caregiver.indexOf(BVIDF) >= 0*/) {
                sum += act.validatedTimeIntFormat * weekToApply;
            }
        })
        state.evaluation.avqTimeSum = sum
        return sum;
    },
    getSeanceDetailsByDay: (state, getters) => (day) => {
        const periods = ["Lever", "Matin", "Midi", "Après-midi", "Soirée", "Nuit"];
        const avqList = getters.getAvqActList;
        const aivqList = getters.getAivqActList;
        const avqListKeys = Object.keys(avqList);
        const aivqListKeys = Object.keys(aivqList);
        const data = [];
        let transfertCount = 0;

        for(let period of periods) {
            let periodActs = {
                period: period,
                list: [],
                time: getters.getValidatedTimeSumByPeriod(period, true)
            }
            if(avqListKeys.includes(period)) {
                const relatedActs = avqList[period].filter((el) => el.weekDays && el.weekDays.includes(day));
                if(relatedActs.length > 0) {
                    transfertCount += relatedActs.reduce((acc, link) => {
                        if(link.aggirQuestion && link.aggirQuestion.item === "Transferts") {
                            return acc+1;
                        }
                        return acc;
                    }, 0);

                    periodActs.list = [...periodActs.list, ...relatedActs.map((link) => link.act.actName)]
                }
            }
            if(aivqListKeys.includes(period)) {
                const relatedActs = aivqList[period].filter((el) => el.weekDays && el.weekDays.includes(day));
                if(relatedActs.length > 0) {
                    periodActs.list = [...periodActs.list, ...relatedActs.map((link) => link.act.actName)]
                }
            }
            if(periodActs.list.length > 0) {
                data.push(periodActs);
            }
        }

        return {
            details: data,
            transfertCount: transfertCount
        };
    },
    getGeneralQuestionsAnswersByCategory: (state) => (category) => {
        let idCategoryGeneralQuestion = state.generalQuestions.reduce((items, item) => {
           if(item.category === category){
               items.push(item.id);
           }
           return items;
        }, []);
        if(!state.evaluation.generalQuestionsAnswers){
            return [];
        }
        return state.evaluation.generalQuestionsAnswers.reduce((items, item) => {
            if(idCategoryGeneralQuestion.includes(item.generalQuestion.id)){
                items.push(item);
            }
            return items;
        }, []);
    }
};