export default {
    setInterventionLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setInterventionList(state, interventions) {
        state.interventions = interventions
        state.loading = false;
        state.error = null;
    },
    setTotalInterventionList(state, total) {
        state.total = total;
    },
    setInterventionError(state, error) {
        state.loading = false;
        state.error = error;
    },
    setInterventionProperty(state, property) {
        if(!state.intervention[property.name]){
            state.intervention[property.name] = "";
        }
        state.intervention[property.name] = property.value;
    },
    setInterventionSaveLoading(state) {
        state.error = null;
    },
    setInterventionSuccess(state, intervention) {
        state.intervention = {
            ...intervention,
            clientId: intervention.client ? intervention.client.id : null,
            prestationId: intervention.prestation ? intervention.prestation.id : null,
            intervenantId: intervention.intervenant ? intervention.intervenant.id : null
        };
        state.loading = false;
        state.error = null;
    },
    setInitIntervention(state) {
        state.intervention = {
            erpId: '',
            dateStart: '',
            dateEnd: '',
            client: {},
            clientId: '',
            prestation: {},
            prestationId: '',
            intervenant: {},
            intervenantId: '',
            clientHealth: '',
            clientMoral: '',
            additionalComments: '',
            familyComments: '',
            sendHour: '',
            photo: '',
            status: false,
            hasNoBadge: false,
            hasNoBadgeDescription: '',
            hasAbsence: false,
            signalements: []
        };
        state.error = null;
    },
    setInterventionChartDataBySignalementLoading(state) {
        state.countsFromSignalementType = [];
        state.loading = true;
        state.error = null;
    },
    setInterventionChartDataBySignalement(state, countsFromSignalementType) {
        state.countsFromSignalementType = countsFromSignalementType
        state.loading = false;
        state.error = null;
    },
    setInterventionRatioChartDataByHoursLoading(state) {
        state.hoursByMonth = [];
        state.loading = true;
        state.error = null;
    },
    setInterventionRatioChartDataByHours(state, hoursByMonth) {
        state.hoursByMonth = hoursByMonth
        state.loading = false;
        state.error = null;
    },
}