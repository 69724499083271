import gql from 'graphql-tag'

export const SAVE_STRUCTURE = gql`
    mutation saveStructure(
        $id: Int!,
        $erpId: Int!,
        $name: String!,
        $address: String!,
        $status: Boolean!
    ) {
        saveStructure(
            id: $id,
            erpId: $erpId,
            name: $name,
            address: $address,
            status: $status
        ) {
            id,
            erpId,
            name,
            address,
            status,
            logo,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            },
        }
    }
`
