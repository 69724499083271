<template>
  <div class="eval-form eval-form--check id-check--item">
    <div class="id-check--title" v-if="hasSubtitle">{{subtitle}}</div>
    <div class="id-check--card" :class="cardClass">
      <slot name="content">
        <div class="id-check--option" v-for="(option, index) in options" :key="index">
          <input
              class="id-check--input"
              :id="`${id}-${index}`"
              :name="givenName"
              :type="inputType"
              :value="option"
              v-model="val"
          />
          <label :for="`${id}-${index}`">
            <span class="id-check--indicator">{{option}}</span>
          </label>
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "EvalFormCheck",
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    hasSubtitle: {
      type: Boolean,
      default: true
    },
    subtitle: {
      type: String,
      default: "Subtitle",
    },
    inputType: {
      type: String,
      default: "checkbox",
    },
    name: {
      type: String,
      default: null,
    },
    cardClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      val: this.value,
    };
  },
  watch: {
    val(v) {
      this.$emit("input", v);
      if (v!==this.value) {
        this.$emit("change", v);
      }
    },
    value(v) {
      this.val = v;
    }
  },
  computed: {
    givenName() {
      if (this.name) {
        return this.name;
      } else {
        var t = this.subtitle.split(' ').join('-')
        t = t.split('').join('-').toLowerCase()
        return t
      }
    },
    id() {
      var chars =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()_-+={}[]><.,?/~|",
        id = [];
      for (let index = 0; index < 5; index++) {
        var randomIndex = Number(Math.random() * chars.length).toFixed(0);
        id.push(chars[randomIndex]);
      }
      id = id.join("");

      return id;
    },
  }
};
</script>