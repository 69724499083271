import gql from 'graphql-tag'

export const PRESTATION_LIST = gql`
    query prestationList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!
    ) {
        prestationList(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                id,
                structure {
                    id,
                    name
                },
                name,
                nameType,
                schedule,
                recurrency,
                time,
                price
            },
            total
        }
    }
`
