import gql from 'graphql-tag'

export const GET_INTERVENTION_CHART_DATA_BY_SIGNALEMENT = gql`
    query interventionChartDataBySignalement(
        $intervenantId: String,
        $agencyId: String,
        $structureId: String
    ) {
        interventionChartDataBySignalement(
            intervenantId: $intervenantId,
            agencyId: $agencyId,
            structureId: $structureId
        )
    }
`
