import gql from 'graphql-tag'

export const TREATMENT_LIST = gql`
    query treatmentByEvaluationId(
        $evaluationId: Int!,
    ) {
        treatmentByEvaluationId(
            evaluationId: $evaluationId,
        ) {
            id,
            questionType,
            questionName,
            response
        }
    }
`;
