<template>
  <div class="wrapper wrapper--eval eval-alt">
    <header class="eval--header" ref="topNav">
      <div class="container-fluid">
        <nav-bar/>
      </div>
    </header>
    <main class="eval--main" :style="mainStyle">
      <div class="eval--content">
        <div class="container-fluid eval--title-wrap">
          <slot name="title">
            <h3 class="eval--title">{{title}}</h3>
          </slot>
          <div class="ml-auto">
              <nav class="nav eval--alt-nav">
                <a :href="`mailto:?${client && client.email ? client.email : ''}subject=Projet de vie`"
                   target="_blank"
                   class="nav-link">
                  <img src="@/assets/icon-plein-mail.svg" alt="">
                  <span>Envoyer</span>
                </a>
                <slot name="print">
                    <a href="#" class="nav-link" @click.prevent="printSynthesis">
                        <img src="@/assets/icon-plein-print.svg" alt="">
                        <span>Imprimer</span>
                    </a>
                </slot>
                  <a v-if="hasSignDevis" href="#" class="nav-link" @click.prevent="signDevis">
                    <img src="@/assets/icon-plein-contract.svg" alt="">
                    <span>Signer le devis</span>
                  </a>
                  <a v-if="hasSignDownload" href="#" class="nav-link" @click.prevent="downloadDevis">
                    <img src="@/assets/icon-plein-contract.svg" alt="">
                    <span>Télécharger le devis signé</span>
                  </a>
              </nav>
          </div>
        </div>
        <div class="eval--container">
          <div class="eval--slot">
            <slot />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import {mapState} from "vuex";
import EvalWrap from './EvalWrap'

export default {
  name: "EvalAltWrap",
  extends: EvalWrap,
  props: {
    title: {
      type: String,
      default: "Title",
    },
    hasSignDevis: {
      type: Boolean,
      default: false
    },
    hasSignDownload: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("Evaluation", ["evaluation"]),
    ...mapState("Client", ["client"]),
  },
  methods: {
    printSynthesis() {
      this.$emit("printIsPressed")
    },
    signDevis() {
      this.$emit("signDevis")
    },
    downloadDevis() {
      this.$emit("downloadDevis")
    },
    setMainStyle() {
      if (!this.$refs.topNav) {
        return;
      }
      this.mainStyle = {
        marginTop: `${(this.$refs.topNav.clientHeight + 50)}px`
      }
    }
  }
};
</script>
