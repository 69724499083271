<template>
  <custom-modal :active="active" @close="close">
    <div class="eval-modal-title">
      <div></div>
      <img src="@/assets/icon-plein-info.svg" alt />
      <h1>INFORMATIONS A CONSERVER</h1>
    </div>
    <div class="eval-risks-modal">
      <div class="eval-risks-content">
        <div class="eval-form--check">
          <div class="row">
            <div class="col-sm-12 col-md-12 d-flex align-items-center">
                <textarea class="form-control" v-model="info" rows="5"></textarea>
            </div>
            <small v-if="$v.info.$error" class="text-danger">Ce champ est obligatoire.</small>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 d-flex align-items-center">
              <div class="info-select-area">
                <span>Affectation: </span>
                <select class="ml-2" name id v-model="assignment">
                  <option value=""></option>
                  <option v-for="item in assignmentList" :key="item" :value="item">{{item}}</option>
                </select>
              </div>
            </div>
            <small v-if="$v.assignment.$error" class="text-danger">Ce champ est obligatoire.</small>
          </div>

          <div class="row mt-3"
               v-for="(info, index) in additionalInformation"
               :key="`additional-information-${index}`"
               @click.prevent="info.updating = true">
            <div class="col-sm-12 col-md-12 d-flex align-items-center">
              <textarea
                  class="form-control"
                  v-model="info.info"
                  rows="5"
                  readonly
                  disabled>
              </textarea>
            </div>
            <div class="col-sm-12 col-md-12 d-flex align-items-center">
              <div class="info-select-area">
                <span>Affectation: </span>
                <select class="ml-2" name id v-model="info.assignment" disabled>>
                  <option value=""></option>
                  <option v-for="item in assignmentList" :key="item" :value="item">{{item}}</option>
                </select>
              </div>
            </div>
            <div class="d-flex justify-content-center my-3">
              <button class="btn btn-danger" @click="deleteInfo(index)">Supprimer</button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center my-3">
          <button class="btn btn-primary" @click="validate">Valider</button>
        </div>
      </div>
    </div>
  </custom-modal>
</template>
<script>
import {requiredIf} from "vuelidate/lib/validators";

export default {
  name: "InfosModal",
  props: {
    active: {
      type: Boolean,
    },
    information: {
      type: Array,
      default: () => {}
    }
  },
  validations() {
    return {
      info: {
          required: requiredIf('isAddingNew')
      },
      assignment: {
        required: requiredIf('isAddingNew')
      }
    };
  },
  computed:{
    isAddingNew(){
        return this.info !== '' || this.assignment !== ''
    }
  },
  data() {
    return {
      additionalInformation: [],
      info: '',
      assignment: '',
      assignmentList: ['Aidants', 'Évaluateur', 'Responsable de secteur', 'Famille'],
    };
  },
  watch:{
    information(value) {
      this.additionalInformation = [];
      value.forEach((c) => {
        this.additionalInformation.push({
          ...c,
          updating: false
        })
      });
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    validate() {
      if(this.isAddingNew && this.$v.$invalid){
        this.$v.$touch();
        return;
      }
      if(this.info !== '' && this.assignment !== '') {
        let data = {info: this.info, assignment: this.assignment};
        this.additionalInformation.push(data);
      }
      this.$emit("validate", this.additionalInformation);
      this.info = '';
      this.assignment = '';
      this.additionalInformation.forEach((c) => {
        c.updating = false;
      });
      this.close();
    },
    deleteInfo(index){
      this.additionalInformation.splice(index, 1);
    }
  },
};
</script>