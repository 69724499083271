export default {
    setTypeAideLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setTypeAideList(state, typeAides) {
        state.typeAides = typeAides;
        state.loading = false;
        state.error = null;
    },
    setTotalTypeAideList(state, total) {
        state.total = total;
    },
    setTypeAideError(state, error) {
        state.loading = false;
        state.error = error;
    },
}