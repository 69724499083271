<template>
  <div class="click-to-read" @click="$emit('read', data.id)" :class="hasSeen ? 'hasSeen' : ''">
    <div class="col-md-12 alert-body" v-if="data.type==1">
      <b-alert variant="warning" show>
        <span class="bold click-to-go" @click="goToIntervenant(data.intervenant.id)">{{data.intervenantName}}</span> a passé plus de temps que prévu chez <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span>
      </b-alert>
      <span class="delete-alert" @click="$emit('remove', data.id)"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'trash']"/></span>
    </div>
    <div class="col-md-12 alert-body" v-if="data.type==4">
      <b-alert variant="warning" show>
        <span class="bold click-to-go" @click="goToIntervenant(data.intervenant.id)">{{data.intervenantName}}</span> a passé moins de temps que prévu chez <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span>
      </b-alert>
      <span class="delete-alert" @click="$emit('remove', data.id)"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'trash']"/></span>
    </div>

    <div class="col-md-12 alert-body" v-if="data.type==2">
      <b-alert variant="warning" show>
        <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span> a été signalé avec une dégradation de santé entre {{today}} et {{yesterday}}
        <span class="delete-alert" @click="$emit('remove', data.id)"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'trash']"/></span>
      </b-alert>
    </div>
    <div class="col-md-12 alert-body" v-if="data.type==3">
      <b-alert variant="danger" show>
        <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span> a été signalé avec une dégradation de santé 3 fois ces 7 derniers jours
      </b-alert>
      <span class="delete-alert" @click="$emit('remove', data.id)"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'trash']"/></span>
    </div>

    <div class="col-md-12 alert-body" v-if="data.type==5 || data.type==8">
      <b-alert :variant=" data.type==5 ? 'danger' : 'warning'" show>
        <span class="bold click-to-go" @click="goToIntervenant(data.intervenant.id)">{{data.intervenantName}}</span> a laissé un signalement au sujet de <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span>: "<span class="font-italic">{{data.additionalInfos}}</span>"
      </b-alert>
      <span class="delete-alert" @click="$emit('remove', data.id)"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'trash']"/></span>
    </div>

    <div class="col-md-12 alert-body" v-if="data.type==6">
      <b-alert variant="warning" show>
        <span class="bold click-to-go" @click="goToIntervenant(data.intervenant.id)">{{data.intervenantName}}</span> a signalé son impossibilité de badger pour son intervention chez <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span>: "<span class="font-italic">{{data.additionalInfos}}</span>"
      </b-alert>
      <span class="delete-alert" @click="$emit('remove', data.id)"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'trash']"/></span>
    </div>

    <div class="col-md-12 alert-body" v-if="data.type==7">
      <b-alert variant="warning" show>
        <span class="bold click-to-go" @click="goToIntervenant(data.intervenant.id)">{{data.intervenantName}}</span> a signalé l'absence de <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span> pour son intervention: "<span class="font-italic">{{data.additionalInfos}}</span>"
      </b-alert>
      <span class="delete-alert" @click="$emit('remove', data.id)"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'trash']"/></span>
    </div>
  </div>
</template>
<script>
import AlertItem from "@/components/BO/AlertItem";

export default {
  name: "BoAlertItemExt",
  extends: AlertItem,
  props: {
    hasSeen: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.hasSeen{
  opacity: 0.4;
}
.click-to-go,
.click-to-read{
  cursor: pointer;
}
.click-to-go:hover{
  text-decoration: underline;
}
.delete-alert{
  position: absolute;
  right: -35px;
  width: 0;
  height: 100%;
  padding: 0;
  background: red;
  opacity: 1;
  top: 0;
  color: white;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s ease;
}
.delete-alert:hover,
.alert-body:hover .delete-alert{
  width: 50px;
  padding: 12px;
}
</style>