<template>
  <div class="id--section">
    <div class="id--section-title">
      <h1>PRISE EN CHARGE</h1>
    </div>
    <div class="id--section-content">
      <div class="row">
        <div class="col-12" v-for="q in questions" :key="q.id">
          <div
            class="id-check--group"
            v-if="(q.type === 'radio' || q.type === 'checkbox') && toShow(q)"
          >
            <eval-form-check
              v-model="answers[q.id]"
              v-if="q.type === 'radio'"
              :title="q.title"
              :options="q.responses"
              @change="changeCheck(q.id)"
            />
            <eval-form-check-alt
              v-model="answers[q.id]"
              v-if="q.type === 'checkbox'"
              :subtitle="q.title"
              inputType="checkbox"
              :options="q.responses"
              @change="changeCheck(q.id)"
            />
          </div>
          <div
            v-if="(q.type === 'inputbox' || q.type === 'date') && toShow(q)"
            class="form-group"
          >
            <div v-if="q.type === 'inputbox'">
              <label for>{{ q.title }}</label>
              <input
                v-if="q.id === 43"
                type="number"
                class="form-control"
                name
                id
                v-model="texts[q.id]"
                max="6"
                min="0"
                @change="changeCheck(q.id)"
              />
              <input
                v-else-if="q.id === 44"
                type="number"
                class="form-control"
                name
                id
                v-model="texts[q.id]"
                max="100"
                min="0"
                @change="changeCheck(q.id)"
              />
              <input
                v-else-if="q.id === 39"
                type="text"
                maxlength="20"
                class="form-control"
                name
                id
                v-model="texts[q.id]"
                @change="changeCheck(q.id)"
              />
              <input
                v-else-if="q.id === 40"
                type="text"
                maxlength="20"
                class="form-control"
                name
                id
                v-model="texts[q.id]"
                @change="changeCheck(q.id)"
              />
              <input
                v-else
                type="text"
                class="form-control"
                name
                id
                v-model="texts[q.id]"
                @change="changeCheck(q.id)"
              />
              <p v-if="q.id === 43 && !isInteger" class="text-danger">
                Ce champs doit être un nombre.
              </p>
              <p v-if="q.id === 44 && !isNumberString" class="text-danger">
                Ce champs doit être au format xx,xx.
              </p>
            </div>
            <form-date-picker
              v-model="texts[q.id]"
              v-if="q.type === 'date'"
              :label="q.title"
              :login-input="true"
              @change="changeCheck(q.id)"
              :date-picker-options="dueDatePickerOptions(q.id)"
            />
          </div>

          <form-textarea
            v-model="texts[q.id]"
            v-if="q.type === 'text' && toShow(q)"
            :label="q.title"
            :rows="3"
            class="w-100"
            :login-input="false"
            :disabled="false"
            @blur="changeCheck(q.id)"
          />
        </div>
        <div class="col-12">
          <table
            class="table id--table"
            v-if="answers[45] && answers[45].length > 0"
          >
            <thead>
              <tr>
                <th>Mode</th>
                <th>Type d'heure</th>
                <th>Nombre d'heures/mois</th>
                <th>Taux horaire</th>
                <th>Montant du plan d'aide</th>
              </tr>
            </thead>
            <tbody v-for="q of tableQuestions" :key="q.id">
              <tr>
                <td rowspan="2">{{ q.name }}</td>
                <td>Heures semaine</td>
                <td>
                  <form-input
                    v-model="texts[q.questions[0].id]"
                    :rows="1"
                    type="number"
                    class="w-100"
                    :login-input="true"
                    :min="0"
                    :max="672"
                    :validation-function="minMaxValidation"
                    :disabled="false"
                    @blur="changeCheck(q.questions[0].id)"
                  />
                  <p
                    v-if="!isNumber(texts[q.questions[0].id])"
                    class="text-danger"
                  >
                    Ce champs doit être un nombre.
                  </p>
                </td>
                <td>
                  <form-input
                    v-model="texts[q.questions[1].id]"
                    :rows="1"
                    type="number"
                    class="w-100"
                    :login-input="true"
                    :disabled="
                      !isNumber(texts[q.questions[0].id]) ||
                      (isNumber(texts[q.questions[0].id]) &&
                        texts[q.questions[0].id] < 1)
                    "
                    @blur="changeCheck(q.questions[1].id)"
                  />
                  <p
                    v-if="!isNumber(texts[q.questions[1].id])"
                    class="text-danger"
                  >
                    Ce champs doit être un nombre.
                  </p>
                </td>
                <td>
                  {{
                    q.name === "Prestataire"
                      ? prestataireWeekAmount
                      : q.name === "Mandataire"
                      ? mandataireWeekAmount
                      : gagWeekAmount
                  }}
                </td>
              </tr>
              <tr>
                <td>Heures D/F</td>
                <td>
                  <form-input
                    v-model="texts[q.questions[3].id]"
                    :rows="1"
                    type="number"
                    class="w-100"
                    :min="0"
                    :max="192"
                    :validation-function="minMaxValidation"
                    :login-input="true"
                    :disabled="false"
                    @blur="changeCheck(q.questions[3].id)"
                  />
                  <p
                    v-if="!isNumber(texts[q.questions[3].id])"
                    class="text-danger"
                  >
                    Ce champs doit être un nombre.
                  </p>
                </td>
                <td>
                  <form-input
                    v-model="texts[q.questions[4].id]"
                    :rows="1"
                    type="number"
                    class="w-100"
                    :login-input="true"
                    :disabled="
                      !isNumber(texts[q.questions[3].id]) ||
                      (isNumber(texts[q.questions[3].id]) &&
                        texts[q.questions[3].id] < 1)
                    "
                    @blur="changeCheck(q.questions[4].id)"
                  />
                  <p
                    v-if="!isNumber(texts[q.questions[4].id])"
                    class="text-danger"
                  >
                    Ce champs doit être un nombre.
                  </p>
                </td>
                <td>
                  {{
                    q.name === "Prestataire"
                      ? prestataireDfAmount
                      : q.name === "Mandataire"
                      ? mandataireDfAmount
                      : gagDfAmount
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12">
          <form-textarea
            v-model="texts[64]"
            v-if="answers[37] !== 'Aucun'"
            label="Autres éléments inscrits au plan d'aide"
            :rows="3"
            class="w-100"
            :login-input="false"
            :disabled="false"
            @blur="changeCheck(64)"
          />
        </div>
        <div class="col-12">
          <form-textarea
            v-model="pecComment"
            label="Commentaires de l’évaluateur sur la prise en charge (préciser si le médecin traitant est informé de la démarche)"
            :rows="3"
            class="w-100"
            :login-input="false"
            :disabled="false"
            @blur="changeCommentaryValue()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EvalFormCheck from "@/components/Eval/FormCheck";
import FormDatePicker from "@/components/Form/DatePicker";
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";
import { mapActions, mapState, mapMutations } from "vuex";
import FormTextarea from "@/components/Form/Textarea.vue";

export default {
  components: {
    FormTextarea,
    EvalFormCheck,
    EvalFormCheckAlt,
    FormDatePicker,
  },
  data: () => ({
    texts: {},
    answers: {},
    pourcentParticipation: null,
    GRINumber: null,
    folderNumber: null,
    adresseOrganisationPec: null,
    datePecStart: null,
    datePecEnd: null,
    HelpInProgress: null,
    PECInProgress: null,
    tableItem: [
      {
        name: "Prestataire",
        questionsIds: [46, 47, 48, 49, 50, 51],
      },
      {
        name: "Mandataire",
        questionsIds: [52, 53, 54, 55, 56, 57],
      },
      {
        name: "Gré à gré",
        questionsIds: [58, 59, 60, 61, 62, 63],
      },
    ],
  }),
  computed: {
    ...mapState("Evaluation", ["evaluation", "generalQuestions"]),
    questions() {
      return this.generalQuestions.filter((e) => {
        return e.category === "PEC";
      });
    },
    tableQuestions() {
      const items = this.tableItem;
      const tableQuestions = [];
      for (let i = 0; i < items.length; i++) {
        const questions = this.generalQuestions.filter((e) =>
          items[i].questionsIds.includes(e.id)
        );
        if (!questions || questions.length === 0) {
          return [];
        }
        if (this.toShowTable(items[i].name)) {
          tableQuestions.push({
            ...items[i],
            questions: questions,
          });
        }
      }
      return tableQuestions;
    },
    isInteger() {
      if (!this.texts[43]) {
        return true;
      }
      if (/^\d+$/.test(this.texts[43])) {
        return true;
      }
      return false;
    },
    isNumberString() {
      if (!this.texts[44]) {
        return true;
      }
      if (/^\d{1,}(,|.)\d{2}/.test(this.texts[44])) {
        return true;
      }
      return false;
    },
    prestataireWeekAmount() {
      return this.texts[46] && this.texts[47]
        ? (parseFloat(this.texts[46]) * parseFloat(this.texts[47])).toFixed(2)
        : null;
    },
    mandataireWeekAmount() {
      return this.texts[52] && this.texts[53]
        ? (parseFloat(this.texts[52]) * parseFloat(this.texts[53])).toFixed(2)
        : null;
    },
    gagWeekAmount() {
      return this.texts[58] && this.texts[59]
        ? (parseFloat(this.texts[58]) * parseFloat(this.texts[59])).toFixed(2)
        : null;
    },
    prestataireDfAmount() {
      return this.texts[49] && this.texts[50]
        ? (parseFloat(this.texts[49]) * parseFloat(this.texts[50])).toFixed(2)
        : null;
    },
    mandataireDfAmount() {
      return this.texts[55] && this.texts[56]
        ? (parseFloat(this.texts[55]) * parseFloat(this.texts[56])).toFixed(2)
        : null;
    },
    gagDfAmount() {
      return this.texts[61] && this.texts[62]
        ? (parseFloat(this.texts[61]) * parseFloat(this.texts[62])).toFixed(2)
        : null;
    },
    pecComment: {
      get() {
        return this.evaluation.evaluatorComments
          ? this.evaluation.evaluatorComments.pecComment
          : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.pecComment = value;
      },
    },
  },
  async mounted() {
    await this.getEvaluation({ id: this.$route.params.evaluationId });
    const v = this.evaluation;
    if (v.generalQuestionsAnswers) {
      var answers = {};
      var texts = {};
      v.generalQuestionsAnswers.forEach((e) => {
        if (e.generalQuestion.type === "radio") {
          answers[e.generalQuestion.id] = e.answers
            ? e.generalQuestion.responses[e.answers[0]]
            : null;
        }
        if (e.generalQuestion.type === "checkbox") {
          let answer = [];
          if (e.answers) {
            e.answers.forEach((t) => {
              answer.push(e.generalQuestion.responses[t]);
            });
          }
          answers[e.generalQuestion.id] = answer;
        }
        texts[e.generalQuestion.id] = e.text;
        if (e.generalQuestion.type === "date") {
          texts[e.generalQuestion.id] = e.text ? new Date(e.text) : null;
        }
        if (e.generalQuestion.type === "text") {
          answers[e.generalQuestion.id] = e.text ? e.text : null;
        }
      });
      this.answers = answers;
      this.texts = texts;
    }
  },
  methods: {
    ...mapActions("Evaluation", [
      "getEvaluation",
      "putEvaluation",
      "getGeneralQuestions",
      "postGeneralQuestionAnswer",
    ]),
    ...mapMutations("Evaluation", [
      "setGeneralAnswerSuccess",
      "setEvaluationProperty",
    ]),
    dueDatePickerOptions(questionId) {
      if (questionId === 42) {
        const selectedMinDate = this.texts[41];
        return {
          disabledDate(selectedDate) {
            if (!selectedMinDate) {
              return false;
            }
            return selectedDate.getTime() < selectedMinDate.getTime();
          },
        };
      }
      if (questionId === 41) {
        const selectedMaxDate = this.texts[42];
        return {
          disabledDate(selectedDate) {
            if (!selectedMaxDate) {
              return false;
            }
            return selectedDate.getTime() > selectedMaxDate.getTime();
          },
        };
      }
      return {
        disabledDate() {
          return false;
        },
      };
    },
    changeCheck(questionId) {
      if (questionId === 43 && !this.isInteger) {
        return;
      }
      if (questionId === 43 && !this.isNumberString) {
        return;
      }

      if (questionId === 43) {
        this.texts[43] = parseInt(this.texts[43]);
      }
      if (questionId === 44) {
        this.texts[44] = parseFloat(this.texts[44]).toFixed(2);
      }

      let question = this.questions.find((e) => e.id === questionId);
      let generalAnswer = this.evaluation.generalQuestionsAnswers.find(
        (e) => e.generalQuestion.id === questionId
      );

      if (!question) return;
      let answers = null;
      if (question.type === "radio") {
        answers = [question.responses.indexOf(this.answers[questionId])];
      }
      if (question.type === "checkbox") {
        answers = [];
        this.answers[questionId].forEach((e) => {
          answers.push(question.responses.indexOf(e));
        });
      }
      const text =
        question.type === "inputbox" ||
        question.type === "date" ||
        question.type === "text"
          ? this.texts[questionId]
          : "";

      this.setGeneralAnswerSuccess({
        id: generalAnswer ? generalAnswer.id : 0,
        questionId: questionId,
        answers: answers,
        text: text,
      });
      this.postGeneralQuestionAnswer().then(() => {
        this.checkChangeOnTable(questionId);
      });
    },
    checkChangeOnTable(questionId) {
      if (questionId === 46 || questionId === 47) {
        const amount = this.prestataireWeekAmount;
        if (amount) {
          this.texts[48] = amount;
          this.changeCheck(48);
        }
        return;
      }

      if (questionId === 52 || questionId === 53) {
        const amount = this.mandataireWeekAmount;
        if (amount) {
          this.texts[54] = amount;
          this.changeCheck(54);
        }
        return;
      }

      if (questionId === 58 || questionId === 59) {
        const amount = this.gagWeekAmount;
        if (amount) {
          this.texts[60] = amount;
          this.changeCheck(60);
        }
        return;
      }
      if (questionId === 49 || questionId === 50) {
        const amount = this.prestataireDfAmount;
        if (amount) {
          this.texts[51] = amount;
          this.changeCheck(51);
        }
        return;
      }
      if (questionId === 55 || questionId === 56) {
        const amount = this.mandataireDfAmount;
        if (amount) {
          this.texts[57] = amount;
          this.changeCheck(57);
        }
        return;
      }
      if (questionId === 61 || questionId === 62) {
        const amount = this.gagDfAmount;
        if (amount) {
          this.texts[63] = amount;
          this.changeCheck(63);
        }
      }
    },
    toShow(question) {
      if (question.id === 37) {
        return true;
      }
      if (question.id === 38) {
        return this.answers[37] === "Aucun";
      }
      if (question.id === 66) {
        return (
          this.answers[37] === "Aucun" &&
          this.answers[38] &&
          this.answers[38].length > 0
        );
      }
      if (question.id >= 39 && question.id <= 45) {
        return this.answers[37] !== "Aucun" && this.answers[38] !== "Aucune";
      }
    },
    toShowTable(question) {
      const cond = this.answers[45];
      if (!cond) return false;
      if (question === "Prestataire") {
        if (cond.includes("Prestataire")) return true;
      } else if (question === "Mandataire") {
        if (cond.includes("Mandataire")) return true;
      } else if (question === "Gré à gré") {
        if (cond.includes("Gré à gré")) return true;
      }
    },
    isInt(val) {
      if (!val) {
        return true;
      }
      if (/^\d+$/.test(val)) {
        return true;
      }
      return false;
    },
    isNumber(val) {
      if (!val) {
        return true;
      }
      if (/^\d*\.?\d+$/.test(val)) {
        return true;
      }
      return false;
    },
    changeCommentaryValue() {
      this.putEvaluation();
    },
    minMaxValidation(value, min, max) {
      if (value > max) {
        return max;
      } else if (value < min) {
        return min;
      } else {
        return value;
      }
    },
  },
};
</script>
