<template>
  <div class="soin-item">
    <div class="soin-item--title" @click.prevent="toggleActive">
      <h1>Fonctions d'élimination</h1>
      <button class="btn" :class="{'inverted': !active}">
        <img src="@/assets/chevron-up.svg" alt />
      </button>
    </div>
    <div class="soin-item--content">
      <eval-card-check
          :showContentSlot="true"
          :checked="sfe01 !== null && sfe01 !== ''"
          title="La personne, est-elle stomisée ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sfe01" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <template v-if="sfe01 === 'r1'">
        <eval-card-check
            :showContentSlot="true"
            :checked="sfe02 !== null && sfe02.length > 0"
            title="Si oui, de quel(s) type(s) de stomie(s) s'agit-il ?"
            v-if="active">
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[]">
                  <template slot="content">
                    <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label:'Urostomie'},
                      {value:'r2', label:'Iléostomie'},
                      {value:'r3', label:'Colostomie'},
                      {value:'r4', label:'NSP'}]"
                         :key="index">
                      <input class="id-check--input"
                             :id="`sfe02-option-${index}`"
                             type="checkbox"
                             :value="option.value"
                             v-model="sfe02"/>
                      <label :for="`sfe02-option-${index}`">
                        <span class="id-check--indicator">{{option.label}}</span>
                      </label>
                    </div>
                  </template>
                </eval-form-check-alt>
              </div>
            </div>
          </template>
        </eval-card-check>
        <eval-card-check
          :showContentSlot="true"
          :checked="sfe05 !== null && sfe05 !== ''"
          title="La personne est-elle porteuse d'une sonde vésicale à demeure ou d'un étui pénien ?"
          v-if="sfe02 == 'r1' && active">
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="sfe05" name id>
                    <option value="r1">Non</option>
                    <option value="r2">Sonde vésicale</option>
                    <option value="r3">Étui pénien</option>
                    <option value="r4">NSP</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>
      
        <eval-card-check
            :showContentSlot="true"
            :checked="sfe07 !== null && sfe07 !== ''"
            title="Eprouvez-vous habituellement des difficultés pour uriner ?"
            v-if="sfe02 == 'r1' && active && (sfe05 == 'r2' || sfe05 == 'r3')">
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="sfe07" name id>
                    <option value="r1">Difficulté a se retenir</option>
                    <option value="r2">Incontinence</option>
                    <option value="r3">Brulures à la miction</option>
                    <option value="r4">Aucun de ces signes</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>
        <eval-card-check
            :showContentSlot="true"
            :checked="sfe03 !== null && sfe03.length > 0"
            title="Qui gèrent les changements de poche ?"
            v-if="active">
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[]">
                  <template slot="content">
                    <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label:'La personne elle-même'},
                      {value:'r2', label:'Sa famille'},
                      {value:'r3', label:'Un SAAD ou un aidant professionnel'},
                      {value:'r4', label:'Une infirmière libérale ou un Service de soins'}]"
                         :key="index">
                      <input class="id-check--input"
                             :id="`sfe03-option-${index}`"
                             type="checkbox"
                             :value="option.value"
                             v-model="sfe03"/>
                      <label :for="`sfe03-option-${index}`">
                        <span class="id-check--indicator">{{option.label}}</span>
                      </label>
                    </div>
                  </template>
                </eval-form-check-alt>
              </div>
            </div>
          </template>
        </eval-card-check>
        <eval-card-check
            :showContentSlot="true"
            :checked="sfe03b !== null && sfe03b.length > 0"
            title="Qui gèrent les changements de support de la stomie ?"
            v-if="active">
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[]">
                  <template slot="content">
                    <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label:'La personne elle-même'},
                      {value:'r2', label:'Sa famille'},
                      {value:'r3', label:'Un SAAD ou un aidant professionnel'},
                      {value:'r4', label:'Une infirmière libérale ou un Service de soins'}]"
                         :key="index">
                      <input class="id-check--input"
                             :id="`sfe03b-option-${index}`"
                             type="checkbox"
                             :value="option.value"
                             v-model="sfe03b"/>
                      <label :for="`sfe03b-option-${index}`">
                        <span class="id-check--indicator">{{option.label}}</span>
                      </label>
                    </div>
                  </template>
                </eval-form-check-alt>
              </div>
            </div>
          </template>
        </eval-card-check>
      </template>
      <eval-card-check
          :showContentSlot="true"
          :checked="sfe04 !== null && sfe04.length > 0"
          title="Ressentez-vous des troubles du transit intestinal ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[]">
                <template slot="content">
                  <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label:'Constipation'},
                      {value:'r2', label:'Gaz, flatulences'},
                      {value:'r3', label:'Diarrhées'},
                      {value:'r4', label:'Maux d\'estomac'},
                      {value:'r5', label:'Parfois'},
                      {value:'r6', label:'Souvent'},
                      {value:'r7', label:'Pas de troubles'}]"
                       :key="index">
                    <input class="id-check--input"
                           :id="`sfe04-option-${index}`"
                           type="checkbox"
                           :value="option.value"
                           v-model="sfe04"/>
                    <label :for="`sfe04-option-${index}`">
                      <span class="id-check--indicator">{{option.label}}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="true"
          :checked="sfe06 !== null && sfe06.length > 0"
          title="Qui gèrent les changements du collecteur d'urine ?"
          v-if="sfe05 === 'r1' && active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[]">
                <template slot="content">
                  <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label:'La personne elle-même'},
                      {value:'r2', label:'Sa famille'},
                      {value:'r3', label:'Une infirmière libérale ou un Service de soins'}]"
                       :key="index">
                    <input class="id-check--input"
                           :id="`sfe06-option-${index}`"
                           type="checkbox"
                           :value="option.value"
                           v-model="sfe06"/>
                    <label :for="`sfe06-option-${index}`">
                      <span class="id-check--indicator">{{option.label}}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="true"
          :checked="sfe08 !== null && sfe08.length > 0"
          title="Port de protection"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[]">
                <template slot="content">
                  <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label:'Aucune'},
                      {value:'r2', label:'Protection anatomique'},
                      {value:'r3', label:'Diarrhées'},
                      {value:'r4', label:'Change complet'},
                      {value:'r5', label:'La journée'},
                      {value:'r6', label:'La nuit'}]"
                       :key="index">
                    <input class="id-check--input"
                           :id="`sfe08-option-${index}`"
                           type="checkbox"
                           :value="option.value"
                           v-model="sfe08"/>
                    <label :for="`sfe08-option-${index}`">
                      <span class="id-check--indicator">{{option.label}}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>
    </div>
  </div>
</template>
<script>
  import SubQuestionExtend from "./SubQuestionExtend";
  import {mapActions, mapMutations, mapState} from "vuex";
  import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";

  export default {
    extends: SubQuestionExtend,
    components:{
      EvalFormCheckAlt
    },
    methods:{
      ...mapMutations('Evaluation', ['setTreatmentProperty']),
      ...mapActions('Evaluation', ['saveTreatment'])
    },
    computed : {
      ...mapState('Evaluation', ['elimination']),
      sfe01 : {
        get() {
          return this.elimination.sfe01.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfe01',
            value
          });
          this.saveTreatment({id: "elimination", name: "sfe01", evaluationId: this.$route.params.evaluationId});
        }
      },
      sfe02: {
        get() {
          return this.elimination.sfe02.response ? this.elimination.sfe02.response.split(',') : [];
        },
        set(value) {
          const prevValue = this.elimination.sfe02.response;
          this.setTreatmentProperty({
            name: 'sfe02',
            value: value.join(',')
          });
          if (value.join(',')!==prevValue) {
            this.saveTreatment({id: "elimination", name: "sfe02", evaluationId: this.$route.params.evaluationId});
          }
        }
      },
      sfe03: {
        get() {
          return this.elimination.sfe03.response ? this.elimination.sfe03.response.split(',') : [];
        },
        set(value) {
          const prevValue = this.elimination.sfe03.response;
          this.setTreatmentProperty({
            name: 'sfe03',
            value: value.join(',')
          });
          if (value.join(',')!==prevValue) {
            this.saveTreatment({id: "elimination", name: "sfe03", evaluationId: this.$route.params.evaluationId});
          }
        }
      },
      sfe03b: {
        get() {
          return this.elimination.sfe03b.response ? this.elimination.sfe03b.response.split(',') : [];
        },
        set(value) {
          const prevValue = this.elimination.sfe03b.response;
          this.setTreatmentProperty({
            name: 'sfe03b',
            value: value.join(',')
          });
          if (value.join(',')!==prevValue) {
            this.saveTreatment({id: "elimination", name: "sfe03b", evaluationId: this.$route.params.evaluationId});
          }
        }
      },
      sfe04: {
        get() {
          return this.elimination.sfe04.response ? this.elimination.sfe04.response.split(',') : [];
        },
        set(value) {
          const prevValue = this.elimination.sfe04.response;
          this.setTreatmentProperty({
            name: 'sfe04',
            value: value.join(',')
          });
          if (value.join(',')!==prevValue) {
            this.saveTreatment({id: "elimination", name: "sfe04", evaluationId: this.$route.params.evaluationId});
          }
        }
      },
      sfe05: {
        get() {
          return this.elimination.sfe05.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfe05',
            value
          });
          this.saveTreatment({id: "elimination", name: "sfe05", evaluationId: this.$route.params.evaluationId});
        }
      },
      sfe06: {
        get() {
          return this.elimination.sfe06.response ? this.elimination.sfe06.response.split(',') : [];
        },
        set(value) {
          const prevValue = this.elimination.sfe06.response;
          this.setTreatmentProperty({
            name: 'sfe06',
            value: value.join(',')
          });
          if (value.join(',')!==prevValue) {
            this.saveTreatment({id: "elimination", name: "sfe06", evaluationId: this.$route.params.evaluationId});
          }
        }
      },
      sfe07: {
        get() {
          return this.elimination.sfe07.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfe07',
            value
          });
          this.saveTreatment({
            id: "elimination",
            name: "sfe07",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      sfe08: {
        get() {
          return this.elimination.sfe08.response ? this.elimination.sfe08.response.split(',') : [];
        },
        set(value) {
          const prevValue = this.elimination.sfe08.response;
          this.setTreatmentProperty({
            name: 'sfe08',
            value: value.join(',')
          });
          if (value.join(',') !== prevValue) {
            this.saveTreatment({id: "elimination", name: "sfe08", evaluationId: this.$route.params.evaluationId});
          }
        }
      }
    }
  };
</script>