import {client as ApolloClient} from '@/api/Apollo';
import { MATERIAL_LIST } from '@/api/GraphQLQueries/bo/material/list-query'
import { GET_MATERIAL } from '@/api/GraphQLQueries/bo/material/get-material-query'
import { SAVE_MATERIAL } from '@/api/GraphQLQueries/bo/material/save-material-mutation'
import {postUpload} from '@/api/Fetch';
import {API_URL} from "@/components/Util/EnvVariable";
import {DELETE_MATERIAL} from "@/api/GraphQLQueries/bo/material/delete-material-mutation";

export const getMaterialList = ({ commit }, {
    filters = '{}',
    sortColumn = 'name',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setMaterialLoading');
    return ApolloClient.query({
        query: MATERIAL_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setMaterialList', res.data.materialList.items);
        commit('setTotalMaterialList', res.data.materialList.total);
    }).catch((err) => {
        console.error(err);
        commit('setMaterialError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getMaterial = ({ commit }, { id }) => {
    commit('setMaterialLoading');
    return ApolloClient.query({
        query: GET_MATERIAL,
        variables: {
            id: id
        }
    }).then((res) => {
        if (res.errors) {
            console.error(res.errors);
            commit('setMaterialError', res.errors ? res.errors : null);
            throw res.errors;
        }
        commit('setMaterialSuccess', res.data.material);
        return res.data;
    })
};

export const putMaterial = ({ commit, state }) => {
    commit('setMaterialSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_MATERIAL,
        variables: state.material
    }).then((res) => {
        commit('setPutMaterialSuccess', res.data.saveMaterial);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setMaterialError', err.response ? err.response.data : err);
        throw err;
    })
};

export const postMaterialPhoto = ({ commit, state }) => {
    commit('setPostMaterialPhoto');
    const data = state.material.photo;
    postUpload(`${API_URL}/material/photo/${state.material.id}/upload`, data)
        .then(response => {
            if (!response.ok) {
                if (response.status === 404 || response.status === 401) {
                    commit('setPostMaterialPhotoError', response);
                    throw response;
                }
            } else {
                commit('setPostMaterialPhotoSuccess');
            }
            return response;
        })
        .catch((err) => {
            console.error(err);
            commit('setPostMaterialPhotoError', err);
            throw err;
        });
};

export const postMaterialVideo = ({ commit, state }) => {
    commit('setPostMaterialVideo');
    const data = state.material.video;
    postUpload(`${API_URL}/material/video/${state.material.id}/upload`, data)
        .then(response => {
            if (!response.ok) {
                if (response.status === 404 || response.status === 401) {
                    commit('setPostMaterialVideoError', response);
                    throw response;
                }
            } else {
                commit('setPostMaterialVideoSuccess');
            }
            return response;
        })
        .catch((err) => {
            console.error(err);
            commit('setPostMaterialVideoError', err);
            throw err;
        });
};

export const postMaterial = ({ commit, state }) => {
    commit('setMaterialSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_MATERIAL,
        variables: state.material
    }).then((res) => {
        commit('setPostMaterialSuccess', res.data.saveMaterial);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setMaterialError', err.response ? err.response.data : err);
        throw err;
    })
};

export const deleteMaterial = ({ commit }, { id }) => {
    commit('setMaterialLoading');
    return ApolloClient.mutate({
        mutation: DELETE_MATERIAL,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setDeleteMaterialSuccess', res.data.deleteMaterial);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setMaterialError', err.response ? err.response.data : err);
        throw err;
    })
};

export default {
    getMaterialList,
    getMaterial,
    putMaterial,
    postMaterial,
    postMaterialPhoto,
    postMaterialVideo,
    deleteMaterial
};
