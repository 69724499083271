<template>
    <div class="bo--gridLight">
        <div v-if="label" class="row">
            <div class="col">
                <a href="#" @click.prevent="showTable">{{label}} <font-awesome-icon :icon="['fas', viewable ? 'chevron-up' : 'chevron-down']"/></a>
            </div>
        </div>
        <table :class="[loading ? 'loading' : '','list list--bo mt-1']" v-if="viewable">
            <thead>
            <tr>
                <th :style="tdStyle" v-for="(column, index) in columns" :key="`thead-${id}-${index}`">
                    <div class="overflow-visible">
                            <template>
                                <span class="cell-column-name">{{column.name}}</span>
                            </template>
                    </div>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, indexComponent) in dataList" :key="`tbody-tr-${id}-${indexComponent}`">
                <div class="cell position-relative">
                    <td :style="tdStyle" v-for="(column, index) in columns" :key="`tbody-td-${id}-${indexComponent}-${index}`">
                        <div class="cell-elements" @click.prevent="$emit('clickAssign')">
                            <template v-if="row[column.value]">
                                <a v-if="column.clickable && !disabled" href="#" @click.prevent="$emit('cliked', row)">{{row[column.value]}}</a>
                                <span v-else>{{row[column.value]}}</span>
                            </template>
                            <template v-else>
                                <span class="empty-dot"></span>
                            </template>
                        </div>
                        <div class="removeAction">
                          <font-awesome-icon
                              v-if="canDelete"
                              class="ic ic-danger ml-4 mr-1"
                              :icon="['fas', 'trash']"
                              @click.prevent="$emit('confirmRemove', row)"
                          />
                          <font-awesome-icon
                              v-if="canDownload"
                              class="ic ic-primary ml-4 mr-1"
                              :icon="['fas', 'download']"
                              @click.prevent="$emit('download', row)"
                          />
                        </div>
                    </td>
                    <td>
                    </td>
                </div>
            </tr>
            </tbody>
        </table>
        <div class="row">
            <div class="col text-center">
                <div v-if="assignable && viewable && !disabled">
                    <button class="btn btn-outline-secondary mt-1" @click="$emit('clickAssign')"> Affecter</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Grid",
        components:{},
        data() {
            return {
                viewable: false
            }
        },
        props: {
          id: String,
          label: String,
          columns: Array,
          dataList: Array,
          loading: Boolean,
          assignable: Boolean,
          canDelete: {
            type: Boolean,
            default: false
          },
          canDownload: {
            type: Boolean,
            default: false
          },
          disabled: {
            type: Boolean,
            default: false
          },
          viewTable: {
            type: Boolean,
            default: false
          }
        },
        mounted: function () {
            this.viewable = this.viewTable
        },
        computed:{
            tdStyle(){
                return "width: " + 100/this.columns.length + "%;max-width: 110px;min-width: 110px;"
            }
        },
        watch:{
            currentPage(value){
                this.localCurrentPage = value;
            }
        },
        methods: {
            showTable() {
                if (this.viewable) {
                    this.viewable = false;
                } else {
                    this.viewable = true;
                }
            }
        }
    };
</script>
<style>
  .removeAction {
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
</style>