import gql from 'graphql-tag'

export const SAVE_CARE_PLAN_PRESTATION = gql`
    mutation saveCarePlanPrestation(
        $id: Int,
        $evaluationId: Int!,
        $name: String!,
        $typeAide: [String!]!,
        $schedule: String!,
        $recurrency: [String!]!,
        $time: Int!,
        $observation: [String!],
    ) {
        saveCarePlanPrestation(
            id: $id,
            evaluationId: $evaluationId,
            name: $name,
            typeAide: $typeAide,
            schedule: $schedule,
            recurrency: $recurrency,
            time: $time,
            observation: $observation,
        ) {
            id,
            name,
            typeAide,
            schedule,
            recurrency,
            time,
            observation
        }
    }
`;
