import gql from 'graphql-tag'

export const ALERTS = gql`
    query alerts(
        $filters: [String!]!,
        $sortColumn: String!, 
        $sortDirection: String!, 
        $limit: Int!,
        $offset: Int!

    ) {
        alerts(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ){
            items {
                id,
                user{
                    id,
                },
                hasSeen,
                intervenant{
                    id
                },
                intervenantName,
                client{
                    id
                },
                clientName,
                type,
                createdAt,
                additionalInfos
            },
            total    
        }
    }
`
