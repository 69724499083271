import gql from 'graphql-tag'

export const DELETE_CLIENT = gql`
    mutation deleteClient(
        $id: Int!,
    ) {
        deleteClient(
            id: $id
        )
    }
`
