<template>
  <div class="bo-alert">
    <el-drawer
          :visible.sync="show"
          direction="rtl"
          :show-close="false"
          :withHeader="false"
          @close="$emit('close')">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="Non lues" name="unread">
          <div class="alert-group">
            <div class="pl-4 ml-1">Alertes majeures<span class="super-alert">({{getSuperAlertsNotSeen.length}})</span></div>
            <div class="alert-scroll-panel">
              <alert-item
                v-for="alert in getSuperAlertsNotSeen"
                :key="alert.id"
                :data="alert"
                :seen="false"
                :isSuper="true"
                @read="readAlert"
                @remove="remove"
                @close="$emit('close')" />
            </div>
          </div>
          <div class="alert-group">
            <div class="pl-4 ml-1">Alertes <span class="unsuper-alert">({{getAlertsNotSeen.length}})</span></div>
            <div class="alert-scroll-panel">
              <alert-item
                v-for="alert in getAlertsNotSeen"
                :key="alert.id"
                :data="alert"
                :seen="false"
                :isSuper="false"
                @read="readAlert"
                @remove="remove"
                @close="$emit('close')" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Lues" name="read">
          <div class="alert-group">
            <div class="pl-4 ml-1">Alertes majeures <span class="super-alert">({{getSuperAlertsSeen.length}})</span></div>
            <div class="alert-scroll-panel">
              <alert-item
                v-for="alert in getSuperAlertsSeen"
                :key="alert.id"
                :data="alert"
                :seen="true"
                :isSuper="true"
                @read="readAlert"
                @remove="remove"
                @close="$emit('close')" />
            </div>
          </div>
          <div class="alert-group">
            <div class="pl-4 ml-1">Alertes <span class="unsuper-alert">({{getAlertsSeen.length}})</span></div>
            <div class="alert-scroll-panel">
              <alert-item
                v-for="alert in getAlertsSeen"
                :key="alert.id"
                :data="alert"
                :seen="true"
                :isSuper="false"
                @read="readAlert"
                @remove="remove"
                @close="$emit('close')" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>
<script>
import AlertItem from './AlertItem';
import {mapGetters, mapActions} from "vuex";

export default {
    components: { AlertItem },
    name:"BoAlert",
    data() {
      return {
        show:false,
        activeTab: 'unread'
      };
    },
    props:{
        showAlert:{
            type:Boolean,
            default: false
        },
    },
    watch: {
      showAlert(v) {
        this.show = v;
      }
    },
    computed: {
    ...mapGetters('Alert', [
        'getSuperAlertsNotSeen',
        'getAlertsNotSeen',
        'getSuperAlertsSeen',
        'getAlertsSeen'
      ])
    },
    methods: {
      ...mapActions('Alert', ['putSeenAlert', 'deleteAlert']),
      readAlert(id) {
        this.putSeenAlert({id: id});
      },
      remove(id) {
        this.deleteAlert({id: id});
      },
    }
}
</script>
<style>
.el-tabs__item:focus.is-active.is-focus:not(:active){
  box-shadow: none;
}
</style>
