<template>
  <eval-wrap class="id-page" title="Prestation">
    <div class="container" @click="clickedOnPage($event)">
      <div class="header">
        <h1 v-if="!this.$route.params.carePlanPrestation">Remplissage de l'autonomie <br> et création de la prestation</h1>
        <h1 v-if="this.$route.params.carePlanPrestation">Modification de la prestation : {{carePlanPrestation.name}}</h1>
        <a href="#" @click.prevent="showPlanning()">Afficher le planning</a>
      </div>
      <div class="day-time">
        <span v-for="(period, index) in periodOptions" :key="index" :class="choosenPeriod === period.label ? 'highlight' : ''" @click="choosePeriod(period.label)">
          <i v-if="choosenPeriod === period.label" class="fa fa-check"></i>
          {{period.label}}
        </span>
      </div>
      <div class="informations">
        <div class="first-group">
          <img src="/img/icon-plein-info.116bf32c.svg" alt="">
          <b>GIR  {{evaluation.gir === 0 ? "--" : evaluation.gir}}</b>
          <small>Temps d'aide estimé/24 heures: <span>{{tempsEstime}}</span></small>
        </div>
        <div class="second-group">
          <small>Plan d'aide mensuel en cours: </small>
          <small>HS: <span>{{evaluation.heuresSemaine ? convertTime(evaluation.heuresSemaine) : convertTime(0)}}</span></small>
          <small>HD/F: <span>{{evaluation.heuresDimanche ? convertTime(evaluation.heuresDimanche) : convertTime(0)}}</span></small>
        </div>
      </div>
      <div class="aggirgrid">
        <table class="table aggirgrid-table">
          <tbody>
          <tr>
            <td></td>
            <td>
              <div class="aggirgrid-stch aggirgrid-stch--th">
                <div class="aggirgrid-stch--item">
                  <span>S</span>
                </div>
                <div class="aggirgrid-stch--item">
                  <span>T</span>
                </div>
                <div class="aggirgrid-stch--item">
                  <span>C</span>
                </div>
                <div class="aggirgrid-stch--item">
                  <span>H</span>
                </div>
              </div>
            </td>
            <td>RÉSULTAT</td>
            <td>TYPE D'AIDE</td>
            <td>COMMENTAIRES</td>
          </tr>
            <tr v-for="(item, index) in aggirgridItems"
                :key="index" :class="(selectedData[item.id] != 'Sans objet' || retrieveComment(item.id) != 'Écrire un commentaire...') ? 'was-active' : ''" >
              <td :class="[!item.resA && !item.resB ? 'not-set' : '']">
                <div class="aggirgrid--letter-name">
                  <div>{{item.name}}
                    <a @click="openInfoModal(item)">
                      <img src="@/assets/bouton-secondaire-question-mark.svg" alt />
                    </a>
                    <font-awesome-icon v-show="item.loading" :icon="['fas', 'spinner']" spin/>
                  </div>
                  <div>
                    <span v-if="!item.answerId"
                      class="aggirgrid--letter-active"
                      @click="makeQuestionActive(item.id)">A</span>
                  </div>
                </div>
              </td>
              <td :class="[!item.resA && !item.resB ? 'not-set' : '']">
                <GridAnswerStch
                    :id="item.id ? item.id: null"
                    @validateAnswer = "validateAnswer(item.id)">
                </GridAnswerStch>
              </td>

              <td :class="[!item.resA && !item.resB ? 'not-set' : '']">
                <div class="aggirgrid-stch">
                  <div class="aggirgrid-stch--item">
                    <template v-if="item.resA">
                      <span
                        class="aggirgrid--letter-item blank"
                        :class="{'yellow': item.resA==='B','red':item.resA==='C'}">{{item.resA}}</span>
                    </template>
                    <div v-else class="aggirgrid--blank">-</div>
                  </div>
                </div>
              </td>
              <td class="select-cell">
                <button @click="showPopup(item.id)" data-popup="no-hide">
                  <span data-popup="no-hide">{{selectedData[item.id]}}</span>
                  <i :class="focusItem === item.id ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-popup="no-hide"></i>
                </button>
                <div class="popup-box" data-blur="no-blur" data-popup="no-hide">
                  <ul class="popup" :id="focusItem === item.id ? 'show' : 'hide'" data-popup="no-hide">
                    <li v-for="(typeAide, i) in typeAides" :key="i" @click="saveSelectedAide(typeAide.name, item, genAide(item.name, i))" data-popup="no-hide"> 
                      <span class="aggirgrid--check-item" :class="typeAideSelected.includes(genAide(item.name, i)) ? 'blue' : ''" data-popup="no-hide"></span>
                      <span :class="typeAideSelected.includes(genAide(item.name, i)) ? 'selected' : ''" data-popup="no-hide">{{typeAide.name}}</span>
                    </li>
                  </ul>
                </div>
              </td>
              <td class="comment-cell">
                <button @click="showCommentModal(item)" class="comment-button">
                  <i class="fa fa-pencil"></i>
                  <span v-if="retrieveComment(item.id).length > 21">{{ retrieveComment(item.id).substring(0, 21) }}...</span>
                  <span v-else>{{ retrieveComment(item.id) }}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="prestion-button">
        <a href="#" @click.prevent="showPrestationPlanModal()">
          <i class="fa fa-plus"></i>
          <span>{{this.$route.params.carePlanPrestation ? 'Valider la planification' : 'Planifier une prestation'}}</span>
        </a>
      </div>
    </div>
    <planification-prestation-modal
          :active="modalPlanificationPrestation"
          :periode="choosenPeriod"
          :comments="commentaires" 
          :typeAide="selectedTypeAide"
          :gridItems="aggirgridItems"
          :carePlanPrestationId="carePlanPrestationId"
          :choosenDays="choosenDays"
          :estimTime="choosenTime"
          @prestationDays="calculTempsEstime"
          @close="closePrestationPlanModal" />

    <commentaire-modal
          :active="modalCommentaire"
          :questionAggir="questionComment"
          @close="modalCommentaire=false" />
    <save-prestation-confirm-modal
          :active="modalConfirm"
          :updated="isAnUpdate"
          @close="modalConfirm=false" />
    <custom-modal :active="aggirAnswerModal" @close="aggirAnswerModal=false" v-if="question">
      <div class="cuisine-modal">
        <h1 class="cuisine-modal--title">INFORMATION : {{question.item}} {{question.subItem ? '- ' + question.subItem : ''}}</h1>
        <p class="cuisine-modal--text">{{question.openingStatement}}</p>
        <div class="cuisine-modal--form">
          <div class="cuisine-modal--item">
            <div class="cuisine-modal--check-item">
              <input type="checkbox" id="custom-modal--check-1" v-model="answerStch.s" />
              <label for="custom-modal--check-1">
                <span>S</span>
                <p v-if="question.itemStch.s">{{question.itemStch.s.text}}</p>
              </label>
            </div>
          </div>
          <div class="cuisine-modal--item">
            <div class="cuisine-modal--check-item">
              <input type="checkbox" id="custom-modal--check-2" v-model="answerStch.t" />
              <label for="custom-modal--check-2">
                <span>T</span>
                <p v-if="question.itemStch.t">{{question.itemStch.t.text}}</p>
              </label>
            </div>
          </div>
          <div class="cuisine-modal--item">
            <div class="cuisine-modal--check-item">
              <input type="checkbox" id="custom-modal--check-3" v-model="answerStch.c" />
              <label for="custom-modal--check-3">
                <span>C</span>
                <p v-if="question.itemStch.c">{{question.itemStch.c.text}}</p>
              </label>
            </div>
          </div>
          <div class="cuisine-modal--item">
            <div class="cuisine-modal--check-item">
              <input type="checkbox" id="custom-modal--check-4" v-model="answerStch.h" />
              <label for="custom-modal--check-4">
                <span>H</span>
                <p v-if="question.itemStch.h">{{question.itemStch.h.text}}</p>
              </label>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button class="btn btn-primary" @click="validateModalAnswer">Valider</button>
        </div>
      </div>
    </custom-modal>
  </eval-wrap>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import GridAnswerStch from "@/components/Eval/GridAnswerStch";
import {getAnswerTotal, getClassicRuleScore} from "@/components/Util/UtilFunction";
import PlanificationPrestationModal from '@/components/Eval/PlanificationPrestationModal';
import CommentaireModal from '@/components/Eval/CommentaireModal';
import SavePrestationConfirmModal from '@/components/Eval/SavePrestationConfirmModal';
import {ORD_DAYS} from "@/components/Eval/CalculTempsEstime";

export default {
  name: "Autonomie",
  components: {
    GridAnswerStch,
    PlanificationPrestationModal,
    CommentaireModal,
    SavePrestationConfirmModal
  },
  data() {
    return {
      // data des types d'aide choisis par question agir
      selectedTypeAide: {},
      displayPopup: {},
      selectedData: {},
      periodOptions: [
        { id: 1, label: "lever" },
        { id: 2, label: "matin" },
        { id: 3, label: "midi" },
        { id: 4, label: "après-midi" },
        { id: 5, label: "soir" },
        { id: 6, label: "nuit" },
      ],
      // data de la période choisie
      choosenPeriod: '',
      focusItem: '',
      typeAideSelected: [],
      modalPlanificationPrestation: false,
      modalCommentaire: false,
      questionComment: null,
      modalConfirm: false,
      tempsEstime: '0',
      gridItemsListing: null,
      carePlanPrestationId: parseInt(this.$route.params.carePlanPrestation),
      choosenDays: null,
      choosenTime: null,
      isAnUpdate: false,
      prevDays: null,
      prevEstimTime: null,
      aggirAnswerModal: false,
      question: null,
      answerStch: {
        s: false,
        c: false,
        t: false,
        h: false
      },
      answer: null,
      inModal: false,
    };
  },
  mounted() {
    this.getAggirQuestionList({limit: 0});
    this.getEvaluation({limit: 0, id: this.$route.params.evaluationId});
    this.getCarePlanPrestationList({filters: '{"column" : "evaluationId", "val" : ' + this.$route.params.evaluationId + '}', limit: 5});
    this.getTypeAideList({limit: 0});
    if (this.$route.params.carePlanPrestation) {
      this.getCarePlanPrestation({id: this.$route.params.carePlanPrestation}).then(datas => {
        this.prevDays = typeof datas.carePlanPrestation.recurrency === "string" ? JSON.parse(datas.carePlanPrestation.recurrency) : JSON.parse(datas.carePlanPrestation.recurrency[0]);
        this.prevEstimTime = datas.carePlanPrestation.time;
      });
    } else {
      this.initCarePlanPrestation();
    }
  },
  computed: {
    ...mapState("AggirQuestion", ["aggirQuestions", "itemList"]),
    ...mapState("Evaluation", ["evaluation", "heuresSem", "heuresDim"]),
    ...mapState("CarePlanPrestation", ["carePlanPrestations", "commentaires", "carePlanPrestation"]),
    ...mapState("TypeAide", ["typeAides"]),
    aggirgridItems() {
      const gridItems = [];
      const checkedItems = [];
      const answerList = this.evaluation.answerList;

      for (let question of this.aggirQuestions) {
        const asAnswer = answerList.find(answer => answer.question.id === question.id);
        let scores = this.getQuestionScores(question);

        const item = {
          id: question.id,
          item: question.item,
          itemStch: JSON.parse(question.itemStch),
          openingStatement: question.openingStatement,
          name: question.subItem ? question.item + ' - ' + question.subItem : question.item,
          answer: asAnswer ? JSON.parse(asAnswer.answerStch) : null,
          answerId: asAnswer ? asAnswer.id : null,
          resA: scores ? scores.resA : null,
          resB: scores ? scores.resB : null,
          rowspan: !checkedItems.includes(question.item) && scores ? scores.total : 0,
          loading: false
        }
        gridItems.push(item);
        if (!checkedItems.includes(question.item)) {
          checkedItems.push(question.item)
        }
      }
      this.initDatas(gridItems);
      this.setGridItems(gridItems);
      if (this.$route.params.carePlanPrestation) {
        this.initPrestation();
      }
      this.calculTempsEstime();
      return gridItems;
    },
    items() {
      // rowspan loop
      return [];
    }
  },
  methods: {
    getClassicRuleScore,
    getAnswerTotal,
    ...mapActions('AggirQuestion', ['getAggirQuestionList']),
    ...mapActions('Evaluation', ['getEvaluation', 'postAggirAnswer', 'postEvaluation']),
    ...mapMutations('Evaluation', ['setEvaluationAnswerProperty', 'setHeureSem', 'setHeureDim']),
    ...mapMutations('CarePlanPrestation', ['initCarePlanPrestation']),
    ...mapActions('CarePlanPrestation', ['getCarePlanPrestationList', 'getCarePlanPrestation']),
    ...mapActions('TypeAide', ['getTypeAideList']),
    getQuestionScores(question) {
      let resA = null;
      let resB = null;
      let subItemCount = this.aggirQuestions.filter(q => q.item === question.item).length;
      subItemCount = subItemCount === 0 ? 1 : subItemCount;

      const answer = this.evaluation.answerList.find(answer => answer.question.id === question.id);

      if (!answer) {
        return {
            id: question.id,
            resA: resA,
            resB: resB,
            total: subItemCount
        }
      }

      return {
        id: question.id,
        resA: answer.subItemScore,
        resB: answer.itemScore,
        total: subItemCount
      }

    },
    getInterpretatedScore(subItemResult, item) {
      const concernedItem = this.itemList.find(el => el.value === item);
      if (!concernedItem) {
        return '';
      }

      if (concernedItem.interpretationRule) {
        return concernedItem.interpretationRule[subItemResult] ? concernedItem.interpretationRule[subItemResult] : concernedItem.interpretationRule['other'];
      }
      return '';
    },
    makeQuestionActive(questionId) {
      const relatedQuestion = this.aggirQuestions.find(el => el.id === questionId);
      if(!relatedQuestion) {
        return;
      }
      const concernedItem = this.itemList.find(el => el.value === relatedQuestion.item);
      if (!concernedItem) {
        return '';
      }

      const answerSend = {
        id: this.answerId,
        questionId: questionId,
        answerStch: JSON.stringify({
          s: false, t: false, c: false, h: false
        })
      }
      this.setEvaluationAnswerProperty(answerSend);
      return this.postAggirAnswer({
        data: {
          interpretationRule: concernedItem.interpretationRule ? JSON.stringify(concernedItem.interpretationRule) : ''
        }
      }).then(() => {
        this.$notify.success('réponse sauvegardée');
      }).catch((err) => {
        if(err === 'cancel token'){
          throw err;
        }
        console.error(err);
        this.$notify.error("sauvegarde échouée");
      });
    },
    validateAnswer(id) {
      const relatedQuestion = this.aggirQuestions.find(el => el.id === id);
      if(!relatedQuestion) {
        console.error('relatedQuestion, not found');
        return;
      }
      const concernedItem = this.itemList.find(el => el.value === relatedQuestion.item);
      if (!concernedItem) {
        console.error('concernedItem, not found');
      }

      let item = this.aggirgridItems.find(el => el.id === id);
      item.loading = true;
      this.postAggirAnswer({
        data: {
          interpretationRule: concernedItem && concernedItem.interpretationRule ? JSON.stringify(concernedItem.interpretationRule) : ''
        }
      }).then(() => {
        this.$notify.success('réponse sauvegardée');
        item.loading = false;
      }).catch((err) => {
        if(err === 'cancel token'){
          throw err;
        }
        console.error(err);
        this.$notify.error("sauvegarde échouée");
        item.loading = false;
      })
    },
    initDatas(gridItems) {
      if(Object.keys(this.displayPopup).length == 0 && Object.keys(this.selectedData).length == 0) {
        for(let gridItem of gridItems) {
          this.displayPopup[gridItem.id] = "hide";
          this.selectedData[gridItem.id] = "Sans objet";
        }
      }
    },
    showPopup(index) {
      this.displayPopup[index] = (this.displayPopup[index] === "show") ? "hide" : "show";
      this.focusItem = (this.displayPopup[index] === "show") ? index : '';
    },
    clickedOnPage($event) {
      let origin = $event.target;
      if(origin instanceof HTMLElement && !origin.hasAttribute('data-popup')) {
          for(let key of Object.keys(this.displayPopup)) {
            this.displayPopup[key] = "hide";
          }
          this.focusItem = '';
      }
    },
    saveSelectedAide(typeAide, item, aideData) {
      let choices = this.selectedTypeAide[item.id];
      if(choices) {
        if(choices.includes(typeAide)) {
          choices = choices.filter(elt => {
            return elt !==  typeAide;
          });
          this.typeAideSelected = this.typeAideSelected.filter(elt => {
            return elt !==  aideData;
          });
        }else if(typeAide ==='Sans objet' || typeAide ==='Autonome') {
          if(typeAide ==='Autonome' && choices.includes("Inciter / surveiller")) {
            choices = ['Autonome', 'Inciter / surveiller'];
            let otherItemChoices = [];
            for(let i=0; i<=6; i++) {
              otherItemChoices.push(this.genAide(item.name, i));
            }
            this.typeAideSelected = this.typeAideSelected.filter(elt => {
              return !otherItemChoices.includes(elt);
            });
            this.typeAideSelected.push(this.genAide(item.name, 0));
            this.typeAideSelected.push(this.genAide(item.name, 1));
          }else{
            choices = typeAide ==='Sans objet' ? [] : [typeAide];
            let otherItemChoices = [];
            for(let i=0; i<=6; i++) {
              otherItemChoices.push(this.genAide(item.name, i));
            }
            this.typeAideSelected = this.typeAideSelected.filter(elt => {
              return !otherItemChoices.includes(elt);
            });
            let indice = typeAide ==='Sans objet' ? 6 : 0;
            this.typeAideSelected.push(this.genAide(item.name, indice));
          }
        }else{
          if(choices.length < 3 && !choices.includes('Sans objet') && !choices.includes('Autonome') ) {
            choices.push(typeAide);
            this.typeAideSelected.push(aideData);
          }else if(choices.includes('Autonome') && typeAide==="Inciter / surveiller") {
            choices.push(typeAide);
            this.typeAideSelected.push(aideData);
          }
        }
      }else{
        choices = [typeAide];
        this.typeAideSelected.push(aideData);
      }
      if(choices.length > 0) {
        this.selectedTypeAide[item.id] = choices;

        // this.selectedData[item.id] = `Type d'aide[${this.selectedTypeAide[item.id].length}/3]`;
        this.selectedData[item.id] = choices;
      }else{
        delete this.selectedTypeAide[item.id];
        this.selectedData[item.id] = "Sans objet";
      }
    },
    choosePeriod(p) {
      this.choosenPeriod = p;
    },
    genAide(name, index) {
      return name + index;
    },
    showCommentModal(question) {
      this.questionComment = question;
      this.modalCommentaire = true;
    },
    closePrestationPlanModal(data) {
      if(data) {
        this.isAnUpdate = data.update;
        this.modalConfirm = data.showConfirm;
      }
      this.modalPlanificationPrestation = false;
    },
    showPrestationPlanModal() {
      if(this.choosenPeriod.length === 0) {
        this.$notify.error("Vous devez choisir une période");
      }else if(Object.keys(this.selectedTypeAide).length === 0) {
        this.$notify.error("Vous devez choisir au moins un type d'aide pour un acte aggir");
      }else {
        this.modalPlanificationPrestation = true
      }
    },
    showPlanning() {
      this.$router.push({name: "planning"});
    },
    calculTempsEstime(data = null) {
      //base en minutes
      const base = 230;
      let temps = 0;

      if(this.evaluation.gir == 1) {
        temps = base;
      }else if(this.evaluation.gir == 2) {
        temps = base * 84 / 100;
      }else if(this.evaluation.gir == 3) {
        temps = base * 66 / 100;
      }else if(this.evaluation.gir == 4) {
        temps = base * 42 / 100;
      }else if(this.evaluation.gir == 5) {
        temps = base * 25 / 100;
      }else if(this.evaluation.gir == 6) {
        temps = base * 7 / 100;
      }else{
        temps = 0;
      } 

      this.tempsEstime = this.convertTime(temps);
      if(data && data.datas && data.datas.showConfirm) {
        let hs = Math.ceil(data.tempsEstim * data.workDays * 2.166);
        let hd = Math.ceil(data.tempsEstim * data.weekends * 2.166);
        if(this.prevDays) {
          let prevWorkDays = 0;
          let prevWeekends = 0;
          Object.keys(this.prevDays).map(week => {
            this.prevDays[week].map(day => {
              prevWorkDays = ORD_DAYS.includes(day) ? prevWorkDays+1 : prevWorkDays;
              prevWeekends = day == 'dimanche' ? prevWeekends +1 : prevWeekends;
            });
          });
          let prevHs = Math.ceil(this.prevEstimTime * prevWorkDays * 2.166);
          let prevHd = Math.ceil(this.prevEstimTime * prevWeekends * 2.166);
          hs = hs - prevHs;
          hd = hd - prevHd;
        }
        this.setHeureDim(hd);
        this.setHeureSem(hs);
      }
    },
    convertTime(n) {
      let hours = (n / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + "h" + rminutes + "min";
    },
    initPrestation() {
      if(this.carePlanPrestation.id !== '' && this.carePlanPrestation.id !== null) {
        this.choosenPeriod = this.carePlanPrestation.schedule;
        this.choosenDays = typeof this.carePlanPrestation.recurrency === "string" ? JSON.parse(this.carePlanPrestation.recurrency) : JSON.parse(this.carePlanPrestation.recurrency[0]);

        if(Object.keys(this.selectedTypeAide).length === 0) {
          let carePlanTypeAide = JSON.parse(this.carePlanPrestation.typeAide);
          this.selectedTypeAide = carePlanTypeAide;

          Object.keys(carePlanTypeAide).map(itemId => {
            let item = this.gridItemsList.filter(elt => {
              return elt.id == itemId;
            });
            item = item[0];
            carePlanTypeAide[itemId].map(typeAide => {
              let typeAideObject = this.typeAides.filter(elem => {
                return elem.name == typeAide;
              })
              let indice = this.typeAides.indexOf(typeAideObject[0]);
              if (item) {
                this.typeAideSelected.push(this.genAide(item.name, indice));
              }
            });
            if (item) {
              this.selectedData[item.id] = this.selectedTypeAide[itemId];
            }
          });
        }

        let comments = JSON.parse(this.carePlanPrestation.observation);
        if(Object.keys(comments).length > 0) {
          Object.keys(comments).map(itemId => {
            this.commentaires[itemId] = comments[itemId];
          });
        }

        this.choosenTime = ''+this.carePlanPrestation.time;
      }
    },
    setGridItems(grid) {
      this.gridItemsList = grid;
    },
    retrieveComment(questionAggirId) {
      return this.commentaires[questionAggirId] ?? 'Écrire un commentaire...';
    },
    openInfoModal(val) {
      this.question = val;
      const answer = this.evaluation.answerList.find((answer) => answer.question.id === val.id);
      this.answer = answer;
      this.answerStch =  answer ? JSON.parse(answer.answerStch) : {};
      this.aggirAnswerModal = true;
    },
    validateModalAnswer() {
      this.aggirAnswerModal = false;
      const answerSend = {
        id: this.answer ? this.answer.id : null,
        questionId: this.question.id,
        answerStch: JSON.stringify(this.answerStch)
      }
      this.setEvaluationAnswerProperty(answerSend);
      return this.validateAnswerCard(this.question.id);
    },
    validateAnswerCard(id) {
      return this.validateAnswer(id);
    }, 
  }
};
</script>