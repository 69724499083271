<template>
    <div class="container pt-4">
      <h5 class="eval-item--title">Autonomie</h5>
      <div class="eval-item--card cr--context">
        <div class="row">
          <div class="col-sm-12">
            <p>{{autonomyPreview}}</p>
            <p class="font-weight-bold">Groupe Iso-Ressources: {{evaluation.gir ? evaluation.gir : "--"}}</p>
          </div>
        </div>

        <!-- permit to show radar -->
        <CRExtended ref="crextended"/>

        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">Dans le cadre des activités quotidiennes de {{client.firstname ? client.firstname : DEFAULT_VALUE}} {{client.lastname ? client.lastname : DEFAULT_VALUE}} l'évaluation à reperé les besoins d'aide suivants :</p>
          </div>
        </div>
        <table class="table autonomyResults-table">
          <thead>
          <tr>
            <th></th>
            <th>Pas d'aide</th>
            <th>Aide partielle</th>
            <th>Aide totale</th>
          </tr>
          </thead>
          <tbody>
          <CrAutonomyResultEntry
              v-for="(result, index) in autonomyResultsList" :key="index"
              :result="result">
          </CrAutonomyResultEntry>
          </tbody>
        </table>
        <div class="row materiel-section">
          <table class="table intervention-table">
            <thead>
              <tr>
                <th>Matériel à prévoir</th>
                <th>Matériel existant</th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td>
                  <span v-for="(material, idx) in evaluation.materials" :key="idx">
                    <span v-if="material.required">{{material.material.name}}, </span>
                  </span>
                </td>
                <td>
                  <span v-for="(material, idx) in evaluation.materials" :key="idx">
                    <span v-if="material.existing">{{material.material.name}}, </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">Commentaires de l'évaluateur sur l'autonomie :</p>
            <form-textarea
                v-model="autonomyComment"
                :rows="3"
                class="w-100"
                :login-input="true"
                :disabled="false"
                @blur="changeCommentaryValue()" />
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import CRExtended from "@/views/Evaluation/CR/CRExtended";
import {formatCivility} from "@/components/Util/UtilFunction";
import {mapMutations, mapState} from "vuex";
import CRComponent from "@/views/Evaluation/CR/CRComponent";
import CrAutonomyResultEntry from "@/components/Eval/CrAutonomyResultEntry";

export default {
  name: "CR_Autonomy",
  extends: CRComponent,
  components:{
    CRExtended,
    CrAutonomyResultEntry
  },
  created() {
    this.DEFAULT_VALUE = 'non renseigné';
  },
  methods: {
    ...mapMutations('Evaluation', ['setEvaluationProperty']),
    formatCivility,
    exportSynthesis() {
      const autonomyTable = this.autonomyResultsList;
      const charts = this.$refs.crextended;
      let dataChart = {};
      if(charts) {
        dataChart = charts.exportChart();
      }

      const existingMaterials = this.evaluation.materials? this.evaluation.materials.filter(el => el.existing).map(m => m.material.name) : [];
      const providedMaterials = this.evaluation.materials? this.evaluation.materials.filter(el => el.required).map(m => m.material.name) : [] ;

      return {
        ...dataChart,
        avqResult: this.calculateCategoryAverage([3, 7, 11, 5, 1, 2, 16]).result,
        avqTotal: this.calculateCategoryAverage([3, 7, 11, 5, 1, 2, 16]).total,
        aivqResult: this.calculateCategoryAverage([23, 21, 15]).result,
        aivqTotal: this.calculateCategoryAverage([23, 21, 15]).total,
        homeActivitiesResult: this.calculateCategoryAverage([20, 19, 13, 10, 14]).result,
        homeActivitiesTotal: this.calculateCategoryAverage([20, 19, 13, 10, 14]).total,
        socialActivitiesResult: this.calculateCategoryAverage([17, 18]).result,
        socialActivitiesTotal: this.calculateCategoryAverage([17, 18]).total,
        autonomyComment: this.autonomyComment,
        autonomyTable: JSON.stringify(autonomyTable),
        gir: this.evaluation.gir,
        existingMaterials: existingMaterials.length > 0 ? existingMaterials.join(', ') : 'aucun',
        providedMaterials: providedMaterials.length > 0 ? providedMaterials.join(', ') : 'aucun'
      }
    },
    autonomyEntries(name, questionIds) {
      let existingMaterials = 'aucun';
      let providedMaterials = 'aucun';
      const answerList = [];

      let concernedMaterials = [];
      let finalMaterials = [];
      let alreadyAddedIds = [];

      for (let questionId of questionIds) {
        const answer = this.evaluation.answerList.find(ans => ans.question.id === questionId);
        if (answer) {
          answerList.push(answer);
        }

        if (this.aggirQuestions && this.acts && this.evaluation.materials) {
          const question = this.aggirQuestions.find(q => q.id === questionId);
          if (!question) {
            continue;
          }
          const questionActIds = question.acts.map(el => el.id);
          for (let actId of questionActIds) {
            const act = this.acts.find(el => el.id === actId);

            if (act) {
              concernedMaterials = [...concernedMaterials, ...act.materials.map(m => m.material.id)];
              for (let m of this.evaluation.materials) {
                if (concernedMaterials.includes(m.material.id) && !alreadyAddedIds.includes(m.material.id)) {
                  alreadyAddedIds.push(m.material.id);
                  finalMaterials.push(m);
                }
              }
            }
          }
        }
      }

      existingMaterials = this.evaluation.materials ? this.evaluation.materials.filter(el => el.existing).map(m => m.material.name) : [];
      providedMaterials = this.evaluation.materials ? this.evaluation.materials.filter(el => el.required).map(m => m.material.name) : [];

      let getAllAnswers = answerList.length === questionIds.length;
      const score = answerList.filter(e=>e.itemScore).map(e=>e.itemScore);
      return {
        name: name + (getAllAnswers ? '' : ' (' + this.DEFAULT_VALUE + ')'),
        score: score.length>0 ? score[0] : '',
        noHelp: getAllAnswers ? (!answerList.find(ans => ans.itemScore !== 'A') ? "oui" : "") : "",
        partialHelp: getAllAnswers ? (!answerList.find(ans => ans.itemScore !== 'B') ? "oui" : "") : "",
        totalHelp: getAllAnswers ? (!answerList.find(ans => ans.itemScore !== 'C') ? "oui" : "") : "",
        existingMaterials: existingMaterials.length > 0 ? existingMaterials.join(', ') : 'aucun',
        providedMaterials: providedMaterials.length > 0 ? providedMaterials.join(', ') : 'aucun'
      }
    },
  },
  computed: {
    ...mapState('Client', ['client']),
    ...mapState("AggirQuestion", ["aggirQuestions", "loading"]),
    // ...mapState("Act", ["acts", "loading"]),
    ...mapState("Evaluation", ["evaluation"]),
    autonomyPreview() {
      const avqResult = this.calculateCategoryAverage([3, 7, 11, 5, 1, 2, 16]);
      const aivqResult = this.calculateCategoryAverage([23, 21, 15]);
      const homeActivitiesResult = this.calculateCategoryAverage([20, 19, 13, 10, 14]);
      const socialActivitiesResult = this.calculateCategoryAverage([17, 18]);

      return `${this.getPeopleStr} a un profil d'autonomie ${avqResult.result} ${avqResult.total} pour les activités corporelles et ${aivqResult.result} ${aivqResult.total} pour les activités mentales . Pour ce qui concerne les activités domestiques le profil d'autonomie est ${homeActivitiesResult.result} ${homeActivitiesResult.total}, et ${socialActivitiesResult.result} ${socialActivitiesResult.total} pour les activités sociales.`;
    },
    autonomyResultsList() {
      const list = [];
      list.push(this.autonomyEntries("Effectuer les transferts", [1]));
      list.push(this.autonomyEntries("Se déplacer à l'intérieur", [2]));
      list.push(this.autonomyEntries("Faire la toilette", [3, 4]));
      list.push(this.autonomyEntries("Assurer l'hygiène de son élimination", [5, 6]));
      list.push(this.autonomyEntries("S'habiller et se déshabiller", [7, 8, 9]));
      list.push(this.autonomyEntries("Se servir et manger ", [11, 12]));
      list.push(this.autonomyEntries("Gestion des médicaments", [13]));
      list.push(this.autonomyEntries("Faire la cuisine", [10]));
      list.push(this.autonomyEntries("Faire le ménage", [14]));
      list.push(this.autonomyEntries("Alerter", [15]));
      list.push(this.autonomyEntries("Se déplacer à l'extérieur", [16]));
      list.push(this.autonomyEntries("Utiliser un moyen de transport", [17]));
      list.push(this.autonomyEntries("Activités de temps libre", [18]));
      list.push(this.autonomyEntries("Faire des achats", [19]));
      list.push(this.autonomyEntries("Gérer son budget", [20]));

      return list;
    },
    autonomyComment: {
      get() {
        return this.evaluation.evaluatorComments ? this.evaluation.evaluatorComments.autonomyComment : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.autonomyComment = value;
      }
    }
  }
};
</script>