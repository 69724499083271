import {AREA_MANAGER, INTERVENANT, OPERATION_MANAGER} from "@/components/Util/roleConstants";

export default {
    getUser(state){
        return state.user;
    },
    isUserRoleOperationManager(state) {
        return state.connectedUser.role === OPERATION_MANAGER;
    },
    isUserRoleAreaManager(state) {
        return state.connectedUser.role === AREA_MANAGER;
    },
    isUserRoleIntervenant(state) {
        return !state.connectedUser.role || state.connectedUser.role === INTERVENANT;
    },
    isUserRoleOperationManagerOrAreaManager(state) {
        return state.connectedUser.role === OPERATION_MANAGER || state.connectedUser.role === AREA_MANAGER;
    }
};