<template>
    <div class="card-wrap">
        <div class="d-flex my-2 align-items-center">
            <h6 slot="title" class="mb-0 card-title">{{title}}</h6>
        </div>
        <div class="row">
            <div class="col-5">
                <input :id="title + values[0]" type="radio" :value="values[0]"
                       @click="e => $emit('input', e.target.value)"
                       :disabled="disabled"
                />
                <label
                        :class="['input-radio', value === values[0]?'active':'']"
                        :for="title + values[0]">
                    {{label[0]}}
                </label>
            </div>
            <div class="col-5">
                <input :id="title + values[1]" type="radio" :value="values[1]"
                       @click="e => $emit('input', e.target.value)"
                       :disabled="disabled"
                    />
                <label
                        :class="['input-radio', value === values[1]?'active':'']"
                        :for="title + values[1]">
                    {{label[1]}}
                </label>
            </div>
        </div>
    </div>
</template>
<script>
    import App from "./Card";
    export default {
        name: "Radio",
        extends: App,
        props:{
            label: Array,
            values: Array,
            value: String,
            disabled: Boolean
        },
        methods: {}
    };
</script>
<style lang="scss" scoped>
    input[type="radio"]{
        display: none;
    }
    label.input-radio {
        padding-left: 30px;
        margin-top: 0.5rem;

        &:before {
            content: "";
            position: absolute;
            left: 20px;
            top: 2px;
            width: 19px;
            height: 19px;
            border-radius: 50%;
            margin-top: 0.5rem;
            border: solid 1px #4f5262;
            background-color: #ffffff;
        }

        &.active {
            &:before {
                border: solid 1px #888ca1;
                background-color: #888ca1;
            }
        }
    }
</style>