<template>
  <auth-wrap class="login">
    <div class="login--content">
      <h2 class="login--title-one">Bienvenue sur OBY</h2>
      <h3 class="login--title-two"> Reinitialisation de votre mot de passe</h3>
      <form class="login--form">
        <el-alert
            class="mb-3"
            :title="warning"
            type="warning alert"
            show-icon
            :closable="false">
        </el-alert>
        <div class="mt-auto">
          <div class="p-2 mt-1 custom-form--input active-value-label input--login">
            <form-input
                type="password"
                label="Nouveau mot de passe"
                v-model="password"
                :error="passwordError.passwordEmpty || passwordError.confirmPasswordError ? 'le mot de passe est obligatoire' : null"
                :login-input="true"
                :required="true"
                @input="clearPasswordError"
            />
          </div>
          <div class="p-2 mt-1 custom-form--input active-value-label input--login">
            <form-input
                type="password"
                label="Confirmez nouveau mot de passe"
                v-model="confirmPassword"
                :error="passwordError.confirmPasswordEmpty || passwordError.confirmPasswordError ? 'les 2 mots de passe ne sont pas égaux' : null"
                :login-input="true"
                :required="true"
                @input="clearConfirmPasswordError"
            />
          </div>
        </div>
        <div class="mt-auto d-flex justify-content-center">
          <button class="btn btn-primary btn-lg"
                  style="min-width: 187px;"
                  @click.prevent="submitPassword()"
                  :disabled="loading">
            <font-awesome-icon v-show="loading" :icon="['fas', 'spinner']" spin/> Modifier mon mot de passe </button>
        </div>
      </form>
    </div>
  </auth-wrap>
</template>
<script>
import {mapActions, mapState} from "vuex";
export default {
  name: "ResetPassword",
  data() {
    return {
      warning: 'Saisissez votre nouveau mot de passe',
      password: '',
      confirmPassword: '',
      passwordError: {
        passwordEmpty: false,
        confirmPasswordEmpty: false,
        confirmPasswordError: false,
      },
      loading: false
    };
  },
  computed: {
    ...mapState('User', ['user','passwordSaveLoading']),
    userNotEmpty() {
      return this.password !== null && this.password !== "";
    },
    disabled() {
      return !this.userNotEmpty || this.loading;
    },
  },
  mounted: async function () {
    this.getUserWithForgottenPassword({id: this.$route.params.id})
  },
  methods: {
    ...mapActions('User', ['editUserPassword', 'getUserWithForgottenPassword']),
      clearPasswordError() {
        this.passwordError.passwordEmpty = false
      },
      clearConfirmPasswordError() {
        this.passwordError.confirmPasswordError = false
        this.passwordError.confirmPasswordEmpty = false
      },
      submitPassword() {
        if (this.password === '') {
          this.passwordError.passwordEmpty = true
        }
        if (this.confirmPassword === '') {
          this.passwordError.confirmPasswordEmpty = true
        }
        if (this.password !== this.confirmPassword) {
          this.passwordError.confirmPasswordError = true
        }
        if (this.passwordError.passwordEmpty || this.passwordError.confirmPasswordEmpty || this.passwordError.confirmPasswordError) {
          return
        }

        this.editUserPassword({password: this.password}).then(
            this.password = '',
            this.confirmPassword = '',
            this.$notify.success("Le mot de passe a été modifié")
        )
        this.$router.push({name : 'login', params: {connectedUser : null }});
      },
  }
};
</script>