export default {
    prestation: {
        structure: {},
        structureId: '',
        name: '',
        nameType: '',
        schedule: '',
        recurrency: [],
        time: null,
        price: null,
        tva: null,
        description: ''
    },
    prestations: [],
    loading: false,
    error: null,
    total: 0
}
