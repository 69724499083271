export default {
    material: {
        ref: '',
        name: '',
        description: '',
        status: false,
        photoUrl: '',
        videoUrl: ''
    },
    materials: [],
    loading: false,
    error: null,
    total: 0
}
