import gql from 'graphql-tag'

export const GET_TARIFICATION = gql`
    query tarification(
        $id: Int!,
    ) {
        tarification(
            id: $id
        ) {
            id,
            structure {
                id,
                name
            },
            prestation {
                id,
                name
            },
            tarifType,
            price
        }
    }
`
