import gql from 'graphql-tag'

export const AGENCY_LIST = gql`
    query agencyList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!
    ) {
        agencyList(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                id,
                apologicCode,
                companyName,
                legalForm,
                implementationName,
                address,
                status,
                postalCode,
                city,
                phoneNumber,
                createdAt,
                createdBy{
                    id,
                    firstName,
                    lastName
                },
                updatedAt,
                updatedBy{
                    id,
                    firstName,
                    lastName
                },
                manager{
                    id,
                    firstName,
                    lastName
                },
                structure{
                    id,
                    name
                },
            },
            total
        }
    }
`
