<template>
  <div class="planning-card justify-content-center"
       @drag="drag"
       @click="emitClick"
       @dragstart="dragstart">
    <span class="pi--tag">{{info.name}}</span>
  </div>
</template>
<script>
import {dateFormat} from "@/components/Util/UtilFunction";

export default {
  name: "PlanningItem",
  data() {
    return {
      listEntourage: ["Conjoint", "Famille", "Voisin", "Usager"],
      listAAD: ["Infirmier", "Emploi direct", "Autre SAAD"],
    }
  },
  props: {
    info: {},
    day: null,
    place:null,
    week: null,
    hour: null,
  },
  methods: {
    drag() {},
    dragstart() {
      this.$emit("dragstart", {id: this.info, day: this.day, week: this.week, hour: this.hour});
    },
    formatedDate(val) {
      return dateFormat(val, true)
    },
    emitClick(){
      this.$emit('click')
    },
    sumModalActTime() {
      let sum = 0;
      this.info.forEach((act) => {
        sum += act.validatedTimeIntFormat
      })
      return sum
    }
  },
};
</script>