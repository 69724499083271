import gql from 'graphql-tag'

export const SAVE_TARIFICATION = gql`
    mutation saveTarification(
        $id: Int!,
        $structureId: Int!,
        $prestationId: Int!,
        $tarifType: String!,
        $price: Float!,
    ) {
        saveTarification(
            id: $id,
            structureId: $structureId,
            prestationId: $prestationId,
            tarifType: $tarifType,
            price: $price,
        ) {
            id,
            structure {
                id,
                name
            },
            prestation {
                id,
                name
            },
            tarifType,
            price
        }
    }
`
