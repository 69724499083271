<template>
    <div class="pl-2 pr-2 document-card">
        <img class="rounded cursor-pointer" width="250" height="150" :src="url" @click="$emit('preview', url)">
        <span class="type">{{type}}: </span>
        <span class="description">{{description}}</span>
    </div>
</template>
<script>
    export default {
        name: "DocumentCard",
        data() {
            return {}
        },
        props: {
            url: String,
            type: String,
            description: String
        }
    };
</script>