import gql from 'graphql-tag'

export const DELETE_STRUCTURE = gql`
    mutation deleteStructure(
        $id: Int!,
    ) {
        deleteStructure(
            id: $id
        )
    }
`
