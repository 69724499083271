<template>
  <div class="bo--container">
    <template>
      <div class="container">
        <loader :loader="loading"/>
        <div>
          <bo-card :title="isEdit ? '&lt; Montrer l\'intervention' : '&lt; Créer l\'intervention'">
            <template slot="title">
              <h1 class="bo-card--title">
                <a href="#" @click.prevent="cancel">&lt; </a> Intervention
                <span class="font-weight-light" style="font-size: 14px;font-style: italic;"> réalisé par </span>{{!loading ? intervenant : '--'}}
                <span class="font-weight-light" style="font-size: 14px;font-style: italic;"> pour l'usager </span> {{!loading ? client : '--'}}
              </h1>
              <el-tag :type="this.intervention.status === 2 ? 'success' : 'info'">
                {{status}}
              </el-tag>
              <el-tag :type="hasNoBadge ? 'danger' : 'success'">
                {{hasNoBadge ? 'Problème de badge' : 'Badge ok'}}
              </el-tag>
            </template>


            <div class="row">
              <div class="col-sm-12 col-md-4">
                <form-input v-model="dateStart"
                          label="Date d'intervention :"
                          :login-input="true"
                          :disabled="true"/>
              </div>
              <div class="col-sm-12 col-md-4">
                <form-input v-model="client"
                          label="Client :"
                          :login-input="true"
                          :disabled="true"/>
              </div>
              <div class="col-sm-12 col-md-4">
                <form-input v-model="intervenant"
                          label="Salarié :"
                          :login-input="true"
                          :disabled="true"/>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-3">
                <form-input v-model="dateStartHour"
                          label="Heure d'entrée prévue :"
                          :login-input="true"
                          :disabled="true"/>
              </div>
              <div class="col-sm-12 col-md-3">
                <form-input v-model="dateEndHour"
                          label="Heure de sortie prévue :"
                          :login-input="true"
                          :disabled="true"/>
              </div>
              <div class="col-sm-12 col-md-3">
                  <form-input v-model="diffStartEndPlanned"
                          label="Temps d’intervention prévue :"
                          :login-input="true"
                          :disabled="true"/>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-3">
                <form-input v-model="openingHour"
                            label="Heure d'entrée réelle :"
                            :login-input="true"
                            :disabled="true"/>
              </div>
              <div class="col-sm-12 col-md-3">
                <form-input v-model="sendHour"
                            label="Heure de sortie réelle :"
                            :login-input="true"
                            :disabled="true"/>
              </div>
              <div class="col-sm-12 col-md-3">
                <form-input v-model="sendHourDate"
                            label="Date de clôture :"
                            :login-input="true"
                            :disabled="true"/>
              </div>
              <div class="col-sm-12 col-md-3">
                <form-input v-model="diffStartEndReal"
                            label="Temps d’intervention réel :"
                            :login-input="true"
                            :disabled="true"/>
              </div>
            </div>
            
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <form-textarea v-model="familyComments"
                             label="Informations complémentaires pour la famille"
                             :disabled="true"
                             :rows="3"
                             :login-input="true">
                </form-textarea>
              </div>
              <div class="col-sm-12 col-md-8">
                <form-textarea v-model="hasNoBadgeDescription"
                             label="Description suite au problème de badgage"
                             :disabled="true"
                             :rows="3"
                             :login-input="true">
                </form-textarea>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12 col-sm-12 col-lg-12">
                <GridLight
                    label="Liste des signalements"
                    :columns="signalementColumn"
                    :dataList="signalementsList"
                />
              </div>
            </div>
            <template slot="footer">
              <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
            </template>
          </bo-card>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

import {mapState, mapActions, mapMutations, mapGetters} from "vuex";
import GridLight from "@/components/Grid/GridLight";
import moment from 'moment';

export default {
  name: "BOInterventionForm",
  components: {
    GridLight
  },
  data() {
    return {
      statusLabel: ['Créé', 'Commencé', 'Fini'],
      bo: {
        active: true
      },
      signalementColumn: [
        {name: "Type", value: 'type'},
        {name: "Sous Categorie", value: 'sousCategorie'},
        {name: "Description", value: 'description'}
      ],
    };
  },
  computed: {
    ...mapState('Intervention', ['intervention', 'error', 'loading']),
    ...mapState('User', ['connectedUser']),
    ...mapGetters('User', ['isUserRoleOperationManager']),
    createdDate:{
      get(){
        return this.intervention.createdAt ? moment(this.intervention.createdAt).format('DD/MM/YYYY') : '';
      },
      set(){}
    },
    client:{
      get(){
        return this.intervention.client ? this.intervention.client.firstname + ' ' + this.intervention.client.lastname : '';
      },
      set(){}
    },
    intervenant:{
      get(){
        return this.intervention.intervenant ? this.intervention.intervenant.firstName + ' ' + this.intervention.intervenant.lastName : '';
      },
      set(){ }
    },
    dateStart:{
      get(){
        return this.intervention.dateStart ? moment(this.intervention.dateStart).utcOffset('+0000').format('DD/MM/YYYY') : '';
      },
      set(){ }
    },
    dateStartHour:{
      get(){
        return this.intervention.dateStart ? moment(this.intervention.dateStart).utcOffset('+0000').format('HH:mm') : '';
      },
      set(){ }
    },
    openingDate:{
      get(){
        return this.intervention.openingDate ? moment(this.intervention.openingDate).utcOffset('+0000').format('DD/MM/YYYY HH:mm') : '';
      },
      set(){ }
    },
    openingHour:{
      get(){
        return this.intervention.openingDate ? moment(this.intervention.openingDate).utcOffset('+0000').format('HH:mm') : '';
      },
      set(){ }
    },
    dateEnd:{
      get(){
        return this.intervention.dateEnd ? moment(this.intervention.dateEnd).utcOffset('+0000').format('DD/MM/YYYY HH:mm') : '';
      },
      set(){ }
    },
    dateEndHour:{
      get(){
        return this.intervention.dateEnd ? moment(this.intervention.dateEnd).utcOffset('+0000').format('HH:mm') : '';
      },
      set(){ }
    },
    sendHourDate:{
      get(){
        return this.intervention.sendHour ? moment(this.intervention.sendHour).utcOffset('+0000').format('DD/MM/YYYY') : '';
      },
      set(){ }
    },
    sendHour:{
      get(){
        return this.intervention.sendHour ? moment(this.intervention.sendHour).utcOffset('+0000').format('HH:mm') : '';
      },
      set(){ }
    },
    diffStartEndReal:{
      get(){
        if(!this.intervention.openingDate || !this.intervention.sendHour){
          return null;
        }
        let difHours = moment(this.intervention.sendHour).diff(moment(this.intervention.openingDate), 'hours');
        let difMinutes = moment(this.intervention.sendHour).diff(moment(this.intervention.openingDate), 'minutes');
        return `${difHours} heure(s) ${difMinutes - (difHours*60)} minutes`;
      },
      set(){}
    },
    diffStartEndPlanned:{
      get(){
        if(!this.intervention.dateEnd || !this.intervention.dateStart){
          return null;
        }
        let difHours = moment(this.intervention.dateEnd).diff(moment(this.intervention.dateStart), 'hours');
        let difMinutes = moment(this.intervention.dateEnd).diff(moment(this.intervention.dateStart), 'minutes');
        return `${difHours} heure(s) ${difMinutes - (difHours*60)} minutes`;
      },
      set(){}
    },
    familyComments:{
      get(){
        return this.intervention.familyComments;
      },
      set(){ }
    },
    hasNoBadge:{
      get(){
        return this.intervention.hasNoBadge;
      },
      set(){ }
    },
    hasNoBadgeDescription:{
      get(){
        return this.intervention.hasNoBadgeDescription;
      },
      set(){ }
    },
    status:{
      get(){
        return this.statusLabel[this.intervention.status];
      },
      set(){ }
    },
    isEdit() {
      return (this.$route.params.id !== undefined && this.$route.params.id !== null)
    },
    signalementsList() {
      return this.intervention.signalements ? this.intervention.signalements.map((item) => {

        const sousCat = JSON.parse(item.signalement.actionList).find(element => element.id === item.subSignalementId);

        return {
          type: item.signalement ? item.signalement.type : '',
          sousCategorie: item.signalement? sousCat.name:'',
          description: item.description ? item.description : '',
        }
      }) : [];
    },
  },
  mounted: async function() {
    this.setInitIntervention();
    if (this.isEdit) {
      await this.getIntervention({ id: this.$route.params.id })
      .catch((err) => {
        if (err) {
          this.$notify.error('Impossible d\'accéder à cette intervention')
          this.$router.push({name: 'intervention-list'});
        }
      })
    }
  },
  methods: {
    ...mapMutations('Intervention',['setInterventionProperty', 'setInitIntervention']),
    ...mapActions('Intervention', ['getIntervention']),
    backToList(){
      this.setInitIntervention();
      this.saveLoading = false;
      this.$notify.success(this.isEdit ? 'Le intervention a été modifiée' : 'Le intervention a été créée')
      this.$router.push({name: 'intervention-list'});
    },
    cancel() {
      return this.$router.push({name:'intervention-list'});
    }
  }
};
</script>
