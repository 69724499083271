import gql from 'graphql-tag'

export const GET_CONTACT = gql`
    query contact(
        $id: Int!,
    ) {
        contact(
            id: $id
        ) {
            id,
            lastName,
            firstName,
            phone,
            address,
            city,
            postalCode
        }
    }
`
