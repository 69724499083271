<template>
  <div class="wrapper wrapper--eval">
    <header class="eval--header" ref="topNav">
      <div class="container-fluid">
        <nav-bar/>
      </div>
      <div class="container-fluid eval--menu-wrap">
        <nav class="nav eval--nav row" ref="pdv--nav">
          <span class="nav-select" :style="style"></span>
          <router-link
              class="nav-link"
              :class="{'active': routeName === 'identity' }"
              :to="{name:'identity'}">Identité</router-link>
          <router-link
              class="nav-link"
              :class="{'active': routeName === 'autonomy' }"
              :to="{name:'autonomy'}">Autonomie</router-link>
          <router-link
              class="nav-link"
              :class="{'active': routeName === 'soin' }"
              :to="{name:'soin'}">Santé</router-link>
          <router-link
              class="nav-link"
              :class="{'active': routeName === 'project-life' }"
              :to="{name:'project-life'}">Projet d'intervention</router-link>
        </nav>
      </div>
      <div class="container-fluid eval--submenu-wrap" id="title-wrap">
        <slot name="title">
        </slot>
      </div>
    </header>
    <main class="eval--main" :style="mainStyle">
      <div class="eval--content">
        <div class="eval--container">
          <div class="eval--nav" id="eval-navbar">
          </div>
          <div class="eval--slot">
            <slot />
          </div>
        </div>
      </div>
      <div class="eval--right-bar">
        <nav class="nav nav--right-bar">
          <a href="#" class="nav-link" @click.prevent="modalInfos = true">
            <img src="@/assets/icon-plein-info.svg" alt />
          </a>
          <a href="#" class="nav-link" @click.prevent="showRisksModal">
            <img src="@/assets/icon-plein-risks.svg" alt />
          </a>
          <a href="#" class="nav-link" @click.prevent="openModalAutonomie">
            <img src="@/assets/warning.png" alt class="notifWarning" v-if="displayNotification" />
            <img src="@/assets/icon-plein-ajout.svg" alt />
          </a>
          <a href="#" class="nav-link" @click.prevent="modalContacts = true">
            <img src="@/assets/icon-plein-contacts.svg" alt />
          </a>

        </nav>
      </div>

      <infos-modal
          :active="modalInfos"
          :information="additionalInformation"
          @close="modalInfos=false"
          @validate="validateInfos" />

      <contacts-modal
          :active="modalContacts"
          @close="modalContacts=false"
          @remove="removeClientContact"
          @update="updateClientContact" />

      <risks-modal
          :active="modalRisks"
          :risks="evaluation.risks"
          @close="modalRisks=false"
          @add="assignRisk"
          @validate="validateRisks" />

      <autonomie-modal
          :active="modalAutonomie"
          @close="modalAutonomie=false" />
    </main>
  </div>
</template>
<script>

import ContactsModal from '@/components/Eval/ContactsModal';
import AutonomieModal from '@/components/Eval/AutonomieModal';
import RisksModal from '@/components/Eval/RisksModal';
import InfosModal from '@/components/Eval/InfosModal';
import NavBar from '@/layouts/NavBar';

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "EvalWrap",
  components: {
    AutonomieModal,
    RisksModal,
    ContactsModal,
    InfosModal,
    NavBar
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
  },
  data() {
    return {
      modalContacts: false,
      modalRisks: false,
      modalAutonomie: false,
      modalInfos: false,
      mainStyle: {}
    };
  },
  computed: {
    ...mapState("Client", ["client", "contact"]),
    ...mapState("Evaluation", ["evaluation"]),
    ...mapGetters('Evaluation', ['calculateTime']),
    routeName() {
      return this.$route.name;
    },
    additionalInformation(){
      return this.evaluation.additionalInfos && this.evaluation.additionalInfos !== '' ? JSON.parse(this.evaluation.additionalInfos) : [];
    },
    displayNotification() {
      if(this.evaluation && this.evaluation.materials) {
        for(let link of this.evaluation.materials) {
          if(link.suggested && !link.existing && !link.required) {
            return true;
          }
        }
      }
      return false;
    },
    style() {
      var s
      if(this.routeName === 'identity'){
        s = 25
      } else if(this.routeName === 'autonomy'){
        s = 170
      }else if(this.routeName === 'soin'){
        s = 310
      }else if(this.routeName === 'project-life'){
        s = 480
      }
      return `transform:translateX(${s}px)`;
    }
  },

  methods: {
    ...mapActions("Client", ["postClientContact", "putClientContact", "deleteClientContact"]),
    ...mapActions("Evaluation", ["postAssignRisk", "putValidateRisks","getEvaluationMaterials", "postGeneralAdditionalInformation"]),
    ...mapMutations('Evaluation',['setRisk', 'setEvaluationProperty']),
    showRisksModal() {
      this.modalRisks = true
    },
    removeClientContact(contactId) {
      this.deleteClientContact({contactId: contactId}).then((res) => {
        this.$notify.success('Le contact a été supprimé');
        return res;
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        throw err;
      });
    },
    assignRisk(name) {
      this.setRisk({
        id: this.evaluation.id,
        name: name
      });
      this.postAssignRisk().then((res) => {
        this.$notify.success('Le risque a été enregistré');
        return res;
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        throw err;
      });
    },
    validateRisks(checkedRisks) {
      this.putValidateRisks({checklist: checkedRisks}).then((res) => {
        //this.$notify.success('Le risque a été enregistré');
        return res;
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        throw err;
      });
    },
    updateClientContact(contact) {
      this.putClientContact({contact: contact}).then((res) => {
        this.$notify.success('Le contact a été modifié');
        return res;
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        throw err;
      });
    },
    validateInfos(info) {
      this.setEvaluationProperty({
        name: "additionalInfos",
        value:  JSON.stringify(info)
      });
      this.postGeneralAdditionalInformation().then((res) => {
        this.$notify.success("L'information a été modifiée.");
        return res;
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        throw err;
      });
    },
    async openModalAutonomie() {
      await this.getEvaluationMaterials({id: this.$route.params.evaluationId});
      this.modalAutonomie = true;
    },
    setMainStyle() {
      if(!this.$refs.topNav) {return;}
      this.mainStyle = {
        marginTop: this.$refs.topNav.clientHeight + "px"
      }
    }
  },
  mounted() {
    this.setMainStyle();
    window.addEventListener('resize', this.setMainStyle);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setMainStyle);
  }
};
</script>
