import gql from 'graphql-tag'

export const SAVE_SYNTHESE_GOALS = gql`
    mutation saveSyntheseGoals(
        $evaluationId: Int!,
        $item: String!,
        $problem: String!,
        $goals: String!,
        $actions: String!,
        $affectation: String!,
        $active: Boolean!
    ) {
        saveSyntheseGoals(
            evaluationId: $evaluationId,
            item: $item,
            problem: $problem,
            goals: $goals,
            actions: $actions,
            affectation: $affectation,
            active: $active
        ) {
            id,
            item,
            problem,
            goals,
            actions,
            affectation,
            active
        }
    }
`;
