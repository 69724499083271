import gql from 'graphql-tag'

export const GET_PRESTATION = gql`
    query prestation(
        $id: Int!,
    ) {
        prestation(
            id: $id
        ) {
            id,
            tarificationList {
                id,
                structure {
                    name
                }
                label,
                tarifType,
                price
            }
            name,
            erpId,
            erpTypeId,
            schedule,
            recurrency,
            time,
            price,
            erpTypeId,
            nameType,
            tva,
            description
        }
    }
`
