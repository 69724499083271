export default {
    getInterventions(state){
        return state.interventions;
    },
    getInterventionsByIds: (state) => (ids) => {
        return state.interventions.reduce((interventions, intervention) => {
            if(ids.includes(intervention.id)){
                interventions.push(intervention)
            }
            return interventions;
        }, []);
    }
};