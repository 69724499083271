<template>
  <div class="pdv-card--item" v-if="isRightPeriod(acts, indexCheck)">
    <div class="d-flex">
      <div class="pdv-card--item-label">
        <img :src="getImg" alt/>
        <span>{{ title }}</span>
      </div>
    </div>

    <template v-for="act in acts">
      <div class="pdv-card--item-content row align-items-baseline" v-if="act.period === indexCheck" :key="act.id">
        <div class="col-md-12">
          <span class="lieu" style="font-size: 16px; font-weight: bold;">{{ act.act.actName }}</span>
        </div>

        <div class="eval-card--form select col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="pdv-card--item-tag">
                <span :class="act.caregiver.replace(' ', '').toLowerCase()">{{act.caregiver}}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group eval-form eval-form--select">
                <time-picker
                    @change="time => changeTime(act.id, time ? time : null)"
                    :value="act.validatedTimeIntFormat !== null  ? act.validatedTimeStringFormat : formattedDate(act.estimatedTime)"
                    :is-offre-time-picker="true"/>
              </div>
            </div>
          </div>
        </div>
        <div class="pdv-card--item-tag" v-if="tag">
          <a>{{ tag }}</a>
        </div>
      </div>
    </template>

  </div>
</template>
<style scoped>
.eval-form input {
  padding: 0 !important;
  text-align: center;
  height: 30px;
  font-size: 12px;
}
.eval-card--form {
  height: 30px;
}
.eval-card--form.select .form-control {
  min-width: 0 !important;
  height: auto;
}
</style>
<script>
import TimePicker from "@/components/Form/TimePicker";
import {mapGetters} from "vuex";
import {dateFormat} from "@/components/Util/UtilFunction";

export default {
  name: "PDVCardItem",
  components: {
    TimePicker,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    lieu: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: null,
    },
    estimatedTime: {
      type: String,
      default: null
    },
    acts: {
      type: Array,
      default: null
    },
    indexCheck: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters('Evaluation', ['getActEvaluationFromId']),
    getImg(){
      if(this.title === 'WC'){
        return require(`@/assets/pdv-bathroom.svg`);
      }
      if(this.title === 'Cuisine'){
        return require(`@/assets/pdv-cooking.svg`);
      }
      if(this.title === 'Salon'){
        return require(`@/assets/pdv-livingroom.svg`);
      }
      if(this.title === 'Extérieur'){
        return require(`@/assets/pdv-outside.svg`);
      }
      return require(`@/assets/pdv-bedroom.svg`);
    },
    validatedTime: {
      get() {
        return this.getActEvaluationFromId()
      }
    }
  },
  methods: {
    changeTime(id, time) {
      this.$emit('changeTime', {id: id, time: time})
    },
    isRightPeriod(acts, indexCheck) {
      for (let i = 0; acts.length > i; i++) {
        let act = acts[i]
        if (act.period === indexCheck) {
          return true;
        }
        var periodCheck = act.period === indexCheck
      }
      return periodCheck
    },
    formattedDate(val) {
      return dateFormat(val, false)
    }
  }
};
</script>