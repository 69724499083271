import gql from 'graphql-tag'

export const EDIT_USER_PASSWORD = gql`
    mutation editUserPassword(
          $id: Int!
          $password: String!,
    ) {
        editUserPassword(
          id: $id
          password: $password,  
        )
    }
`
