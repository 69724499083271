<template>
  <div class="eval-contact-form-card">
    <div class="ecc--form">
        <div class="ecc--form-item">
            <img src="@/assets/icon-filaire-user.svg" alt="">
            <input v-model="lastName" type="text" class="form-control form-control-sm" placeholder="Nom *">
            <input v-model="firstName" type="text" class="form-control form-control-sm" placeholder="Prénom">
        </div>
        <div v-if="!$v.lastName.required && $v.lastName.$error" class="text-left">
          <small class="text-danger">Le nom est obligatoire</small>
        </div>
        <div class="ecc--form-item pl-1">
            <input v-model="titre" type="text" class="form-control form-control-sm ml-4" placeholder="Titre">
        </div>
        <div class="ecc--form-item">
            <img src="@/assets/icon-filaire-home.svg" alt="">
            <input v-model="address" type="text" class="form-control form-control-sm" placeholder="Adresse">
        </div>
        <div class="ecc--form-item pl-1">
            <input v-model="postalCode" type="text" class="form-control form-control-sm ml-4" placeholder="Code postal">
            <input v-model="city" type="text" class="form-control form-control-sm ml-4" placeholder="Ville">
        </div>
        <div class="ecc--form-item">
            <img src="@/assets/icon-filaire-tel.svg" alt="">
            <input v-model="phone" type="text" class="form-control form-control-sm" placeholder="Numéro de téléphone *">
        </div>
      <div v-if="$v.phone && $v.phone.$error" class="text-left">
        <small  class="text-danger">Le numéro de téléphone est obligatoire</small>
      </div>
      <div class="ecc--form-item-referent">
        <label :for="`referent-${type}`" :class="[isReferent ? 'active' : '', 'btn mb-0']">Est référent</label>
        <input type="checkbox"
               :id="`referent-${type}`"
               name="Est référent"
               :value="true"
               v-model="isReferent"/>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from "vuex";
import {numeric, required} from "vuelidate/lib/validators";

export default {
  name: "EvalContactFormCard",
  props: {
    type: {
      type: String,
      default: "family",
    },
  },
  validations() {
    return {
      phone: {
        required,
        numeric
      },
      lastName: {
        required
      }
    };
  },
  computed: {
    ...mapState('Client', ['contact']),
    lastName:{
      get(){
        return this.contact[this.type].lastName;
      },
      set(value){
        this.setContactProperty({
          type: this.type,
          name: "lastName",
          value: value
        });
      }
    },
    firstName:{
      get(){
        return this.contact[this.type].firstName;
      },
      set(value){
        this.setContactProperty({
          type: this.type,
          name: "firstName",
          value: value
        });
      }
    },
    titre:{
      get(){
        return this.contact[this.type].titre;
      },
      set(value){
        this.setContactProperty({
          type: this.type,
          name: "titre",
          value: value
        });
      }
    },
    address:{
      get(){
        return this.contact[this.type].address;
      },
      set(value){
        this.setContactProperty({
          type: this.type,
          name: "address",
          value: value
        });
      }
    },
    city:{
      get(){
        return this.contact[this.type].city;
      },
      set(value){
        this.setContactProperty({
          type: this.type,
          name: "city",
          value: value
        });
      }
    },
    phone:{
      get(){
        return this.contact[this.type].phone;
      },
      set(value){
        this.setContactProperty({
          type: this.type,
          name: "phone",
          value: value
        });
      }
    },
    postalCode:{
      get(){
        return this.contact[this.type].postalCode;
      },
      set(value){
        this.setContactProperty({
          type: this.type,
          name: "postalCode",
          value: value
        });
      }
    },
    isReferent: {
      get(){
        return this.contact[this.type].isReferent;
      },
      set(value){
        const types = ['family','medical','other']
        if(value === true){
          types.splice(
              types.findIndex(element=> element === this.type),
              1
          )
          types.forEach((el)=>{
            this.setContactProperty({
              type: el,
              name: "isReferent",
              value: false
            });
          })
        }
        this.setContactProperty({
          type: this.type,
          name: "isReferent",
          value: value
        });
      }
    }
  },
  methods: {
    ...mapMutations('Client',['setContactProperty'])
  }
};
</script>