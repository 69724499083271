export const getFilterStructure = (state) => {
    let filter = [{label:"Tous", value:""}];
    state.structures.forEach((structure) => {
        filter.push({value:structure.id, label:structure.name});
    });
    return filter;
};

export default {
    getFilterStructure
};