import gql from 'graphql-tag'

export const DELETE_PRESTATION = gql`
    mutation deletePrestation(
        $id: Int!
    ) {
        deletePrestation(
            id: $id
        )
    }
`
