
import {client as ApolloClient} from '@/api/Apollo';

import { EVALUATION_LIST } from '@/api/GraphQLQueries/evaluation/evaluation/list-query'
import { GET_EVALUATION } from '@/api/GraphQLQueries/evaluation/evaluation/get-evaluation-query'
import { GET_EVALUATION_MATERIALS } from '@/api/GraphQLQueries/evaluation/evaluation/get-evaluation-materials-query'
import {
    DUPLICATE_EVALUATION,
    SAVE_EVALUATION
} from '@/api/GraphQLQueries/evaluation/evaluation/save-evaluation-mutation'
import { SAVE_EVALUATION_HAS_ACT } from '@/api/GraphQLQueries/evaluation/evaluation/save-evaluation-has-act-mutation'
import { DELETE_EVALUATION } from '@/api/GraphQLQueries/evaluation/evaluation/delete-evaluation-mutation'
import { GET_GENERAL_QUESTIONS } from '@/api/GraphQLQueries/evaluation/evaluation/get-general-questions-query'
import { SAVE_GENERAL_QUESTION_ANSWER } from '@/api/GraphQLQueries/evaluation/evaluation/save-general-question-answer-mutation'
import { SAVE_ADDITIONAL_INFORMATION } from '@/api/GraphQLQueries/evaluation/evaluation/save-additionnal_assignment'
import { DELETE_RISK } from '@/api/GraphQLQueries/evaluation/evaluation/delete-risk-mutation'
import { ASSIGN_HABIT } from '@/api/GraphQLQueries/evaluation/evaluation/assign-habit-mutation'
import { ASSIGN_RISK } from '@/api/GraphQLQueries/evaluation/evaluation/assign-risk-mutation'
import { VALIDATE_RISKS } from '@/api/GraphQLQueries/evaluation/evaluation/validate-risks-mutation'
import { SAVE_AGGIR_ANSWER } from '@/api/GraphQLQueries/evaluation/evaluation/save-aggir-answer-mutation'
import { SAVE_DEFAULT_AGGIR_ANSWERS } from '@/api/GraphQLQueries/evaluation/evaluation/save-default-aggir-answers-mutation'
import { SAVE_TREATMENT } from "@/api/GraphQLQueries/evaluation/treatment/save-mutation"
import { SAVE_CONTRACT } from "@/api/GraphQLQueries/evaluation/contract/save-mutation"
import { TREATMENT_LIST } from "@/api/GraphQLQueries/evaluation/treatment/list-query"
import { SAVE_HABIT_EVALUATION } from "@/api/GraphQLQueries/evaluation/evaluation/save-habit-evaluation-mutation";
import { SAVE_SEANCE_DETAILS } from "@/api/GraphQLQueries/evaluation/evaluation/save-seance-details-mutation";
import { APPLY_DAYS_ON_ACTS } from "@/api/GraphQLQueries/evaluation/evaluation/apply-date-on-acts-query";
import { SAVE_SYNTHESE_GOALS } from "@/api/GraphQLQueries/evaluation/syntheseGoals/save-mutation"
import { SYNTHESE_GOALS_LIST } from "@/api/GraphQLQueries/evaluation/syntheseGoals/list-query"
import {postUpload} from "@/api/Fetch";
import {API_URL} from "@/components/Util/EnvVariable";
import {DEVIS_STATUS} from "@/store/Modules/Evaluation/state";
import { SAVE_HEURES_SEMAINE_DIMANCHE } from "@/api/GraphQLQueries/evaluation/evaluation/save-heures-semaine-dimanche-mutation";
import {ASSIGN_MATERIALS} from "@/api/GraphQLQueries/evaluation/evaluation/assign-materials-mutation";

export const getEvaluationList =  ({ commit }, {
    filters = '{}',
    sortColumn = 'structure.name',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setEvaluationLoading');
    return ApolloClient.query({
        query: EVALUATION_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setEvaluationList', res.data.evaluationList.items);
        commit('setTotalEvaluationList', res.data.evaluationList.total);
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getEvaluation =  ({ commit, dispatch }, { id }) => {
    commit('setEvaluationLoading');
    return ApolloClient.query({
        query: GET_EVALUATION,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setEvaluationSuccess', res.data.evaluation);
        dispatch('Client/getClient', {id: res.data.evaluation.client.id}, {root: true});
        return res.data;
    }).catch((err) => {
        console.error(JSON.stringify(err));
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getEvaluationMaterials =  ({ commit }, { id }) => {
    commit('setEvaluationLoading');
    return ApolloClient.query({
        query: GET_EVALUATION_MATERIALS,
        variables: {
            evaluationId: id
        }
    }).then((res) => {
        commit('setEvaluationMaterialSuccess', res.data.evaluationMaterialList);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
    })
}
export const getGeneralQuestions =  ({ commit }) => {
    commit('setEvaluationLoading');
    return ApolloClient.query({
        query: GET_GENERAL_QUESTIONS
    }).then((res) => {
        commit('setGeneralQuestionsSuccess', res.data.generalQuestions);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const postEvaluation =  ({ commit, state }, { id }) => {
    commit('setEvaluationSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_EVALUATION,
        variables: {
            ...state.evaluation,
            evaluatorComments: JSON.stringify(state.evaluation.evaluatorComments),
            clientId: id
        }
    }).then((res) => {
        commit('setPostEvaluationSuccess', res.data.saveEvaluation);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const duplicateEvaluation =  ({ commit }, { id }) => {
    commit('setEvaluationSaveLoading');
    return ApolloClient.mutate({
        mutation: DUPLICATE_EVALUATION,
        variables: {
            id
        }
    }).then((res) => {
        commit('setPostEvaluationSuccess', res.data.duplicateEvaluation);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const postAssignMaterials =  ({ commit, state }) => {
    return ApolloClient.mutate({
        mutation: ASSIGN_MATERIALS,
        variables: {
            materials: state.evaluation.materials.concat(state.habits).reduce((tab, value) => {
                tab.push(JSON.stringify(value));
                return tab;
            }, []),
            evaluationId: state.evaluation.id
        }
    }).then((res) => {
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};
export const postAssignMaterial =  ({ commit }, {material}) => {
    commit('setUpdateMaterial', material);
};

export const postAssignHabit =  ({ commit, state }) => {
    commit('setEvaluationSaveLoading');

    return ApolloClient.mutate({
        mutation: ASSIGN_HABIT,
        variables: {
            id: state.evaluation.id,
            habits: state.evaluation.habits.concat(state.habits).reduce((tab, value) => {
                tab.push(JSON.stringify(value));
                return tab;
            }, [])
        }
    }).then((res) => {
        commit('setPostAssignHabitSuccess', res.data.assignHabit);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const postAssignRisk =  ({ commit, state/*, dispatch*/ }) => {
    const tempRiskId = new Date().getTime();
    commit('setPostAssignRiskLoading', {id: tempRiskId, checked: true, risk: {id: tempRiskId , name: state.risk.name}});
    return ApolloClient.mutate({
        mutation: ASSIGN_RISK,
        variables: state.risk
    }).then((res) => {
        commit('setPostAssignRiskSuccess', {tempRiskId: tempRiskId, risk: res.data.assignRisk});
        //dispatch('getEvaluation', {id: state.evaluation.id});
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const putValidateRisks =  ({ commit, state/*, dispatch*/ }, {checklist}) => {
    commit('setPutRisksLoading', checklist);
    return ApolloClient.mutate({
        mutation: VALIDATE_RISKS,
        variables: {
            evaluationId: state.evaluation.id,
            checklist: checklist
        }
    }).then((res) => {
        commit('setPutRisksSuccess', res.data.validateRisks);
        //dispatch('getEvaluation', {id: state.evaluation.id});
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const deleteRisk =  ({ commit }, { risk }) => {
    commit('setEvaluationLoading');
    return ApolloClient.mutate({
        mutation: DELETE_RISK,
        variables: {
            evaluationId: risk.evaluationId,
            riskId: risk.riskId
        }
    }).then((res) => {
        commit('setDeleteRiskSuccess', res.data.deleteRisk);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const postAggirAnswer = ({ commit, state, getters }, {data}) => {
    if(!state.aggirAnswer) {
        return;
    }
    commit('setAggirAnswerLoading');
    if(state.cancelPromise.has(state.aggirAnswer.question.id)){
        state.cancelPromise.get(state.aggirAnswer.question.id)();
        state.cancelPromise.delete(state.aggirAnswer.question.id);
    }
    return new Promise((resolve, reject) => {
        ApolloClient.mutate({
            mutation: SAVE_AGGIR_ANSWER,
            variables: {
                evaluationId: state.evaluation.id,
                questionId: state.aggirAnswer.question.id,
                answerStch: state.aggirAnswer.answerStch,
                aggirPercentage: getters.getAggirPercentage,
                interpretationRule: data.interpretationRule
            }
        }).then((res) => {
            resolve(res);
        }).catch((err) => {
            reject(err);
        });

        state.cancelPromise.set(
            state.aggirAnswer.question.id,
            () => {
                ApolloClient.stop();
                reject('cancel token');
            });
    }).then((res) => {
        commit('setPutAggirAnswerSuccess', res.data.saveAggirAnswer);
        return res.data;
    }).catch((err) => {
        console.error(err);
        if(err === 'cancel token'){
            return;
            //throw err;
        }
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    });
};

export const postDefaultAggirAnswer =  ({state, commit}, {questions}) => {
    commit('setEvaluationSaveLoading');
    const newPercetage = Math.round(((state.evaluation.answerList.length + questions.length) * 100) / 24);

    return ApolloClient.mutate({
        mutation: SAVE_DEFAULT_AGGIR_ANSWERS,
        variables: {
            evaluationId: state.evaluation.id,
            questionList: JSON.stringify(questions),
            aggirPercentage: newPercetage > 100 ? 100 : newPercetage
        }
    }).then((res) => {
        commit('setPostDefaultAggirAnswer', res.data.saveDefaultAggirAnswers);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const postEvaluationHasAct =  ({ state, commit }, { link }) => {
    commit('setEvaluationSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_EVALUATION_HAS_ACT,
        variables: {
            id: link.id,
            actId: link.actId,
            evaluationId: state.evaluation.id,
            questionId: link.questionId,
            caregiver: link.caregiver,
            period: link.period,
            place: link.place,
            active: link.active,
            validatedTime: link.validatedTimeIntFormat,
            weekDays: link.weekDays,
            addedManually: link.addedManually ? link.addedManually : false
        }
    }).then((res) => {
        if(res.data.saveEvaluationHasAct !== null){
            link.aggirQuestion = res.data.saveEvaluationHasAct.aggirQuestion;
            link.id = res.data.saveEvaluationHasAct.id;
            link.estimatedTime = res.data.saveEvaluationHasAct.estimatedTime;
            link.validatedTimeIntFormat = res.data.saveEvaluationHasAct.validatedTimeIntFormat;
            link.validatedTimeStringFormat = res.data.saveEvaluationHasAct.validatedTimeStringFormat;
            link.weekDays = res.data.saveEvaluationHasAct.weekDays;
        }
        commit('setPostActSuccess', link);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const putEvaluation =  ({ commit, state }) => {
    return ApolloClient.mutate({
        mutation: SAVE_EVALUATION,
        variables: {
            ...state.evaluation,
            evaluateBy: state.evaluation.evaluateById,
            evaluatorComments: JSON.stringify(state.evaluation.evaluatorComments),
        }
    }).then((res) => {
        commit('setPutEvaluationSuccess', res.data.saveEvaluation);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const deleteEvaluation =  ({ commit }, { id }) => {
    commit('setEvaluationLoading');
    return ApolloClient.mutate({
        mutation: DELETE_EVALUATION,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setDeleteEvaluationSuccess', res.data.deleteEvaluation);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getTreatment =  ({ commit }, evaluationId ) => {
    return ApolloClient.mutate({
        mutation: TREATMENT_LIST,
        variables: {
            evaluationId: parseInt(evaluationId)
        }
    }).then((res) => {
        res.data.treatmentByEvaluationId.forEach((treatment) => {
            commit('setTreatmentProperty', {
                name: treatment.questionName,
                value: treatment.response
            });
        });
        return res.data;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
};

export const saveTreatment =  ({ state }, {id, name, evaluationId}) => {
    let treatment = state[id][name];
    return ApolloClient.mutate({
        mutation: SAVE_TREATMENT,
        variables: {
            ...treatment,
            evaluationId: evaluationId
        }
    }).then((res) => {
        //let treatment = res.data.saveTreatment;
        /*commit('setTreatmentProperty', {
            name: treatment.questionName,
            value: treatment.response
        });*/
        return res.data;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
};

export const saveSyntheseGoals = async ({ state }, {name, category, subItem = null, }) => {
    let goalsActions = state[category];
    const isMaterialRelated = (category === "technicalHelp");
    const isRiskRelated = (category === "risks");
    if(subItem) {
        goalsActions = goalsActions[subItem][name];
    } else if(!isMaterialRelated && !isRiskRelated){
        goalsActions = goalsActions[name]
    } else if(isMaterialRelated) {
        goalsActions = goalsActions.find((el) => el.item === ("technicalHelp-"+name));
    } else {
        goalsActions = goalsActions.find((el) => el.item === ("risks-"+name));
    }

    return ApolloClient.mutate({
        mutation: SAVE_SYNTHESE_GOALS,
        variables: {
            id: goalsActions.id,
            item: goalsActions.item,
            problem: typeof goalsActions.problem === "string" ? JSON.stringify(goalsActions.problem) : "",
            goals: JSON.stringify(goalsActions.goal),
            actions: JSON.stringify(goalsActions.actions),
            affectation: JSON.stringify(goalsActions.affectation),
            evaluationId: state.evaluation.id,
            active: goalsActions.active
        }
    }).then((res) => {
        return res.data;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
}

export const getSyntheseGoals = async ({commit}, evaluationId) => {
    return ApolloClient.mutate({
        mutation: SYNTHESE_GOALS_LIST,
        variables: {
            evaluationId: parseInt(evaluationId)
        }
    }).then((res) => {
        res.data.syntheseGoalsByEvaluationId.forEach((syntheseGoal) => {
            const affect = JSON.parse(syntheseGoal.affectation);
            commit('setSyntheseGoalProperty', {
                item: syntheseGoal.item,
                problem: syntheseGoal.problem ? JSON.parse(syntheseGoal.problem) : null,
                goal: JSON.parse(syntheseGoal.goals),
                actions: JSON.parse(syntheseGoal.actions),
                affectation: affect,
                active: syntheseGoal.active
            });
        });
        return res.data;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
};

export const saveContract =  ({state}) => {
    return ApolloClient.mutate({
        mutation: SAVE_CONTRACT,
        variables: {
            ...state.evaluation.contract,
            evaluationId: state.evaluation.id,
            aivqDetails: JSON.stringify(state.evaluation.contract.aivqDetails),
            avqDetails: JSON.stringify(state.evaluation.contract.avqDetails),
            pecDetails: JSON.stringify(state.evaluation.contract.pecDetails),
        }
    }).then((res) => {
        state.evaluation.contract.id = res.data.saveContract.id;
        state.evaluation.contract.status = DEVIS_STATUS;
        return res.data;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
};

export const postGeneralQuestionAnswer =  ({ commit, state/*, dispatch*/ }) => {
    commit('setEvaluationSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_GENERAL_QUESTION_ANSWER,
        variables: {
            ...state.generalAnswer,
            evaluationId: state.evaluation.id
        }
    }).then((res) => {
        commit('setPostGeneralQuestionAnswerSuccess', res.data.saveGeneralQuestionAnswer);
        //dispatch('getEvaluation', {id: state.evaluation.id});
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const postGeneralAdditionalInformation =  ({ commit, state/*, dispatch*/ }) => {
    return ApolloClient.mutate({
        mutation: SAVE_ADDITIONAL_INFORMATION,
        variables: {
            evaluationId: state.evaluation.id,
            additionalInformation: state.evaluation.additionalInfos
        }
    }).then((res) => {
        //dispatch('getEvaluation', {id: state.evaluation.id});
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const saveHabitEvaluation =  ({commit}, {habit}) => {
    commit('setEvaluationLoading')
    return ApolloClient.mutate({
        mutation: SAVE_HABIT_EVALUATION,
        variables: {
            id: habit.id,
            evaluationId: habit.evaluationId,
            description: habit.description,
            period: habit.period,
            place: habit.period,
            validatedTime: habit.validatedTime
        }
    }).then((res) => {
        commit('setLoading', false);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err;
    })
}

export const applyDaysOnActs = ({commit}, {ids, selectedDays, period}) => {
    commit('setEvaluationLoading')
    return ApolloClient.mutate({
        mutation: APPLY_DAYS_ON_ACTS,
        variables: {
            ids: ids,
            selectedDays: selectedDays,
            period: period
        }
    }).then((res) => {
        commit('setLoading', false);
        return res.data
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err
    })
}

export const postGenerageSynthese = ({state}, {data}) => {
    return postUpload(`${API_URL}/generate/synthese/${ (state.evaluation.contract.id ? state.evaluation.contract.id : '') }`, data)
        .then(response => {
            if (!response.ok) {
                if (response.status === 404 || response.status === 401) {
                    throw response;
                }
            }
            return response.json();
        }).then((data) => {
            return data;
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

export const postGenerageDevis = (commit, {data, contractId}) => {
    return postUpload(`${API_URL}/generate/devis/${contractId}`, data)
        .then(response => {
            if (!response.ok) {
                if (response.status === 404 || response.status === 401) {
                    throw response;
                }
            }
            window.open(`${API_URL}/download/devis/${contractId}`, '_blank');
            return response.json();
        }).catch((err) => {
            console.error(err);
            throw err;
        });
};

export const updateSeanceDetails = ({state, commit}) => {
    commit('setEvaluationLoading')
    return ApolloClient.mutate({
        mutation: SAVE_SEANCE_DETAILS,
        variables: {
            evaluationId: state.evaluation.id,
            seanceDetails: JSON.stringify(state.evaluation.seanceDetails)
        }
    }).then((res) => {
        commit('setLoading', false);
        return res.data
    }).catch((err) => {
        console.error(err);
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err
    })
}

export const updateHeuresSemaineDimanche = ({state, commit}) => {
    commit('setEvaluationLoading')
    return ApolloClient.mutate({
        mutation: SAVE_HEURES_SEMAINE_DIMANCHE,
        variables: {
            evaluationId: state.evaluation.id,
            heuresSemaine: state.evaluation.heuresSemaine,
            heuresDimanche: state.evaluation.heuresDimanche
        }
    }).then((res) => {
        commit('setLoading', false);
        return res.data
    }).catch((err) => {
        console.error(JSON.stringify(err));
        commit('setEvaluationError', err.response ? err.response.data : err);
        throw err
    })
}

export default {
    getEvaluationList,
    getEvaluation,
    postEvaluation,
    duplicateEvaluation,
    postAssignHabit,
    postAssignRisk,
    putValidateRisks,
    postAggirAnswer,
    postEvaluationHasAct,
    putEvaluation,
    deleteEvaluation,
    deleteRisk,
    getTreatment,
    saveTreatment,
    getGeneralQuestions,
    postGeneralQuestionAnswer,
    postAssignMaterial,
    postAssignMaterials,
    getEvaluationMaterials,
    saveHabitEvaluation,
    postGeneralAdditionalInformation,
    applyDaysOnActs,
    saveContract,
    postGenerageSynthese,
    postGenerageDevis,
    postDefaultAggirAnswer,
    updateSeanceDetails,
    saveSyntheseGoals,
    getSyntheseGoals,
    updateHeuresSemaineDimanche
};