<template>
  <div class="id--section">
    <div class="id--section-title">
      <h1>ENTOURAGE ET PARTICIPATION SOCIALE</h1>
    </div>
    <div class="id--section-content">
      <div class="row">
        <div class="col-12" v-for="q in questions" :key="q.id">
          <div class="id-check--group" v-if="(q.type==='radio' || q.type==='checkbox') && toShow(q)">
            <eval-form-check
                v-model="answers[q.id]"
                v-if="q.type==='radio'"
                :title="q.title"
                :options="q.responses"
                @change="changeCheck(q.id)"
            />
            <eval-form-check-alt
                v-model="answers[q.id]"
                v-if="q.type==='checkbox'"
                :subtitle="q.title"
                inputType="checkbox"
                :options="q.responses"
                @change="changeCheck(q.id)" />
          </div>
          <form-textarea v-model="texts[q.id]"
                v-if="q.type==='text'"
                v-show="q.id !== 28 && toShow(q)"
                :label="q.title"
                :rows="3"
                class="w-100"
                :login-input="false"
                :disabled="false"
                @blur="changeCheck(q.id)" />
          <div v-if="q.id === 28 && toShow(q)">
            <p class="id-check--title">COORDONNÉES DU CURATEUR OU TUTEUR</p>
            <button class="btn" v-if="!texts[q.id]" @click.prevent="addContact = true">
              <img src="@/assets/bouton-primaire-add.svg" alt />
            </button>
            <div class="evaluationContact" v-if="relatedContact">
              <div>
                <p>{{evaluationContact.name}}</p>
                <p>{{evaluationContact.phone}}</p>
              </div>

              <button class="btn" @click.prevent="removeContact">
                <img src="@/assets/cross-sign.svg" alt />
              </button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <form-textarea
              v-model="entourageComment"
              label="Commentaires de l’évaluateur sur l’entourage et la participation sociale"
              :rows="3"
              class="w-100"
              :login-input="false"
              :disabled="false"
              @blur="changeCommentaryValue()"
          />
        </div>
      </div>
    </div>
    <custom-modal :active="addContact" @close="addContact=false">
      <div class="add-act-modal">
        <h1 class="cuisine-modal--title">Ajouter un contact présent</h1>
        <div class="row">
          <div class="col-md-12 col-lg-4 text-center">
            <label>FAMILIAL</label>
            <eval-contact-form-card type="family" />
          </div>
          <div class="col-md-12 col-lg-4 text-center">
            <label>MÉDICAL</label>
            <eval-contact-form-card type="medical" class="theme-green" />
          </div>
          <div class="col-md-12 col-lg-4 text-center">
            <label>AUTRES AIDANTS</label>
            <eval-contact-form-card type="other" class="theme-red" />
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button class="btn btn-primary" @click.prevent="addPresentContact">Valider</button>
          <button class="btn btn-outline-primary ml-4" @click.prevent="addContact = false">Annuler</button>
        </div>
      </div>
    </custom-modal>
  </div>
</template>
<script>
  import EvalFormCheck from "@/components/Eval/FormCheck";
  import FormTextarea from "@/components/Form/Textarea";
  import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";
  import EvalContactFormCard from "@/components/Eval/EvalContactFormCard";
  import {mapActions, mapState, mapMutations} from "vuex";

  export default {
    components:{
      EvalFormCheck,
      EvalFormCheckAlt,
      FormTextarea,
      EvalContactFormCard
    },
    data: () => ({
      texts: {},
      answers: {},
      wishOtherActivity: null,
      warningAnimal: null,
      hasAnimal: null,
      addContact: false,
      relatedContact: null
    }),
    computed: {
      ...mapState('Evaluation', ['evaluation', 'generalQuestions']),
      ...mapState('Client', ['client','contact']),
      questions() {
        const commentActivityQuestion1 = this.generalQuestions.find(e => e.id === 67);
        const commentActivityQuestion2 = this.generalQuestions.find(e => e.id === 68);
        let questions = this.generalQuestions.filter(e=>{return e.category==='Entourage'})
        const commentActivityQuestion1Index = questions.findIndex(e => e.id === 67);
        const commentActivityQuestion2Index = questions.findIndex(e => e.id === 68);

        if(commentActivityQuestion1Index !== -1 && commentActivityQuestion1) {
          questions.splice(commentActivityQuestion1Index, 1);
          const prevQuestionIndex = questions.findIndex(e => e.id === 32);
          questions = [...questions.slice(0, prevQuestionIndex+1), commentActivityQuestion1, ...questions.slice(prevQuestionIndex+1)]
        }
        if(commentActivityQuestion2Index !== -1 && commentActivityQuestion2) {
          questions.splice(commentActivityQuestion2Index, 1);
          const prevQuestionIndex = questions.findIndex(e => e.id === 33);
          questions = [...questions.slice(0, prevQuestionIndex+1), commentActivityQuestion2, ...questions.slice(prevQuestionIndex+1)]
        }
        return questions;
      },
      evaluationContact() {
        return {
          name: this.relatedContact && this.relatedContact.lastName ? (this.relatedContact.firstName ? this.relatedContact.firstName : '') + ' ' + this.relatedContact.lastName : "",
          phone: this.relatedContact && this.relatedContact.phone ? this.relatedContact.phone : "",
        }
      },
      entourageComment: {
        get() {
          return this.evaluation.evaluatorComments ? this.evaluation.evaluatorComments.entourageComment : "";
        },
        set(value) {
          this.evaluation.evaluatorComments.entourageComment = value;
        }
      },
    },
    async mounted() {
      await this.getEvaluation({id: this.$route.params.evaluationId})
      const v = this.evaluation
      if (v.generalQuestionsAnswers) {
        var answers = {}
        var texts = {}
        v.generalQuestionsAnswers.forEach(e => {
          if (e.generalQuestion.type==='radio') {
            answers[e.generalQuestion.id] = e.answers ? e.generalQuestion.responses[e.answers[0]] : null
          }
          if (e.generalQuestion.type==='checkbox') {
            let answer = [];
            if (e.answers) {
              e.answers.forEach(t=> {
                answer.push(e.generalQuestion.responses[t]);
              })
            }
            answers[e.generalQuestion.id] = answer
          }
          texts[e.generalQuestion.id] = e.text
        })
        this.answers = answers
        this.texts = texts
      }
      this.getRelatedContact();
    },
    methods: {
      ...mapActions('Evaluation', ['getEvaluation', 'putEvaluation', 'getGeneralQuestions', 'postGeneralQuestionAnswer']),
      ...mapMutations('Evaluation', ['setGeneralAnswerSuccess', 'setEvaluationProperty']),
      ...mapActions("Client", ["postClientContact"]),
      ...mapMutations('Client',['setInitContact']),
      ...mapActions('Contact', ['getContact']),
      changeCheck(questionId) {
        let question = this.questions.find(e=>e.id == questionId);
        let generalAnswer = this.evaluation.generalQuestionsAnswers.find(e=>e.generalQuestion.id == questionId);
        if (!question) return;
        let answers = null;
        if (question.type==='radio') {
          answers = [question.responses.indexOf(this.answers[questionId])];
        }
        if (question.type==='checkbox') {
          answers = [];
          this.answers[questionId].forEach(e=>{
            answers.push(question.responses.indexOf(e));
          });
        }
        const text = question.type==='text' ? this.texts[questionId] : '';
        this.setGeneralAnswerSuccess({
          id : (generalAnswer ? generalAnswer.id : 0),
          questionId: questionId,
          answers: answers,
          text: text
        });
        this.postGeneralQuestionAnswer();
      },
      toShow(question) {
        if (question.id === 28 || question.id === 30 || question.id === 67 || question.id === 68) {
          if (question.id === 28) {
            if (this.answers[27]==='Sauvegarde de justice' || this.answers[27]==='Curatelle simple' || this.answers[27]==='Curaretelle renfocée' || this.answers[27]==='Tutelle') {
              return true;
            }
          }
          if (question.id === 30) {
            if (this.answers[29]==='Oui') {
              return true;
            }
          }
          if(question.id === 67) {
            if (this.answers[32]==='Oui') {
              return true;
            }
          }
          if(question.id === 68) {
            if (this.answers[33]==='Oui') {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      },
      getRelatedContact() {
        if(!parseInt(this.texts[28])) {
          return;
        }
        const contactId = parseInt(this.texts[28]);
        this.getContact({id: contactId}).then((res) => {
          this.relatedContact = res.contact;
        })
      },
      removeContact() {
        this.texts[28] = null;
        this.changeCheck(28);
        this.relatedContact = null;
      },
      addPresentContact() {
        const contact = this.contact;

        if (this.isInputed(contact.family)) {
          this.submitPostContact(contact.family);
        }
        if (this.isInputed(contact.medical)) {
          this.submitPostContact(contact.medical);
        }
        if (this.isInputed(contact.other)) {
          this.submitPostContact(contact.other);
        }
        this.setInitContact();
        this.addContact = false;
      },
      isInputed(contact) {
        return contact.lastName && contact.phone
      },
      submitPostContact(contact) {
        this.postClientContact({contact: contact}).then((res) => {
          this.$notify.success('L\'information a été sauvegardée');
          const newContactId = res.saveClientContact.contactList[res.saveClientContact.contactList.length - 1].id;
          this.texts[28] = newContactId;
          this.changeCheck(28);
          this.relatedContact = this.client.contactList.find((contact) => contact.id === newContactId);
          return res;
        }).catch((err) => {
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          throw err;
        });
      },
      changeCommentaryValue() {
        this.putEvaluation();
      },
    },
  };
</script>