<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import {setErrorHandler} from "@/api/Apollo";

  export default {
    name: 'App',
    methods: {
      ...mapActions("User", ["getUserConnected"]),
      // ...mapActions('Structure', ["getStructureList"]),
      ...mapActions("Notification", ["getNotification"]),
      ...mapActions("Filter", ["getFilterStructureList"]),
    },
    mounted() {
      //set error handler of apollo
      setErrorHandler(this.$router, this.$route);
      //get structure list to apply filter
      if (this.$route.path === "/resetPassword/:id") {
        this.getUserConnected().then(() => {
          this.getNotification();
          if (this.$route.path === "/login") {
            this.$router.push({name: "structure-list"});
          } else if (this.$route.path === "/evaluation-login") {
            this.$router.push({name: 'accueil'});
          }
        })
      }
    }
  }

</script>
