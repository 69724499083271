import gql from 'graphql-tag'

export const GET_EVALUATION_MATERIALS = gql`
    query evaluationMaterialList(
        $evaluationId: Int!
    ) {
        evaluationMaterialList(
            evaluationId: $evaluationId
        ) {
            items {
              material {
                id,
                name
              },
              existing,
              required,
              suggested
            }
        }
    }
`
