var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group custom-form custom-form--input",class:{
    required: _vm.required,
    disabled: _vm.disabled,
    'has-bg': _vm.bgIcon != null,
    'has-label': _vm.label != null,
    'has-error': _vm.hasError,
    'active-focus-label': _vm.activeFocusLabel,
    'active-value-label': _vm.activeValueLabel,
    'input--login': _vm.loginInput,
  }},[(_vm.label)?_c('label',{attrs:{"for":_vm.givenId}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('el-select',{staticClass:"form-control",class:{
      customClass: _vm.customClass,
      'form-control-sm': _vm.size == 'sm',
      'form-control-lg': _vm.size == 'lg',
    },style:(_vm.background),attrs:{"filterable":"","remote":"","multiple":_vm.multiple,"id":_vm.givenId,"name":_vm.name,"aria-describedby":_vm.helpId,"required":_vm.required,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"loading-text":"En cours de chargement ...","no-match-text":"Aucune donnée","remote-method":_vm.onInput},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"change":_vm.onSelect},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}},_vm._l((_vm.list),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),(_vm.error && _vm.loginInput)?[_c('img',{staticClass:"input--error-icon",attrs:{"src":require("@/assets/error.svg"),"alt":""}})]:_vm._e(),_vm._t("error",function(){return [_c('small',{staticClass:"input--error",attrs:{"id":_vm.helpId}},[_vm._v(_vm._s(_vm.error))])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }