export default {
    setTarificationProperty(state, property) {
        if(!state.tarification[property.name]){
            state.tarification[property.name] = "";
        }
        state.tarification[property.name] = property.value;
    },
    setTarificationLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setTarificationSaveLoading(state) {
        state.error = null;
    },
    setTarificationList(state, tarifications) {
        state.tarifications = tarifications
        state.loading = false;
        state.error = null;
    },
    setTotalTarificationList(state, total) {
        state.total = total;
    },
    setTarificationSuccess(state, tarification) {
        state.tarification = {
            ...tarification,
            structureId: tarification.structure.id,
            prestationId: tarification.prestation.id
        };
        state.loading = false;
        state.error = null;
    },
    setInitTarification(state) {
        state.tarification = {
            structure: {},
            structureId: '',
            prestation: {},
            prestationId: '',
            tarifType: '',
            price: null
        };
        state.error = null;
      },
    setTarificationError(state, error) {
        state.loading = false;
        state.error = error;
    },
    setPostTarificationSuccess(state, tarification) {
        state.tarifications.push(tarification);
        state.error = null;
        state.total = state.total + 1
    },
    setPutTarificationSuccess(state, tarification) {
        state.tarifications.splice(
            state.tarifications.findIndex(tarificationSearch => tarificationSearch.id === tarification.id),
            1,
            tarification
        );
        state.error = null;
    },
    setDeleteTarificationSuccess(state, id) {
        state.tarifications.splice(
            state.tarifications.findIndex(tarification => tarification.id == id),
            1
        );
        state.loading = false;
        state.error = null;
        state.total = state.total - 1
    }
}