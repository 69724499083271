import gql from 'graphql-tag'

const DATA = `{
    id,
    structure {
        id,
        name
    },
    agency {
        id,
        companyName
    },
    demandDate,
    evaluationDate,
    startingDate,
    endingDate,
    additionalInfos,
    client{
        id,
        firstname,
        lastname,
    },
    evaluateBy{
        id,
        firstName,
        lastName
    },
    aggirPercentage,
    answerList {
        id,
        answerStch,
        question {
           id
        },
      itemScore
    },
    generalQuestionsAnswers {
        id,
        generalQuestion{
            id
        },
        answers,
        text
    },
    answerList {
        id,
        answerStch,
        question {
           id
        },
      itemScore
    },
    evaluatorComments,
    seanceDetails
}`;

export const SAVE_EVALUATION = gql`
    mutation saveEvaluation(
        $id: Int,
        $aggirPercentage: Int!,
        $demandDate: String,
        $evaluationDate: String,
        $startingDate: String,
        $endingDate: String,
        $additionalInfos: String,
        $evaluatorComments: String,
        $clientId: Int!,
        $structureId: String,
        $agencyId: String
    ) {
        saveEvaluation(
            id: $id,
            aggirPercentage: $aggirPercentage,
            clientId: $clientId,
            demand_date: $demandDate,
            evaluation_date: $evaluationDate,
            starting_date: $startingDate,
            ending_date: $endingDate,
            additional_infos: $additionalInfos,
            evaluator_comments: $evaluatorComments,
            structureId: $structureId,
            agencyId: $agencyId
        )
        ${DATA}
    }
`

export const DUPLICATE_EVALUATION = gql`
    mutation duplicateEvaluation(
        $id: Int!
    ) {
        duplicateEvaluation(
            id: $id,
        ) 
        ${DATA}
    }
`
