<template>
  <div class="bo--agence row mx-0 justify-content-center">
    <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
      <div class="d-flex align-items-center">
        <h1 class="bo--agence-title">Statistiques</h1>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <form-select v-model="structureIdRatio"
                                :options="structureList"
                                label="Structure"
                                :login-input="true"
                                @input="updateFilterRatio">
                    <template v-slot:first>
                      <option value="" selected></option>
                    </template>
                  </form-select>
                </div>
                <div class="col-sm-12 col-md-12">
                  <form-select v-model="agencyIdRatio"
                                :options="agencyList"
                                label="Agence"
                                :login-input="true"
                                @input="updateFilterRatio">
                    <template v-slot:first>
                      <option value="" selected></option>
                    </template>
                  </form-select>
                </div>
                <div class="col-sm-12 col-md-12">
                  <form-select v-model="intervenantIdRatio"
                                :options="intervenantList"
                                label="Intervenant"
                                :login-input="true"
                                @input="updateFilterRatio">
                    <template v-slot:first>
                      <option value="" selected></option>
                    </template>
                  </form-select>
                </div>
                <div class="col-sm-12 col-md-12">
                  <form-input v-model="gir"
                                label="GIR"
                                :login-input="true"
                                @input="updateFilterRatio">
                  </form-input>
                </div>
              </div>
            </div>
            <div class="col-md-8" v-if="loading" v-loading="loading"></div>
            <div class="col-md-8" v-else>
              <LineChart :data="chartDataRatio"></LineChart>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <form-select v-model="structureId"
                                :options="structureList"
                                label="Structure"
                                :login-input="true"
                                @input="updateFilter">
                    <template v-slot:first>
                      <option value="" selected></option>
                    </template>
                  </form-select>
                </div>
                <div class="col-sm-12 col-md-12">
                  <form-select v-model="agencyId"
                                :options="agencyList"
                                label="Agence"
                                :login-input="true"
                                @input="updateFilter">
                    <template v-slot:first>
                      <option value="" selected></option>
                    </template>
                  </form-select>
                </div>
                <div class="col-sm-12 col-md-12">
                  <form-select v-model="intervenantId"
                                :options="intervenantList"
                                label="Intervenant"
                                :login-input="true"
                                @input="updateFilter">
                    <template v-slot:first>
                      <option value="" selected></option>
                    </template>
                  </form-select>
                </div>
              </div>
            </div>
            <div class="col-md-8" v-if="loading" v-loading="loading"></div>
            <div class="col-md-8" v-else>
              <PieChart :data="chartData"></PieChart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  import LineChart from '@/components/Form/LineChart';
  import PieChart from '@/components/Form/PieChart';

  export default {
    name: "StatistiquesChart",
    components: {
      LineChart,
      PieChart
    },
    data() {
      return {
        structureIdRatio: '',
        intervenantIdRatio: '',
        agencyIdRatio: '',
        gir: '',
        structureId: '',
        intervenantId: '',
        agencyId: ''
      };
    },
    computed: {
      ...mapState('Structure', ['structures']),
      ...mapState('User', ['users']),
      ...mapState('Agency', ['agencies']),
      ...mapState("Intervention", ["loading", "countsFromSignalementType", "hoursByMonth", "total", "error"]),
      agencyList() {
        if(this.agencies.length === 0){
          return [];
        }
        return this.agencies.reduce((list, agency) => {
          list.push({value: agency.id, text: agency.companyName});
          return list;
        }, []);
      },
      structureList() {
        if(this.structures.length === 0){
          return [];
        }
        return this.structures.reduce((list, structure) => {
          list.push({value: structure.id, text: structure.name});
          return list;
        }, []);
      },
      intervenantList() {
        if(this.users.length === 0){
          return [];
        }
        return this.users.reduce((list, user) => {
          list.push({value: user.id, text: user.firstName + ' ' + user.lastName});
          return list;
        }, []);
      },
      chartDataRatio() {
        if (this.hoursByMonth.length === 0) {
          return {};
        }
        const labels = this.hoursByMonth.map(item=> {
          const jsonData = JSON.parse(item);
          return jsonData.month;
        })
        const plan = this.hoursByMonth.map(item=> {
          const jsonData = JSON.parse(item);
          return parseFloat(jsonData.plan) || 0;
        })
        const spent = this.hoursByMonth.map(item=> {
          const jsonData = JSON.parse(item);
          return parseFloat(jsonData.spent) || 0;
        })
        return {
          labels: labels,
          datasets: [
            {
              label: 'Temps prévu',
              backgroundColor: '',
              data: plan
            },
            {
              label: 'Temps passé',
              backgroundColor: '#f87979',
              data: spent
            }
          ]
        }
      },
      chartData() {
        if (this.countsFromSignalementType.length === 0) {
          return {};
        }
        const labels = this.countsFromSignalementType.map(item=> {
          const jsonData = JSON.parse(item);
          return jsonData.type;
        })
        const countData = this.countsFromSignalementType.map(item=> {
          const jsonData = JSON.parse(item);
          return parseInt(jsonData.count) || 0;
        })
        let bgColors = [];
        let colorCode = 16711680;
        for (let i=0; i<this.countsFromSignalementType.length; i++) {
          colorCode += i * Math.floor((256 * 256 * 256 - 1) / (this.countsFromSignalementType.length*1.5));
          if (colorCode>256*256*256) {
            colorCode -= 256*256*256;
          }
          const hexCode = '000000' + Number(colorCode).toString(16);
          bgColors.push('#' + hexCode.substr(-6, 6))
        }
        return {
          labels: labels,
          datasets: [
            {
              backgroundColor: bgColors,
              data: countData
            }
          ]
        }
      }
    },
    mounted: async function () {
      this.getStructureList({limit: 10000});
      this.getUserList({limit: 100000});
      this.getAgencyList({limit: 100000});
      await this.getInterventionChartDataBySignalement({});
      await this.getInterventionRatioChartDataByHours({});
    },
    methods: {
      ...mapActions("Agency", ["getAgencyList"]),
      ...mapActions("Structure", ["getStructureList"]),
      ...mapActions("User", ["getUserList"]),
      ...mapActions("Intervention", ["getInterventionChartDataBySignalement", "getInterventionRatioChartDataByHours"]),
      updateFilterRatio() {
        this.getInterventionRatioChartDataByHours({
          intervenantId: this.intervenantIdRatio,
          agencyId: this.agencyIdRatio,
          structureId: this.structureIdRatio,
          gir: this.gir
        });
      },
      updateFilter() {
        this.getInterventionChartDataBySignalement({
          intervenantId: this.intervenantId,
          agencyId: this.agencyId,
          structureId: this.structureId
        });
      },
      refresh() {
        this.structureIdRatio = '';
        this.intervenantIdRatio = '';
        this.agencyIdRatio = '';
        this.gir = '';
        this.structureId = '';
        this.intervenantId = '';
        this.agencyId = '';
        this.updateFilter();
      }
    }
  };
</script>