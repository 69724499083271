import gql from 'graphql-tag'

export const NOTIFICATION = gql`
    query notification
    {
        notification{
            titre
            message
            type
            createdAt
        }
        clientFinishedSupport{
            id
            lastname
            firstname
            city
            postalCode,
            sector,
            portablePhoneNumber,
            clientSupportList{
                id
                dateEnd
            }
        }
        userFinishedContract{
            id
            agency{
                companyName
            }
            email
            lastName
            firstName,
            endedAt
        }
    }
`