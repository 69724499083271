<template>
    <div class="bo--container">
        <template>
            <div class="container">
                <loader :loader="loading"/>
                <div>
                    <bo-card>
                        <template slot="title">
                            <h1 class="bo-card--title">
                                <a href="#" @click.prevent="cancel">&lt; </a> {{isEdit ? 'Voir le contrat' : "Voir un contrat"}}
                            </h1>
                        </template>
                        <div v-if="contract.erpId" class="row">
                            <div class="col-sm-12 col-md-6">
                                <form-input :value="contract.erpId"
                                            label="Code Apologic"
                                            :login-input="true"
                                            :disabled="true"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <form-input :value="client ? client.lastname + ' ' + client.firstname : ''"
                                            label="Nom du client"
                                            :login-input="true"
                                            :disabled="true">
                                    <template slot="append">
                                        <div class="--append">
                                            <font-awesome-icon class="ic ic-primary ml-1 mr-1" :icon="['fas', 'eye']" @click.prevent="gotClient"/>
                                        </div>
                                    </template>
                                </form-input>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <form-input :value="agency.companyName"
                                            label="Agence"
                                            :login-input="true"
                                            :disabled="true"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <form-input :value="priceByMonth"
                                            label="Prix par mois"
                                            :login-input="true"
                                            :disabled="true"/>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <form-input :value="nbHourByMonth"
                                            label="Nb heure par mois"
                                            :login-input="true"
                                            :disabled="true"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <form-input :value="discount"
                                            label="Remise"
                                            :login-input="true"
                                            :disabled="true"/>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <form-input :value="status"
                                            label="Statut"
                                            :login-input="true"
                                            :disabled="true"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <form-input :value="dateStart"
                                            label="Date de début de contrat"
                                            :login-input="true"
                                            :disabled="true"/>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <form-input :value="dateEnd"
                                            label="Date de fin de contrat"
                                            :login-input="true"
                                            :disabled="true"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                Liste des prestations
                                <GridLight id="prestationContractList"
                                           :columns="columns"
                                           :dataList="contract.prestationList"
                                            @cliked="goToPrestationType"/>
                            </div>
                        </div>
                        <template slot="footer">
                            <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
                        </template>
                    </bo-card>
                </div>
            </div>
        </template>
    </div>
</template>
<script>

    import {mapActions, mapMutations, mapState} from "vuex";
    import moment from "moment"
    import GridLight from '@/components/Grid/GridLight'

    export default {
        name: "BOContractForm",
        components: {
            GridLight
        },
        data() {
            return {
                bo: {
                    active: true
                },
                saveLoading: false,
                listOnly: true,
                columns: [
                    {name: "Nom d'acte", value: 'acteLabel', clickable: true},
                    {name: "Horaire", value: 'schedule'},
                    {name: "Récurrence", value: 'recurrency'},
                    {name: "Nombre d'heure", value: 'nbHour'},
                    {name: "Prix HT", value: 'priceByHourHT'},
                    {name: "Total HT (mois)", value: 'totalByMonthHT'},
                ],
                sortColumn: '',
                sortDirection: 'ASC',
                limit: 10,
                currentPage: 1,
            };
        },
        computed: {
            ...mapState('Contract', ['contract', 'error', 'loading', 'prestations']),
            tdStyle(){
                return "width: 110px;max-width: 110px;min-width: 110px;"
            },
            client:{
                get(){
                    return this.contract.client;
                }
            },
            agency:{
                get(){
                    return this.contract.agency;
                }
            },
            prestationsList:{
                get(){
                    return this.prestations
                }
            },
            nbHourByMonth:{
                get(){
                    return this.contract.nbHourByMonth;
                }
            },
            priceByMonth:{
                get(){
                    return this.contract.priceByMonth;
                }
            },
            discount:{
                get(){
                    return this.contract.discount;
                }
            },
            status:{
                get(){
                    return this.contract.statusErp;
                }
            },
            dateStart: {
                get(){
                    return moment(this.contract.dateStart).format("DD/MM/YYYY")
                }
            },
            dateEnd: {
              get(){
                  return this.contract.dateEnd ? moment(this.contract.dateEnd).format("DD/MM/YYYY") : 'Pas de fin de contrat'
              }
            },
            isEdit() {
                return (this.$route.params.id !== undefined && this.$route.params.id !== null)
            },
            prestationsListSelect() {
                return this.prestationsList.reduce((tab, item) => {
                    tab.push({value: item.id, text: item.prestation.name});
                    return tab;
                }, []);
            }
        },
        mounted: async function() {
            if (this.isEdit) {
                await this.getContract({ id: this.$route.params.id })
            } else {
                this.setInitContract();
            }
        },
        methods: {
            ...mapActions('Contract', ['getContract']),
            ...mapMutations('Contract', ['setInitContract']),
            cancel() {
                this.setInitContract();
                return this.$router.push({name:'contract-list'})
            },
            customTdStyle(width){
                return `width: ${width}; min-width:${width}; max-width: ${width}`;
            },
            gotClient(){
                this.$router.push({name:"client-form", id: this.contract.client.id});
            },
            goToPrestationType(prestationCOntract){
                this.$router.push({name: "prestation-form", params:{
                    id:prestationCOntract.prestation.id
                }});
            }
        }
    };
</script>