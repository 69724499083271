import gql from 'graphql-tag'

export const DELETE_CLIENT_DOCUMENT = gql`
    mutation deleteClientDocument(
        $id: Int!,
    ) {
        deleteClientDocument(
            id: $id
        ) {
            id,
            documents {
                id,
                path,
                fileFormat
                mainType,
                type,
                description
            }
        }
    }
`
