import gql from 'graphql-tag'

export const CARE_PLAN_PRESTATION_LIST = gql`
    query carePlanPrestationList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!
    ) {
        carePlanPrestationList(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                id,
                name,
                typeAide,
                name,
                typeAide,
                schedule,
                recurrency,
                time,
                observation
            },
            total
        }
    }
`
