<template>
  <div class="bo-card">
    <h1 class="bo-card--title">
      <slot name="title">
        {{title}}
      </slot>
    </h1>
    <div class="bo-card--content">
      <slot />
    </div>
    <div class="bo-card--footer">
        <slot name="footer"/>
    </div>
  </div>
</template>
<script>
export default {
    name:"BoCard",
    props:{
        title:{
            type:String,
        },
    }
}
</script>