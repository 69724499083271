<template>
    <div v-show="loader" class="container-loader">
        <div class="loader">
            <div class="loader--dot"></div>
            <div class="loader--dot"></div>
            <div class="loader--dot"></div>
            <div class="loader--dot"></div>
            <div class="loader--dot"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        props:{
            loader: Boolean
        }
    };
</script>
<style lang="scss" scoped>
    .container-loader {
        position: fixed;
        height: 100%;
        width: 100%;
        font-family: Helvetica;
        background-color: rgba(255, 255, 255, 0.6);
        z-index: 100;
        top: 114px;
        left: 0;
    }

    .loader {
        height: 20px;
        width: 250px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .loader--dot {
        animation-name: loader;
        animation-timing-function: ease-in-out;
        animation-duration: 1.8s;
        animation-iteration-count: infinite;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        background-color: black;
        position: absolute;
        border-style: none;
    }

    .loader--dot:first-child {
        background-color: #1b42c7;
        animation-delay: 0.3s;
        opacity: 0.2;
    }

    .loader--dot:nth-child(2) {
        background-color: #1b42c7;
        animation-delay: 0.25s;
        opacity: 0.4;
    }

    .loader--dot:nth-child(3) {
        background-color: #1b42c7;
        animation-delay: 0.2s;
        opacity: 0.6;
    }

    .loader--dot:nth-child(4) {
        background-color: #1b42c7;
        animation-delay: 0.15s;
        opacity: 0.8;
    }

    .loader--dot:nth-child(5) {
        background-color: #1b42c7;
        animation-delay: 0.1s;
        opacity: 1;
    }

    .loader--text {
        position: absolute;
        top: 200%;
        left: 0;
        right: 0;
        width: 4rem;
        margin: auto;
        color: white;
    }

    .loader--text:after {
        content: "Loading";
        font-weight: bold;
        animation-name: loading-text;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }

    @keyframes loader {
        15% {
            transform: translateX(0);
        }
        45% {
            transform: translateX(230px);
        }
        65% {
            transform: translateX(230px);
        }
        95% {
            transform: translateX(0);
        }
    }

    @keyframes loading-text {
        0% {
            content: "Loading";
        }
        25% {
            content: "Loading.";
        }
        50% {
            content: "Loading..";
        }
        75% {
            content: "Loading...";
        }
    }

</style>
