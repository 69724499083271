<template>
    <div class="pt-5 pl-5 pr-5" v-if="calendarOptions.events.length > 0">
        <FullCalendar
                :options="calendarOptions"/>
    </div>
</template>
<script>
    import FullCalendar from '@fullcalendar/vue';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import {mapActions, mapMutations, mapState} from "vuex";
    import moment from "moment";

    export default {
        components: {
            FullCalendar // make the <FullCalendar> tag available
        },
        data() {
            return {
                calendarStaticOptions: {
                    plugins: [ timeGridPlugin, interactionPlugin ],
                    initialView: 'timeGridWeek',
                    dateClick: this.handleDateClick,
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'timeGridWeek,timeGridDay'
                    },
                    navLinks: true,
                    editable: true,
                    dayMaxEvents: true,
                    events: [],
                    eventChange: this.eventChange
                }
            }
        },
        computed:{
            ...mapState('Client', ['client']),
            calendarOptions() {
                let calendarStaticOptions = this.calendarStaticOptions;
                if(!this.client.intervenantList){
                    return calendarStaticOptions;
                }
                calendarStaticOptions.events = [];
                this.client.intervenantList.map( (item) => {
                    let value = {
                        id: item.id,
                        userId: item.intervenant ? item.intervenant.id : null,
                        title: `${item.intervenant.lastName} ${item.intervenant.firstName}`,
                        start: item.dateStart ? moment(item.dateStart).format('YYYY-MM-DD HH:mm') : null,
                        end: item.dateEnd ? moment(item.dateEnd).format('YYYY-MM-DD HH:mm') : null,
                    };
                    calendarStaticOptions.events.push(value);
                    return value
                });
                return calendarStaticOptions;
            },
        },
        methods: {
            ...mapActions('Client', ['getClient', 'postAssignUser']),
            ...mapMutations('Client', ['setClientProperty']),
            handleDateClick(arg) {
                //TODO add new event
                console.info(arg);
            },
            eventChange({event}){
                let eventIntervention = this.calendarOptions.events.find((c) => parseInt(c.id) === parseInt(event.id));
                this.submitAssignUser({
                    ...eventIntervention,
                    start: event.startStr,
                    end: event.endStr
                });
            },
            submitAssignUser({id, userId, start, end}) {
                start = moment(start).subtract(2,"hour").format();
                end = moment(end).subtract(2,"hour").format();
                this.setClientProperty({name: "assignClientIid", value: this.client.id});
                this.setClientProperty({name: "assignUserId", value: userId});
                this.setClientProperty({name: "assignId", value: id});
                this.setClientProperty({name: "startDate", value: start});
                this.setClientProperty({name: "endDate", value: end});
                this.postAssignUser().then((res) => {
                    this.$notify.success('Le Affecter a été modifié')
                    this.$bvModal.hide('intervenant_assign');
                    return res;
                }).catch((err) => {
                    this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies");
                    throw err;
                });
            },
        },
        async mounted(){
            await this.getClient({id: this.$route.params.id});
        }
    }
</script>
<style lang="scss">
    .fc-view-harness{
        table thead,
        table tbody {
            display: contents;
        }
        padding-bottom: calc(50vw - 205px)!important;
        .fc-v-event{
            background-color: #4065e3!important
        }
        .fc-day-today{
            background-color: #4065e34d!important;
        }
    }

    .fc-header-toolbar {
        .fc-button-primary {
            background-color: #4065e3 !important;
            border-color: #4065e3 !important;

            &.fc-button-active {
                color: #4065e3 !important;
                background-color: #ffffff !important;
                border-color: #4065e3 !important;
            }
        }
    }
</style>