import gql from 'graphql-tag'

export const GLOBAL_FILTER_STRUCTURE_FROM_SESSION = gql`
    query globalStructureFromSession {
        globalStructureFromSession {
            id,
            name,
        }
    }
`
