import {client as ApolloClient} from '@/api/Apollo';
import { GET_CONTACT } from '@/api/GraphQLQueries/evaluation/contact/get-contact-query'

export const getContact = ({ commit }, { id }) => {
    commit('setContactLoading');
    return ApolloClient.query({
        query: GET_CONTACT,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setContactSuccess', res.data.contact);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setContactError', err.response ? err.response.data : err);
        throw err;
    })
};

export default {
    getContact
};
