<template>
    <div class="container pt-4">
      <h5 class="eval-item--title">Habitat et environnement de proximité</h5>
      <div class="eval-item--card cr--context">
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Type de logement :</p>
            <p class="response">{{logementType}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Accès au logement :</p>
            <p class="response">{{logementAccess}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Eléments posant des difficultés dans la chambre :</p>
            <p class="response">{{bedroomDifficulties}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Eléments posant des difficultés dans les autres pièces de vie et espaces de circulation :</p>
            <p class="response">{{otherPlacesDifficulties}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p class="mb-0 font-weight-bold">Equipement de la salle de bain et éléments posant des difficultés</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 cr_entry">
            <p>Equipement de la salle de bain :</p>
            <p class="response">{{bathroomEquipment}}</p>
          </div>
          <div class="col-sm-12 col-md-4 cr_entry" v-if="bathroomDifficulties.display">
            <p>Eléments posant des difficultés :</p>
            <p class="response">{{bathroomDifficulties.resp}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p class="mb-0 font-weight-bold">Equipement des WC et éléments posant des difficultés</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 cr_entry">
            <p>Equipement des WC :</p>
            <p class="response">{{toiletEquipment}}</p>
          </div>
          <div class="col-sm-12 col-md-4 cr_entry" v-if="toiletDifficulties.display">
            <p>Eléments posant des difficultés :</p>
            <p class="response">{{toiletDifficulties.resp}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Commerces et services de proximité ( à moins de 10 mn de marche) :</p>
            <p class="response">{{closeShops}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Moyens de transport à proximité (a moins de 10 mn à pied) :</p>
            <p class="response">{{closeTransports}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">Commentaires de l'évaluateur sur l'habitat et l'environnement de proximité :</p>

            <form-textarea
                v-model="habitatComment"
                :rows="3"
                class="w-100"
                :login-input="true"
                :disabled="false"
                @blur="changeCommentaryValue()"
            />
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import CRComponent from "./CRComponent";
import {mapMutations} from "vuex";

export default {
  name: "CR_Habitat",
  extends: CRComponent,
  created() {
    this.DEFAULT_VALUE = '--';
  },
  methods: {
    ...mapMutations('Evaluation', ['setEvaluationProperty']),
    exportSynthesis() {
      return {
          logement: this.logementType,
          logementAccess: this.logementAccess,
          bedroomDifficulties: this.bedroomDifficulties,
          otherPlacesDifficulties: this.otherPlacesDifficulties,
          bathroomEquipment: this.bathroomEquipment,
          bathroomDifficulties: this.bathroomDifficulties.display ? this.bathroomDifficulties.resp : "Non concerné",
          toiletEquipment: this.toiletEquipment,
          toiletDifficulties: this.toiletDifficulties.display ? this.toiletDifficulties.resp : "Non concerné",
          closeShops: this.closeShops,
          closeTransports: this.closeTransports,
          habitatComment: this.habitatComment,
      }
    }
  },
  computed: {
    logementType() {
      const logType = this.getQuestionAnswers(11).list.join(', ');
      const roomNb = this.getQuestionAnswers(15).list.join(', ');
      let surface = this.getQuestionAnswers(14);
      const localisation = this.getQuestionAnswers(10).list.join(', ');

      if(surface.responsesIds && surface.responsesIds[0] === 0 ) {
        surface = "inférieure à 20 M2"
      } else if(surface.responsesIds && surface.responsesIds[0] === 5 ) {
        surface = "supérieure à 100 M2"
      } else {
        surface = surface.list.join(',')
      }

      return logType + " de "+ roomNb + " pièce(s), d'une surface " + surface + ", en milieu " + localisation;
    },
    logementAccess() {
      return this.getQuestionAnswers(13).list.join(', ');
    },
    bedroomDifficulties() {
      return this.getQuestionAnswers(16).list.join(', ');
    },
    otherPlacesDifficulties() {
      return this.getQuestionAnswers(17).list.join(', ');
    },
    bathroomEquipment() {
      return this.getQuestionAnswers(18).list.join(', ');
    },
    bathroomDifficulties() {
      const cond = this.getQuestionAnswers(18).responsesIds;
      if (cond && !cond.includes(4)) {
        return {
          resp: this.getQuestionAnswers(19).list.join(', '),
          display: true
        }
      }
      return {
        resp: null,
        display: false
      }
    },
    toiletEquipment() {
      return this.getQuestionAnswers(20).list.join(', ');
    },
    toiletDifficulties() {
      const cond = this.getQuestionAnswers(20).responsesIds;
      if (cond && !cond.includes(3)) {
        return {
          resp: this.getQuestionAnswers(21).list.join(', '),
          display: true
        }
      }
      return {
        resp: null,
        display: false
      }
    },
    closeShops() {
      return this.getQuestionAnswers(22).list.join(', ');
    },
    closeTransports() {
      return this.getQuestionAnswers(23).list.join(', ');
    },
    commentsHabitat() {
      return this.getQuestionAnswers(24).list.join(', ');
    },
    habitatComment: {
      get() {
        return this.evaluation.evaluatorComments ? this.evaluation.evaluatorComments.habitatComment : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.habitatComment = value;
      }
    },
  }
};
</script>