export default {
    setUserProperty(state, property) {
        if (!state.user[property.name]) {
            state.user[property.name] = "";
        }
        state.user[property.name] = property.value;
    },
    setPutUserDataProperty(state, property) {
        if (!state.putUserData[property.name]) {
            state.putUserData[property.name] = "";
        }
        state.putUserData[property.name] = property.value;
    },
    setConnectedUser(state, connectedUser) {
        state.connectedUser = {
         ...connectedUser,
         role: connectedUser && connectedUser.role ? connectedUser.role.label : null
        };
    },
    setUserList(state, value) {
        state.users = value
        state.loading = false
        state.error = null
    },
    setRoleList(state, value) {
        state.roleList = value
        state.loading = false
        state.error = null
    },
    setUserLoading(state) {
        state.loading = true
        state.error = null
    },
    setUserSaveLoading(state) {
        state.saveLoading = true;
        state.error = null;
    },
    setTotalUserList(state, total) {
        state.total = total
    },
    setUserSuccess(state, user) {
        state.user = {
            ...user,
            // TODO : ERROR HUGO pas rôle dispo en db pour le user connecté
            roleId: user.role && user.role.id ? user.role.id : 3,
            agencies: user.agencies ? user.agencies : null,
            agenciesId: user.agencies ? user.agencies.map((agency) => agency.id) : null,
            structureId: user.structure ? user.structure.id : null,
            managerId: user.manager ? user.manager.id : null,
            password: user.password ? user.password: null
        };
        state.loading = false;
        state.saveLoading = false;
        state.error = null;
    },
    setInitUser(state) {
        state.user = {
            id: null,
            archived: null,
            roleId: null,
            intervenantType: null,
            status: false,
            password: null,
            agenciesId: [],
            agencies: [],
            structureId: null,
            structure: null,
            email: '',
            lastName: '',
            firstName: '',
            birthName: '',
            birthDate: null,
            effectiveDate: null,
            type: '',
            address: '',
            addressComplement: '',
            city: '',
            cp: '',
            sector: '',
            portablePhoneNumber: '',
            homePhoneNumber: '',
            personalEmail: '',
            homeProfesionnalPhoneNumber: '',
            portableProfesionnalPhoneNumber: '',
            profesionnalEmail: '',
            manager: null,
            managerId: null,
            role: null,
            hasPassword: null,
        };
        state.error = null;
    },
    setUserError(state, error) {
        state.loading = false;
        state.saveLoading = false;
        state.error = error
    },
    setSendUserPasswordSuccess(state) {
        state.loading = false;
    },
    setPostUserSuccess(state, user) {
        state.users.push(user);
        state.saveLoading = false;
        state.error = null;
        state.total = state.total + 1
    },
    setPutUserSuccess(state, user) {
        state.users.splice(
            state.users.findIndex(userSearch => userSearch.id === user.id),
            1,
            user
        );
        state.saveLoading = false;
        state.error = null;
    },
    setDeleteUserSuccess(state, id) {
        state.users.splice(
            state.users.findIndex(user => user.id == id),
            1
        );
        state.loading = false;
        state.error = null;
        state.total = state.total - 1
    },
    setEditUserPasswordLoading(state) {
        state.passwordSaveLoading = true
        state.error = null
    },
    setEditUserPasswordSuccess(state, user) {
        state.users.splice(
            state.users.findIndex(user => user.id === user.id),
            1,
            user
        );
        state.saveLoading = false;
        state.error = null;
    },
    setEditUserPasswordError(state, error) {
        state.loading = false
        state.passwordSaveLoading = false
        state.error = error
    }

}