<template>
  <div class="dropdown" v-click-outside="close">
    <div class="dropdown--toggle" @click="toggle">
      <span class="dropdown--toggle-text">{{prepend}}{{val}}{{append}}</span>
    </div>
    <div class="dropdown--menu" :class="{'active':active}">
      <div class="dropdown--item" v-for="(option, index) in options" :key="index">
        <input type="radio" @change="close" v-model="val" :id="`${name}-${option}`" :value="option" :name="name" />
        <label :for="`${name}-${option}`">{{prepend}}{{option}}{{append}}</label>
      </div>
    </div>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
export default {
  name: "Dropdown",
  props: {
    value: {
      type: [String,Number],
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    prepend: {
      type: String,
      default: null
    },
    append: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      active: false,
      val: this.value
    };
  },
  computed: {
    name() {
      var chars =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()_-+={}[]><.,?/~|",
        id = [];
      for (let index = 0; index < 5; index++) {
        var randomIndex = Number(Math.random() * chars.length).toFixed(0);
        id.push(chars[randomIndex]);
      }
      id = id.join("");
      return id;
    }
  },
  watch: {
    val(v) {
      this.$emit("input", v);
    }
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },
    close() {
      this.active = false;
    }
  },
  directives: {
    ClickOutside
  }
};
</script>