<template>
    <div class="container pt-4">
      <h5 class="eval-item--title">Planning prévisionnel sur 2 semaines</h5>
      <div class="eval-item--card cr--context">
        <Planning
          :isEditable="false"
        />
        <div class="row mt-4">
          <div class="col-sm-12">
            <p class="mb-0">Expression de la personne sur le plan d’aide, le plan d’intervention et le planning proposé :</p>
            <form-textarea
                v-model="clientComment"
                :rows="3"
                class="w-100"
                :login-input="true"
                :disabled="false"
                @blur="changeCommentaryValue()" />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">Expression du tiers présent sur le plan d’aide, le plan d’intervention et le planning proposé :</p>
            <form-textarea
                v-model="tiersComment"
                :rows="3"
                class="w-100"
                :login-input="true"
                :disabled="false"
                @blur="changeCommentaryValue()" />
          </div>
        </div>
      </div>

    </div>
</template>
<script>
import {mapState} from "vuex";
import CRComponent from "./CRComponent";
import Planning from "@/components/Eval/Planning";
import html2canvas from "html2canvas";

export default {
  name: "CR_Planning",
  extends: CRComponent,
  components: {
    Planning
  },
  created() {
    this.DEFAULT_VALUE = 'non renseigné';
  },
  methods: {
    async exportSynthesis() {
      const imgData = await this.handleCapture();
      return {
        planningImg: imgData,
        clientComment: this.clientComment,
        tiersComment: this.tiersComment
      }
    },
    async handleCapture() {
      const captureContainer = document.querySelector(".planning--table-wrap");
      const canvas = await html2canvas(captureContainer, {
        backgroundColor: '#ffffff',
        allowTaint: false,
        useCORS: true,
        scrollX: 0,
        scrollY: -window.scrollY
      })
      if(!canvas) {
        return null;
      }
      return canvas.toDataURL("image/jpg");

    }
  },
  computed: {
    ...mapState("Evaluation", ["evaluation"]),
    clientComment: {
      get() {
        return this.evaluation.evaluatorComments ? this.evaluation.evaluatorComments.clientComment : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.clientComment = value;
      }
    },
    tiersComment: {
      get() {
        return this.evaluation.evaluatorComments ? this.evaluation.evaluatorComments.tiersComment : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.tiersComment = value;
      }
    },
  }
};
</script>