import gql from 'graphql-tag'

export const GLOBAL_FILTER_STRUCTURE = gql`
    mutation setGlobalStructure(
        $id: Int!
    ) {
        setGlobalStructure(
            id: $id
        ) {
            id,
            name,
            erpId,
            address,
            status,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            },
        }
    }
`
