export const imcCalcul = (weight, size) => {
  if (!weight || !size || weight < 0 || weight > 600 || size < 0 || size > 300) {
    return 0;
  }
  const sizeInMeter = size * 0.01;
  let IMC = Math.round((weight / (sizeInMeter * sizeInMeter)) * 10) / 10;
  if(isNaN(IMC)) IMC=0;
  return IMC;
};

export const mnaCalcul = (
  peopleStr,
  imc,
  sn03,
  sn04,
  sn05,
  sn06,
  aggirMoveIn,
  aggirCoherence
) => {
  if (!imc || !sn03 || sn03 === "r1") {
    return null;
  }
  if (!aggirMoveIn || !aggirCoherence) {
    return {
      score: "--",
      interpretation: "MNA non calculable, grille AGGIR à compléter d'abord",
    };
  }

  let total = 0;
  if (sn04 === "r2") {
    total += 1;
  }
  if (sn04 === "r3") {
    total += 2;
  }
  if (sn05 === "r2") {
    total += 1;
  }
  if (sn05 === "r3") {
    total += 2;
  }
  if (sn05 === "r4") {
    total += 3;
  }

  //calcul by aggir moving
  if (aggirMoveIn) {
    if (aggirMoveIn === "A") {
      total += 2;
    } else if (aggirMoveIn === "B") {
      total += 1;
    }
  }

  //calcul by aggir coherence
  if (aggirCoherence) {
    if (aggirCoherence === "A") {
      total += 2;
    } else if (aggirCoherence === "B") {
      total += 1;
    }
  }

  if (sn06 === "r2") {
    total += 2;
  }

  if (19 <= imc && imc < 21) {
    total += 1;
  }
  if (21 <= imc && imc < 23) {
    total += 2;
  }
  if (imc > 23) {
    total += 3;
  }

  if (total < 8) {
    return {
      score: total,
      interpretation:
        peopleStr +
        " est en état de dénutrition avérée, suivi médical fortement recommandé.",
    };
  }
  if (8 <= total && total < 12) {
    return {
      score: total,
      interpretation: peopleStr + " à risque de dénutrition, suivi recommandé.",
    };
  }
  if (12 <= total && total < 15) {
    return {
      score: total,
      interpretation: peopleStr + " est en état nutritionnel normal.",
    };
  }
  return null;
};

export const chairLift = (peopleStr, sfm03) => {
  if (!sfm03) {
    return "Test n'ayant pu être réalisé.";
  }
  if (sfm03 < 11.2) {
    return `Test réalisé en ${parseFloat(sfm03).toFixed(
      1
    )} s, soit 4 points sur 4 : ${peopleStr} a une bonne mobilité.`;
  }
  if (sfm03 >= 11.2 && sfm03 < 11.99) {
    return `Test réalisé en ${parseFloat(sfm03).toFixed(
      1
    )} s, soit 3 points sur 4 : ${peopleStr} a une mobilité correcte.`;
  }
  if (sfm03 >= 12 && sfm03 <= 15) {
    return `Test réalisé en ${parseFloat(sfm03).toFixed(
      1
    )} s, soit 2 points sur 4 : ${peopleStr} a une mobilité critique. Évaluation approfondie recommandée.`;
  }
  if (sfm03 >= 15.1 && sfm03 <= 59.9) {
    return `Test réalisé en ${parseFloat(sfm03).toFixed(
      1
    )} s, soit 1 point sur 4 : ${peopleStr} a une mobilité réduite. Risque de chute important. Évaluation approfondie recommandée.`;
  }
  if (sfm03 > 60) {
    return `Test réalisé en ${parseFloat(sfm03).toFixed(
      1
    )} s, soit 0 point sur 4 : ${peopleStr} a une mobilité très réduite ou impossible. Risque de chute important. Évaluation approfondie recommandée.`;
  }
  return "Test n'ayant pu être réalisé.";
};

export const timeUpAndGo = (peopleStr, sfm04) => {
  if (!sfm04) {
    return "Test n'ayant pu être réalisé.";
  }
  if (sfm04 < 4.82) {
    return `Test réalisé en ${parseFloat(sfm04).toFixed(
      1
    )} s, soit 4 points sur 4 : ${peopleStr} a une vitesse de marche normale.`;
  }
  if (sfm04 >= 4.82 && sfm04 <= 6.2) {
    return `Test réalisé en ${parseFloat(sfm04).toFixed(
      1
    )} s, soit 3 points sur 4 : ${peopleStr} a une vitesse de marche correcte.`;
  }
  if (sfm04 >= 6.21 && sfm04 <= 8.7) {
    return `Test réalisé en ${parseFloat(sfm04).toFixed(
      1
    )} s, soit 2 points sur 4 : ${peopleStr} a une vitesse de marche légèrement ralentie. Évaluation approfondie recommandée.`;
  }
  if (sfm04 >= 8.71 && sfm04 <= 14) {
    return `Test réalisé en ${parseFloat(sfm04).toFixed(
      1
    )} s, soit 1 point sur 4 : ${peopleStr} a une vitesse de marche ralentie. Évaluation approfondie recommandée.`;
  }
  if (sfm04 > 14) {
    return `Test réalisé en ${parseFloat(sfm04).toFixed(
      1
    )} s, soit 0 point sur 4 : ${peopleStr} a une mobilité très ralentie ou impossible. Évaluation approfondie recommandée.`;
  }
  return "Test n'ayant pu être réalisé.";
};

export const balance = (peopleStr, sfm101, sfm102, sfm103) => {
  if (!sfm101 || !sfm102 || !sfm103) {
    return {
      total: 0,
      interpretation: "Test n'ayant pu être réalisé.",
    };
  }
  let total = 0;
  if (sfm101 >= 10) {
    total += 1;
  }
  if (sfm102 >= 10) {
    total += 1;
  }
  if (sfm103 > 3 && sfm103 < 10) {
    total += 1;
  } else if (sfm103 >= 10) {
    total += 2;
  }

  switch (total) {
    case 4:
      return {
        total,
        interpretation: `${total}/4 points : ${peopleStr} n'a aucun problème au test d'équilibre.`,
      };
    case 3:
      return {
        total,
        interpretation: `${total}/4 points : ${peopleStr} a effectué un test d'équilibre légèrement perturbé.`,
      };
    case 2:
      return {
        total,
        interpretation: `${total}/4 points : ${peopleStr} a effectué un test d'équilibre modérement perturbé.`,
      };
    case 1:
      return {
        total,
        interpretation: `${total}/4 points : ${peopleStr} a effectué un test d'équilibre très perturbé.`,
      };
    case 0:
      return {
        total,
        interpretation: `${total}/4 points : ${peopleStr} n'a aucun équilibre au test d'équilibre.`,
      };
  }

  return {
    total,
    interpretation: "Test n'ayant pu être réalisé.",
  };
};

export const movingInterpretation = (
  peopleStr,
  deplInt,
  deplExt,
  materials,
  idMaterielsCheck
) => {
  if (!deplExt || !deplInt) {
    return null;
  }

  let listNameMaterialsUsed = null;
  if (materials) {
    listNameMaterialsUsed = materials
      .reduce((tabNameMaterialsUsed, materiel) => {
        if (
          materiel.material &&
          idMaterielsCheck.includes(materiel.material.id)
        ) {
          tabNameMaterialsUsed.push(materiel.material.name);
        }
        return tabNameMaterialsUsed;
      }, [])
      .join(" ; ");
  }

  if (listNameMaterialsUsed && listNameMaterialsUsed !== "") {
    listNameMaterialsUsed = ` et utilise : ${listNameMaterialsUsed}, pour ses déplacements.`;
  } else {
    listNameMaterialsUsed = ".";
  }
  switch (deplInt + deplExt) {
    case "AA":
      return `${peopleStr} se déplace de manière autonome à l'intérieur comme a l'extérieur${listNameMaterialsUsed}`;
    case "AB":
      return `${peopleStr} se déplace de manière autonome à l'intérieur et partiellement autonome a l'extérieur${listNameMaterialsUsed}`;
    case "BA":
      return `${peopleStr} se déplace de manière partiellement autonome à l'intérieur et  autonome a l'extérieur${listNameMaterialsUsed}`;
    case "BB":
      return `${peopleStr} se déplace de manière partiellement autonome à l'intérieur comme a l'extérieur${listNameMaterialsUsed}`;
    case "BC":
      return `${peopleStr} se déplace de manière partiellement autonome à l'intérieur mais pas à l'extérieur${listNameMaterialsUsed}`;
    case "CB":
      return `${peopleStr} ne se déplace pas de manière autonome à l'intérieur mais partiellement à l'extérieur${listNameMaterialsUsed}`;
    case "CC":
      return `${peopleStr} n'a aucune autonomie pour se déplacer tant à l'intérieur qu'a l'expérieur${listNameMaterialsUsed}`;
  }
  return null;
};

const physiqueCondition = (
  gir,
  getScoreQuestionAggirOrientationTemps,
  getScoreQuestionAggirOrientationEspace,
  getScoreQuestionAggirCoherenceComportement,
  getScoreQuestionAggirCoherenceCommunication,
  getScoreQuestionAggirMoveIn,
  getScoreQuestionAggirTransfert
) => {
  if (
    !gir ||
    !getScoreQuestionAggirOrientationTemps ||
    !getScoreQuestionAggirOrientationEspace ||
    !getScoreQuestionAggirCoherenceComportement ||
    !getScoreQuestionAggirCoherenceCommunication ||
    !getScoreQuestionAggirMoveIn ||
    !getScoreQuestionAggirTransfert
  ) {
    return null;
  }
  if (gir === 6 || (gir === 5 && getScoreQuestionAggirMoveIn === "A")) {
    return 4;
  }
  if (
    (gir === 5 &&
      (getScoreQuestionAggirMoveIn === "B" ||
        getScoreQuestionAggirMoveIn === "C")) ||
    (gir === 4 &&
      getScoreQuestionAggirMoveIn === "A" &&
      getScoreQuestionAggirTransfert === "A")
  ) {
    return 3;
  }
  if (
    (gir === 4 &&
      (getScoreQuestionAggirMoveIn !== "A" ||
        getScoreQuestionAggirTransfert !== "A")) ||
    gir === 3
  ) {
    return 2;
  }
  return 1;
};

const brainCondition = (
  orientationTemps,
  orientationEspace,
  coherenceComportement,
  coherenceCommunication
) => {
  if (
    !orientationTemps ||
    !orientationEspace ||
    !coherenceComportement ||
    !coherenceCommunication
  ) {
    return null;
  }
  if (
    orientationTemps === "A" &&
    orientationEspace === "A" &&
    coherenceComportement === "A" &&
    coherenceCommunication === "A"
  ) {
    return 4;
  }
  if (
    (coherenceComportement === "B" || coherenceComportement === "C") &&
    coherenceCommunication === "B"
  ) {
    return 3;
  }
  if (
    (coherenceComportement === "B" || coherenceComportement === "C") &&
    coherenceCommunication === "C"
  ) {
    return 1;
  }
  return 2;
};

const activity = (deplacementInt, transfert) => {
  if (!deplacementInt) {
    return null;
  }
  if (deplacementInt === "A") {
    return 4;
  }
  if (deplacementInt === "B") {
    return 3;
  }
  if (deplacementInt === "B" && (transfert === "B" || transfert === "C")) {
    return 2;
  }
  return 1;
};

const mobility = (deplacementInt, transfert) => {
  if (!deplacementInt || !transfert) {
    return null;
  }
  if (deplacementInt === "A" && transfert === "A") {
    return 4;
  }
  if (
    (transfert === "B" && (deplacementInt === "A" || deplacementInt === "B")) ||
    (deplacementInt === "B" && (transfert === "A" || transfert === "B"))
  ) {
    return 3;
  }
  if (deplacementInt === "C" && transfert === "C") {
    return 1;
  }
  return 2;
};

const incontinence = (eliminationFec, eliminationUri) => {
  if (!eliminationFec) {
    return null;
  }
  if (eliminationFec === "A" && eliminationUri === "A") {
    return 4;
  }
  if (eliminationFec === "B" || eliminationUri === "B") {
    return 3;
  }
  if (eliminationFec === "C" || eliminationUri === "C") {
    return 1;
  }
  return 2;
};

export const nortonCalcul = (
  peopleStr,
  gir,
  orientationTemps,
  orientationEspace,
  coherenceComportement,
  coherenceCommunication,
  deplacementInt,
  transfert,
  eliminationFec,
  eliminationUri
) => {
  const physique = physiqueCondition(
    gir,
    orientationTemps,
    orientationEspace,
    coherenceComportement,
    coherenceCommunication,
    deplacementInt,
    transfert
  );
  const brain = brainCondition(
    orientationTemps,
    orientationEspace,
    coherenceComportement,
    coherenceCommunication
  );
  const acti = activity(deplacementInt, transfert);
  const mobi = mobility(deplacementInt, transfert);
  const incon = incontinence(eliminationFec, eliminationUri);

  if (!physique || !brain || !acti || !mobi || !incon) {
    return {
      total: "--",
      interpretation: `Test non vérifié. Vérifier que les données suivantes de la grille AGGIR soient bien remplies : Orientation ; Cohérence ; Déplacement ; Transfert ; Elimination.`,
    };
  }
  let total = physique + brain + acti + mobi + incon;
  if (total === 0) {
    return null;
  }
  if (17 <= total && total <= 20) {
    return {
      total: total,
      interpretation: `${peopleStr} est sans risque d'escarre.`,
    };
  }
  if (15 <= total && total <= 16) {
    return {
      total: total,
      interpretation: `${peopleStr} présente un Faible risque d'escarre.`,
    };
  }
  if (13 <= total && total <= 14) {
    return {
      total: total,
      interpretation: `${peopleStr} présente un risque modéré d'escarre.`,
    };
  }
  if (10 <= total && total <= 12) {
    return {
      total: total,
      interpretation: `${peopleStr} présente un risque élevé d'escarre.`,
    };
  }
  return {
    total: total,
    interpretation: `${peopleStr} présente un risque très elevé d'escarre.`,
  };
};

export const miniCogCalcul = (peopleStr, smd03, smd02) => {
  if (smd03 === null || smd02 === null) {
    return {
      total: 0,
      interpretation:
        "Veuillez compléter toutes les informations pour avoir une interprétation des résultats.",
    };
  }
  if (typeof smd03 === "string") {
    smd03 = smd03.split(",");
  }
  let total = smd03.length;

  if (smd02 === "r1") {
    total += 2;
  }
  if (total >= 4) {
    return {
      total,
      interpretation: `${peopleStr} a un test mini-cog de ${total}/5. La mémoire et les capacités de réflexion semblent satisfaisantes.`,
    };
  }
  if (3 === total) {
    return {
      total,
      interpretation: `${peopleStr} a un test mini-cog de ${total}/5. La mémoire et les capacités de réflexion semblent partiellement perturber, une exploration spécialisée pourrait s'avérer utile.`,
    };
  }
  if (3 > total) {
    return {
      total,
      interpretation: `${peopleStr} a un test mini-cog de ${total}/5. La mémoire et les capacités de réflexion semblent significativement perturbées et  nécessitent d'être complétées par une exploration spécialisée.`,
    };
  }
  return null;
};

export const getICOPECognition = (
  smd03,
  orientationTemps,
  orientationEspace
) => {
  if (!smd03) {
    return "Le test n'a pas pu être réalisé.";
  }
  if (typeof smd03 === "string") {
    smd03 = smd03.split(",");
  }

  if (
    smd03.length === 0 &&
    (orientationTemps === "B" || orientationTemps === "C") &&
    (orientationEspace === "B" || orientationEspace === "C")
  ) {
    return `Les tests d'orientation du programme ICOPE sont nettement perturbés, une évaluation complète de ce domaine est recommandée.`;
  } else if (
    smd03.length >= 0 &&
    smd03.length < 3 &&
    orientationTemps === "A" &&
    orientationEspace === "A"
  ) {
    return `L'orientation est correcte mais le test de mémorisation des 3 mots du programme ICOPE est perturbés, une évaluation complète de ce domaine est recommandée`;
  } else if (
    smd03.length === 3 &&
    orientationTemps === "A" &&
    orientationEspace === "A"
  ) {
    return `Les tests de mémorisation et d'orientation du programme ICOPE ne sont pas perturbés.`;
  } else if (
    smd03.length === 3 &&
    (orientationTemps === "B" || orientationTemps === "C") &&
    (orientationEspace === "B" || orientationEspace === "C")
  ) {
    return `Les tests d'orientation du programme ICOPE sont perturbés, la mémorisation des 3 mots est intacte, une évaluation complète de ce domaine est à envisager`;
  } else if (
    smd03.length > 0 &&
    smd03.length < 3 &&
    (orientationTemps === "B" || orientationTemps === "C") &&
    (orientationEspace === "B" || orientationEspace === "C")
  ) {
    return `Les tests d'orientation et de mémorisation du programme ICOPE sont partiellement, perturbés, une évaluation complète de ces domaines est recommandée`;
  } else {
    return `Les tests d'orientation du programme ICOPE sont nettement perturbés, une évaluation complète de ce domaine est recommandée.`;
  }
};

export const getICOPEAudition = (sos05, sos07, sos08) => {
  if (sos07 === "r3" || sos08 === "r3") {
    return "Le test de Wispher n'a pas pu être réalisé.";
  }

  if (
    (sos05 === "r1" || sos05 === "r2" || sos05 === "r3") &&
    sos07 === "r1" &&
    sos08 === "r1"
  ) {
    return "Pas d'anomalie auditive déclarée.";
  }

  if (sos05 === "r1" || sos07 === "r2" || sos08 === "r2") {
    return "Une évaluation complète de l'ouie est recommandée.";
  }

  return null;
};

export const getICOPENutritionSn04 = (sn04) => {
  if (sn04 === "r1") {
    return "Baisse sévère des prises alimentaires - Evaluation complète recommandée";
  }
  if (sn04 === "r2") {
    return "Légère baisse des prises alimentaires - Evaluation recommandée";
  }
  if (sn04 === "r3") {
    return "Pas de baisse des prises alimentaires";
  }
  return null;
};

export const getICOPENutritionSn05 = (sn05) => {
  if (sn05 === "r1") {
    return "Perte de poids > 3 kg - Evaluation complète recommandée";
  }
  if (sn05 === "r2") {
    return "Ne sait pas - Evaluation  recommandée";
  }
  if (sn05 === "r3") {
    return "Perte de poids entre 1 et 3 kg - Evaluation recommandée";
  }
  if (sn05 === "r4") {
    return "Pas de perte de poids";
  }
  return null;
};

export const getICOPENutrition = (sn04, sn05) => {
  if (!sn04 || !sn05) {
    return null;
  }
  const resultSn04 = [
    { value: "r1", label: "Baisse sévère des prises alimentaires" },
    { value: "r2", label: "Légère baisse des prises alimentaires" },
    { value: "r3", label: "Pas de baisse des prises alimentaires" },
  ];
  const nutritionSn04 = resultSn04.find((resp) => resp.value === sn04).label;

  const resultSn05 = [
    { value: "r1", label: "Perte de poids > 3 kg" },
    {
      value: "r2",
      label:
        "Ne sait pas s'il y a eu perte de poids au cours de ces 3 derniers mois",
    },
    { value: "r3", label: "Perte de poids entre 1 et 3 kg" },
    { value: "r4", label: "Pas de perte de poids" },
  ];
  const nutritionSn05 = resultSn05.find((resp) => resp.value === sn05).label;

  let result = nutritionSn04 + " - " + nutritionSn05;

  if (sn04 === "r3" && sn05 === "r4") {
    result += " | " + "ICOPE NUTRITION normal";
  } else if (sn04 === "r2" && (sn05 === "r2" || sn05 === "r4")) {
    result += " | " + "ICOPE NUTRITION légèrement perturbé à surveiller";
  } else if (sn04 === "r3" && (sn05 === "r2" || sn05 === "r3")) {
    result += " | " + "ICOPE NUTRITION légèrement perturbé à surveiller";
  } else {
    result +=
      " | " +
      "ICOPE NUTRITION perturbé, évaluation médicale ou diététique recommandée";
  }

  return result;
};

export const getRSMD05 = (smd04, smd05) => {
  if (!smd04 || !smd05) {
    return null;
  }
  if (smd04 === "r1" || smd05 === "r1") {
    return `Une évaluation complète des symptômes dépressifs est recommandée.`;
  }
  if (smd04 === "r2" && smd05 === "r2") {
    return "Pas d'anomalies détectées au test ICOPE DÉPRESSION";
  }
  return null;
};

export const miniGdsCalcul = (peopleStr, smd04, smd05, smd06, smd07) => {
  if (smd04 === null || smd05 === null || smd06 === null || smd07 === null) {
    return {
      total: "--",
      interpretation: `Veuillez compléter toutes les informations pour avoir une interprétation des résultats.`,
    };
  }
  let total = 0;
  if (smd04 === "r1") {
    total += 1;
  }
  if (smd05 === "r1") {
    total += 1;
  }
  if (smd06 === "r2") {
    total += 1;
  }
  if (smd07 === "r1") {
    total += 1;
  }
  let message = `${peopleStr} a un test mini-GDS de ${total}/4.`;
  if (total === 0) {
    return {
      total: 0,
      interpretation: `${message}, ${peopleStr} est heureux(se) la plupart du temps et ne manifeste aucun sentiment de découragement, de tristesse, de desespoir.`,
    };
  }
  let tabMessage = [];
  if (smd04 === "r1") {
    tabMessage.push(`dit ressentir du découragement et de la tristesse`);
  }
  if (smd05 === "r1") {
    tabMessage.push(`avoir le sentiment que sa vie est vide`);
  }
  if (smd06 === "r2") {
    tabMessage.push(`déclare ne pas être heureux(se) la plupart du temps`);
  }
  if (smd07 === "r1") {
    tabMessage.push(`avoir l'impression que sa situation est désespérée`);
  }
  return {
    total: total,
    interpretation: `${message} ${tabMessage.join(", ")}.${
      total >= 1 ? " Une évaluation complète est recommandée." : ""
    } `,
  };
};

export default {
  imcCalcul,
  mnaCalcul,
  chairLift,
  timeUpAndGo,
  movingInterpretation,
  miniCogCalcul,
  miniGdsCalcul,
  nortonCalcul,
  balance,
  getICOPECognition,
  getICOPENutritionSn04,
  getICOPENutritionSn05,
  getICOPENutrition,
};

export const DEFAULT_AGGIR_VALUE = [
  { id: "A", name: "Autonome" },
  { id: "B", name: "Autonomie partielle" },
  { id: "C", name: "Absence d’autonomie" },
];
