export default {
    setStructureProperty(state, property) {
        if(!state.structure[property.name]){
            state.structure[property.name] = "";
        }
        state.structure[property.name] = property.value;
    },
    setStructureLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setStructureSaveLoading(state) {
        state.error = null;
    },
    setStructureList(state, structures) {
        state.structures = structures
        state.loading = false;
        state.error = null;
    },
    setTotalStructureList(state, total) {
        state.total = total;
    },
    setStructureSuccess(state, structure) {
        state.structure = structure;
        state.loading = false;
        state.error = null;
    },
    setInitStructure(state) {
        state.structure = {
            agency: {},
            agencyId: '',
            erpId: '',
            name: '',
            address: '',
            status: false
        };
        state.error = null;
      },
    setStructureError(state, error) {
        state.loading = false;
        state.error = error;
    },
    setPostStructureSuccess(state, structure) {
        state.structures.push(structure);
        state.error = null;
        state.total = state.total + 1
    },
    setPutStructureSuccess(state, structure) {
        state.structures.splice(
            state.structures.findIndex(structureSearch => structureSearch.id === structure.id),
            1,
            structure
        );
        state.error = null;
    },
    setDeleteStructureSuccess(state, id) {
        state.structures.splice(
            state.structures.findIndex(structure => structure.id == id),
            1
        );
        state.loading = false;
        state.error = null;
        state.total = state.total - 1
    },
    setPostStructureLogo(state) {
        state.error = null;
    },
    setPostStructureLogoSuccess(state) {
        state.error = null;
    },
    setPostStructureLogoError(state, error) {
        state.error = error;
    },
    setGlobalStructureSuccess(state, globalStructure) {
        state.globalStructure = globalStructure
    }
}