<template>
  <custom-modal :active="active" @close="close" class="planification-modal">
    <div class="eval-modal-title">
      <h2>Planification de la prestation</h2>
    </div>
    <div class="eval-modal-content">
        <div v-for="(week, i) in weeks" :key="i">
            <div class="card-title">
                <h4>{{week}}</h4>
                <div v-if="i > 0" class="copy-card">
                    <input
                        type="checkbox"
                        v-model="copyDays"
                        @change="copyDatas()"
                    />
                    <label>Identique</label>
                </div>
            </div>
            <div class="week-card">
                <div v-for="(day, index) in days" :key="index" @click="saveWeekDays(week, day.key)">
                    <span class="aggirgrid--check-item" :class="choosenWeekDays.includes(gen(week, day.key)) ? 'blue' : ''"></span>
                    <label>{{ day.value }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="prestation-modal-footer">
        <div class="time-estimation">
            <span>Temps estimé:</span>
            <span>{{calculTempsEstime()}} min</span>
        </div>
        <div class="time-validated">
            <span>Temps validé:</span>
            <input @input="checkValue()" v-model="validTime" />
        </div>
    </div>
    <div class="save-prestation">
        <button @click.prevent="confirmUpdate()" :disabled="saveLoading">
          <font-awesome-icon v-show="saveLoading" :icon="['fas', 'spinner']" spin/>
          <span>{{this.carePlanPrestationId ? 'Valider' : 'Enregistrer'}} la prestation</span>
        </button>
    </div>
  </custom-modal>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {TEMPS_SCORE, ORD_DAYS} from "@/components/Eval/CalculTempsEstime";
export default {
  name: "PlanificationPrestationModal",
  props: {
    active: {
      type: Boolean,
    },
    periode: {
        type: String,
    },
    comments: {
        type: Object,
        default:()  => []
    },
    typeAide: {
        type: Object,
    },
    gridItems: {
      type: Array,
    },
    carePlanPrestationId: {
      type: Number,
      default:()  => null
    },
    choosenDays: {
      type: Object,
      default:()  => {}
    },
    estimTime: {
      type: String,
      default:()  => '0'
    },
  },
  data() {
    return {
      weeks: ['Semaine 1', 'Semaine 2'],
      days: [
          {key: 'lundi', value: 'Lundi'},
          {key: 'mardi', value: 'Mardi'},
          {key: 'mercredi', value: 'Mecredi'},
          {key: 'jeudi', value: 'Jeudi'},
          {key: 'vendredi', value: 'Vendredi'},
          {key: 'samedi', value: 'Samedi'},
          {key: 'dimanche', value: 'Dimanche'},
      ],
      choosenWeekDays: [],
      copyDays: false,
      saveLoading: false,
    };
  },
  computed: {
    ...mapState("CarePlanPrestation", ["carePlanPrestation", "commentaires"]),
    ...mapState("Evaluation", ["evaluation"]),
    weekDays() {
      return this.choosenDays ?? {};
    },
    validTime: {
      // getter
      get: function () {
        return this.carePlanPrestation.time ?? '0'
      },
      // setter
      set: function (newValue) {
        this.setCarePlanPrestationProperty({
          name: 'time',
          value: newValue
        })
      }
    }
  },
  methods: {
    ...mapActions("CarePlanPrestation", ["postCarePlanPrestation"]),
    ...mapActions("Evaluation", ["updateHeuresSemaineDimanche"]),
    ...mapMutations("CarePlanPrestation", ["setCarePlanPrestationProperty", "setCarePlanPrestation"]),
    close(data = null) {
      this.calculateDays(data);
      this.$emit("close", data);
      this.choosenWeekDays = [];
    },
    gen(week, day) {
        return week + day;
    },
    saveWeekDays(week, day) {
        if(this.choosenWeekDays.includes(this.gen(week,day))) {
            let weekDay = this.weekDays[week];
            weekDay = weekDay.filter(elt => {
                return elt !== day;
            });
            if(weekDay.length > 0) {
              this.weekDays[week] = weekDay;
            }else{
              delete this.weekDays[week];
            }
            this.choosenWeekDays = this.choosenWeekDays.filter(elt => {
                return elt != this.gen(week, day);
            })
        }else{
            if(this.weekDays[week]) {
                this.weekDays[week].push(day);
            }else{
                this.weekDays[week] = [day];
            }
            this.choosenWeekDays.push(this.gen(week, day));
        }
        this.copyDatas();
    },
    confirmUpdate() {
      if(this.carePlanPrestationId) {
        this.$confirm('Êtes vous sûr de vouloir modifier cette prestation ?').then(() => {
            this.savePrestation();
        }).catch(() => {});
      }else{
        this.savePrestation();
      }
    },
    savePrestation() {
        if(Object.keys(this.weekDays).length <= 1) {
          this.$notify.error('Vous devez choisir au moins un jour de chaque semaine');
        }else{
          this.setCarePlanPrestationProperty({
            name: 'time',
            value: (this.validTime.toString().trim().length === 0 || parseInt(this.validTime) === 0) ? ''+this.calculTempsEstime() : this.validTime
          });
          this.saveLoading = true;

          if(this.carePlanPrestationId) {
            this.carePlanPrestation.id = this.carePlanPrestationId;
          }
          this.carePlanPrestation.evaluationId = this.evaluation.id;
          this.carePlanPrestation.name = this.periode;
          this.carePlanPrestation.typeAide = JSON.stringify(this.typeAide);
          this.carePlanPrestation.schedule = this.periode;
          this.carePlanPrestation.recurrency = JSON.stringify(this.weekDays);
          this.carePlanPrestation.observation = JSON.stringify(this.comments);

          this.postCarePlanPrestation().then((res) => {
            this.setCarePlanPrestation(res.saveCarePlanPrestation);
            this.saveLoading = false;
            this.$notify.success('La prestation a été enregistrée');
            this.close({
              showConfirm: true,
              update: !!this.carePlanPrestationId
            });
            this.updateHeuresSemaineDimanche().then((result) => {
              this.$notify.success('Le temps de prestation de l\'évaluation a été mis à jour');
              result;
            }).catch((err) => {
              console.log(err);
              this.$notify.error("Erreur de sauvegarde du temps estimé");
            })

          }).catch((err) => {
            this.saveLoading = false;
            this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
            throw err;
          });
        }
    },
    copyDatas() {
      if(this.copyDays) {
        let sem_one = this.weekDays['Semaine 1'];
        if(sem_one) {
          this.weekDays['Semaine 2'] = sem_one;
          this.choosenWeekDays = [];
          for(let day of sem_one) {
            this.choosenWeekDays.push(this.gen('Semaine 1', day));
            this.choosenWeekDays.push(this.gen('Semaine 2', day));
          }
        }
      }
    },
    checkValue() {
      if (this.validTime!=='') {
        let val = parseInt(this.validTime);
        this.validTime = (Number(val) === val && val % val === 0) ? this.validTime : '0';
      }
    },
    calculateDays(closeData) {
      let data = this.weekDays;
      let fiveDays = 0;
      let holDays = 0;
      Object.keys(data).map(week => {
        data[week].map(day => {
          fiveDays = ORD_DAYS.includes(day) ? fiveDays+1 : fiveDays;
          holDays = day === 'dimanche' ? holDays +1 : holDays;
        });
      });
      this.$emit('prestationDays', {
        workDays: fiveDays,
        weekends: holDays,
        tempsEstim: (this.validTime.toString().trim().length === 0 || parseInt(this.validTime) === 0) ? this.calculTempsEstime() : parseInt(this.validTime),
        datas: closeData
      });
    },
    calculTempsEstime() {
      this.fillPrestationDays();
      let tempEstimcalc = 0;

      Object.keys(this.typeAide).map(key => {
          let gridElt = this.gridItems.filter(gridItem => {
            return gridItem.id == key;
          });
          let gridEltScore = gridElt[0] ? gridElt[0].resA : null;
          if(gridEltScore && gridEltScore !== 'A') {
            tempEstimcalc += TEMPS_SCORE[key][gridEltScore];
          }
      });

      // pour un temps estimé en minutes
      return Math.ceil(tempEstimcalc);
    },
    fillPrestationDays() {
      if(this.choosenDays && this.choosenWeekDays.length === 0) {
        if(Object.keys(this.choosenDays).length > 0) {
          Object.keys(this.choosenDays).map(week => {
            this.choosenDays[week].map(day => {
              this.choosenWeekDays.push(this.gen(week, day))
            })
          })
        }
      }
    },
  },
};
</script>