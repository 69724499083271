<template>
  <div>
    <div class="id--info">
      <div class="id--info-photo cursor-pointer">
        <div class="id--info-photo--avatar" v-if="profilPicture" :style="{ backgroundImage: `url(${profilPicture ? API_URL + profilPicture.path : ''})` }" @click="profilPreview">
        </div>
        <img v-else class="id--info-photo--avatar" src="@/assets/photo-id.svg" alt @click="profilPictureModal"/>
      </div>
      <div class="id--info-container">
        <div class="row" v-if="!loading">
          <div class="col-6">
            <div class="id--info-item">
              <img class="id--info-item-img" src="@/assets/icon-filaire-user.svg" alt />
              <div class="id--info-wrap">
                <div style="display: flex; align-items: center;">
                  <h6>{{civility === 'male'? 'Monsieur' : 'Madame'}} {{firstname}} {{lastname}} {{nationality? `(${nationality})` : ''}}</h6>
                  <button class="btn id--edit-btn" @click.prevent="editInfo=!editInfo">
                    <img src="@/assets/icon-plein-edit.svg" alt />
                  </button>
                </div>
                <p v-if="!editInfo">
                  {{email}}
                  <br />{{birthDate ? birthDateFR : 'date de naissance N/R'}}, {{birthDate ? age : 'age N/R'}} ans ({{birthCity ? birthCity : 'lieu de naissance N/R'}})
                  <br />{{socialSecurityNumber ? socialSecurityNumber : 'non renseigné'}}
                </p>
                <div class="row" v-else>
                  <div class="col-12">
                    <label for>Civilité</label>
                    <select class="form-control" v-model="civility">
                      <option value="male">Monsieur</option>
                      <option value="female">Madame</option>
                    </select>
                  </div>
                  <div class="col-12">
                    <label for>Prénom</label>
                    <input class="form-control" v-model="firstname" name id/>
                  </div>
                  <div class="col-12">
                    <label for>Nom</label>
                    <input class="form-control" v-model="lastname" name id/>
                  </div>
                  <div class="col-12">
                    <label for="nationality">Nationalité</label>
                    <!--<input class="form-control" v-model="nationality" name id/>-->
                    <select class="form-control" id="nationality" name="nationality" v-model="nationality">
                      <optgroup label="France">
                        <option value="France">France</option>
                      </optgroup>
                      <optgroup label="Autres pays">
                        <option value="Algerie">Algerie</option>
                        <option value="Allemagne">Allemagne</option>
                        <option value="Andorre">Andorre</option>
                        <option value="Angleterre">Angleterre</option>
                        <option value="Belgique">Belgique</option>
                        <option value="Espagne">Espagne</option>
                        <option value="Italie">Italie</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Maroc">Maroc</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Pays-Bas">Pays-Bas</option>
                        <option value="Suisse">Suisse</option>
                        <option value="Tunisie">Tunisie</option>
                      </optgroup>
                      <optgroup label="Pays du monde">
                        <option value="Afghanistan">Afghanistan </option>
                        <option value="Afrique_Centrale">Afrique Centrale </option>
                        <option value="Afrique_du_sud">Afrique du Sud </option>
                        <option value="Albanie">Albanie </option>
                        <option value="Angola">Angola </option>
                        <option value="Anguilla">Anguilla </option>
                        <option value="Arabie_Saoudite">Arabie Saoudite </option>
                        <option value="Argentine">Argentine </option>
                        <option value="Armenie">Armenie </option>
                        <option value="Australie">Australie </option>
                        <option value="Autriche">Autriche </option>
                        <option value="Azerbaidjan">Azerbaidjan </option>
                        <option value="Bahamas">Bahamas </option>
                        <option value="Bangladesh">Bangladesh </option>
                        <option value="Barbade">Barbade </option>
                        <option value="Bahrein">Bahrein </option>
                        <option value="Belize">Belize </option>
                        <option value="Benin">Benin </option>
                        <option value="Bermudes">Bermudes </option>
                        <option value="Bielorussie">Bielorussie </option>
                        <option value="Bolivie">Bolivie </option>
                        <option value="Botswana">Botswana </option>
                        <option value="Bhoutan">Bhoutan </option>
                        <option value="Boznie_Herzegovine">Boznie Herzegovine </option>
                        <option value="Bresil">Bresil </option>
                        <option value="Brunei">Brunei </option>
                        <option value="Bulgarie">Bulgarie </option>
                        <option value="Burkina_Faso">Burkina Faso </option>
                        <option value="Burundi">Burundi </option>
                        <option value="Caiman">Caiman </option>
                        <option value="Cambodge">Cambodge </option>
                        <option value="Cameroun">Cameroun </option>
                        <option value="Canada">Canada </option>
                        <option value="Canaries">Canaries </option>
                        <option value="Cap_vert">Cap Vert </option>
                        <option value="Chili">Chili </option>
                        <option value="Chine">Chine </option>
                        <option value="Chypre">Chypre </option>
                        <option value="Colombie">Colombie </option>
                        <option value="Comores">Colombie </option>
                        <option value="Cook">Cook </option>
                        <option value="Coree_du_Nord">Coree du Nord </option>
                        <option value="Coree_du_Sud">Coree du Sud </option>
                        <option value="Costa_Rica">Costa Rica </option>
                        <option value="Cote_d_Ivoire">Côte d'Ivoire </option>
                        <option value="Croatie">Croatie </option>
                        <option value="Cuba">Cuba </option>
                        <option value="Danemark">Danemark </option>
                        <option value="Djibouti">Djibouti </option>
                        <option value="Dominique">Dominique </option>
                        <option value="Egypte">Egypte </option>
                        <option value="Emirats_Arabes_Unis">Emirats Arabes Unis </option>
                        <option value="Equateur">Equateur </option>
                        <option value="Erythree">Erythree </option>
                        <option value="Estonie">Estonie </option>
                        <option value="Etats_Unis">Etats Unis </option>
                        <option value="Ethiopie">Ethiopie </option>
                        <option value="Falkland">Falkland </option>
                        <option value="Feroe">Feroe </option>
                        <option value="Fidji">Fidji </option>
                        <option value="Finlande">Finlande </option>
                        <option value="France">France </option>
                        <option value="Gabon">Gabon </option>
                        <option value="Gambie">Gambie </option>
                        <option value="Georgie">Georgie </option>
                        <option value="Ghana">Ghana </option>
                        <option value="Gibraltar">Gibraltar </option>
                        <option value="Grece">Grece </option>
                        <option value="Grenade">Grenade </option>
                        <option value="Groenland">Groenland </option>
                        <option value="Guadeloupe">Guadeloupe </option>
                        <option value="Guam">Guam </option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernesey">Guernesey </option>
                        <option value="Guinee">Guinee </option>
                        <option value="Guinee_Bissau">Guinee Bissau </option>
                        <option value="Guinee equatoriale">Guinee Equatoriale </option>
                        <option value="Guyana">Guyana </option>
                        <option value="Guyane_Francaise ">Guyane Francaise </option>
                        <option value="Haiti">Haiti </option>
                        <option value="Hawaii">Hawaii </option>
                        <option value="Honduras">Honduras </option>
                        <option value="Hong_Kong">Hong Kong </option>
                        <option value="Hongrie">Hongrie </option>
                        <option value="Inde">Inde </option>
                        <option value="Indonesie">Indonesie </option>
                        <option value="Iran">Iran </option>
                        <option value="Iraq">Iraq </option>
                        <option value="Irlande">Irlande </option>
                        <option value="Islande">Islande </option>
                        <option value="Israel">Israel </option>
                        <option value="Jamaique">Jamaique </option>
                        <option value="Jan Mayen">Jan Mayen </option>
                        <option value="Japon">Japon </option>
                        <option value="Jersey">Jersey </option>
                        <option value="Jordanie">Jordanie </option>
                        <option value="Kazakhstan">Kazakhstan </option>
                        <option value="Kenya">Kenya </option>
                        <option value="Kirghizstan">Kirghizistan </option>
                        <option value="Kiribati">Kiribati </option>
                        <option value="Koweit">Koweit </option>
                        <option value="Laos">Laos </option>
                        <option value="Lesotho">Lesotho </option>
                        <option value="Lettonie">Lettonie </option>
                        <option value="Liban">Liban </option>
                        <option value="Liberia">Liberia </option>
                        <option value="Liechtenstein">Liechtenstein </option>
                        <option value="Lituanie">Lituanie </option>
                        <option value="Luxembourg">Luxembourg </option>
                        <option value="Lybie">Lybie </option>
                        <option value="Macao">Macao </option>
                        <option value="Macedoine">Macedoine </option>
                        <option value="Madagascar">Madagascar </option>
                        <option value="Madère">Madère </option>
                        <option value="Malaisie">Malaisie </option>
                        <option value="Malawi">Malawi </option>
                        <option value="Maldives">Maldives </option>
                        <option value="Mali">Mali </option>
                        <option value="Malte">Malte </option>
                        <option value="Man">Man </option>
                        <option value="Mariannes du Nord">Mariannes du Nord </option>
                        <option value="Maroc">Maroc </option>
                        <option value="Marshall">Marshall </option>
                        <option value="Martinique">Martinique </option>
                        <option value="Maurice">Maurice </option>
                        <option value="Mauritanie">Mauritanie </option>
                        <option value="Mayotte">Mayotte </option>
                        <option value="Mexique">Mexique </option>
                        <option value="Micronesie">Micronesie </option>
                        <option value="Midway">Midway </option>
                        <option value="Moldavie">Moldavie </option>
                        <option value="Mongolie">Mongolie </option>
                        <option value="Montserrat">Montserrat </option>
                        <option value="Mozambique">Mozambique </option>
                        <option value="Namibie">Namibie </option>
                        <option value="Nauru">Nauru </option>
                        <option value="Nepal">Nepal </option>
                        <option value="Nicaragua">Nicaragua </option>
                        <option value="Niger">Niger </option>
                        <option value="Nigeria">Nigeria </option>
                        <option value="Niue">Niue </option>
                        <option value="Norfolk">Norfolk </option>
                        <option value="Norvege">Norvege </option>
                        <option value="Nouvelle_Caledonie">Nouvelle Caledonie </option>
                        <option value="Nouvelle_Zelande">Nouvelle Zelande </option>
                        <option value="Oman">Oman </option>
                        <option value="Ouganda">Ouganda </option>
                        <option value="Ouzbekistan">Ouzbekistan </option>
                        <option value="Pakistan">Pakistan </option>
                        <option value="Palau">Palau </option>
                        <option value="Palestine">Palestine </option>
                        <option value="Panama">Panama </option>
                        <option value="Papouasie_Nouvelle_Guinee">Papouasie Nouvelle Guinee </option>
                        <option value="Paraguay">Paraguay </option>
                        <option value="Pays_Bas">Pays Bas </option>
                        <option value="Perou">Perou </option>
                        <option value="Philippines">Philippines </option>
                        <option value="Pologne">Pologne </option>
                        <option value="Polynesie">Polynesie </option>
                        <option value="Porto_Rico">Porto Rico </option>
                        <option value="Portugal">Portugal </option>
                        <option value="Qatar">Qatar </option>
                        <option value="Republique_Democratique_Du_Congo">République démocratique du Congo </option>
                        <option value="Republique_Dominicaine">République Dominicaine </option>
                        <option value="Republique_Du_Congo">République du Congo </option>
                        <option value="Republique_Tcheque">Republique Tcheque </option>
                        <option value="Reunion">Reunion </option>
                        <option value="Roumanie">Roumanie </option>
                        <option value="Royaume_Uni">Royaume Uni </option>
                        <option value="Russie">Russie </option>
                        <option value="Rwanda">Rwanda </option>
                        <option value="Sahara Occidental">Sahara Occidental </option>
                        <option value="Sainte_Lucie">Sainte-Lucie </option>
                        <option value="Saint_Marin">Saint-Marin </option>
                        <option value="Salomon">Salomon </option>
                        <option value="Salvador">Salvador </option>
                        <option value="Samoa_Occidentales">Samoa Occidentales</option>
                        <option value="Samoa_Americaine">Samoa Americaine </option>
                        <option value="Sao_Tome_et_Principe">Sao Tome et Principe </option>
                        <option value="Senegal">Senegal </option>
                        <option value="Seychelles">Seychelles </option>
                        <option value="Sierra Leone">Sierra Leone </option>
                        <option value="Singapour">Singapour </option>
                        <option value="Slovaquie">Slovaquie </option>
                        <option value="Slovenie">Slovenie</option>
                        <option value="Somalie">Somalie </option>
                        <option value="Soudan">Soudan </option>
                        <option value="Sri_Lanka">Sri Lanka </option>
                        <option value="Suede">Suede </option>
                        <option value="Suisse">Suisse </option>
                        <option value="Surinam">Surinam </option>
                        <option value="Swaziland">Swaziland </option>
                        <option value="Syrie">Syrie </option>
                        <option value="Tadjikistan">Tadjikistan </option>
                        <option value="Taiwan">Taiwan </option>
                        <option value="Tonga">Tonga </option>
                        <option value="Tanzanie">Tanzanie </option>
                        <option value="Tchad">Tchad </option>
                        <option value="Thailande">Thailande </option>
                        <option value="Tibet">Tibet </option>
                        <option value="Timor_Oriental">Timor Oriental </option>
                        <option value="Togo">Togo </option>
                        <option value="Trinite_et_Tobago">Trinite et Tobago </option>
                        <option value="Tristan da cunha">Tristan de cuncha </option>
                        <option value="Turkmenistan">Turmenistan </option>
                        <option value="Turquie">Turquie </option>
                        <option value="Ukraine">Ukraine </option>
                        <option value="Uruguay">Uruguay </option>
                        <option value="Vanuatu">Vanuatu </option>
                        <option value="Vatican">Vatican </option>
                        <option value="Venezuela">Venezuela </option>
                        <option value="Vierges_Americaines">Vierges Americaines </option>
                        <option value="Vierges_Britanniques">Vierges Britanniques </option>
                        <option value="Vietnam">Vietnam </option>
                        <option value="Wake">Wake </option>
                        <option value="Wallis et Futuma">Wallis et Futuma </option>
                        <option value="Yemen">Yemen </option>
                        <option value="Yougoslavie">Yougoslavie </option>
                        <option value="Zambie">Zambie </option>
                        <option value="Zimbabwe">Zimbabwe </option>
                      </optgroup>
                    </select>
                  </div>
                  <div class="col-12">
                    <label for>Email</label>
                    <input class="form-control" type="email" v-model="email" name id/>
                    <small v-if="!$v.email.email && $v.email.$error" class="text-danger">Format de l'email incorrect.</small>
                  </div>
                  <!--TODO add date picker-->
                  <div class="col-12">
                  </div>
                  <div class="col-12">
                    <label for></label>
                    <FormDatePicker v-model="birthDate"
                                    label="Date de naissance"
                                    :value ="birthDate"
                                    :login-input="true">
                    </FormDatePicker>
                    <small v-if="!$v.birthDate.isDate && $v.birthDate.$error" class="text-danger">Format de la date de naissance incorrect.</small>
                  </div>
                  <div class="col-12">
                    <label for>Lieu de naissance</label>
                    <input class="form-control" maxlength="50" v-model="birthCity" name id/>
                  </div>
                  <div class="col-12">
                    <label for>Numéro de sécurité sociale</label>
                    <input class="form-control" v-model="socialSecurityNumber" name id/>
                    <small v-if="$v.socialSecurityNumber.$error" class="text-danger">Le Numéro de sécurité sociale est obligatoire et doit être au format : x xx xx xx xxx xxx xx.</small>
                  </div>
                  <div class="col-12 text-center">
                    <b-button class="mt-2"
                              variant="btn mt-4 mb-4 btn-outline-primary"
                              @click.prevent="updateInfo">
                      Sauvegarder
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="id--info-item">
              <img class="id--info-item-img" src="@/assets/icon-filaire-tel.svg" alt />
              <div class="id--info-wrap">
                <p v-if="!editPhone">
                  {{portablePhoneNumber}}
                  <br />{{homePhoneNumber}}
                </p>
                <div class="row" v-else>
                  <div class="col-12">
                    <label for>Téléphone port.</label>
                    <input class="form-control" type="tel" v-model="portablePhoneNumber" name id/>
                  </div>
                  <div class="col-12">
                    <label for>Téléphone fixe</label>
                    <input class="form-control" type="tel" v-model="homePhoneNumber" name id/>
                  </div>
                  <div class="col-12 text-center">
                    <b-button class="mt-2"
                              variant="btn mt-4 mb-4 btn-outline-primary"
                              @click.prevent="updatePhone">
                      Sauvegarder
                    </b-button>
                  </div>
                </div>
              </div>
              <button class="btn id--edit-btn" @click="editPhone=!editPhone">
                <img src="@/assets/icon-plein-edit.svg" alt />
              </button>
            </div>
          </div>
          <div class="col-6">
            <div class="id--info-item">
              <img class="id--info-item-img" src="@/assets/icon-filaire-home.svg" alt />
              <div class="id--info-wrap">
                <p v-if="!editAddress">
                  {{address}}
                  <br />{{postalCode}} {{city}}
                </p>
                <div class="row" v-else>
                  <div class="col-12">
                    <label for>Adresse</label>
                    <input class="form-control" v-model="address" name id/>
                  </div>
                  <div class="col-6">
                    <label for>Code postal</label>
                    <input class="form-control" v-model="postalCode" name id/>
                    <small v-if="!$v.postalCode.integer && $v.postalCode.$error" class="text-danger">Format du code postal incorrect.</small>
                  </div>
                  <div class="col-6">
                    <label for>Ville</label>
                    <input class="form-control" v-model="city" name id/>
                  </div>
                  <div class="col-12 text-center">
                    <b-button class="mt-2"
                              variant="btn mt-4 mb-4 btn-outline-primary"
                              @click.prevent="updateAddress">
                      Sauvegarder
                    </b-button>
                  </div>
                </div>
              </div>
              <button class="btn id--edit-btn" @click.prevent="editAddress=!editAddress">
                <img src="@/assets/icon-plein-edit.svg" alt />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="id--section">
      <div class="id--section-title">
        <h1>DOCUMENTS</h1>
      </div>
      <div class="id--section-content">
          <eval-grid-document
              id="Liste des documents"
              label="document"
              :loading="false"
              :dataList="documents"
              @remove="removeDocument"
              @modalActive="modaleActive"/>
      </div>
    </div>

    <div class="id--section">
      <div class="id--section-title">
        <h1>ÉLÉMENTS D'ACCÈS AU LOGEMENT POUR LES INTERVENANTS</h1>
      </div>
      <div class="id--section-content">
        <div class="row mb-3">
          <div class="col-12">
            <label for>Description des modalités d'accès depuis la rue</label>
            <textarea v-model="housingAccess" class="form-control" @change="updateClientInfo"></textarea>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label for>Modalités d'accès au logement</label>
            <textarea v-model="digicode" class="form-control" @change="updateClientInfo"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="id--section">
      <div class="id--section-title">
        <h1>REPÉRAGE PHOTO</h1>
      </div>
      <div class="id--section-content">
        <eval-grid-document
            id="Liste des Lieux"
            label="lieux"
            :loading="false"
            :dataList="lieuxDocuments"
            @remove="removeDocument"
            @modalActive="modaleActive('acces')"/>
      </div>
    </div>

    <div class="id--section">
      <div class="id--section-content">
        <div class="row mb-3">
          <div class="col-12">
            <label for>Commentaires de l’évaluateur sur les informations du bénéficiaire</label>
            <textarea v-model="clientComment" class="form-control" @change="changeCommentaryValue()"></textarea>
          </div>
        </div>
      </div>
    </div>

    <custom-modal :active="modalActive" @close="modalActive = false;isSubmitDocument=false;">
      <div class="id-modal">
        <div class="id-modal--header">
          <img src="@/assets/icon-plein-add.svg" alt />
          <h1 class="id-modal--title">AJOUTER UN DOCUMENT</h1>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group eval-form eval-form--select">
              <form-select v-model="modalFormType"
                           :options="documentModalListType"
                           label="Type de document"
                           :disabled="false"
                           :error="!modalFormType && isSubmitDocument ? 'Le type est obligatoire.' : null"
                           :login-input="true"
                           :show-first-option="false"
                           @change="handleSelectChange"


              >
              </form-select>
            </div>
            <div class="form-group eval-form eval-form--textarea">
              <form-textarea v-model="modalDescription" label="Description" :rows="3"
                             :value="modalDescription" :login-input="true" :disabled="false"/>
            </div>
          </div>
          <div class="col-6">
            <h5 class="id-modal--subtitle">PHOTO</h5>
            <document-simple
                title=""
                v-if="modalActive"
                v-model="document"
                imageUrl=""
                mention="100px*100px min"
                @input="filesDocument"/>
            <p class="text-danger" v-if="!document && isSubmitDocument">* Le document est obligatoire.</p>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button class="btn btn-primary" @click.prevent="submitUploadDocument">Valider</button>
        </div>
      </div>
    </custom-modal>

    <custom-modal :active="profilModal" @close="profilModal = false;isSubmitDocument=false;">
      <div class="id-modal">
        <div class="id-modal--header">
          <img src="@/assets/icon-plein-add.svg" alt />
          <h1 class="id-modal--title">AJOUTER UNE PHOTO DU CLIENT</h1>
        </div>
        <div class="row">
          <div class="col-6">
            <h5 class="id-modal--subtitle">PHOTO</h5>
            <document-simple
                title=""
                v-model="document"
                v-if="profilModal"
                imageUrl=""
                mention="100px*100px min"
                @input="profilPictureDocument"/>
            <p class="text-danger" v-if="!document && isSubmitDocument">* Le document est obligatoire.</p>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button class="btn btn-primary" :disabled="isSubmitDocument" @click.prevent="submitUploadDocument">Valider</button>
        </div>
      </div>
    </custom-modal>
    <custom-modal :active="profilPreviewModal" @close="profilPreviewModal = false">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <img :src="previewURL" width="100%">
      </div>
      <div>
        <b-button class="mt-2" variant="btn col-3 btn-outline-danger" v-if="previewDocumentId" @click.prevent="removeDocument(previewDocumentId)">Supprimer</b-button>
        <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="profilPreviewModal = false">Annuler</b-button>
      </div>
    </custom-modal>
  </div>
</template>
<script>
  import Moment from "moment";
  import DocumentSimple from "@/components/Form/DocumentSimple";
  import FormTextarea from "@/components/Form/Textarea";
  import EvalGridDocument from "@/components/Eval/EvalGridDocument";
  import {mapActions, mapMutations, mapState} from "vuex";
  import {API_URL} from '@/components/Util/EnvVariable';
  import {email, integer, required} from 'vuelidate/lib/validators';
  import {formatFirstCharToUpperCase, formatSecuritySocialNumber} from "@/components/Util/UtilFunction";
  import FormDatePicker from "@/components/Form/DatePicker.vue";


  export default {
    data() {
      return {
        API_URL: API_URL,
        document: null,
        isSubmitDocument: false,
        editInfo: false,
        editAddress: false,
        editPhone: false,
        modalActive: false,
        modalFormType: null,
        modalDescription: null,
        documentMainType: null,
        documentModalListType: [],
        profilModal: false,
        profilPreviewModal: false,
        previewURL: null,
        previewDocumentId: null,
        birthDateDay: null,
        birthDateMonth: null,
        birthDateYear: null,
        birthDateError: false,
      };
    },
    components:{
      FormDatePicker,
      FormTextarea,
      DocumentSimple,
      EvalGridDocument
    },
    validations() {
        return {
          email: {
            email,
            required: false
          },
          postalCode: {
            integer,
            required: false
          },
          birthDate:{
            isDate(value){
              if(!value)return true;
              return Moment(value).isValid() && Moment().isAfter(value);
            },
            required: false
          },
          socialSecurityNumber: {
            isSocialNumber(value) {
              return value.length === 21;
            },
            required
          }
        };
    },
    methods:{
      ...mapMutations('Client', ['setClientProperty', 'deleteClientDocumentSuccess']),
      ...mapActions('Client', ['putClient', 'getClient', 'postClientDocument', 'deleteClientDocument']),
      ...mapActions('Evaluation', ['putEvaluation']),
      modaleActive(type) {
        this.document = null;
        this.documentModalListType = type === 'document' ? this.typesDocument : this.accesTypeList;
        this.modalActive = true;
        this.modalFormType = null;
        this.modalDescription = null;
        this.documentMainType = type === 'document' ? 'documents' : 'lieux';
      },
      handleSelectChange(){
        this.isSubmitDocument=false;
      },
      filesDocument() {
        let formData = new FormData();
        formData.append('document', this.document);
        this.setClientProperty({name:"document", value: formData});
        this.isSubmitDocument = false;
      },
      submitUploadDocument() {

        if(this.isSubmitDocument) return;
        this.isSubmitDocument = true;

        if (!this.document || (!this.modalFormType && this.documentMainType !== "profil" )) {
          return;
        }
        let formData = new FormData();
        formData.append('document', this.document);
        formData.append('main_type', this.documentMainType);
        formData.append('type', this.modalFormType ? this.modalFormType : "");
        formData.append('description', this.modalDescription ? this.modalDescription : false);
        this.setClientProperty({name: "document", value: formData});
        this.postClientDocument().then((data) => {
          this.getClient({id: this.client.id});
          this.profilModal = false;
          this.modalActive = false;
          this.isSubmitDocument = false;
          this.$notify.success("Document sauvegardé !");
          return data;
        }).catch((err) => {
          console.error(err);
          this.$notify.error("Erreur de sauvegarde du document.");
          throw err;
        });

      },
      updatePhone(){
        this.updateInfoApi()
        this.editPhone = !this.editPhone
      },
      updateAddress(){
        if(this.$v.postalCode.$invalid){
          this.$v.postalCode.$touch();
          return;
        }
        this.updateInfoApi()
        this.editAddress = !this.editAddress
      },
      updateInfo() {
        if(this.$v.email.$invalid){
          this.$notify.error("Veuillez insérer un Email correct")
          this.$v.email.$touch();
          return;
        }
        if(this.$v.birthDate.$invalid){
          this.$v.birthDate.$touch();
          return;
        }
        if(this.$v.socialSecurityNumber.$invalid){
          this.$v.socialSecurityNumber.$touch();
          return;
        }
        this.updateInfoApi()
        this.editInfo = !this.editInfo
      },
      updateInfoApi(){
        this.setClientProperty({
          name: "age",
          value: this.age
        });
        this.updateClientInfo();
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      updateClientInfo() {
        this.initNullValue();
        this.setClientProperty({
          name: "interfaceSyncrhonisation",
          value: true
        });
        this.putClient('evaluation').then((res) => {
          this.$notify.success('L\'information a été sauvegardée');
          return res;
        }).catch((err) => {
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          throw err;
        });
      },
      initNullValue() {
        this.setClientProperty({
          name: "protectiveMeasure",
          value: ''
        });
        this.setClientProperty({
          name: "csp",
          value: ''
        });
      },
      removeDocument(documentId) {
        this.deleteClientDocument({id: documentId}).then((res) => {
          this.$bvModal.hide('preview_document_document');
          this.$bvModal.hide('preview_document_lieux');
          this.$bvModal.hide('preview_document');
          this.profilPreviewModal = false;
          this.$notify.success('Document supprimé.');
          return res;
        }).catch((err) => {
          this.$notify.error("Failed.")
          throw err;
        });
      },
      profilPictureModal() {
        this.document = null;
        this.profilModal = true;
        this.documentMainType = "profil";
      },
      profilPreview() {
        this.profilPreviewModal = true;
        this.previewURL = this.profilPicture ? API_URL + this.profilPicture.path: null;
        this.previewDocumentId = this.profilPicture ?  this.profilPicture.id: null;
      },
      profilPictureDocument() {
        let formData = new FormData();
        formData.append('document', this.document);
        this.setClientProperty({name:"document", value: formData});
      },
      initBirthday(value){
        let momentDate = Moment(value);
        if (!momentDate.isValid()) {
          return;
        }
        let date = momentDate.format('YYYY-MM-DD');
        let dateSplit = date.split('-');
        this.birthDateDay = dateSplit[2];
        this.birthDateMonth = dateSplit[1];
        this.birthDateYear = dateSplit[0];
      },
      changeCommentaryValue() {
        this.putEvaluation();
      },
    },
    computed: {
      ...mapState('Client', ['client', 'typesDocument', 'loading', 'accesTypeList']),
      ...mapState("Evaluation", ["evaluation"]),
      age() {
        if(!this.birthDate || this.birthDate === ''){
          return 0;
        }
        return Moment().diff(Moment(this.birthDate), 'years');
      },
      documents() {
        return this.client.documents ? this.client.documents.filter(item => item.mainType == 'documents') : []
      },
      lieuxDocuments() {
        return this.client.documents ? this.client.documents.filter(item => item.mainType == 'lieux') : []
      },
      profilPicture() {
        const docs = this.client.documents ? this.client.documents.filter(item => item.mainType == 'profil') : []
        if(docs.length > 0) {
          return docs[0];
        }
        return null;
      },
      civility: {
        get() {
          return this.client.civility;
        },
        set(value) {
          this.setClientProperty({
            name: "civility",
            value: value
          });
        }
      },
      lastname: {
        get() {
          return this.client.lastname;
        },
        set(value) {
          this.setClientProperty({
            name: "lastname",
            value: value
          });
        }
      },
      firstname: {
        get() {
          return this.client.firstname;
        },
        set(value) {
          this.setClientProperty({
            name: "firstname",
            value: value
          });
        }
      },
      birthDate: {
        get() {
          return this.client.birthDate ? this.client.birthDate : null
        },
        set(value) {
          this.setClientProperty({
            name: "birthDate",
            value: value
          });
        }
      },
      birthDateFR: {
        get() {
          return this.client.birthDate ? Moment(this.client.birthDate).format('DD/MM/YYYY') : null;
        }
      },
      socialSecurityNumber: {
        get() {
          return formatSecuritySocialNumber(this.client.socialSecurityNumber);
        },
        set(value) {
          value = formatSecuritySocialNumber(value);
          this.setClientProperty({
            name: "socialSecurityNumber",
            value: value
          });
        }
      },
      portablePhoneNumber: {
        get() {
          return this.client.portablePhoneNumber;
        },
        set(value) {
          this.setClientProperty({
            name: "portablePhoneNumber",
            value: value
          });
        }
      },
      homePhoneNumber: {
        get() {
          return this.client.homePhoneNumber;
        },
        set(value) {
          this.setClientProperty({
            name: "homePhoneNumber",
            value: value
          });
        }
      },
      address: {
        get() {
          return this.client.address;
        },
        set(value) {
          this.setClientProperty({
            name: "address",
            value: value
          });
        }
      },
      city: {
        get() {
          return this.client.city;
        },
        set(value) {
          this.setClientProperty({
            name: "city",
            value: formatFirstCharToUpperCase(value)
          });
        }
      },
      postalCode: {
        get() {
          return this.client.postalCode;
        },
        set(value) {
          this.setClientProperty({
            name: "postalCode",
            value: value
          });
        }
      },
      birthCity: {
        get() {
          return this.client.birthCity;
        },
        set(value) {
          this.setClientProperty({
            name: "birthCity",
            value: formatFirstCharToUpperCase(value)
          });
        }
      },
      nationality: {
        get() {
          return this.client.nationality;
        },
        set(value) {
          this.setClientProperty({
            name: "nationality",
            value: value
          });
        }
      },
      email: {
        get() {
          return this.client.email;
        },
        set(value) {
          this.setClientProperty({
            name: "email",
            value: value
          });
        }
      },
      housingAccess: {
        get() {
          return this.client.housingAccess;
        },
        set(value) {
          this.setClientProperty({
            name: "housingAccess",
            value: value
          });
        }
      },
      digicode: {
        get() {
          return this.client.digicode;
        },
        set(value) {
          this.setClientProperty({
            name: "digicode",
            value: value
          });
        }
      },
      clientComment: {
        get() {
          return this.evaluation.evaluatorComments ? this.evaluation.evaluatorComments.clientComment : "";
        },
        set(value) {
          this.evaluation.evaluatorComments.clientComment = value;
        }
      },
    },
    watch: {
      birthDate(value) {
        this.initBirthday(value);
      },
      modalFormType(newValue)
      {
        if(newValue)
        {
          this.isSubmitDocument=false;
        }

      }
    },
    mounted() {
      this.initBirthday(this.birthDate);
    }
  };
</script>
<style scoped>
  input.input-split-date{
    max-width: 60px;
    margin-left: 10px;
    margin-right: 10px;
  }
  input.input-split-date:nth-child(1){
    margin-left: 0;
  }
  input.input-split-date-year {
    max-width: 90px;
    margin-left: 10px;
    margin-right: 10px;
  }
  div.display-flex{
    display: flex;
  }
</style>