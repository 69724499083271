import {getAnswerTotal, getClassicRuleScore} from "@/components/Util/UtilFunction";

export default {
    getQuestionAggirMoveOut(state) {
        return state.aggirQuestions.find((c) => c.item === 'Déplacements extérieurs');
    },
    getQuestionAggirMoveIn(state) {
        return state.aggirQuestions.find((c) => c.item === 'Déplacements intérieurs');
    },
    getQuestionAggirTransfert(state) {
        return state.aggirQuestions.find((c) => c.item === 'Transferts');
    },
    getQuestionAggirOrientationTemps(state) {
        return state.aggirQuestions.find((c) => c.item === 'Orientation' && c.subItem === 'Temps');
    },
    getQuestionAggirOrientationEspace(state) {
        return state.aggirQuestions.find((c) => c.item === 'Orientation' && c.subItem === 'Espace');
    },
    getQuestionAggirCoherenceComportement(state) {
        return state.aggirQuestions.find((c) => c.item === 'Cohérence' && c.subItem === 'Comportement');
    },
    getQuestionAggirCoherenceCommunication(state) {
        return state.aggirQuestions.find((c) => c.item === 'Cohérence' && c.subItem === 'Communication');
    },
    getQuestionAggirEliminationFecale(state) {
        return state.aggirQuestions.find((c) => c.item === 'Elimination' && c.subItem === 'Fécale');
    },
    getQuestionAggirEliminationUrinaire(state) {
        return state.aggirQuestions.find((c) => c.item === 'Elimination' && c.subItem === 'Urinaire');
    },
    getAggirQuestion(state) {
        return state.aggirQuestion;
    },

    /**SCORE**/
    getScoreQuestionAggirMoveOut(state, {getQuestionAggirMoveOut}, {Evaluation}) {
        if(!getQuestionAggirMoveOut){
            return null;
        }
        let answer = Evaluation.evaluation.answerList.find((c) => c.question.id === getQuestionAggirMoveOut.id);
        if (!answer) {
            return null;
        }
        return getClassicRuleScore(getAnswerTotal(answer.answerStch));
    },
    getScoreQuestionAggirMoveIn(state, {getQuestionAggirMoveIn}, {Evaluation}) {
        if(!getQuestionAggirMoveIn){
            return null;
        }
        let answer = Evaluation.evaluation.answerList.find((c) => c.question.id === getQuestionAggirMoveIn.id);
        if (!answer) {
            return null;
        }
        return getClassicRuleScore(getAnswerTotal(answer.answerStch));
    },
    getScoreQuestionAggirTransfert(state, {getQuestionAggirTransfert}, {Evaluation}) {
        if(!getQuestionAggirTransfert){
            return null;
        }
        let answer = Evaluation.evaluation.answerList.find((c) => c.question.id === getQuestionAggirTransfert.id);
        if (!answer) {
            return null;
        }
        return getClassicRuleScore(getAnswerTotal(answer.answerStch));
    },
    getScoreQuestionAggirOrientationTemps(state, {getQuestionAggirOrientationTemps}, {Evaluation}) {
        if(!getQuestionAggirOrientationTemps){
            return null;
        }
        let answer = Evaluation.evaluation.answerList.find((c) => c.question.id === getQuestionAggirOrientationTemps.id);
        if (!answer) {
            return null;
        }
        return getClassicRuleScore(getAnswerTotal(answer.answerStch));
    },
    getScoreQuestionAggirOrientationEspace(state, {getQuestionAggirOrientationEspace}, {Evaluation}) {
        if(!getQuestionAggirOrientationEspace){
            return null;
        }
        let answer = Evaluation.evaluation.answerList.find((c) => c.question.id === getQuestionAggirOrientationEspace.id);
        if (!answer) {
            return null;
        }
        return getClassicRuleScore(getAnswerTotal(answer.answerStch));
    },
    getScoreQuestionAggirCoherenceComportement(state, {getQuestionAggirCoherenceComportement}, {Evaluation}) {
        if(!getQuestionAggirCoherenceComportement){
            return null;
        }
        let answer = Evaluation.evaluation.answerList.find((c) => c.question.id === getQuestionAggirCoherenceComportement.id);
        if (!answer) {
            return null;
        }
        return getClassicRuleScore(getAnswerTotal(answer.answerStch));
    },
    getScoreQuestionAggirCoherenceCommunication(state, {getQuestionAggirCoherenceCommunication}, {Evaluation}) {
        if(!getQuestionAggirCoherenceCommunication){
            return null;
        }
        let answer = Evaluation.evaluation.answerList.find((c) => c.question.id === getQuestionAggirCoherenceCommunication.id);
        if (!answer) {
            return null;
        }
        return getClassicRuleScore(getAnswerTotal(answer.answerStch));
    },
    getScoreQuestionAggirEliminationFecale(state, {getQuestionAggirEliminationFecale}, {Evaluation}) {
        if(!getQuestionAggirEliminationFecale){
            return null;
        }
        let answer = Evaluation.evaluation.answerList.find((c) => c.question.id === getQuestionAggirEliminationFecale.id);
        if (!answer) {
            return null;
        }
        return getClassicRuleScore(getAnswerTotal(answer.answerStch));
    },
    getScoreQuestionAggirEliminationUrinaire(state, {getQuestionAggirEliminationUrinaire}, {Evaluation}) {
        if(!getQuestionAggirEliminationUrinaire){
            return null;
        }
        let answer = Evaluation.evaluation.answerList.find((c) => c.question.id === getQuestionAggirEliminationUrinaire.id);
        if (!answer) {
            return null;
        }
        return getClassicRuleScore(getAnswerTotal(answer.answerStch));
    }
};