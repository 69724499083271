export default {
    user: {
        id: null,
        agenciesId: [],
        agencies: [],
        structureId: null,
        structure: {},
        email: '',
        lastName: '',
        firstName: '',
        birthName: '',
        birthDate: null,
        effectiveDate: null,
        type: '',
        address: '',
        addressComplement: '',
        city: '',
        cp: '',
        sector: '',
        portablePhoneNumber: '',
        homePhoneNumber: '',
        personalEmail: '',
        homeProfesionnalPhoneNumber: '',
        portableProfesionnalPhoneNumber: '',
        profesionnalEmail: '',
        manager: null,
        managerId: null,
        status: false,
        role: null,
        intervenantType: '',
        hasPassword: null,
    },
    connectedUser: null,
    users: [],
    roleList: [],
    loading: false,
    saveLoading: false,
    passwordSaveLoading: false,
    error: null,
    total: 0

}
