<template>
  <div class="soin-item">
    <div class="soin-item--title" @click.prevent="toggleActive">
      <h1>Fonctions respiratoires et circulatoires</h1>
      <button class="btn" :class="{ inverted: !active }">
        <img src="@/assets/chevron-up.svg" alt />
      </button>
    </div>
    <div class="soin-item--content">
      <eval-card-check
        :showContentSlot="true"
        :checked="src01 !== null && src01.length > 0"
        title="Vous arrive-t-il d'être essouflé(e) ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                inputType="checkbox"
                subtitle=""
                :options="[]"
              >
                <template slot="content">
                  <div
                    class="id-check--option"
                    v-for="(option, index) in [
                      { value: 'r1', label: 'Jamais' },
                      { value: 'r2', label: 'A l\'effort' },
                      { value: 'r3', label: 'Au repos' },
                      { value: 'r4', label: 'La journée' },
                      { value: 'r5', label: 'La nuit' },
                      { value: 'r6', label: 'Fumeur(euse) actuel' },
                      { value: 'r7', label: 'Ancien fumeur(euse)' },
                    ]"
                    :key="index"
                  >
                    <input
                      class="id-check--input"
                      :id="`src01-option-${index}`"
                      type="checkbox"
                      :value="option.value"
                      v-model="src01"
                    />
                    <label :for="`src01-option-${index}`">
                      <span class="id-check--indicator">{{
                        option.label
                      }}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
        :showContentSlot="true"
        :checked="src02 !== null && src02.length > 0"
        title="Recevez-vous de l'oxygène sur prescription médicale."
        v-if="
          (src01.includes('r2') ||
            src01.includes('r3') ||
            src01.includes('r4') ||
            src01.includes('r5')) &&
          active
        "
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                inputType="checkbox"
                subtitle=""
                :options="[]"
              >
                <template slot="content">
                  <div
                    class="id-check--option"
                    v-for="(option, index) in [
                      { value: 'r1', label: 'Jamais' },
                      { value: 'r2', label: 'La journée' },
                      { value: 'r3', label: 'La nuit' },
                    ]"
                    :key="index"
                  >
                    <input
                      class="id-check--input"
                      :id="`src02-option-${index}`"
                      type="checkbox"
                      :value="option.value"
                      v-model="src02"
                    />
                    <label :for="`src02-option-${index}`">
                      <span class="id-check--indicator">{{
                        option.label
                      }}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
        :showContentSlot="true"
        :checked="src01 !== null && src01 !== ''"
        title="Dormez-vous en position demie assise ?"
        v-if="
          (src01.includes('r2') ||
            src01.includes('r3') ||
            src01.includes('r4') ||
            src01.includes('r5')) &&
          active
        "
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="src03" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
        :showContentSlot="true"
        :checked="src04 !== null && src04 !== ''"
        title="Êtes vous porteur(se) d'un Pacemaker ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="src04" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
        :showContentSlot="true"
        :checked="src05 !== null && src05.length > 0"
        title="Avez-vous les jambes qui gonflent ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                inputType="checkbox"
                subtitle=""
                :options="[]"
              >
                <template slot="content">
                  <div
                    class="id-check--option"
                    v-for="(option, index) in [
                      { value: 'r1', label: 'Non' },
                      { value: 'r2', label: 'En station debout' },
                      { value: 'r3', label: 'Au repos' },
                    ]"
                    :key="index"
                  >
                    <input
                      class="id-check--input"
                      :id="`src05-option-${index}`"
                      type="checkbox"
                      :value="option.value"
                      v-model="src05"
                    />
                    <label :for="`src05-option-${index}`">
                      <span class="id-check--indicator">{{
                        option.label
                      }}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
        :showContentSlot="true"
        :checked="src06 !== null && src06.length > 0"
        title="Dormez-vous avec les jambes surélevées et/ou portez-vous des bas de contention ?"
        v-if="src05.includes('r2') && active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                inputType="checkbox"
                subtitle=""
                :options="[]"
              >
                <template slot="content">
                  <div
                    class="id-check--option"
                    v-for="(option, index) in [
                      { value: 'r1', label: 'Non' },
                      { value: 'r2', label: 'Jambes surélevées' },
                      { value: 'r3', label: 'Bas de contention' },
                      { value: 'r4', label: 'La journée' },
                      { value: 'r5', label: 'La nuit' },
                    ]"
                    :key="index"
                  >
                    <input
                      class="id-check--input"
                      :id="`src06-option-${index}`"
                      type="checkbox"
                      :value="option.value"
                      v-model="src06"
                    />
                    <label :for="`src06-option-${index}`">
                      <span class="id-check--indicator">{{
                        option.label
                      }}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SubQuestionExtend from "@/views/Evaluation/Soin/SubQuestionExtend";
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";

export default {
  extends: SubQuestionExtend,
  components: {
    EvalFormCheckAlt,
  },
  methods: {
    ...mapMutations("Evaluation", ["setTreatmentProperty"]),
    ...mapActions("Evaluation", ["saveTreatment"]),
  },
  computed: {
    ...mapState("Evaluation", ["breathing"]),
    src01: {
      get() {
        return this.breathing.src01.response
          ? this.breathing.src01.response.split(",")
          : [];
      },
      set(value) {
        const prevValue = this.breathing.src01.response;
        this.setTreatmentProperty({
          name: "src01",
          value: value.join(","),
        });
        if (value.join(",") !== prevValue) {
          this.saveTreatment({
            id: "breathing",
            name: "src01",
            evaluationId: this.$route.params.evaluationId,
          });
        }
      },
    },
    src02: {
      get() {
        return this.breathing.src02.response
          ? this.breathing.src02.response.split(",")
          : [];
      },
      set(value) {
        const prevValue = this.breathing.src02.response;
        this.setTreatmentProperty({
          name: "src02",
          value: value.join(","),
        });
        if (value.join(",") !== prevValue) {
          this.saveTreatment({
            id: "breathing",
            name: "src02",
            evaluationId: this.$route.params.evaluationId,
          });
        }
      },
    },
    src03: {
      get() {
        return this.breathing.src03.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "src03",
          value,
        });
        this.saveTreatment({
          id: "breathing",
          name: "src03",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    src04: {
      get() {
        return this.breathing.src04.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "src04",
          value,
        });
        this.saveTreatment({
          id: "breathing",
          name: "src04",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    src05: {
      get() {
        return this.breathing.src05.response
          ? this.breathing.src05.response.split(",")
          : [];
      },
      set(value) {
        const prevValue = this.breathing.src05.response;
        this.setTreatmentProperty({
          name: "src05",
          value: value.join(","),
        });
        if (value.join(",") !== prevValue) {
          this.saveTreatment({
            id: "breathing",
            name: "src05",
            evaluationId: this.$route.params.evaluationId,
          });
        }
      },
    },
    src06: {
      get() {
        return this.breathing.src06.response
          ? this.breathing.src06.response.split(",")
          : [];
      },
      set(value) {
        const prevValue = this.breathing.src06.response;
        this.setTreatmentProperty({
          name: "src06",
          value: value.join(","),
        });
        if (value.join(",") !== prevValue) {
          this.saveTreatment({
            id: "breathing",
            name: "src06",
            evaluationId: this.$route.params.evaluationId,
          });
        }
      },
    },
    src07: {
      get() {
        return this.breathing.src07.response
          ? this.breathing.src07.response.split(",")
          : [];
      },
      set(value) {
        const prevValue = this.breathing.src07.response;
        this.setTreatmentProperty({
          name: "src07",
          value: value.join(","),
        });
        if (value.join(",") !== prevValue) {
          this.saveTreatment({
            id: "breathing",
            name: "src07",
            evaluationId: this.$route.params.evaluationId,
          });
        }
      },
    },
    src08: {
      get() {
        return this.breathing.src08.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "src08",
          value,
        });
        this.saveTreatment({
          id: "breathing",
          name: "src08",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
  },
};
</script>
