import gql from 'graphql-tag'

export const UPDATE_USER = gql`
    mutation updateUser(
          $id: Int!
          $agenciesId: [Int!],
          $structureId: Int,
          $email: String,
          $lastName: String,
          $firstName: String,
          $birthName: String,
          $birthDate: DateTime,
          $effectiveDate: DateTime,
          $type: String,
          $address: String,
          $addressComplement: String,
          $city: String,
          $cp: String,
          $sector: String,
          $portablePhoneNumber: String,
          $homePhoneNumber: String,
          $personalEmail: String,
          $homeProfesionnalPhoneNumber: String,
          $portableProfesionnalPhoneNumber: String,
          $profesionnalEmail: String,
          $managerId: Int,
          $status: Boolean!,
          $roleId: Int!,
          $intervenantType: String!,
          $password: String,        
    ) {
        updateUser(
          id: $id
          agenciesId: $agenciesId,
          structureId: $structureId,
          email: $email,
          lastName: $lastName,
          firstName: $firstName,
          birthName: $birthName,
          birthDate: $birthDate,
          effectiveDate: $effectiveDate,
          type: $type,
          address: $address,
          addressComplement: $addressComplement,
          city: $city,
          cp: $cp,
          sector: $sector,
          portablePhoneNumber: $portablePhoneNumber,
          homePhoneNumber: $homePhoneNumber,
          personalEmail: $personalEmail,
          homeProfesionnalPhoneNumber: $homeProfesionnalPhoneNumber,
          portableProfesionnalPhoneNumber: $portableProfesionnalPhoneNumber,
          profesionnalEmail: $profesionnalEmail,
          managerId: $managerId,
          status: $status,
          roleId: $roleId,
          intervenantType: $intervenantType,
          password: $password,  
        ) {
          id,
          email,
          lastName,
          firstName,
          birthName,
          birthDate,
          effectiveDate,
          type,
          address,
          addressComplement,
          city,
          cp,
          sector,
          portablePhoneNumber,
          homePhoneNumber,
          personalEmail,
          homeProfesionnalPhoneNumber,
          portableProfesionnalPhoneNumber,
          profesionnalEmail,
          status,
          role{
            id
            label
            realLabel
          }
          agencies {
            id  
            companyName
          }
          structure{
            id
            name  
          }
          intervenantType,
          password,      
            createdBy{
                id,
                firstName,
                lastName
            },
        }
    }
`
