<template>
  <div class="id-documents row">
    <div class="col-4 id-documents--item mb-3"
         v-for="({id, path, type, description}, index) in dataList"
         :key="`doc-${index}`">
      <div class="id-documents--card">
        <img height="111px" :src="API_URL + path" alt @click.prevent="showPreview(API_URL + path, id)"/>
      </div>
      <div class="id-documents--caption">
        <p>{{type}} {{description !== null ? ': ' + description : ''}}</p>
      </div>
    </div>
    <div class="col-1 id-documents--add">
      <button class="btn id-documents--add-btn" @click.prevent="$emit('modalActive', 'document')">
        <img src="@/assets/bouton-primaire-add.svg" alt />
      </button>
    </div>
    <b-modal modal-class="modal-margin" :id="'preview_document_'+label" title="Preview Document" :centered="true" size="md">
      <template v-slot:modal-title>
        Aperçu
      </template>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <img :src="previewURL" width="100%">
      </div>
      <template v-slot:modal-footer>
        <b-button class="mt-2 d-flex justify-content-center" variant="btn col-3 btn-outline-danger" v-if="documentId" @click.prevent="$emit('remove', documentId)">Supprimer</b-button>
        <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('preview_document_'+label)">Annuler</b-button>
      </template>
    </b-modal>

  </div>
</template>
<style scoped>
/deep/ .modal-margin > .modal-dialog > .modal-content {
  margin-top:200px;
}
</style>
<script>
  import {API_URL} from '@/components/Util/EnvVariable';

  export default {
    name: "GridDocumentEval",
    data() {
        return {
            API_URL: API_URL,
            previewURL: '',
            documentId: null
        }
    },
    props: {
      dataList: Array,
      label: String
    },
    methods: {
      showPreview(url, documentId = null) {
          this.previewURL = url;
          this.documentId = documentId;
          this.$bvModal.show('preview_document_'+this.label);
      }
    }
  }
</script>