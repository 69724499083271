<template>
  <div class="soin-item">
    <div class="soin-item--title" @click.prevent="toggleActive">
      <h1>Douleur</h1>
      <button class="btn" :class="{'inverted': !active}">
        <img src="@/assets/chevron-up.svg" alt />
      </button>
    </div>
    <div class="soin-item--content">
      <eval-card-check
          :showContentSlot="true"
          :checked="sd01 !== null && sd01 !== ''"
          title="La personne, est-elle en mesure de communiquer sur ses douleurs ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sd01" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="sd02 !== null && sd02 !== ''"
          title="La personne, signale-t-elle des douleurs ?"
          v-if="sd01 === 'r1' && active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sd02" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="sd03 !== null && sd03 !== ''"
          title="Sur une échelle de 0 (pas de douleurs) à 10 (douleur insupportable) ou la personne situe-t-elle l'intensité de ses douleurs ?"
          v-if="sd02 === 'r1' && active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sd03" name id>
                  <option value="r1">1</option>
                  <option value="r2">2</option>
                  <option value="r3">3</option>
                  <option value="r4">4</option>
                  <option value="r5">5</option>
                  <option value="r6">6</option>
                  <option value="r7">7</option>
                  <option value="r8">8</option>
                  <option value="r9">9</option>
                  <option value="r10">10</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="sd04 !== null && sd04.length > 0"
          title="Nature des douleurs"
          v-if="sd02 === 'r1' && active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                inputType="checkbox"
                subtitle=""
                :options="[]">
                <template slot="content">
                  <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label:'Occasionnelles'},
                      {value:'r2', label:'Intermittentes'},
                      {value:'r3', label:'Permanentes'},
                      {value:'r4', label:'Le jour'},
                      {value:'r5', label:'La nuit'},
                      {value:'r6', label:'Au repos'},
                      {value:'r7', label:'Lors de la mobilisation'}]"
                       :key="index">
                    <input class="id-check--input"
                           :id="`sd04-option-${index}`"
                           type="checkbox"
                           :value="option.value"
                           v-model="sd04"/>
                    <label :for="`sd04-option-${index}`">
                      <span class="id-check--indicator">{{option.label}}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="sd05 !== null && sd05 !== ''"
          title="Manifestations somatiques de la douleur"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sd05" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="sd06 !== null && sd06 !== ''"
          title="Description des manifestations somatiques de la douleur"
          v-if="sd05 === 'r1' && active">
        <template v-slot:content>
          <div class="w-100">
            <div class="eval-card--form input">
              <div class="form-group eval-form eval-form--input">
                <textarea
                    v-model="sd06"
                    class="form-control"
                    placeholder="Saisir une réponse"
                    style="width: 100%;max-width: 100%;"
                    @blur="onBlur"></textarea>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
    </div>
  </div>
</template>
<script>
import SubQuestionExtend from "./SubQuestionExtend";
import {mapActions, mapMutations, mapState} from "vuex";
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";

export default {
  extends: SubQuestionExtend,
  components:{
    EvalFormCheckAlt
  },
  methods: {
    ...mapMutations('Evaluation', ['setTreatmentProperty']),
    ...mapActions('Evaluation', ['saveTreatment']),
    onBlur(){
      this.saveTreatment({
        id: "pain",
        name: "sd06",
        evaluationId: this.$route.params.evaluationId
      });
    }
  },
  computed: {
    ...mapState('Evaluation', ['pain']),
    sd01: {
      get() {
        return this.pain.sd01.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'sd01',
          value
        });
        this.saveTreatment({
          id: "pain",
          name: "sd01",
          evaluationId: this.$route.params.evaluationId
        });
      }
    },
    sd02: {
      get() {
        return this.pain.sd02.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'sd02',
          value
        });
        this.saveTreatment({
          id: "pain",
          name: "sd02",
          evaluationId: this.$route.params.evaluationId
        });
      }
    },
    sd03: {
      get() {
        return this.pain.sd03.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'sd03',
          value
        });
        this.saveTreatment({
          id: "pain",
          name: "sd03",
          evaluationId: this.$route.params.evaluationId
        });
      }
    },
    sd04: {
      get() {
        return this.pain.sd04.response ? this.pain.sd04.response.split(',') : [];
      },
      set(value) {
        const prevValue = this.pain.sd04.response;
        this.setTreatmentProperty({
          name: 'sd04',
          value: value.join(',')
        });
        if (value.join(',')!==prevValue) {
          this.saveTreatment({
            id: "pain",
            name: "sd04",
            evaluationId: this.$route.params.evaluationId
          });
        }
      }
    },
    sd05: {
      get() {
        return this.pain.sd05.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'sd05',
          value
        });
        this.saveTreatment({
          id: "pain",
          name: "sd05",
          evaluationId: this.$route.params.evaluationId
        });
      }
    },
    sd06: {
      get() {
        return this.pain.sd06.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'sd06',
          value
        });
      }
    },
  }
};
</script>