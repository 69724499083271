const toiletteH = "AIDE A TOILETTE HAUT";
const toiletteB = "AIDE A TOILETTE BAS";
const elimination = "AIDE A L'ELIMINATION";
const habillage = "AIDE A HABILLAGE/DESHABILLAGE";
const transfert = "AIDE AUX TRANSFERTS";
const deplacementInt = "AIDE AUX DEPLACEMENTS INTERIEURS";
const treatment = "SUIVI TRAITEMENT / SURVEILLANCE / ALERTES";
const menage = "AIDE AU MENAGE";
const menageGlobal = "AIDE AU MENAGE GLOBAL";
const cuisine = "AIDE A LA CUISINE ET COURSES";
const repas = "AIDE A LA PRISE DE REPAS";
const linge = "AIDE A L'ENTRETIEN DU LINGE";
const achat = "AIDE AUX ACHATS ET GESTION";
const activite = "AIDE AUX ACTIVITES DE TEMPS LIBRE";
const transversal = "SURVEILLANCES";
const deplacementExt = "AIDE AUX TRANSPORTS ET AUX DEPLACEMENTS EXTERIEURS";
const courses = "AIDE AUX COURSES";

export default {
    aggirQuestion: {
        id: '',
        placeRelated: [],
        item: '',
        subItem: null,
        openingStatement: '',
        itemPeriod: [],
        itemStch: null,
        stchS: null,
        stchStime: null,
        stchT: null,
        stchTtime: null,
        stchC: null,
        stchCtime: null,
        stchH: null,
        stchHtime: null,
        ruleA: '',
        ruleB: '',
        ruleC: ''
    },
    aggirQuestions: [],
    loading: false,
    error: null,
    total: 0,
    placeRelatedList: [
        {value: 'Salle de bain', text: 'Salle de bain'},
        {value: 'Chambre', text: 'Chambre'},
        {value: 'WC', text: 'WC'},
        {value: 'Cuisine', text: 'Cuisine'},
        {value: 'Salle à manger', text: 'Salle à manger'},
        {value: 'Salon', text: 'Salon'},
        {value: 'Extérieur', text: 'Extérieur'}
    ],
    itemPeriodList: [
        {value: 'Lever', text: 'Lever'},
        {value: 'Matin', text: 'Matin'},
        {value: 'Midi', text: 'Midi'},
        {value: 'Après-midi', text: 'Après-midi'},
        {value: 'Soirée', text: 'Soirée'},
        {value: 'Nuit', text: 'Nuit'}
    ],
    itemList: [
        {
            value: 'Transferts', text: 'Transferts', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Déplacements intérieurs', text: 'Déplacements intérieurs', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Toilette', text: 'Toilette', subItems: [
                {value: 'Haut', text: 'Haut (visage (cis rasage et coiffage) , tronc, bras, mains)'},
                {value: 'Bas', text: 'Bas (régions intimes, jambes, pieds)'}
            ], interpretationRule: {
                AA: "A",
                CC: "C",
                CB: "C",
                BC: "C",
                CA: "C",
                AC: "C",
                other: "B"
            }
        },
        {
            value: 'Elimination', text: 'Élimination', subItems: [
                {value: 'Urinaire', text: 'Urinaire'},
                {value: 'Fécale', text: 'Fécale'}
            ], interpretationRule: {
                AA: "A",
                CC: "C",
                CB: "C",
                BC: "C",
                CA: "C",
                AC: "C",
                other: "B"
            }
        },
        {
            value: 'Habillage', text: 'Habillage', subItems: [
                {value: 'Haut', text: 'Haut (le fait de passer des vêtements par la tête et/ou les bras)'},
                {
                    value: 'Moyen',
                    text: 'Moyen (le fait de fermer un vêtement, mettre une ceinture, bretelles, soutien-gorge)'
                },
                {
                    value: 'Bas',
                    text: 'Bas ( enfiler des vêtements par le bas du corps, y compris chaussettes, chaussures)'
                }
            ], interpretationRule: {
                AAA: "A",
                CCC: "C",
                other: "B"
            }
        },
        {
            value: 'Cuisine', text: 'Cuisine', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Alimentation', text: 'Alimentation', subItems: [
                {
                    value: 'Se servir',
                    text: 'Se servir (couper la viande, ouvrir un pot de yaourt, peler un fruit, remplir son verre)'
                },
                {value: 'Manger', text: 'Manger ( Porter les aliments à la bouche et les avaler)'}
            ], interpretationRule: {
                AA: "A",
                CC: "C",
                CB: "C",
                BC: "C",
                other: "B"
            }
        },
        {
            value: 'Suivi du traitement', text: 'Suivi du traitement', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Ménage', text: 'Ménage', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Alerter', text: 'Alerter', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Déplacements extérieurs', text: 'Déplacements extérieurs', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Transports', text: 'Transports', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Activités de temps libre', text: 'Activités de temps libre', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Achats', text: 'Achats', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Gestion', text: 'Gestion', subItems: [
                {value: null, text: ''}
            ]
        },
        {
            value: 'Orientation', text: 'Orientation', subItems: [
                {value: 'Temps', text: 'Temps'},
                {value: 'Espace', text: 'Espace'}
            ], interpretationRule: {
                AA: "A",
                CC: "C",
                CB: "C",
                BC: "C",
                CA: "C",
                AC: "C",
                other: "B"
            }
        },
        {
            value: 'Cohérence', text: 'Cohérence', subItems: [
                {value: 'Communication', text: 'Communication'},
                {value: 'Comportement', text: 'Comportement'}
            ], interpretationRule: {
                AA: "A",
                CC: "C",
                CB: "C",
                BC: "C",
                CA: "C",
                AC: "C",
                other: "B"
            }
        }
    ],
    itemOrder: [
        {
            place: "chambre",
            list: [transfert, deplacementInt, toiletteH, toiletteB, elimination, habillage, treatment, menage, menageGlobal, transversal]
        },
        {
            place: "salle-de-bain",
            list: [transfert, deplacementInt, toiletteH, toiletteB, elimination, habillage, menage, menageGlobal, treatment, transversal],
        },
        {
            place: "cuisine",
            list: [transfert, deplacementInt, cuisine, repas, menage, menageGlobal, linge, treatment, transversal],
        },
        {
            place: "salon",
            list: [transfert, deplacementInt, repas, menage, menageGlobal, achat, activite, treatment, transversal],
        },
        {
            place: "exterieur",
            list: [transfert, deplacementExt, courses, activite, treatment, transversal]
        }
    ]
}
