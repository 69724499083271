<template>
    <Card :title="title" card-body-class="p-0">
        <template v-if="mention" v-slot:post-title>{{mention}}</template>
        <div class="d-flex h-100 justify-content-center align-items-center">
            <input id="upload-video" name="video" type="file" accept=".mp4,.mpg,.avi" @change="filesDocument"/>
            <label for="upload-video" class="w-100">
                <div class="d-flex h-100 justify-content-center align-items-center w-100">
                    <video v-if="videoUpload && value" :src="videoUpload" controls width="200" height="200" />
                    <video v-else-if="videoUrl" :src="videoUrl" controls width="200" height="200" />
                    <img v-else width="200px" height="200px" src="@/assets/photo-add-gray.svg" alt/>
                </div>
            </label>
        </div>
    </Card>
</template>
<script>
    import Card from "./Card";
    export default {
        name: "VideoSimple",
        components: {Card},
        props:{
            title: String,
            videoUrl: String,
            mention: {
                type: String,
                default:()  => false
            },
            value: {
                type: File,
                default: () => {}
            },
            isLogo: {
                type: Boolean,
                default:()  => false
            },
        },
        data: () => ({
            video: null,
            videoUpload: null
        }),
        methods:{
            filesDocument: function(e){
                this.$emit('input', e.target.files[0]);
                let fileReader = new FileReader();
                fileReader.onload = (e) => {
                    this.videoUpload = e.target.result;
                };
                fileReader.readAsDataURL(e.target.files[0]);
            }
        }
    };
</script>