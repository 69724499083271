<template>
    <div class="container pt-4">
      <h5 class="eval-item--title">Plan d'intervention</h5>
      <div class="eval-item--card cr--context">
        <p class="font-weight-bold">Objectifs :</p>
        <p>Proposer une aide humaine adaptée au niveau d'autonomie, au rythme et aux habitudes de vie, ainsi qu'à l'environnement.</p>
        <table class="table intervention-table">
          <thead>
            <tr>
              <th>Semaine 1</th>
              <th>Semaine 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Lundi</th>
              <th>
                <div v-for="(plan, index) in mondayActs['Semaine 1']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
              <th>Lundi</th>
              <th>
                <div v-for="(plan, index) in mondayActs['Semaine 2']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
            </tr>
            <tr>
              <th>Mardi</th>
              <th>
                <div v-for="(plan, index) in tuesdayActs['Semaine 1']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
              <th>Mardi</th>
              <th>
                <div v-for="(plan, index) in tuesdayActs['Semaine 2']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
            </tr>
            <tr>
              <th>Mercredi</th>
              <th>
                <div v-for="(plan, index) in wednesdayActs['Semaine 1']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
              <th>Mercredi</th>
              <th>
                <div v-for="(plan, index) in wednesdayActs['Semaine 2']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
            </tr>
            <tr>
              <th>Jeudi</th>
              <th>
                <div v-for="(plan, index) in thursdayActs['Semaine 1']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
              <th>Jeudi</th>
              <th>
                <div v-for="(plan, index) in thursdayActs['Semaine 2']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
            </tr>
            <tr>
              <th>Vendredi</th>
              <th>
                <div v-for="(plan, index) in fridayActs['Semaine 1']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
              <th>Vendredi</th>
              <th>
                <div v-for="(plan, index) in fridayActs['Semaine 2']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
            </tr>
            <tr>
              <th>Samedi</th>
              <th>
                <div v-for="(plan, index) in saturdayActs['Semaine 1']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
              <th>Samedi</th>
              <th>
                <div v-for="(plan, index) in saturdayActs['Semaine 2']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
            </tr>
            <tr>
              <th>Dimanche</th>
              <th>
                <div v-for="(plan, index) in sundayActs['Semaine 1']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
              <th>Dimanche</th>
              <th>
                <div v-for="(plan, index) in sundayActs['Semaine 2']" :key="index" class="intervention-table--detailsAct">
                  <p class="intervention-table--detailsAct-period">{{plan.name}}</p>
                  <p v-for="(act, id) in getTypeAides(plan)" :key="id" class="intervention-table--detailsAct-list">{{act.action}}: 
                    <span v-for="(aide, idx) in act.aides" :key="idx">{{aide}}, </span>
                  </p>
                </div>
              </th>
            </tr>
          </tbody>
        </table>

        <p class="font-weight-bold">Résumé du plan d'intervention :</p>
        <div class="row">
          <div class="col-md-4">
            <p>Nombre d'heures de semaine préconisées par mois :</p>
            <p class="font-weight-bold">{{convertTime(evaluation.heuresSemaine)}}</p>
          </div>
          <div class="col-md-4">
            <p>Nombre d'heures "D/F" préconisées par mois :</p>
            <p class="font-weight-bold">{{convertTime(evaluation.heuresDimanche)}}</p>
          </div>
          <div class="col-md-4">
            <p>Total d'heures préconisées par mois :</p>
            <p class="font-weight-bold">{{convertTime(evaluation.heuresSemaine + evaluation.heuresDimanche)}}</p>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import {mapGetters, mapState, mapActions} from "vuex";
import CRComponent from "./CRComponent";
import {dateFormat} from "@/components/Util/UtilFunction";
import {WEEK_ONE, WEEK_TWO, DAYS} from "@/views/Evaluation/CR/WeekDays";

export default {
  name: "CR_Intervention",
  extends: CRComponent,
  data() {
    return {
      periods: ["Lever", "Matin", "Midi", "Après-midi", "Soirée", "Nuit"],
    }
  },
  created() {
    this.DEFAULT_VALUE = 'non renseigné';
  },
  mounted() {
    this.getCarePlanPrestationList({filters: '{"column" : "evaluationId", "val" : ' + this.$route.params.evaluationId + '}'});
    this.getAggirQuestionList({limit: 0});
  },
  computed: {
    ...mapState("Evaluation", ["evaluation"]),
    ...mapGetters('Evaluation', ["getSeanceDetailsByDay","getAivqValidatedTimeWeekSum", "getAvqValidatedTimeWeekSum","getAivqValidatedTimeDFSum", "getAvqValidatedTimeDFSum","getAvqValidatedTimeSum","getAivqValidatedTimeSum"]),
    ...mapState("CarePlanPrestation", ["carePlanPrestations"]),
    ...mapState("AggirQuestion", ["aggirQuestions"]),
    aggirgridItems() {
      const gridItems = [];

      for (let question of this.aggirQuestions) {
        const item = {
          id: question.id,
          name: question.subItem ? question.item + ' - ' + question.subItem : question.item
        }
        gridItems.push(item);
      }
      return gridItems;
    },
    mondayActs() {
      let res = {
        [WEEK_ONE]: [],
        [WEEK_TWO]: []
      };
      this.carePlanPrestations.map((carePlan) => {
        let schedule = JSON.parse(carePlan.recurrency[0]);
        if(schedule[WEEK_ONE].includes(DAYS[0])) {
          res[WEEK_ONE].push(carePlan);
        }
        if(schedule[WEEK_TWO].includes(DAYS[0])) {
          res[WEEK_TWO].push(carePlan);
        }
      });
      return res;
    },
    tuesdayActs() {
      let res = {
        [WEEK_ONE]: [],
        [WEEK_TWO]: []
      };
      this.carePlanPrestations.map((carePlan) => {
        let schedule = JSON.parse(carePlan.recurrency[0]);
        if(schedule[WEEK_ONE].includes(DAYS[1])) {
          res[WEEK_ONE].push(carePlan);
        }
        if(schedule[WEEK_TWO].includes(DAYS[1])) {
          res[WEEK_TWO].push(carePlan);
        }
      })
      return res;
    },
    wednesdayActs() {
      let res = {
        [WEEK_ONE]: [],
        [WEEK_TWO]: []
      };
      this.carePlanPrestations.map((carePlan) => {
        let schedule = JSON.parse(carePlan.recurrency[0]);
        if(schedule[WEEK_ONE].includes(DAYS[2])) {
          res[WEEK_ONE].push(carePlan);
        }
        if(schedule[WEEK_TWO].includes(DAYS[2])) {
          res[WEEK_TWO].push(carePlan);
        }
      })
      return res;
    },
    thursdayActs() {
      let res = {
        [WEEK_ONE]: [],
        [WEEK_TWO]: []
      };
      this.carePlanPrestations.map((carePlan) => {
        let schedule = JSON.parse(carePlan.recurrency[0]);
        if(schedule[WEEK_ONE].includes(DAYS[3])) {
          res[WEEK_ONE].push(carePlan);
        }
        if(schedule[WEEK_TWO].includes(DAYS[3])) {
          res[WEEK_TWO].push(carePlan);
        }
      })
      return res;
    },
    fridayActs() {
      let res = {
        [WEEK_ONE]: [],
        [WEEK_TWO]: []
      };
      this.carePlanPrestations.map((carePlan) => {
        let schedule = JSON.parse(carePlan.recurrency[0]);
        if(schedule[WEEK_ONE].includes(DAYS[4])) {
          res[WEEK_ONE].push(carePlan);
        }
        if(schedule[WEEK_TWO].includes(DAYS[4])) {
          res[WEEK_TWO].push(carePlan);
        }
      })
      return res;
    },
    saturdayActs() {
      let res = {
        [WEEK_ONE]: [],
        [WEEK_TWO]: []
      };
      this.carePlanPrestations.map((carePlan) => {
        let schedule = JSON.parse(carePlan.recurrency[0]);
        if(schedule[WEEK_ONE].includes(DAYS[5])) {
          res[WEEK_ONE].push(carePlan);
        }
        if(schedule[WEEK_TWO].includes(DAYS[5])) {
          res[WEEK_TWO].push(carePlan);
        }
      })
      return res;
    },
    sundayActs() {
      let res = {
        [WEEK_ONE]: [],
        [WEEK_TWO]: []
      };
      this.carePlanPrestations.map((carePlan) => {
        let schedule = JSON.parse(carePlan.recurrency[0]);
        if(schedule[WEEK_ONE].includes(DAYS[6])) {
          res[WEEK_ONE].push(carePlan);
        }
        if(schedule[WEEK_TWO].includes(DAYS[6])) {
          res[WEEK_TWO].push(carePlan);
        }
      })
      return res;
    },
    weekHours() {
      const total = Math.floor((this.getAivqValidatedTimeWeekSum + this.getAvqValidatedTimeWeekSum) * 4.34);
      return {
        formatted: dateFormat(total, true),
        total: total
      }
    },
    dfHours() {
      const total = Math.floor((this.getAivqValidatedTimeDFSum + this.getAvqValidatedTimeDFSum) * 4.34);

      return {
        formatted: dateFormat(total, true),
        total: total
      }
    },
    totalHours() {
      const total = this.weekHours.total + this.dfHours.total;
      return {
        formatted: dateFormat(total, true),
        total: total
      }
    },
    totalAvq() {
      return dateFormat(Math.floor(this.getAvqValidatedTimeSum * 4.34));
    },
    totalAivq() {
      return dateFormat(Math.floor(this.getAivqValidatedTimeSum * 4.34));
    },
    transfertCount() {
      return Math.floor((this.getSeanceDetailsByDay("Lun").transfertCount
        + this.getSeanceDetailsByDay("Mar").transfertCount
        + this.getSeanceDetailsByDay("Mer").transfertCount
        + this.getSeanceDetailsByDay("Jeu").transfertCount
        + this.getSeanceDetailsByDay("Ven").transfertCount
        + this.getSeanceDetailsByDay("Sam").transfertCount
        + this.getSeanceDetailsByDay("Dim").transfertCount) * 4.34);
    }
  },
  methods: {
    ...mapActions('CarePlanPrestation', ['getCarePlanPrestationList']),
    ...mapActions('AggirQuestion', ['getAggirQuestionList']),
    exportSynthesis() {
      return {
        mondaySemaine1: this.convertToSemaineData(this.mondayActs[WEEK_ONE]),
        tuesdaySemaine1: this.convertToSemaineData(this.tuesdayActs[WEEK_ONE]),
        wednesdaySemaine1: this.convertToSemaineData(this.wednesdayActs[WEEK_ONE]),
        thursdaySemaine1: this.convertToSemaineData(this.thursdayActs[WEEK_ONE]),
        fridaySemaine1: this.convertToSemaineData(this.fridayActs[WEEK_ONE]),
        saturdaySemaine1: this.convertToSemaineData(this.saturdayActs[WEEK_ONE]),
        sundaySemaine1: this.convertToSemaineData(this.sundayActs[WEEK_ONE]),
        mondaySemaine2: this.convertToSemaineData(this.mondayActs[WEEK_TWO]),
        tuesdaySemaine2: this.convertToSemaineData(this.tuesdayActs[WEEK_TWO]),
        wednesdaySemaine2: this.convertToSemaineData(this.wednesdayActs[WEEK_TWO]),
        thursdaySemaine2: this.convertToSemaineData(this.thursdayActs[WEEK_TWO]),
        fridaySemaine2: this.convertToSemaineData(this.fridayActs[WEEK_TWO]),
        saturdaySemaine2: this.convertToSemaineData(this.saturdayActs[WEEK_TWO]),
        sundaySemaine2: this.convertToSemaineData(this.sundayActs[WEEK_TWO]),
        weekHours: this.convertTime(this.evaluation.heuresSemaine),
        dfHours: this.convertTime(this.evaluation.heuresDimanche),
        totalHours: this.convertTime(this.evaluation.heuresSemaine + this.evaluation.heuresDimanche),
        totalAvq: this.totalAvq,
        totalAivq: this.totalAivq,
        transfertCount: this.transfertCount
      }
    },
    convertToSemaineData(data) {
      return data.map(item=>{
        let typeAides = this.getTypeAides(item);
        let itemText = '';
        typeAides.map(typeAide => {
          itemText += typeAide.action + ': ' + typeAide.aides.join(', ') + '; ';
        })
        return item.name + ' - ' + itemText;
      }).join(' '.repeat(100));
    },
    convertTime(n) {
      let hours = (n / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + "h" + rminutes + "min";
    },
    getTypeAides(carePlan) {
      let datas = [];
      let typeAides = JSON.parse(carePlan.typeAide);
      Object.keys(typeAides).map(itemId => {
        let item = this.aggirgridItems.filter(elt => {
          return elt.id == itemId;
        });
        item = item[0];
        if(item) {
          datas.push({action: item.name, aides: typeAides[itemId]});
        }
      });
      return datas;
    },
  }
  
}
</script>