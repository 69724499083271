export default {
    getSuperAlertsNotSeen(state) {
        return state.alerts.filter(item=> state.superAlertsIds.includes(item.type) && !item.hasSeen);
    },
    getAlertsNotSeen(state) {
        return state.alerts.filter(item=> !state.superAlertsIds.includes(item.type) && !item.hasSeen);
    },
    getSuperAlertsSeen(state) {
        return state.alerts.filter(item=> state.superAlertsIds.includes(item.type) && item.hasSeen);
    },
    getAlertsSeen(state) {
        return state.alerts.filter(item=> !state.superAlertsIds.includes(item.type) && item.hasSeen);
    }
};