export default {
    structure: {
        name: '',
        erpId: '',
        address: '',
        status: false
    },
    structures: [],
    globalStructure: null,
    loading: false,
    error: null,
    total: 0
}
