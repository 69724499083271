<template>
  <div class="soin-item">
    <div class="soin-item--title" @click.prevent="toggleActive">
      <h1>Fonctions motrices</h1>
      <button class="btn" :class="{'inverted': !active}">
        <img src="@/assets/chevron-up.svg" alt />
      </button>
    </div>
    <div class="soin-item--content">
      <eval-card-check
          :showContentSlot="true"
          :checked="sfm01 !== null && sfm01.length > 0"
          title="Présence d'un déficit moteur ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form">
              <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[]">
                  <template slot="content">
                    <div class="id-check--option" v-for="(option, index) in
                              [{value:'r1', label:'Non'},
                                {value:'r2', label:'A droite'},
                                {value:'r3', label:'A gauche'},
                                {value:'r4', label:'Membre supérieur'},
                                {value:'r5', label:'Membre inférieur'},
                                {value:'r6', label:'Faciale'},
                                {value:'r7', label:'Complet'},
                                {value:'r8', label:'Incomplet'},
                                {value:'r9', label:'Origine vasculaire'},
                                {value:'r10', label:'Origine neurologique'},
                                {value:'r11', label:'Origine inconnue ou autre'},
                                ]"
                         :key="index"
                         style="margin-right: 40px;">
                      <input class="id-check--input"
                             :id="`sfm01-option-${index}`"
                             type="checkbox"
                             :value="option.value"
                             v-model="sfm01"/>
                      <label :for="`sfm01-option-${index}`">
                        <span class="id-check--indicator">{{option.label}}</span>
                      </label>
                    </div>
                  </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="true"
          :checked="sfm11 !== null && sfm11.length > 0"
          title="La personne présente t-elle une aphasie ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form">
              <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[]">
                <template slot="content">
                  <div class="id-check--option" v-for="(option, index) in
                            [{value:'r1', label:'Oui'},
                              {value:'r2', label:'Non'},
                              {value:'r3', label:'NSP'},
                              {value:'r4', label:'Origine vasculaire'},
                              {value:'r5', label:'Origine neurologique'},
                              {value:'r6', label:'Origine inconnue ou autre'}
                              ]"
                       :key="index">
                    <input class="id-check--input"
                           :id="`sfm11-option-${index}`"
                           type="checkbox"
                           :value="option.value"
                           v-model="sfm11"/>
                    <label :for="`sfm11-option-${index}`">
                      <span class="id-check--indicator">{{option.label}}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="false"
          :checked="sfm03 !== null && sfm03 !== ''"
          :hasQuestion="true"
          title="TEST DU LEVER DE CHAISE – FTSST (Five Times Sit To Stand Test) : ce test sert à déterminer si une personne âgée a besoin d’une évaluation plus poussée de sa mobilité réduite."
          v-if="active && ((!getScoreQuestionAggirMoveIn || (getScoreQuestionAggirMoveIn && getScoreQuestionAggirMoveIn !== 'C')) && (!getScoreQuestionAggirTransfert || (getScoreQuestionAggirTransfert && getScoreQuestionAggirTransfert !== 'C')))"
          @question="openTestModal('sfm03')">
      </eval-card-check>

      <eval-card-check
          v-if="sfm03 !== '' || active"
          :showMainSlot="true"
          :has-check="false"
          class="theme-red"
          title="Interprétation : TEST DU LEVER DE CHAISE – FTSST (Five Times Sit To Stand Test)"
          v-show="sfm03Result">
        <template v-slot:main>
          {{ sfm03Result }}
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="false"
          :checked="sfm04 !== null && sfm04 !== ''"
          :hasQuestion="true"
          title="TEST DE LA VITESSE DE MARCHE SUR 4 MÈTRES : ce test sert à déterminer si une personne âgée a besoin d’une évaluation plus poussée de sa mobilité à la marche."
          v-if="active && ((!getScoreQuestionAggirMoveIn || (getScoreQuestionAggirMoveIn && getScoreQuestionAggirMoveIn !== 'C')) && (!getScoreQuestionAggirTransfert || (getScoreQuestionAggirTransfert && getScoreQuestionAggirTransfert !== 'C')))"
          @question="openTestModal('sfm04')">
      </eval-card-check>

      <eval-card-check
          v-if="sfm04 !== '' || active"
          :showMainSlot="true"
          :has-check="false"
          class="theme-red"
          title="Interprétation : TEST DE LA VITESSE DE MARCHE SUR 4 MÈTRES"
          v-show="sfm04Result">
        <template v-slot:main>
          {{ sfm04Result }}
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="false"
          :checked="sfm101 !== null && sfm101 !== '' && sfm102 !== null && sfm102 !== '' && sfm103 !== null && sfm103 !== ''"
          :hasQuestion="true"
          title="Test d'équilibre : en 3 parties :
          - debout, les pieds côte à côte
          - debout en semi-tandem
          - debout en tandem"
          v-if="active && ((!getScoreQuestionAggirMoveIn || (getScoreQuestionAggirMoveIn && getScoreQuestionAggirMoveIn !== 'C')) && (!getScoreQuestionAggirTransfert || (getScoreQuestionAggirTransfert && getScoreQuestionAggirTransfert !== 'C')))"
          @question="openTestModal('sfm10')">
      </eval-card-check>

      <eval-card-check
          v-if="(sfm101 !== '' && sfm102 !== '' && sfm101 !== '') || active"
          :showMainSlot="true"
          :has-check="false"
          class="theme-red"
          title="Interprétation : test d'équilibre"
          v-show="sfm10Result">
        <template v-slot:main>
          {{ sfm10Result }}
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="true"
          :checked="sfm05 !== null && sfm05 !== ''"
          title="Difficulté à certains efforts ou ports de charges de la vie quotidienne ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sfm05" name id>
                  <option value="r1">Pas du tout</option>
                  <option value="r2">Un peu</option>
                  <option value="r3">Assez</option>
                  <option value="r4">Beaucoup</option>
                  <option value="r5">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="true"
          :checked="sfm06 !== null && sfm06 !== ''"
          title="Porte de chaussures orthopédiques ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sfm06" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="true"
          :checked="sfm07 !== null && sfm07 !== ''"
          title="Porte de prothèse ou orthèse ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sfm07" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="true"
          :checked="sfm08 !== null && sfm08 !== ''"
          title="Description de la prothèse ou de l'orthèse :"
          v-if="sfm07 === 'r1' && active">
        <template v-slot:content>
          <div class="w-100">
            <div class="eval-card--form input">
              <div class="form-group eval-form eval-form--input">
                <textarea
                    v-model="sfm08"
                    class="form-control"
                    placeholder="Saisir une réponse"
                    style="width: 100%;max-width: 100%;"
                    @blur="onBlurSfm08"></textarea>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showMainSlot="true"
          :has-check="false"
          class="theme-red"
          title="Interprétation : Mode de déplacement"
          v-show="moving">
        <template v-slot:main>
          {{moving}}
        </template>
      </eval-card-check>
    </div>

    <custom-modal :active="motorSmf03Active" @close="closeTestModal('sfm03')">
      <div class="add-act-modal">
        <h1 class="cuisine-modal--title">TEST DU LEVER DE CHAISE – FTSST (Five Times Sit To Stand Test)</h1>
        <div class="row">
          <img src="@/assets/ftsst.png" width="400" style="margin: 20px"/>
        </div>
        <div class="row">
          <p>Ce test sert à déterminer si une personne âgée a besoin d’une évaluation plus poussée de sa mobilité réduite.
            Instructions : Demandez à la personne : « Pensez-vous qu’il serait sûr pour vous d’essayer de vous lever d’une chaise cinq fois de suite sans utiliser vos bras ? » (Faites-lui une démonstration.)
            Si OUI, posez la chaise contre un mur puis, demandez-lui de : </p>
          <p> - s’asseoir au milieu de la chaise ;</p>
          <p> - se croiser les bras et de les maintenir sur sa poitrine ;</p>
          <p> - se mettre debout, puis de s’asseoir à nouveau ;</p>
          <p> - répéter ce geste cinq fois le plus rapidement possible sans s’arrêter.</p>
          <p>Relevez le temps que la personne passe pour faire le test – une évaluation plus approfondie est nécessaire si elle n’arrive pas à se lever cinq fois dans les 12 secondes.</p>
          <p>
            Pour plus d'information,
            <a href="https://cnfs.ca/agees/tests/mesurer-la-capacite-physique/test-du-lever-de-chaise-ftsst"
              target="_blank"
              style="color: #3f65e3;text-decoration: underline;">Vous pouvez vous rendre sur ce site.</a>
          </p>
        </div>
        <div class="row">
          <button class="chrono-btn" @click.prevent="toggleSfm03Chrono">
            <span v-if="!chronoSfm03Start">Démarrer</span>
            <span v-else>Stop</span>
          </button>
          <p class="chrono-result" id="chrono-result-sfm03">0`00 s</p>
          <p v-if="sfm03" class="chrono-result-text">{{sfm03Result}}</p>
        </div>
        <div class="d-flex justify-content-around mt-5">
          <button class="btn btn-success" @click.prevent="closeSmf03">Valider</button>
          <button class="btn btn-danger" @click.prevent="notFeasibleSmf03">Non-réalisable</button>
        </div>
      </div>
    </custom-modal>

    <custom-modal :active="motorSmf04Active" @close="closeTestModal('sfm04')">
      <div class="add-act-modal">
        <h1 class="cuisine-modal--title">TEST DE LA VITESSE DE MARCHE SUR 4 MÈTRES </h1>
        <div class="row">
          <img src="@/assets/tug.png" height="400" style="margin: 20px"/>
        </div>
        <div class="row">
          <p>
            Ce test sert à déterminer si une personne âgée a besoin d’une évaluation plus poussée de sa mobilité à la marche.
            Contre-indications : le participant est incapable de se tenir debout ou de marcher sans l’aide d’une autre personne (questionnaire sur les contre-indications).
          </p>
          <p>
            REMARQUE : Le participant peut utiliser une aide à la mobilité. (canne, …)
          </p>
          <p>
            Important : la position du participant est très importante afin que le test soit reproductible et qu’on puisse comparer les visites. Les étapes les plus importantes sont indiquées avec l’icône suivante :
          </p>
          <p>
            Expliquez les consignes et faites une démonstration du test au participant.
          </p>
          <p>
            Permettez au participant de faire un essai pour se pratiquer avant de commencer le test. Dites au participant que le chronométrage commencera quand vous direz « « Prêt, partez ».
          </p>
          <p>
            Commencez le chronométrage aussitôt après avoir dit « partez ».
          </p>
          <p>
            Si la personne n’est pas en mesure de réaliser le test laissé le test défiler au-delà de 14 secondes.
          </p>
        </div>
        <div class="row">
          <button class="chrono-btn" @click.prevent="toggleSfm04Chrono">
            <span v-if="!chronoSfm04Start">Démarrer</span>
            <span v-else>Stop</span>
          </button>
          <p class="chrono-result" id="chrono-result-sfm04">0`00 s</p>
          <p v-if="sfm04" class="chrono-result-text">{{sfm04Result}}</p>
        </div>
        <div class="d-flex justify-content-around mt-5">
          <button class="btn btn-success" @click.prevent="closeSmf04">Valider</button>
          <button class="btn btn-danger" @click.prevent="notFeasibleSmf04">Non realisable</button>
        </div>
      </div>
    </custom-modal>

    <custom-modal :active="motorSmf10Active" @close="closeTestModal('sfm10')">
      <div class="add-act-modal">
        <h1 class="cuisine-modal--title">TEST D’EQUILIBRE </h1>
        <div class="row">
          <p>
            Ce test sert à déterminer si une personne âgée a besoin d’une évaluation plus poussée de sa capacité à se tenir debout en équilibre.
            Contre-indications : le participant est incapable de se tenir debout sans l’aide d’une autre personne.
          </p>
          <p>
            Expliquez les consignes et faites une démonstration du test au participant.
          </p>
          <p>
            Permettez au participant de faire un essai pour se pratiquer avant de commencer le test. Dites au participant que le chronométrage commencera quand vous direz « « Prêt, partez ».
          </p>
          <p>
            Commencez le chronométrage aussitôt après avoir dit « partez »
          </p>
          <p>
            Si la personne n’est pas en mesure de réaliser le test laissez les 3 parties du test défiler au-delà de 10 secondes.
          </p>
          <p>
            Chaque partie du test peut être interrompue quand le sujet a atteint les 10 secondes.
          </p>
        </div>

        <!-- CHRONO #1 -->
        <div class="row">
          <div class="col-2">
            <img src="@/assets/bbs1.png"/>
          </div>
          <div class="col-10">
            <div class="row">
              <div class="col-12">
                <p style="padding: 30px;">1 - debout pieds côte à côte</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex">
                <button class="chrono-btn" @click.prevent="toggleSfm101Chrono">
                  <span v-if="!chronoSfm101Start">Démarrer</span>
                  <span v-else>Stop</span>
                </button>
                <p class="chrono-result" id="chrono-result-sfm101">0`00 s</p>
              </div>
            </div>
          </div>
        </div>

        <!-- CHRONO #2 -->
        <div class="row">
          <div class="col-2">
            <img src="@/assets/bbs2.png"/>
          </div>
          <div class="col-10">
            <div class="row">
              <div class="col-12">
                <p style="padding: 30px;">2 - debout en semi-tandem</p>
              </div>
              <div class="col-12 d-flex">
                <button class="chrono-btn" @click.prevent="toggleSfm102Chrono">
                  <span v-if="!chronoSfm102Start">Démarrer</span>
                  <span v-else>Stop</span>
                </button>
                <p class="chrono-result" id="chrono-result-sfm102">0`00 s</p>
              </div>
            </div>
          </div>
        </div>

        <!-- CHRONO #3 -->
        <div class="row">
          <div class="col-2">
            <div class="row">
              <div class="col-12">
                <img src="@/assets/bbs3-1.png" style="padding-right: 60px;"/>
              </div>
              <div class="col-12">
                <img src="@/assets/bbs3-2.png" style="padding-left: 60px;"/>
              </div>
            </div>
          </div>
          <div class="col-10">
            <div class="row">
              <div class="col-12">
                <p style="padding: 30px;">3 - debout en tandem</p>
              </div>
              <div class="col-12 d-flex">
                <button class="chrono-btn" @click.prevent="toggleSfm103Chrono">
                  <span v-if="!chronoSfm103Start">Démarrer</span>
                  <span v-else>Stop</span>
                </button>
                <p class="chrono-result" id="chrono-result-sfm103">0`00 s</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <p v-if="sfm101 || sfm102 || sfm103" class="chrono-result-text">{{sfm10Result}}</p>
        </div>
        <div class="d-flex justify-content-around mt-5">
          <button class="btn btn-success" @click.prevent="closeSmf10">Valider</button>
          <button class="btn btn-danger" @click.prevent="notFeasibleSmf10">Non realisable</button>
        </div>
      </div>
    </custom-modal>

  </div>
</template>
<script>
  import SubQuestionExtend from "./SubQuestionExtend";
  import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";
  import {mapActions, mapMutations, mapState, mapGetters} from "vuex";
  import {getAnswerTotal, getClassicRuleScore} from "@/components/Util/UtilFunction";
  import Moment from 'moment';
  import {chairLift, timeUpAndGo, movingInterpretation, balance} from "@/components/Eval/SoinCalcul";

  export default {
    extends: SubQuestionExtend,
    components: {
      EvalFormCheckAlt
    },
    methods: {
      ...mapMutations('Evaluation', ['setTreatmentProperty']),
      ...mapActions('Evaluation', ['saveTreatment']),
      getClassicRuleScore,
      getAnswerTotal,
      onBlurSfm02() {
        this.saveTreatment({
          id: "motor",
          name: "sfm02",
          evaluationId: this.$route.params.evaluationId
        });
      },
      onBlurSfm03() {
        this.saveTreatment({
          id: "motor",
          name: "sfm03",
          evaluationId: this.$route.params.evaluationId
        });
      },
      onBlurSfm04() {
        this.saveTreatment({
          id: "motor",
          name: "sfm04",
          evaluationId: this.$route.params.evaluationId
        });
      },
      onBlurSfm08() {
        this.saveTreatment({
          id: "motor",
          name: "sfm08",
          evaluationId: this.$route.params.evaluationId
        });
      },
      toggleSfm03Chrono(){
        this.chronoSfm03ResultElement = document.getElementById('chrono-result-sfm03');
        if(!this.chronoSfm03Start){
          this.startChrono();
          this.chronoSfm03Start = true;
          this.timeOutSfm03Chrono();
        }else{
          const diff = this.endChrono();
          this.chronoSfm03Start = false;
          this.setSfm03resultChrono(this.getChronoResultStr(diff));
          this.sfm03 = (diff / 1000);
        }
      },
      toggleSfm04Chrono(){
        this.chronoSfm04ResultElement = document.getElementById('chrono-result-sfm04');
        if(!this.chronoSfm04Start){
          this.startChrono();
          this.chronoSfm04Start = true;
          this.timeOutSfm04Chrono();
        }else{
          const diff = this.endChrono();
          this.chronoSfm04Start = false;
          this.setSfm04resultChrono(this.getChronoResultStr(diff));
          this.sfm04 = (diff / 1000);
        }
      },
      toggleSfm101Chrono(){
        this.chronoSfm102Start = false;
        this.chronoSfm103Start = false;

        this.chronoSfm101ResultElement = document.getElementById('chrono-result-sfm101');
        if(!this.chronoSfm101Start){
          this.startChrono();
          this.chronoSfm101Start = true;
          this.timeOutSfm101Chrono();
        }else{
          const diff = this.endChrono();
          this.chronoSfm101Start = false;
          this.setSfm101resultChrono(this.getChronoResultStr(diff));
          this.sfm101 = (diff / 1000);
        }
      },
      toggleSfm102Chrono(){
        this.chronoSfm101Start = false;
        this.chronoSfm103Start = false;

        this.chronoSfm102ResultElement = document.getElementById('chrono-result-sfm102');
        if(!this.chronoSfm102Start){
          this.startChrono();
          this.chronoSfm102Start = true;
          this.timeOutSfm102Chrono();
        }else{
          const diff = this.endChrono();
          this.chronoSfm102Start = false;
          this.setSfm102resultChrono(this.getChronoResultStr(diff));
          this.sfm102 = (diff / 1000);
        }
      },
      toggleSfm103Chrono(){
        this.chronoSfm101Start = false;
        this.chronoSfm102Start = false;

        this.chronoSfm103ResultElement = document.getElementById('chrono-result-sfm103');
        if(!this.chronoSfm103Start){
          this.startChrono();
          this.chronoSfm103Start = true;
          this.timeOutSfm103Chrono();
        }else{
          const diff = this.endChrono();
          this.chronoSfm103Start = false;
          this.setSfm103resultChrono(this.getChronoResultStr(diff));
          this.sfm103 = (diff / 1000);
        }
      },
      startChrono(){
        this.timeStampStart = Moment();
        this.chronoResult = 0;
      },
      endChrono(){
        this.timeStampEnd = Moment();
        if (this.chronoTimeout) {
          clearTimeout(this.chronoTimeout);
        }
        return this.timeStampEnd.diff(this.timeStampStart);
      },
      timeOutSfm03Chrono() {
        if (this.chronoTimeout) {
          clearTimeout(this.chronoTimeout);
        }
        if (!this.chronoSfm03Start) {
          return;
        }
        this.chronoTimeout = setTimeout(() => {
          this.setSfm03resultChrono();
          this.timeOutSfm03Chrono();
        }, 100);
      },
      timeOutSfm04Chrono() {
        if (this.chronoTimeout) {
          clearTimeout(this.chronoTimeout);
        }
        if (!this.chronoSfm04Start) {
          return;
        }
        this.chronoTimeout = setTimeout(() => {
          this.setSfm04resultChrono();
          this.timeOutSfm04Chrono();
        }, 100);
      },
      timeOutSfm101Chrono() {
        if (this.chronoTimeout) {
          clearTimeout(this.chronoTimeout);
        }
        if (!this.chronoSfm101Start) {
          return;
        }
        this.chronoTimeout = setTimeout(() => {
          this.setSfm101resultChrono();
          this.timeOutSfm101Chrono();
        }, 100);
      },
      timeOutSfm102Chrono() {
        if (this.chronoTimeout) {
          clearTimeout(this.chronoTimeout);
        }
        if (!this.chronoSfm102Start) {
          return;
        }
        this.chronoTimeout = setTimeout(() => {
          this.setSfm102resultChrono();
          this.timeOutSfm102Chrono();
        }, 100);
      },
      timeOutSfm103Chrono() {
        if (this.chronoTimeout) {
          clearTimeout(this.chronoTimeout);
        }
        if (!this.chronoSfm103Start) {
          return;
        }
        this.chronoTimeout = setTimeout(() => {
          this.setSfm103resultChrono();
          this.timeOutSfm103Chrono();
        }, 100);
      },
      setSfm03resultChrono(){
        const value = Moment().diff(this.timeStampStart);
        this.chronoSfm03ResultElement.innerText = this.getChronoResultStr(value);
      },
      setSfm04resultChrono(){
        const value = Moment().diff(this.timeStampStart);
        this.chronoSfm04ResultElement.innerText = this.getChronoResultStr(value);
      },
      setSfm101resultChrono(){
        const value = Moment().diff(this.timeStampStart);
        this.chronoSfm101ResultElement.innerText = this.getChronoResultStr(value);
      },
      setSfm102resultChrono(){
        const value = Moment().diff(this.timeStampStart);
        this.chronoSfm102ResultElement.innerText = this.getChronoResultStr(value);
      },
      setSfm103resultChrono(){
        const value = Moment().diff(this.timeStampStart);
        this.chronoSfm103ResultElement.innerText = this.getChronoResultStr(value);
      },
      getChronoResultStr(value){
        const second = parseInt((value / 1000));
        const millisecond = parseInt((value - (second * 1000)) / 10);
        return `${second}\`${millisecond} s`;
      },
      closeSmf03(){
        this.closeTestModal("sfm03");
        if(this.chronoSfm03Start) {
          this.toggleSfm03Chrono();
        }
      },
      closeSmf04(){
        this.closeTestModal("sfm04");
        if(this.chronoSfm04Start) {
          this.toggleSfm04Chrono();
        }
      },
      closeSmf10(){
        this.closeTestModal("sfm10");
        if(this.chronoSfm101Start) {
          this.toggleSfm101Chrono();
        }
        if(this.chronoSfm102Start) {
          this.toggleSfm102Chrono();
        }
        if(this.chronoSfm103Start) {
          this.toggleSfm103Chrono();
        }
      },
      notFeasibleSmf03(){
        this.notFeasible("sfm03");
        this.closeTestModal("sfm03");
      },
      notFeasibleSmf04(){
        this.notFeasible("sfm04");
        this.closeTestModal("sfm04");
      },
      notFeasibleSmf10(){
        this.notFeasible("sfm10");
        this.closeTestModal("sfm10");
      },

      openTestModal(item) {
        this.scrollBeforeOpeningModal = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;

        switch(item) {
          case "sfm03":
            this.motorSmf03Active = true;
            break;
          case "sfm04":
            this.motorSmf04Active = true;
            break;
          case "sfm10":
            this.motorSmf10Active = true;
            break;
          default:
            break;
        }
      },

      closeTestModal(item) {
        document.body.style.position = '';
        if (this.scrollBeforeOpeningModal) {
          window.scrollTo(0, parseInt(this.scrollBeforeOpeningModal));
        }

        switch(item) {
          case "sfm03":
            this.motorSmf03Active = false;
            break;
          case "sfm04":
            this.motorSmf04Active = false;
            break;
          case "sfm10":
            this.motorSmf10Active = false ;
            break;
        }
      },
      notFeasible(item){

        switch(item) {
          case "sfm03":
            this.sfm03 = '';
            break;
          case "sfm04":
            this.sfm04 = '';
            break;
          case "sfm10":
            this.sfm101 = ''
            this.sfm102 = ''
            this.sfm103 = ''
            break;
        }
      },
    },
    data: () => ({
      idMaterielsCheck: [19, 20, 21, 22, 23, 24, 25, 26],
      chronoResult: 0,
      timeStampStart: 0,
      timeStampEnd: 0,
      chronoSfm03Start: false,
      chronoSfm04Start: false,
      chronoSfm101Start: false,
      chronoSfm102Start: false,
      chronoSfm103Start: false,
      chronoTimeout: null,
      chronoSfm03ResultElement: null,
      chronoSfm04ResultElement: null,
      chronoSfm101ResultElement: null,
      chronoSfm102ResultElement: null,
      chronoSfm103ResultElement: null,
      motorSmf03Active: false,
      motorSmf04Active: false,
      motorSmf10Active: false,
      scrollBeforeOpeningModal: null,
    }),
    computed: {
      ...mapGetters('AggirQuestion', [
        'getScoreQuestionAggirMoveOut',
        'getScoreQuestionAggirMoveIn',
        'getScoreQuestionAggirTransfert'
      ]),
      ...mapState('Evaluation', ['motor', 'evaluation']),
      ...mapGetters('Material', ['getMaterials']),
      moving() {
        return movingInterpretation(
            this.getPeopleStr,
            this.getScoreQuestionAggirMoveIn,
            this.getScoreQuestionAggirMoveOut,
            (this.evaluation.materials ? this.evaluation.materials.filter(material => material.existing) : []),
            this.idMaterielsCheck)
      },
      sfm03Result() {
          return chairLift(this.getPeopleStr, this.sfm03);
      },
      sfm04Result() {
          return timeUpAndGo(this.getPeopleStr, this.sfm04);
      },
      sfm10Result() {
          return balance(this.getPeopleStr, this.sfm101, this.sfm102, this.sfm103).interpretation;
      },
      sfm01: {
        get() {
          return this.motor.sfm01.response ? this.motor.sfm01.response.split(',') : [];
        },
        set(value) {
          const prevValue = this.motor.sfm01.response;
          this.setTreatmentProperty({
            name: "sfm01",
            value: value.join(',')
          });
          if (value.join(',')!==prevValue) {
            this.saveTreatment({id: "motor", name: "sfm01", evaluationId: this.$route.params.evaluationId});
          }
        }
      },
      sfm02: {
        get() {
          return this.motor.sfm02.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfm02',
            value
          });
        }
      },
      sfm03: {
        get() {
          return this.motor.sfm03.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfm03',
            value
          });
          this.saveTreatment({
            id: "motor",
            name: "sfm03",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      sfm04: {
        get() {
          return this.motor.sfm04.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfm04',
            value
          });
          this.saveTreatment({
            id: "motor",
            name: "sfm04",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      sfm05: {
        get() {
          return this.motor.sfm05.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfm05',
            value
          });
          this.saveTreatment({
            id: "motor",
            name: "sfm05",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      sfm06: {
        get() {
          return this.motor.sfm06.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfm06',
            value
          });
          this.saveTreatment({
            id: "motor",
            name: "sfm06",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      sfm07: {
        get() {
          return this.motor.sfm07.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfm07',
            value
          });
          this.saveTreatment({
            id: "motor",
            name: "sfm07",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      sfm08: {
        get() {
          return this.motor.sfm08.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfm08',
            value
          });
          this.saveTreatment({
            id: "motor",
            name: "sfm08",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      sfm11: {
        get() {
          return this.motor.sfm11.response ? this.motor.sfm11.response.split(',') : [];
        },
        set(value) {
          const prevValue = this.motor.sfm11.response;
          this.setTreatmentProperty({
            name: "sfm11",
            value: value.join(',')
          });
          if (value.join(',')!==prevValue) {
            this.saveTreatment({id: "motor", name: "sfm11", evaluationId: this.$route.params.evaluationId});
          }
        }
      },
      sfm101: {
        get() {
          return this.motor.sfm101.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfm101',
            value
          });
          this.saveTreatment({
            id: "motor",
            name: "sfm101",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      sfm102: {
        get() {
          return this.motor.sfm102.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfm102',
            value
          });
          this.saveTreatment({
            id: "motor",
            name: "sfm102",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      sfm103: {
        get() {
          return this.motor.sfm103.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'sfm103',
            value
          });
          this.saveTreatment({
            id: "motor",
            name: "sfm103",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
    },
  };
</script>