import {client as ApolloClient} from '@/api/Apollo';
import {get} from '@/api/Fetch';
import {CONTRACT_LIST} from '@/api/GraphQLQueries/bo/contract/list-query'
import {GET_CONTRACT} from '@/api/GraphQLQueries/bo/contract/get-contract-query'
import {API_URL} from "@/components/Util/EnvVariable";

export const getContractList = ({commit}, {
    filters = '{}',
    sortColumn = '',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setContractLoading');
    return ApolloClient.query({
        query: CONTRACT_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setContractList', res.data.contractList.items);
        commit('setTotalContractList', res.data.contractList.total);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setContractError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getContract = ({commit}, {id}) => {
    commit('setContractLoading');
    return ApolloClient.query({
        query: GET_CONTRACT,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setContractSuccess', res.data.contract)
        return res.data
    }).catch((err) => {
        console.error(err)
        commit('setContractError', err.response ? err.response.data : err)
        throw err
    })
}

export const sendToSign = (vuex, {id}) => {
    return get(`${API_URL}/devis/${id}/sign`).then((res) => {
        return res.json();
    }).then((data) => {
        return data;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
}

export const closeSign = (vuex, {id}) => {
    return get(`${API_URL}/devis/${id}/close`).then((res) => {
        return res.json();
    }).then((data) => {
        return data;
    }).catch((err) => {
        console.error(err);
        throw err;
    });
}

export default {
    getContractList,
    getContract,
    sendToSign,
    closeSign
}