import gql from 'graphql-tag'

export const SYNTHESE_GOALS_LIST = gql`
    query syntheseGoalsByEvaluationId(
        $evaluationId: Int!,
    ) {
        syntheseGoalsByEvaluationId(
            evaluationId: $evaluationId,
        ) {
            id,
            item,
            problem,
            goals,
            actions,
            affectation,
            active
        }
    }
`;
