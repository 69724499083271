import gql from 'graphql-tag'

export const INTERVENTION_LIST = gql`
    query interventionList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!
    ) {
        interventionList(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                id,
                dateStart,
                dateEnd,
                client{
                    id,
                    lastname
                    firstname
                },
                prestation{
                    id,
                    name
                },
                intervenant{
                    id,
                    firstName,
                    lastName
                },
                familyComments,
                sendHour,
                status,
                hasNoBadge,
                hasNoBadgeDescription,
                createdAt
            },
            total
        }
    }
`
