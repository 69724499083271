import gql from 'graphql-tag'

export const SEND_RESET_PASSWORD_LINK = gql`
    mutation sendResetPasswordLink(
          $email: String!
    ) {
        sendResetPasswordLink(
            email: $email
        )
    }
`