import Accueil from "@/views/Evaluation/Accueil";
import Evaluation from "@/views/Evaluation/Index";
import Identity from "@/views/Evaluation/Identity/Index";
import Soin from "@/views/Evaluation/Soin/Index";
import ProjetDeVie from "@/views/Evaluation/ProjectLife/Index";
import ProjetDeVieOffre from "@/views/Evaluation/ProjectLife/Offre";
import ProjetDeViePlanning from "@/views/Evaluation/ProjectLife/Planning";
import Devis from '@/views/Evaluation/Devis/Index';
import DevisRedirect from '@/views/Evaluation/Devis/Redirect';
import Prestation from '@/views/Evaluation/Autonomie/Prestation';

//import CR from '@/views/Evaluation/CR'
import CR from '@/views/Evaluation/CR/Index'
export default [
    {
        path: "/evaluation-accueil",
        name: "accueil",
        component: Accueil
    },
    {
        path: '/evaluation/:evaluationId',
        name: "evaluation",
        component: Evaluation,
        children: [
            { path: 'identite', name: "identity", component: Identity },
            { path: 'soin', name: "soin", component: Soin },
            {
                path: 'projet-de-vie', component: ProjetDeVie, children: [
                    {path: 'offre', name: 'project-life', component: ProjetDeVieOffre},
                    {path: 'planning', name: 'planning', component: ProjetDeViePlanning},
                ]
            },
            { path: 'cr', name: 'cr', component: CR },
            {
                path: 'devis', name: "devis", component: Devis, children: [
                    { path:':contractId/redirect/:status', name:"redirect-devis", component: DevisRedirect }
                ]
            },
            { path: 'autonomie/prestation/:carePlanPrestation?', name: "autonomy", component: Prestation },
            {path: "*", redirect: {name: "accueil"}},
        ]
    },
];