import gql from 'graphql-tag'

export const DELETE_CARE_PLAN_PRESTATION = gql`
    mutation deleteCarePlanPrestation(
        $id: Int!,
    ) {
        deleteCarePlanPrestation(
            id: $id
        )
    }
`