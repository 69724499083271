export default {
    tarification: {
        structure: {},
        structureId: '',
        prestation: {},
        prestationId: '',
        tarifType: '',
        price: null
    },
    tarifications: [],
    loading: false,
    error: null,
    total: 0
}
