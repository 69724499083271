import {client as ApolloClient} from '@/api/Apollo';
import { AGGIR_QUESTION_LIST } from '@/api/GraphQLQueries/bo/aggir-question/list-query'
import { GET_AGGIR_QUESTION } from '@/api/GraphQLQueries/bo/aggir-question/get-aggir-question-query'
import { SAVE_AGGIR_QUESTION } from '@/api/GraphQLQueries/bo/aggir-question/save-aggir-question-mutation'
import { DELETE_AGGIR_QUESTION } from '@/api/GraphQLQueries/bo/aggir-question/delete-aggir-question-mutation'

export const getAggirQuestionList = ({ commit }, {
    filters = '{}',
    sortColumn = 'id',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setAggirQuestionLoading');
    return ApolloClient.query({
        query: AGGIR_QUESTION_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setAggirQuestionList', res.data.aggirQuestionList.items);
        commit('setTotalAggirQuestionList', res.data.aggirQuestionList.total);
    }).catch((err) => {
        console.error(err);
        commit('setAggirQuestionError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getAggirQuestion = ({ commit }, { id }) => {
    commit('setAggirQuestionLoading');

    return ApolloClient.query({
        query: GET_AGGIR_QUESTION,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setAggirQuestionSuccess', res.data.aggirQuestion);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setAggirQuestionError', err.response ? err.response.data : err);
        throw err;
    })
};

export const postAggirQuestion = ({ commit, state }) => {
    commit('setAggirQuestionSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_AGGIR_QUESTION,
        variables: {
            ...state.aggirQuestion,
            itemStch: JSON.stringify(state.aggirQuestion.itemStch),
            id: 0
        }
    }).then((res) => {
        commit('setPostAggirQuestionSuccess', res.data.saveAggirQuestion);
        res.data;
    }).catch((err) => {
        console.error(err);
        commit('setAggirQuestionError', err.response ? err.response.data : err);
        throw err;
    })
};

export const putAggirQuestion = ({ commit, state }) => {
    commit('setAggirQuestionSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_AGGIR_QUESTION,
        variables: {
            ...state.aggirQuestion,
            itemStch: JSON.stringify(state.aggirQuestion.itemStch)
        }
    }).then((res) => {
        commit('setPutAggirQuestionSuccess', res.data.saveAggirQuestion);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setAggirQuestionError', err.response ? err.response.data : err);
        throw err;
    })
};

export const deleteAggirQuestion = ({ commit }, { id }) => {
    commit('setAggirQuestionLoading');
    return ApolloClient.mutate({
        mutation: DELETE_AGGIR_QUESTION,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setDeleteAggirQuestionSuccess', res.data.deleteAggirQuestion);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setAggirQuestionError', err.response ? err.response.data : err);
        throw err;
    })
};

export default {
    getAggirQuestionList,
    getAggirQuestion,
    putAggirQuestion,
    postAggirQuestion,
    deleteAggirQuestion
};