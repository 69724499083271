<template>
  <div class="soin-item">
    <div class="soin-item--title" @click.prevent="toggleActive">
      <h1>Peau, téguments</h1>
      <button class="btn" :class="{'inverted': !active}">
        <img src="@/assets/chevron-up.svg" alt />
      </button>
    </div>
    <div class="soin-item--content">
      <eval-card-check
          :showContentSlot="true"
          :checked="spt01 !== null && spt01 !== ''"
          title="ATCD  d'escarres"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="spt01" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="spt02 !== null && spt02 !== ''"
          title="Présence actuelle d'escarres"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="spt02" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="spt03 !== null && spt03 !== ''"
          title="Localisation actuelle des escarres"
          v-if="spt02 === 'r1' && active">
        <template v-slot:content>
          <div class="w-100">
            <div class="eval-card--form input">
              <div class="form-group eval-form eval-form--input">
                <textarea
                    v-model="spt03"
                    class="form-control"
                    placeholder="Saisir une réponse"
                    style="width: 100%;max-width: 100%;"
                    @blur="onBlurSpt03"></textarea>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="spt04 !== null && spt04 !== ''"
          title="Autres problèmes de peau et téguments"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="spt04" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="spt05 !== null && spt05 !== ''"
          title="Description des autres problèmes de peau et téguments"
          v-if="spt04 === 'r1' && active">
        <template v-slot:content>
          <div class="w-100">
            <div class="eval-card--form input">
              <div class="form-group eval-form eval-form--input">
                <textarea
                    v-model="spt05"
                    class="form-control"
                    placeholder="Saisir une réponse"
                    style="width: 100%;max-width: 100%;"
                    @blur="onBlurSpt05"></textarea>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showMainSlot="true"
          :has-check="false"
          class="theme-red"
          title="Grille de Norton">
        <template v-if="norton" v-slot:main>
          {{norton}}
        </template>
      </eval-card-check>
    </div>
  </div>
</template>
<script>
  import SubQuestionExtend from "./SubQuestionExtend";
  import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
  import {nortonCalcul} from "@/components/Eval/SoinCalcul";

  export default {
    extends: SubQuestionExtend,
    methods: {
      ...mapMutations('Evaluation', ['setTreatmentProperty']),
      ...mapActions('Evaluation', ['saveTreatment']),
      onBlurSpt03() {
        this.saveTreatment({
          id: "skin",
          name: "spt03",
          evaluationId: this.$route.params.evaluationId
        });
      },
      onBlurSpt05() {
        this.saveTreatment({
          id: "skin",
          name: "spt05",
          evaluationId: this.$route.params.evaluationId
        });
      }
    },
    computed: {
      ...mapState('Evaluation', ['skin', 'evaluation']),
      ...mapGetters('AggirQuestion', [
        'getScoreQuestionAggirMoveOut',
        'getScoreQuestionAggirMoveIn',
        'getScoreQuestionAggirTransfert',
        'getScoreQuestionAggirOrientationTemps',
        'getScoreQuestionAggirOrientationEspace',
        'getScoreQuestionAggirCoherenceComportement',
        'getScoreQuestionAggirCoherenceCommunication',
        'getScoreQuestionAggirEliminationFecale',
        'getScoreQuestionAggirEliminationUrinaire'
      ]),
      norton() {
        return nortonCalcul(
            this.getPeopleStr,
            this.evaluation.gir,
            this.getScoreQuestionAggirOrientationTemps,
            this.getScoreQuestionAggirOrientationEspace,
            this.getScoreQuestionAggirCoherenceComportement,
            this.getScoreQuestionAggirCoherenceCommunication,
            this.getScoreQuestionAggirMoveIn,
            this.getScoreQuestionAggirTransfert,
            this.getScoreQuestionAggirEliminationFecale,
            this.getScoreQuestionAggirEliminationUrinaire).interpretation;
      },
      spt01: {
        get() {
          return this.skin.spt01.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'spt01',
            value
          });
          this.saveTreatment({
            id: "skin",
            name: "spt01",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      spt02: {
        get() {
          return this.skin.spt02.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'spt02',
            value
          });
          this.saveTreatment({
            id: "skin",
            name: "spt02",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      spt03: {
        get() {
          return this.skin.spt03.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'spt03',
            value
          });
        }
      },
      spt04: {
        get() {
          return this.skin.spt04.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'spt04',
            value
          });
          this.saveTreatment({
            id: "skin",
            name: "spt04",
            evaluationId: this.$route.params.evaluationId
          });
        }
      },
      spt05: {
        get() {
          return this.skin.spt05.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'spt05',
            value
          });
        }
      },
    }
  };
</script>