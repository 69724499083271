import gql from 'graphql-tag'

export const GET_INTERVENTION_RATIO_CHART_DATA_BY_HOURS = gql`
    query interventionRatioChartDataByHours(
        $intervenantId: String,
        $agencyId: String,
        $structureId: String,
        $gir: String
    ) {
        interventionRatioChartDataByHours(
            intervenantId: $intervenantId,
            agencyId: $agencyId,
            structureId: $structureId,
            gir: $gir
        )
    }
`
