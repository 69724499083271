import gql from 'graphql-tag'

export const GENERATE_CLIENT_QR_CODE = gql`
    mutation generateClientQrCode(
        $id: Int!,
    ) {
        generateClientQrCode(
            id: $id
        ) {
            qrCode
        }
    }
`
