<template>
  <div>
    <div class="pdv-bottom-bar pdv-bottom-bar--main">
      <div class="pdv-bottom-bar--part">
        <span>Temps validé</span>
        <h3>{{convertTime(tempValide)}} / sem.</h3>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters, mapState} from "vuex";
import {formatNumberCurrency} from '@/components/Util/UtilFunction';

export default {
  name: 'ValidatedTimeAct',
  data() {
    return {}
  },
  props: {
    tempValide: {
      type: Number
    }
  },
  computed: {
    ...mapState('Prestation', ['prestations']),
    ...mapGetters('Evaluation', ['getValidatedTimeSum', 'getEstimatedTimeSum', 'getSumOfPrestationCost']),
    getValidatedTimeSumWeek() {
      return this.getValidatedTimeSum;
    },
    actCostSumValidated() {
      return this.getSumOfPrestationCost('validated', this.prestations)
    },
    actCostSumEstimated() {
      return this.getSumOfPrestationCost('estimated', this.prestations)
    },
  },
  methods:{
    formatNumberCurrency,
    goToDevis() {
      this.$router.push({name: 'devis', props: {prestationsAct: this.prestations}});
    },
    convertTime(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + "h" + rminutes + "min";
    },
  },
}
</script>
<style scoped>
  .empty{
    padding-right: 80px;
  }
</style>