<template>
  <div class="bo--agence row mx-0 justify-content-center">
    <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
      <div class="d-flex align-items-center">
        <h1 class="bo--agence-title">Liste des alertes <font-awesome-icon v-show="loading" :icon="['fas', 'spinner']" spin/></h1>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
        </div>
      </div>

      <div class="col-md-12">
         <div class="accordion" role="tablist">

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button class="text-left" block v-b-toggle.accordion-1 variant="info">
                  <font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'stethoscope']"/> Signalement - Niveau 1 ({{getNewSignalementsTotal}})
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div class="row" v-for="(alert, title) in getSignalements.filter((c) => !c.hasSeen)" :key="`not-seen-notif-${title}`" >
                    <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                  </div>
                  <div class="row" v-for="(alert, title) in getSignalements.filter((c) => c.hasSeen)" :key="`seen-notif-${title}`" >
                    <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

           <b-card no-body class="mb-1">
             <b-card-header header-tag="header" class="p-1" role="tab">
               <b-button class="text-left" block v-b-toggle.accordion-6 variant="info">
                 <font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'stethoscope']"/> Signalement - Niveau 2 ({{getNewSignalementsLvl2Total}})
               </b-button>
             </b-card-header>
             <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
               <b-card-body>
                 <div class="row" v-for="(alert, title) in getSignalementsLvl2.filter((c) => !c.hasSeen)" :key="`not-seen-notif-${title}`" >
                   <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                 </div>
                 <div class="row" v-for="(alert, title) in getSignalementsLvl2.filter((c) => c.hasSeen)" :key="`seen-notif-${title}`" >
                   <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                 </div>
               </b-card-body>
             </b-collapse>
           </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button class="text-left" block v-b-toggle.accordion-2 variant="info">
                  <font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'frown']"/> Alerte santé morale usager ({{getNewDecreaseHealthsTotal}})
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div class="row" v-for="(alert, title) in getDecreaseHealths.filter((c) => !c.hasSeen)" :key="`not-seen-notif-${title}`" >
                    <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                  </div>
                  <div class="row" v-for="(alert, title) in getDecreaseHealths.filter((c) => c.hasSeen)" :key="`seen-notif-${title}`" >
                    <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button class="text-left" block v-b-toggle.accordion-3 variant="info">
                  <font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'street-view']"/> Absence ({{getNewAbsencesTotal}})
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div class="row" v-for="(alert, title) in getAbsences.filter((c) => !c.hasSeen)" :key="`not-seen-notif-${title}`" >
                    <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                  </div>
                  <div class="row" v-for="(alert, title) in getAbsences.filter((c) => c.hasSeen)" :key="`seen-notif-${title}`" >
                    <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button class="text-left" block v-b-toggle.accordion-4 variant="info">
                  <font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'history']"/> Alerte sur le temps d'intervention ({{getNewTimeAlertsTotal}})
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div class="row" v-for="(alert, title) in getTimeAlerts.filter((c) => !c.hasSeen)" :key="`not-seen-notif-${title}`" >
                    <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                  </div>
                  <div class="row" v-for="(alert, title) in getTimeAlerts.filter((c) => c.hasSeen)" :key="`seen-notif-${title}`" >
                    <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

           <b-card no-body class="mb-1">
             <b-card-header header-tag="header" class="p-1" role="tab">
               <b-button class="text-left" block v-b-toggle.accordion-5 variant="info">
                 <font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'history']"/> Défault de badge ({{getNewNoBadge}})
               </b-button>
             </b-card-header>
             <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
               <b-card-body>
                 <div class="row" v-for="(alert, title) in getNoBadge.filter((c) => !c.hasSeen)" :key="`not-seen-notif-${title}`" >
                   <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                 </div>
                 <div class="row" v-for="(alert, title) in getNoBadge.filter((c) => c.hasSeen)" :key="`seen-notif-${title}`" >
                   <AlertItemExt :data="alert" :hasSeen="alert.hasSeen" @read="readAlert" @remove="removeAlert"/>
                 </div>
               </b-card-body>
             </b-collapse>
           </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AlertItemExt from "./AlertItemExt";
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";

  export default {
    name: "AlertList",
    data: () => ({
      refreshSetTimeOut: null,
      loading: false
    }),
    components:{
      AlertItemExt
    },
    computed:{
      ...mapGetters('Alert', [
        'getSuperAlertsNotSeen',
        'getAlertsNotSeen',
        'getSuperAlertsSeen',
        'getAlertsSeen'
      ]),
      yesterday() {
        return moment().subtract(1, 'days').format("DD/MM/YYYY");
      },
      today() {
        return moment().format("DD/MM/YYYY");
      },
      getAllAlerts(){
        return this.getSuperAlertsNotSeen
            .concat(this.getAlertsNotSeen)
            .concat(this.getSuperAlertsSeen)
            .concat(this.getAlertsSeen)
      },
      getDecreaseHealths(){
        return this.getAllAlerts
            .filter((c) => c.type === 2 || c.type === 3)
            .sort((a, b) => {
              if(b.type > a.type){
                return 1;
              }
              return -1;
            });
      },
      getNewDecreaseHealthsTotal(){
        return this.getDecreaseHealths.filter((c) => c.hasSeen === false).length
      },
      getSignalements(){
        return this.getAllAlerts
            .filter((c) => c.type === 5);
      },
      getNewSignalementsTotal(){
        return this.getSignalements.filter((c) => c.hasSeen === false).length
      },
      getSignalementsLvl2(){
        return this.getAllAlerts
            .filter((c) => c.type === 8);
      },
      getNewSignalementsLvl2Total(){
        return this.getSignalementsLvl2.filter((c) => c.hasSeen === false).length
      },
      getAbsences(){
        return this.getAllAlerts
            .filter((c) => c.type === 7);
      },
      getNoBadge(){
        return this.getAllAlerts
            .filter((c) => c.type === 6);
      },
      getNewNoBadge(){
        return this.getNoBadge.filter((c) => c.hasSeen === false).length
      },
      getNewAbsencesTotal(){
        return this.getAbsences.filter((c) => c.hasSeen === false).length
      },
      getTimeAlerts(){
        return this.getAllAlerts
            .filter((c) => c.type === 1 || c.type === 4);
      },
      getNewTimeAlertsTotal(){
        return this.getTimeAlerts.filter((c) => c.hasSeen === false).length
      },
    },
    methods: {
      ...mapActions('Alert', ['getAlerts', 'putSeenAlert', 'deleteAlert']),
      readAlert(id) {
        this.loading = true;
        this.putSeenAlert({id: id}).then(() => {
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      removeAlert(id) {
        this.loading = true;
        this.deleteAlert({id: id}).then(() => {
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      refresh(){
        this.loading = true;
        this.getAlerts({}).then(() => {
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      //refresh all 5 min
      auToRefresh(){
        if(this.refreshSetTimeOut){
          clearTimeout(this.refreshSetTimeOut);
        }
        this.refresh();
        this.refreshSetTimeOut = setTimeout(() => {
          this.auToRefresh();
        }, 300000);
      },
    },
    created(){
      this.auToRefresh();
    },
  }
</script>
<style scoped>
.card{
  border: none;
  background-color: #ffffff00;
}

.card-header {
  background: #fff0;
  border-color: #3f65e3;
  color: #3f65e3;
  border: none;
}
.btn-info{
  background: #fff;
  border-color: #3f65e3;
  color: #3f65e3;
}
.btn-info:hover{
  background: #3f65e3;
  border-color: #3f65e3;
  color: #fff;
}
.btn-info:focus, .btn-info.focus{
  color: #ffffff;
  background-color: #3f65e3;
  border-color: #3f65e3;
  box-shadow: 0 0 0 0.2rem #3f65e380;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle{
  color: #ffffff;
  background-color: #3f65e3;
  border-color: #3f65e3;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus{
  box-shadow: 0 0 0 0.2rem #3f65e380;
}
</style>