import gql from 'graphql-tag'

export const ASSIGN_HABIT = gql`
    mutation assignHabit(
        $id: Int!,
        $habits: [String!]!
    ) {
        assignHabit(
            id: $id,
            habits: $habits
        ) {
            id,
            habits {
                id,
                description,
                place,
                period
            }
        }
    }
`
