<template>
  <eval-alt-wrap title="Devis"
                 :hasSignDevis="hasSignDevis"
                 :hasSignDownload="hasSignDownload"
                 @signDevis="signDevis"
                 @downloadDevis="downloadDevis">
    <template v-slot:print>
      <a href="#" class="nav-link" @click.prevent="printDevis">
        <img src="@/assets/icon-plein-print.svg" alt="">
        <span><font-awesome-icon v-show="loadingGenerateResume" :icon="['fas', 'spinner']" spin/> Imprimer</span>
      </a>
    </template>
    <div class="container pt-4 d-flex flex-column h-100">
      <h5 class="eval-item--title">Identité</h5>
      <div class="eval-item--card cr--identite">
        <div class="d-flex align-items-center">
          <div class="cr--avatar">
            <!-- img -->
          </div>
          <div class="cr--user">
            <h6>{{ client.lastname }} {{ client.firstname }}</h6>
            <p class="mb-0">{{ client.address ? client.address + ',' : '' }}</p>
            <p>{{ client.city }}</p>
          </div>
          <div class="devis--top-val ml-auto">
            <h6>Temps validé : {{ calculateValidatedTimeWeek() }} / sem</h6>
          </div>
          <div class="devis--top-val ml-5">
            <h6>Tarif plein : {{ formatNumberCurrency(parseFloat(actCostSumValidated)) }} € / sem</h6>
          </div>
        </div>
      </div>

      <h5 class="eval-item--title">Type d'affichage</h5>
      <div class="eval-item--card pl-3 py-1">
        <div>
          <div class=" col-sm-12 pl-0">
            <select class="form-control mt-2 mb-2 mr-2"  v-model="timePeriod" id="select-sos01" @change="changeTimePeriod">
              <option value="sem">/sem</option>
              <option value="mois">/mois</option>
            </select>
          </div>
        </div>
      </div>

      <h5 class="eval-item--title">Prise en charge <button class="btn eval-card--hint-btn" @click="openInfoPecModal"><span>?</span></button></h5>
      <div class="eval-item--card py-1 pl-2 mb-5">
        <table class="table devis--table">
          <tbody>
          <tr>
            <td>
              <div class="eval-input--devis">
                <form-input type="text" placeholder="Description (nom de la caisse)" v-model="pecDescription" :value="pecDescription"/>
              </div>
            </td>
            <td class="td-2">
              <div class="eval-input--devis">
                <form-input type="text" placeholder="Type de prise en charge (sem / mois)" v-model="pecType" :value="pecType"/>
              </div>
            </td>
            <td></td>
            <td></td>
            <td>
              <div class="eval-input--devis eval-input--devis-total-pec">
                <form-input type="text"  v-model="pecValue" :value="pecValue"/>
                <p>€ / mois</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="eval-input--devis">
                <form-input type="text" placeholder="Description (nom de la caisse)" v-model="pecDFDescription" :value="pecDFDescription"/>
              </div>
            </td>
            <td class="td-2">
              <div class="eval-input--devis">
                <form-input type="text" placeholder="Type de prise en charge (sem / mois)" v-model="pecDFtype" :value="pecDFtype"/>
              </div>
            </td>
            <td></td>
            <td></td>
            <td>
              <div class="eval-input--devis eval-input--devis-total-pec">
                <form-input type="text"  v-model="pecDFValue" :value="pecDFValue"/>
                <p>€ / mois</p>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <h5 class="eval-item--title">Description</h5>
      <div class="eval-item--card py-1 pl-2 mb-5">
        <form-textarea type="text" v-model="description" :value="description"/>
      </div>

      <div class="mt-auto">
        <div class="devis-bottom">
          <div class="devis-bottom--table-wrap">
            <table class="table devis-bottom--table">
              <tbody>
              <tr>
                <td></td>
                <td>
                  <div class="devis-bottom--item">
                    <div class="devis-bottom--label">
                      <span>Devis total</span>
                      <span>avant PEC</span>
                    </div>
                    <div class="devis-bottom--values">
                      <h5> {{finalValidatedPrice(false, false)}} € / sem</h5>
                      <h6>{{ finalValidatedPrice(true, false) }} € / mois</h6>
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="devis-bottom--table-wrap main-table">
            <table class="table devis-bottom--table">
              <tbody>
              <tr class="main">
                <td>
                  <div class="devis-bottom--item">
                    <div class="devis-bottom--label">
                      <span>Temps validé</span>
                    </div>
                    <div class="devis-bottom--values">
                      <h5> {{calculateValidatedTimeWeek()}} / sem</h5>
                      <h6>{{ calculateValidatedTimeMonth()}} / mois</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="devis-bottom--item">
                    <div class="devis-bottom--label">
                      <span>Prix validé</span>
                    </div>
                    <div class="devis-bottom--values">
                      <h5>{{ finalValidatedPrice(false, true) }} € <span style="font-size: 14px">(TTC)</span> / sem</h5>
                      <h6>{{ finalValidatedPrice(true, true) }} € <span style="font-size: 8px">(TTC)</span> / mois</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <button class="btn btn-primary" @click="printDevis">
                      Générer le <span v-if="hasSignDownload">nouveau</span> devis
                      <font-awesome-icon v-show="loadingGenerateResume" :icon="['fas', 'spinner']" spin/>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Sign v-if="hasSignDevis"
          ref="sign"
          :contractId="evaluation.contract.id"/>
    <CRExtended v-show="loadingGenerateResume"
                ref="crextended"/>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
    <custom-modal :active="showInfoPecModal" @close="closePecModal">
      <div class="eval-modal-title">
        <h1 class="cuisine-modal--title">Information sur la prise en charge de l'usager</h1>
      </div>
      <div class="eval--modal">
        <!-- TODO refactor this and create component with PEC of identity -->
        <div class="id--section-content">
          <div class="row">
            <div class="col-12">
              <table class="table id--table">
                <tr>
                  <th>Mode</th>
                  <th>Type d'heure</th>
                  <th>Nombre d'heures/mois</th>
                  <th>Taux horaire</th>
                  <th>Montant du plan d'aide</th>
                </tr>
                <template v-for="(question, index) of questionTabPEC">
                  <tr v-for="(itemQuestion, itemQuestionIndex) in question.questions" :key="`table-tr-pec-${index}-${itemQuestionIndex}`">
                    <td v-if="itemQuestionIndex === 0" rowspan="2" class="font-weight-bold">{{question.name}}</td>
                    <td>{{itemQuestion.name}}</td>
                    <td class="text-center" v-for="(questionId, questionIdIndex) in itemQuestion.questionsIds" :key="`table-tr-td-pec-${index}-${itemQuestionIndex}-${questionIdIndex}`">
                      {{getGeneralQuestionsAnswersPEC[questionId] ? getGeneralQuestionsAnswersPEC[questionId] : '-'}}
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </div>
      </div>
    </custom-modal>
  </eval-alt-wrap>
</template>
<script>
import {mapGetters, mapMutations, mapState, mapActions} from "vuex";
import {formatNumberCurrency} from '@/components/Util/UtilFunction';
import {dateFormat, dateToMin} from "@/components/Util/UtilFunction";
//import TimePicker from "@/components/Form/TimePicker";
import Sign from "@/views/Evaluation/Devis/Sign";
import moment from 'moment';
import {API_URL} from "@/components/Util/EnvVariable";
import CRExtended from "@/views/Evaluation/CR/CRExtended";
import {CONTRACT_STATUS, DEVIS_STATUS} from "@/store/Modules/Evaluation/state";

export default {
  name: "Devis",
  components: {
    //TimePicker,
    Sign,
    CRExtended
  },
  data() {
    return {
      totalAivqTime: null,
      totalAivqPriceWeek: null,
      totalAvqTime: null,
      totalAvqPriceWeek: null,
      listSupportValue: [
        {text: "Acte pris en charge", value: "supported"},
        {text: "Acte non pris en charge", value: "non-supported"},
      ],
      loadingGenerateResume: false,
      showInfoPecModal: false,
      questionTabPEC: [{
        name: 'Prestataire',
        questions: [{
          name: 'Heures semaine',
          questionsIds: [46, 47, 48]
        }, {
          name: 'Heures D/F',
          questionsIds: [49, 50, 51]
        }]
      }, {
        name: 'Mandataire',
        questions: [{
          name: 'Heures semaine',
          questionsIds: [52, 53, 54]
        }, {
          name: 'Heures D/F',
          questionsIds: [55, 56, 57]
        }],
      }, {
        'name': 'Gré à gré',
        'questions': [
          {
            name: 'Heures semaine',
            questionsIds: [58, 59, 60]
          }, {
            name: 'Heures D/F',
            questionsIds: [61, 62, 63]
          }]
      }],
      weekToMonthRatio: 4.348214166666667,
      calculMultiplier: 1000
    }
  },
  mounted() {
    if(this.evaluation.id) {
      return this.initData();
    }
    this.getEvaluation({id: this.$route.params.evaluationId}).then((res) => {
      this.initData();
      return res;
    }).catch((err) => {
      this.$notify.error("Impossible d'accéder à cette évaluation");
      throw err;
    });

  },
  computed: {
    ...mapState('Client', ['client']),
    ...mapState('Evaluation', [
      'evaluation',
      'aivqPrestationCost',
      'aivqPriceWeek',
      'avqPrestationCost',
      'avqPriceWeek',
      'aivqTimeSum',
      'avqTimeSum',
      'aivqTimeWeekSum',
      'avqTimeWeekSum',
      'aivqTimeDFSum',
      'avqTimeDFSum'
    ]),
    ...mapState("CarePlanPrestation", ["carePlanPrestations", "carePlanPrestation"]),
    ...mapState('Prestation', ['prestations']),
    ...mapGetters('Evaluation', [
      "getValidatedTimeSum",
      "getSumOfPrestationCost",
      "getPrestationCost",
      "getAvqValidatedTimeWeekSum",
      "getAivqValidatedTimeWeekSum",
      "getAvqValidatedTimeDFSum",
      "getAivqValidatedTimeDFSum",
      "getGeneralQuestionsAnswersByCategory"
    ]),
    actCostSumValidated() {
      return this.getSumOfPrestationCost('validated', this.prestations)
    },
    getValidatedTimeSumWeek() {
      return this.getValidatedTimeSum;
    },
    getValidatedTimeSumMonth() {
      return this.getValidatedTimeSum;
    },
    aivqTimeSumWeekGet() {
      if (this.aivqTimeWeekSum) {
        return dateFormat(this.aivqTimeWeekSum)
      }
      return dateFormat(this.getAivqValidatedTimeWeekSum)
    },
    aivqTimeSumDFGet() {
      if (this.aivqTimeDFSum) {
        return dateFormat(this.aivqTimeDFSum)
      }
      return dateFormat(this.getAivqValidatedTimeDFSum)
    },
    aivqPrestationCostGet() {
      if (this.aivqPrestationCost) {
        return this.aivqPrestationCost
      }
      return this.getPrestationCost('AIVQ', this.prestations)
    },
    avqTimeSumWeekGet() {
      if (this.avqTimeWeekSum) {
        return dateFormat(this.avqTimeWeekSum)
      }
      return dateFormat(this.getAvqValidatedTimeWeekSum)
    },
    avqTimeSumDFGet() {
      if (this.avqTimeDFSum) {
        return dateFormat(this.avqTimeDFSum)
      }
      return dateFormat(this.getAvqValidatedTimeDFSum)
    },
    avqPrestationCostGet() {
      if (this.avqPrestationCost) {
        return this.avqPrestationCost
      }
      return this.getPrestationCost('AVQ', this.prestations)
    },
    aivqRow() {
      return this.aivqDetails;
    },
    avqRow() {
      return this.avqDetails;
    },
    pecDescription: {
      get() {
        return this.evaluation.contract.pecDetails ?
            this.evaluation.contract.pecDetails.description : "Prestataire";
      },
      set(value) {
        this.evaluation.contract.pecDetails.description = value;
      }
    },
    pecType: {
      get() {
        return this.evaluation.contract.pecDetails ?
            this.evaluation.contract.pecDetails.type : "Heures semaine";
      },
      set(value) {
        this.evaluation.contract.pecDetails.type = value;
      }
    },
    pecValue: {
      get() {
        return this.evaluation.contract.pecDetails ?
            this.evaluation.contract.pecDetails.value : "";
      },
      set(value) {
        this.evaluation.contract.pecDetails.value = value;
      }
    },
    pecDFDescription: {
      get() {
        return this.evaluation.contract.pecDetails ?
            this.evaluation.contract.pecDetails.DFdescription : "Prestataire";
      },
      set(value) {
        this.evaluation.contract.pecDetails.DFdescription = value;
      }
    },
    pecDFtype: {
      get() {
        return this.evaluation.contract.pecDetails ?
            this.evaluation.contract.pecDetails.DFtype : "Heures dimanche / férié";
      },
      set(value) {
        this.evaluation.contract.pecDetails.DFtype = value;
      }
    },
    pecDFValue: {
      get() {
        return this.evaluation.contract.pecDetails ?
            this.evaluation.contract.pecDetails.DFvalue : "";
      },
      set(value) {
        this.evaluation.contract.pecDetails.DFvalue = value;
      }
    },
    aivqDetails() {
      return this.evaluation.contract && this.evaluation.contract.aivqDetails ? this.evaluation.contract.aivqDetails : [];
    },
    avqDetails() {
      return this.evaluation.contract && this.evaluation.contract.avqDetails ? this.evaluation.contract.avqDetails : [];
    },
    hasSignDevis() {
      return this.evaluation
          && this.evaluation.contract
          && this.evaluation.contract.id
          && this.evaluation.contract.status === DEVIS_STATUS;
    },
    hasSignDownload() {
      return this.evaluation
          && this.evaluation.contract
          && this.evaluation.contract.id
          && this.evaluation.contract.status === CONTRACT_STATUS;
    },
    totalAVQCost: {
      get() {
        return this.evaluation.contract.totalAVQCost;
      },
      set(value) {
        this.evaluation.contract.totalAVQCost = value;
      }
    },
    totalAVQMinutes: {
      get() {
        return this.evaluation.contract.totalAVQMinutes;
      },
      set(value) {
        this.evaluation.contract.totalAVQMinutes = value;
      }
    },
    totalAIVQCost: {
      get() {
        return this.evaluation.contract.totalAIVQCost;
      },
      set(value) {
        this.evaluation.contract.totalAIVQCost = value;
      }
    },
    totalAIVQMinutes: {
      get() {
        return this.evaluation.contract.totalAIVQMinutes;
      },
      set(value) {
        this.evaluation.contract.totalAIVQMinutes = value;
      }
    },
    description: {
      get() {
        return this.evaluation.contract.description;
      },
      set(value) {
        this.evaluation.contract.description = value;
      }
    },
    timePeriod: {
      get() {
        return this.evaluation.contract.displayPeriod;
      },
      set(value) {
        this.evaluation.contract.displayPeriod = value;
      }
    },
    getGeneralQuestionsAnswersPEC(){
      //structure response of spec to have id question => response value
      return this.getGeneralQuestionsAnswersByCategory('PEC').reduce((items, item) => {
        items[item.generalQuestion.id] = item.answers ? item.answers : item.text;
        return items;
      }, []);
    },
    prestataireWeekAmount() {
      return this.getGeneralQuestionsAnswersByCategory[46] && this.getGeneralQuestionsAnswersByCategory[47] ? (parseFloat(this.getGeneralQuestionsAnswersByCategory[46]) * parseFloat(this.getGeneralQuestionsAnswersByCategory[47])).toFixed(2) : null;
    },
    mandataireWeekAmount() {
      return this.getGeneralQuestionsAnswersByCategory[52] && this.getGeneralQuestionsAnswersByCategory[53] ? (parseFloat(this.getGeneralQuestionsAnswersByCategory[52]) * parseFloat(this.getGeneralQuestionsAnswersByCategory[53])).toFixed(2) : null;
    },
    gagWeekAmount() {
      return this.getGeneralQuestionsAnswersByCategory[58] && this.getGeneralQuestionsAnswersByCategory[59] ? (parseFloat(this.getGeneralQuestionsAnswersByCategory[58]) * parseFloat(this.getGeneralQuestionsAnswersByCategory[59])).toFixed(2) : null;
    },
    prestataireDfAmount() {
      return this.getGeneralQuestionsAnswersByCategory[49] && this.getGeneralQuestionsAnswersByCategory[50] ? (parseFloat(this.getGeneralQuestionsAnswersByCategory[49]) * parseFloat(this.getGeneralQuestionsAnswersByCategory[50])).toFixed(2) : null;
    },
    mandataireDfAmount() {
      return this.getGeneralQuestionsAnswersByCategory[55] && this.getGeneralQuestionsAnswersByCategory[56] ? (parseFloat(this.getGeneralQuestionsAnswersByCategory[55]) * parseFloat(this.getGeneralQuestionsAnswersByCategory[56])).toFixed(2) : null;
    },
    gagDfAmount() {
      return this.getGeneralQuestionsAnswersByCategory[61] && this.getGeneralQuestionsAnswersByCategory[62] ? (parseFloat(this.getGeneralQuestionsAnswersByCategory[61]) * parseFloat(this.getGeneralQuestionsAnswersByCategory[62])).toFixed(2) : null;
    }
  },
  methods: {
    formatNumberCurrency,
    dateFormat,
    dateToMin,
    ...mapActions('Evaluation', ['saveContract', 'getEvaluation', 'postGenerageDevis']),
    ...mapMutations('Evaluation', ['setActTimeSum', 'setActPrestationCost', 'addActRow', 'removeActRow', 'setRowPrice', 'setRowHour']),
    initData() {
      if (this.aivqDetails && this.aivqDetails.length === 0) {
        if (this.aivqTimeSumWeekGet !== "00:00") {
          const hour = dateToMin(this.aivqTimeSumWeekGet);
          const minutes = hour % 60;
          const hours = Math.floor(hour / 60);

          this.evaluation.contract.aivqDetails.push({
            type: 'Actes ordinaires - AIVQ',
            tva: 5.5,
            cat: 'AIVQ',
            hour: hour,
            minCount: minutes,
            hourCount: hours,
            price: this.aivqPrestationCostGet,
            sum: this.rowPriceWeek(this.aivqTimeSumWeekGet, this.aivqPrestationCostGet, false) + '€ / sem',
            supported: "supported"
          });
        }
        if (this.aivqTimeSumDFGet !== "00:00") {
          const hour = dateToMin(this.aivqTimeSumDFGet);
          const minutes = hour % 60;
          const hours = Math.floor(hour / 60);

          this.evaluation.contract.aivqDetails.push({
            type: 'Actes ordinaires - AIVQ - D/F',
            tva: 5.5,
            cat: 'AIVQ',
            hour: hour,
            minCount: minutes,
            hourCount: hours,
            price: this.aivqPrestationCostGet * 1.1, //TODO get coast for D/F (Dimanche et jour férié)
            sum: this.rowPriceWeek(this.aivqTimeSumDFGet, this.aivqPrestationCostGet * 1.1, false) + '€ / sem',
            supported: "supported"
          });
        }
      }

      if (this.avqDetails && this.avqDetails.length === 0) {
        if (this.avqTimeSumWeekGet !== "00:00") {
          const hour = dateToMin(this.avqTimeSumWeekGet);
          const minutes = hour % 60;
          const hours = Math.floor(hour / 60);

          this.evaluation.contract.avqDetails.push({
            type: 'Actes essentiels - AVQ',
            tva: 5.5,
            cat: 'AVQ',
            hour: hour,
            minCount: minutes,
            hourCount: hours,
            price: this.avqPrestationCostGet,
            sum: this.rowPriceWeek(this.avqTimeSumWeekGet, this.avqPrestationCostGet, false) + '€ / sem',
            supported: "supported"
          });
        }
        if (this.avqTimeSumDFGet !== "00:00") {
          const hour = dateToMin(this.avqTimeSumDFGet);
          const minutes = hour % 60;
          const hours = Math.floor(hour / 60);
          this.evaluation.contract.avqDetails.push({
            type: 'Actes essentiels - AVQ - D/F',
            tva: 5.5,
            cat: 'AVQ',
            hour: hour,
            minCount: minutes,
            hourCount: hours,
            price: this.avqPrestationCostGet * 1.1, //TODO get coast for D/F (Dimanche et jour férié)
            sum: this.rowPriceWeek(this.avqTimeSumDFGet, this.avqPrestationCostGet * 1.1, false) + '€ / sem',
            supported: "supported"
          });
        }
      }

      if (!this.evaluation.generalQuestionsAnswers || this.evaluation.generalQuestionsAnswers.length === 0) {
        return;
      }

      if(!this.evaluation.contract.pecDetails.value) {
        //set PEC value
        const pec = this.evaluation.generalQuestionsAnswers.find((c) => c.generalQuestion.id === 48 /* 48 => general question on pec */);
        if (pec) {
          this.pecValue = pec.text;
        }
      }

      if(!this.evaluation.contract.pecDetails.DFvalue) {
        const DFpec = this.evaluation.generalQuestionsAnswers.find((c) => c.generalQuestion.id === 51 /* 51 => general question on DFpec */);
        if (DFpec) {
          this.pecDFValue = DFpec.text;
        }
      }
    },
    addActRows(category) {
      let newRow = {
        type: category === 'AVQ' ? 'Actes essentiels - AVQ' : 'Actes ordinaires - AIVQ',
        edit: true,
        cat: category,
        hour: 0,
        minCount: 0,
        hourCount: 0,
        price: null,
        tva: 5.5,
        sum: null,
        supported: null
      }
      this.addActRow({row: newRow, category: category})
    },
    deleteActRow(index, category) {
      this.removeActRow({index: index, category: category})
    },
    applyActTimeSum(value, category) {
      this.setActTimeSum({value: dateToMin(value), category: category})
    },
    applyPrestationCost(value, category) {
      this.setActPrestationCost({value: value, category: category})
    },
    rowPriceWeek(hour, price, forCalcul = false, month = false) {
      if (!isNaN(hour / 60 * price) && !forCalcul) {
        return this.formatNumberCurrency(parseFloat(((hour * this.calculMultiplier)/ 60 * price * (month ? this.weekToMonthRatio : 1)) / this.calculMultiplier))
      } else if (!isNaN(hour / 60 * price) && forCalcul) {
        return this.formatNumberCurrency(((hour / 60 * price) * this.calculMultiplier) * (month ? this.weekToMonthRatio : 1) / this.calculMultiplier)
      } else {
        return this.formatNumberCurrency(parseFloat(0))
      }
    },
    totalTimeWeek(category, intVal = false, monthly = false) {
      let addedRowTime = this.aivqRow.concat(this.avqRow).reduce((value, row) => {
        if (
            row.cat !== category
            || !row.hour
            || row.supported === 'non-supported'
        ) {
          return value;
        }
        value += dateToMin(row.hour);
        return value;
      }, 0);
      this.totalAIVQMinutes = addedRowTime;
      this.totalAivqTime = dateFormat(addedRowTime, true);

      if (intVal) {
        return monthly ? Math.round((((addedRowTime * this.calculMultiplier) * this.weekToMonthRatio) / this.calculMultiplier) / 10) * 10 : addedRowTime;
      }
      return monthly ? dateFormat(Math.round((((addedRowTime * this.calculMultiplier) * this.weekToMonthRatio) / this.calculMultiplier) / 10) * 10, true) : dateFormat(addedRowTime);
    },
    totalPriceWeek(category, floatVal = false, monthly = false) {
      let addedRowVal = this.aivqRow.concat(this.avqRow).reduce((value, row) => {
        if(
            row.cat !== category
            || row.supported === 'non-supported'
            || row.type.replace(' ', '').indexOf('D/F') > -1
        ){
          return value;
        }
        value += parseFloat(this.rowPriceWeek(row.hour, row.price, true));
        return value;
      }, 0.0);
      this.totalAIVQCost = addedRowVal;
      this.totalAivqPriceWeek = parseFloat(addedRowVal)
      if (floatVal) {
        return monthly ? parseFloat(((addedRowVal * this.calculMultiplier) * this.weekToMonthRatio) / this.calculMultiplier) : parseFloat(addedRowVal);
      }
      return monthly ? parseFloat(((addedRowVal* this.calculMultiplier) * this.weekToMonthRatio) / this.calculMultiplier) : parseFloat(addedRowVal);
    },
    totalPriceDF(category, floatVal = false, monthly = false) {
      let addedRowVal = this.aivqRow.concat(this.avqRow).reduce((value, row) => {
        if(
            row.cat !== category
            || row.supported === 'non-supported'
            || row.type.replace(' ', '').indexOf('D/F') === -1
        ){
          return value;
        }
        value += parseFloat(this.rowPriceWeek(row.hour, row.price, true));
        return value;
      }, 0);

      this.totalAIVQCost = addedRowVal;
      this.totalAivqPriceWeek = parseFloat(addedRowVal)
      if (floatVal) {
        return monthly ? (parseFloat(addedRowVal * this.calculMultiplier) * this.weekToMonthRatio) / this.calculMultiplier : parseFloat(addedRowVal);
      }
      return monthly ? (parseFloat(addedRowVal * this.calculMultiplier) * this.weekToMonthRatio) / this.calculMultiplier : parseFloat(addedRowVal);

    },
    finalValidatedPrice(monthly = false, pec = false) {
      let totalPrice = this.totalPriceWeek("AIVQ", true, false) + this.totalPriceWeek("AVQ", true, false);
      let totalPriceDF = this.totalPriceDF("AIVQ", true, false) + this.totalPriceDF("AVQ", true, false);

      if(this.timePeriod === "mois" && !monthly) {
        totalPrice = ((totalPrice * this.calculMultiplier) / this.weekToMonthRatio) / this.calculMultiplier;
        totalPriceDF = ((totalPriceDF * this.calculMultiplier) / this.weekToMonthRatio) / this.calculMultiplier;
      } else if (this.timePeriod === "sem" && monthly) {
        totalPrice = (totalPrice * this.calculMultiplier * this.weekToMonthRatio) / this.calculMultiplier;
        totalPriceDF = (totalPriceDF * this.calculMultiplier * this.weekToMonthRatio) / this.calculMultiplier;
      }

      const pecValue = this.pecValue ? parseFloat(this.pecValue.replace(',', '.')) : 0;
      const pecDFValue = this.pecDFValue ? parseFloat(this.pecDFValue.replace(',', '.')) : 0;

      if (pec && pecValue) {
        totalPrice -= monthly ? pecValue : ((pecValue * this.calculMultiplier)/this.weekToMonthRatio) / this.calculMultiplier;
      }
      if (pec && pecDFValue) {
        totalPriceDF -= monthly ? pecDFValue : ((pecDFValue * this.calculMultiplier)/this.weekToMonthRatio) / this.calculMultiplier;
      }

      totalPrice = totalPrice > 0 ? totalPrice : 0;
      totalPriceDF = totalPriceDF > 0 ? totalPriceDF : 0

      return formatNumberCurrency((totalPrice + totalPriceDF))
    },
    finalValidatedPriceWithTVA(monthly = false) {
      if (monthly) {
        return formatNumberCurrency((((
            this.totalPriceWeek("AIVQ", true) + this.totalPriceWeek("AVQ", true)
            + this.totalPriceDF("AIVQ", true) + this.totalPriceDF("AVQ", true)
        ) * this.calculMultiplier) * this.weekToMonthRatio) / this.calculMultiplier);
      }
      return formatNumberCurrency((
          this.totalPriceWeek("AIVQ", true) + this.totalPriceWeek("AVQ", true)
          + this.totalPriceDF("AIVQ", true) + this.totalPriceDF("AVQ", true)
      ));
    },
    finalValidatedTime(monthly = false) {
      if(this.timePeriod === "sem") {
        if(monthly) {
          return dateFormat(Math.floor(this.totalTimeWeek("AIVQ", true, true) + this.totalTimeWeek('AVQ', true, true)), true)
        }
        return dateFormat(Math.floor(this.totalTimeWeek("AIVQ", true) + this.totalTimeWeek('AVQ', true)));
      } else {
        if(monthly) {
          return dateFormat(Math.floor(this.totalTimeWeek("AIVQ", true, false) + this.totalTimeWeek('AVQ', true, false)), true)
        }
        return dateFormat(Math.floor((((this.totalTimeWeek("AIVQ", true) + this.totalTimeWeek('AVQ', true)) * this.calculMultiplier)/this.weekToMonthRatio) / this.calculMultiplier));
      }
    },
    saveContractWithData() {
      return this.saveContract().then((res) => {
        this.$notify.success("Devis sauvegardé avec succès");
        return res;
      }).catch((err) => {
        this.$notify.error("Impossible de sauvegarder le devis");
        throw err;
      });
    },
    printDevis() {
      let tva = 5.5;

      let invoiceData = this.avqRow.concat(this.aivqRow).reduce((tab, item) => {
        tva = 1 + (item.tva / 100);
        tab.push({
          ...item,
          type: item.type + ' | ' + (item.supported === "supported" ? "Acte pris en charge" : "Acte non pris en charge"),
          price: formatNumberCurrency(item.price / tva),
          priceWithTVA: formatNumberCurrency(item.price),
          sum: this.rowPriceWeek(item.hour, item.price, false, this.timePeriod !== "mois"),
          tva: `${formatNumberCurrency(item.tva, 1, 1)}`
        });
        return tab;
      }, []);

      const evalNatureQuestion = this.evaluation.generalQuestionsAnswers.find((el) => el.generalQuestion.id === 1);
      const evalOriginQuestion = this.evaluation.generalQuestionsAnswers.find((el) => el.generalQuestion.id === 2);
      const evalOriginEvents = this.evaluation.generalQuestionsAnswers.find((el) => el.generalQuestion.id === 3);

      const evaluationNature = evalNatureQuestion ? evalNatureQuestion.generalQuestion.responses[evalNatureQuestion.answers[0]] : "";
      const evaluationOrigin = evalOriginQuestion ? evalOriginQuestion.answers.reduce((acc, n) => {
        return acc + evalOriginQuestion.generalQuestion.responses[n] + ", "
      }, "") : "";
      const evaluationEvent = evalOriginEvents ? evalOriginEvents.answers.reduce((acc, n) => {
        return acc + evalOriginEvents.generalQuestion.responses[n] + ", "
      }, "") : "";

      let agency = this.evaluation.agency;
      if (!agency) {
        agency = this.client.agency.length > 0 ? this.client.agency[0] : null;
      }
      let structure = null;
      if (agency) {
        structure = agency.structure;
      }

      const AG_TELFIX = agency ? agency.phoneNumber : '';
      const AG_FAX = agency ? agency.fax : '';
      const AG_CAPITAL = agency ? agency.capital : '';
      const AG_RCS = agency ? agency.rcs : '';
      const AG_SIRET = agency ? agency.siret : '';
      const AG_TVAINTERCOM = agency ? agency.tvaintercom : '';
      const AG_AGSNUM = agency ? agency.agsnum : '';
      const AG_AGQNUMBIS = agency ? agency.agqnumbis : '';
      const AG_CERTIFNF = agency ? agency.certifnf : '';
      const AG_AUTORISATION_NUM = agency ? agency.autorisationNum : '';

      let pecValue = this.pecValue ? parseFloat(this.pecValue.replace(',', '.')) : 0;
      let pecDFValue = this.pecDFValue ? parseFloat(this.pecDFValue.replace(',', '.')) : 0;

      let totalPrice = (this.totalPriceWeek("AIVQ", true, this.timePeriod !== "mois") + this.totalPriceWeek("AVQ", true, this.timePeriod !== "mois"));
      let totalPriceDF = (this.totalPriceDF("AIVQ", true, this.timePeriod !== "mois") + this.totalPriceDF("AVQ", true, this.timePeriod !== "mois"));

      if (pecValue > totalPrice) {
        pecValue = totalPrice;
      }
      if (pecDFValue > totalPriceDF) {
        pecDFValue = totalPriceDF;
      }

      const data = {
        reference: '',
        todayDate: moment().format("DD/MM/YYYY"),
        clientNumber: this.client.erpId ? this.client.erpId : '',
        clientName: this.client.firstname + ' ' + this.client.lastname,
        clientAddress: this.client.address ? this.client.address : '',
        clientPostalCode: this.client.postalCode ? this.client.postalCode : '',
        clientCity: this.client.city ? this.client.city : '',
        invoiceData: JSON.stringify(invoiceData),

        totalPriceWithTVA: this.finalValidatedPriceWithTVA( this.timePeriod !== "mois"),
        pecValue: formatNumberCurrency(pecValue + pecDFValue),
        totalWithPecWithTVA: this.finalValidatedPrice(true, true),
        totalMonthWithoutPecWithTVA: this.finalValidatedPrice(true, false),
        totalMonthWithPecWithTVA: this.finalValidatedPrice(true, true),

        city: this.client.city,

        description: this.description ? this.description : '',
        evaluationNature: evaluationNature,
        evaluationOrigin: evaluationOrigin,
        evaluationEvents: evaluationEvent,
        AG_TELFIX: AG_TELFIX ? AG_TELFIX : '--',
        AG_FAX: AG_FAX ? AG_FAX : '--',
        AG_CAPITAL: AG_CAPITAL ? AG_CAPITAL : '--',
        AG_RCS: AG_RCS ? AG_RCS : '--',
        AG_SIRET: AG_SIRET ? AG_SIRET : '--',
        AG_TVAINTERCOM: AG_TVAINTERCOM ? AG_TVAINTERCOM : '--',
        AG_AGSNUM: AG_AGSNUM ? AG_AGSNUM : '--',
        AG_AGQNUMBIS: AG_AGQNUMBIS ? AG_AGQNUMBIS : '--',
        AG_CERTIFNF: AG_CERTIFNF ? AG_CERTIFNF : '--',
        AG_AUTORISATION_NUM: AG_AUTORISATION_NUM ? AG_AUTORISATION_NUM : '--',
        AG_NAME: agency ? agency.companyName : '--',
        AG_ADDRESS: agency ? agency.address : '--',
        AG_VILLE: agency ? agency.city : '--',
        AG_FINESS: agency ? agency.finess : '--',
        logo: structure ? structure.logo : '--',
      }

      this.saveContractWithData().then((res) => {
        let formData = new FormData();
        Object.keys(data).forEach(item => {
          if(item === "invoiceData") {
            let parsedValue = JSON.parse(data[item]);
            for(let row of parsedValue) {
              row.hour = dateFormat(this.timePeriod === "sem" ? Math.ceil(((row.hour * this.calculMultiplier) * this.weekToMonthRatio) / this.calculMultiplier) : row.hour);
            }
            data[item] = JSON.stringify(parsedValue);
          }
          formData.append(item, data[item]);
        });
        this.loadingGenerateResume = true;
        return this.postGenerageDevis({data: formData, contractId: res.saveContract.id}).then((data) => {
          // create and save associate resume document on devis/contract
          this.loadingGenerateResume = false;
          return data;
          /** TODO fix it to generate synthesis when summary is generated
           * return this.$refs.crextended.printSynthesis(data.id, false).then(() => {
            this.loadingGenerateResume = false;
          }).catch(() => {
            this.loadingGenerateResume = false;
          });
           });**/
        }).catch((err) => {
          this.$notify.error("Impossible de générer le devis");
          this.loadingGenerateResume = false;
          throw err;
        });
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde du devis");
        this.loadingGenerateResume = false;
        throw err;
      });
    },
    signDevis(){
      this.$refs.sign.signDevis();
    },
    downloadDevis() {
      window.open(`${API_URL}/download/devis/${this.evaluation.contract.id}`, '_blank');
    },
    openInfoPecModal(){
      this.showInfoPecModal = true;
    },
    closePecModal(){
      this.showInfoPecModal = false;
    },
    isNumber(val) {
      if (!val) {
        return true;
      }
      if(/^\d*\.?\d+$/.test(val)) {
        return true;
      }
      return false;
    },
    changeTimePeriod() {
      for(let row of this.aivqRow) {
        row.hour = this.timePeriod === "sem" ? ((row.hour * this.calculMultiplier) / this.weekToMonthRatio) / this.calculMultiplier : ((row.hour * this.calculMultiplier) * this.weekToMonthRatio) / this.calculMultiplier;
        row.minCount = row.hour % 60;
        row.hourCount = Math.floor(row.hour / 60);
      }

      for(let row of this.avqRow) {
        row.hour = this.timePeriod === "sem" ? ((row.hour * this.calculMultiplier) / this.weekToMonthRatio) / this.calculMultiplier : ((row.hour * this.calculMultiplier) * this.weekToMonthRatio) / this.calculMultiplier;
        row.minCount = row.hour % 60;
        row.hourCount = Math.floor(row.hour / 60);
      }
    },
    changeHour(type, index, event) {
      const newValue = event.target.value;
      const relatedKey = type === "AVQ" ? "avqDetails" : "aivqDetails";
      const rows = this.evaluation.contract ? this.evaluation.contract[relatedKey] : [];
      if(rows.length > 0) {
        this.evaluation.contract[relatedKey][index].hourCount = parseInt(newValue);
        this.evaluation.contract[relatedKey][index].hour = parseInt(newValue) * 60 + this.evaluation.contract[relatedKey][index].minCount;
      }
    },
    changeMin(type, index, event) {
      const newValue = event.target.value;
      const relatedKey = type === "AVQ" ? "avqDetails" : "aivqDetails";
      const rows = this.evaluation.contract ? this.evaluation.contract[relatedKey] : [];
      if(rows.length > 0) {
        this.evaluation.contract[relatedKey][index].minCount = parseInt(newValue);
        const total = parseInt(newValue) + this.evaluation.contract[relatedKey][index].hourCount * 60;
        this.evaluation.contract[relatedKey][index].hour = total;
      }
    },
    hoursToTime(min) {
      if(!min) {
        return "00:00"
      }
      return dateFormat(min, true)
    },
    calculateValidatedTimeWeek() {
      let total = 0;
      this.carePlanPrestations.map(prestation => {
        total += prestation.time;
      });
      return this.convertTime(total);
    },
    calculateValidatedTimeMonth() {
      let total = 0;
      this.carePlanPrestations.map(prestation => {
        total += prestation.time;
      });
      total *= 4
      return this.convertTime(total);
    },
    convertTime(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + "h" + rminutes + "min";
    },
  }
};
</script>
<style scoped>
.wrapper--eval .eval-card--hint-btn.btn{
  top: auto;
  right: auto;
  margin-top: 10px;
  font-size: 16px;
  color: white;
  border-radius: 50%;
  border: solid 1px white;
  height: 28px;
  width: 28px;
}
</style>