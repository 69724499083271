<template>
  <div class="bo--agence row mx-0 justify-content-center">
    <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
      <div class="d-flex align-items-center">
        <h1 class="bo--agence-title">Liste des questions</h1>
        <span class="bo--agence-title-notification info">Pas de synchronisation</span>
        <span class="bo--agence-title-count">{{total}} {{total > 1 ? 'éléments' : 'élément'}}</span>
      </div>
      <div class="col-md-12">
        <button class="float-right btn btn-primary mt-1 ml-1" @click="createAggirQuestion"> Créer une question</button>
        <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
      </div>
      <Grid ref="grid"
            id="AggirQuestionList"
            :columns="headerColumns"
            :dataList="aggirQuestionList"
            :sortColumn="sortColumn"
            :sortDirection="sortDirection"
            :currentPage="currentPage"
            :loading="loading"
            :total="total"
            :canEditOrDelete="isUserRoleOperationManagerOrAreaManager"
            @updateCurrentPage="limitCo => limit = limitCo"
            @dataLoading="dataLoading"
            @editComponent="editAggirQuestion"
            @confirmRemove="confirmRemove"
            @changeHeaderAutocomplete="filterManager"
      />
    </div>
    <b-modal id="archive" hide-footer title="Archive" :centered="true">
        <template v-slot:modal-title>
          Êtes-vous sur de vouloir archiver cette question ?
        </template>
        <b-button class="mt-3" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('archive')">Annuler</b-button>
        <b-button class="mt-2" variant="btn offset-6 col-3 btn-outline-danger" @click.prevent="removeAggirQuestion">Archiver</b-button>
      </b-modal>
  </div>
</template>

<script>
  import Grid from "@/components/Grid/Grid";
  import {MixinStructureHeader} from "@/components/Util/MixinHeader";
  import {mapState, mapActions, mapGetters} from 'vuex';
  import moment from 'moment';

  export default {
    name: "AggirQuestionList",
    mixins: [MixinStructureHeader()],
    components: {
      Grid
    },
    data() {
      return {
        columns: [
          {name: "Item", value: 'item', column: 'item', sortable: true},
          {name: "Question", value: 'openingStatement', column: 'opening_statement', sortable: false, overflow: 'ellipsis'},
          {name: "Lieu de vie", value: 'placeRelated', column: 'place_related', sortable: true},
          {name: "Période", value: 'itemPeriod', column: 'item_period', sortable: true},
          {name: "Sous-Item", value: 'subItem', column: 'sub_item', sortable: false},
        ],
        sortColumn: 'item',
        sortDirection: 'ASC',
        limit: 10,
        currentPage: 1,
        removeableAggirQuestion: null,
        userQuery: ''
      };
    },
    computed: {
      ...mapState("AggirQuestion", ["loading", "aggirQuestions", "total", "error"]),
      ...mapState("User", ["users"]),
      ...mapGetters("User", ["isUserRoleOperationManagerOrAreaManager"]),
      //headerColumns(){},headerColumns is set in mixin MixinStructureHeader
      tdStyle() {
        return `width:110px`;
      },
      aggirQuestionList() {
        return this.aggirQuestions.map(function (item) {
          return {
            ...item,
            placeRelated: item.placeRelated ? item.placeRelated.join(', ') : '',
            itemPeriod: item.itemPeriod ? item.itemPeriod.join(', ') : '',
            createdUser: item.createdBy ? item.createdBy.firstName + ' ' + item.createdBy.lastName : null,
            updatedUser: item.updatedBy ? item.updatedBy.firstName + ' ' + item.updatedBy.lastName : null,
            createdDate: item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : null,
            updatedDate: item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : null,
          }
        });
      }
    },
    mounted: function () {
      this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : 10;
      if (this.aggirQuestions.length == 0) {
        this.dataLoading({limit: this.limit, offset: this.limit * (this.currentPage -1)});
      }
      //this.getUserList({limit: 100000});
    },
    methods: {
      ...mapActions("AggirQuestion", ["getAggirQuestionList", "deleteAggirQuestion"]),
      ...mapActions("User", ["getUserList"]),
      filterManager(q) {
          this.userQuery = q;
      },
      dataLoading(data) {
        this.getAggirQuestionList(data);
      },
      refresh() {
        this.currentPage = 1;
        this.sortColumn = "item";
        this.sortDirection = "ASC";
        this.getAggirQuestionList({
          limit: this.limit,
          offset: this.limit * (this.currentPage -1),
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        });

        this.$refs.grid.refreshFilter();
      },
      editAggirQuestion(id) {
        this.$router.push({name: 'aggir-question-form', params: {id: id}});
      },
      createAggirQuestion() {
        this.$router.push({name: 'aggir-question-form'});
      },
      confirmRemove(aggirQuestion) {
        this.removeableAggirQuestion = aggirQuestion;
        this.$bvModal.show('archive');
      },
      async removeAggirQuestion() {
        this.$bvModal.hide('archive');
        await this.deleteAggirQuestion({id: this.removeableAggirQuestion.id});
        if (this.error === null) {
          this.$notify.success("La question a été archivée")
        } else {
          this.$notify.success("Impossible d'archiver la question")
        }
        this.removeableAggirQuestion = null;
      }
    }
  };
</script>