<template>
  <div class="custom-form custom-form--toggle">
    <div class="toggle">
      <span>{{prepend}}</span>
      <input
        class="toggle--input"
        type="checkbox"
        :value="true"
        v-model="val"
        :name="name"
        :id="id"
        :disabled="disabled"
      />
      <label :class="disabled ? 'toggle--label active' : 'toggle--label active cursor-pointer'" :for="id">
        <span class="toggle--switch"></span>
      </label>
      <span>{{append}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "FormToggle",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    prepend: {
      type: String,
      default: "[prepend]"
    },
    append: {
      type: String,
      default: "[append]"
    },
    name: {
      type: String,
      default: "toggle"
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      val: this.value
    };
  },
  watch: {
    val(v) {
        this.$emit("input", v);
    },
    value() {
        this.val = this.value;
    }
  },
  computed: {
    id() {
      var chars =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()_-+={}[]><.,?/~|",
        id = [];
      for (let index = 0; index < 5; index++) {
        var randomIndex = Number(Math.random() * chars.length).toFixed(0);
        id.push(chars[randomIndex]);
      }
      id = id.join("");

      return id;
    }
  }
};
</script>