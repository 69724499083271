import {client as ApolloClient} from '@/api/Apollo';
import { STRUCTURE_LIST } from '@/api/GraphQLQueries/bo/structure/list-query'
import { GET_STRUCTURE } from '@/api/GraphQLQueries/bo/structure/get-structure-query'
import { GLOBAL_FILTER_STRUCTURE } from '@/api/GraphQLQueries/bo/structure/global-filter-structure-query'
import { GLOBAL_FILTER_STRUCTURE_FROM_SESSION } from '@/api/GraphQLQueries/bo/structure/global-structure-from-session-query'
import { SAVE_STRUCTURE } from '@/api/GraphQLQueries/bo/structure/save-structure-mutation'
import { DELETE_STRUCTURE } from '@/api/GraphQLQueries/bo/structure/delete-structure-mutation'
import {RESET_GLOBAL_STRUCTURE} from "@/api/GraphQLQueries/bo/structure/reset-global-structure";
import {postUpload} from '@/api/Fetch';
import {API_URL} from "@/components/Util/EnvVariable";

export const getStructureList = ({ commit }, {
    filters = '{}',
    sortColumn = 'name',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setStructureLoading');
    return ApolloClient.query({
        query: STRUCTURE_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setStructureList', res.data.structureList.items);
        commit('setTotalStructureList', res.data.structureList.total);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setStructureError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getStructure = ({ commit }, { id }) => {
    commit('setStructureLoading');
    return ApolloClient.query({
        query: GET_STRUCTURE,
        variables: {
            id: id
        },
        fetchPolicy: 'no-cache'
    }).then((res) => {
        if (res.errors) {
            console.error(res.errors);
            commit('setStructureError', res.errors);
            throw res.errors;
        }
        commit('setStructureSuccess', res.data.structure);
        return res.data;
    })
};

export const postStructure = ({ commit, state }) => {
    commit('setStructureSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_STRUCTURE,
        variables: {
            ...state.structure,
            id: 0
        }
    }).then((res) => {
        commit('setPostStructureSuccess', res.data.saveStructure);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setStructureError', err.response ? err.response.data : err);
        throw err;
    })
};

export const putStructure = ({ commit, state }) => {
    commit('setStructureSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_STRUCTURE,
        variables: state.structure
    }).then((res) => {
        commit('setPutStructureSuccess', res.data.saveStructure);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setStructureError', err.response ? err.response.data : err);
        throw err;
    })
};

export const deleteStructure = ({ commit }, { id }) => {
    commit('setStructureLoading');
    return ApolloClient.mutate({
        mutation: DELETE_STRUCTURE,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setDeleteStructureSuccess', res.data.deleteStructure);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setStructureError', err.response ? err.response.data : err);
        throw err;
    })
};

export const postStructureLogo = async ({ commit, state }) => {
    commit('setPostStructureLogo');
    const data = state.structure.logo;
    await postUpload(`${API_URL}/structure/logo/${state.structure.id}/upload`, data)
        .then(response => {
            if (!response.ok) {
                if (response.status === 404 || response.status === 401) {
                    commit('setPostStructureLogoError', response);
                    throw response;
                }
            } else {
                commit('setPostStructureLogoSuccess');
            }
            return response;
        })
        .catch((err) => {
            console.error(err);
            commit('setPostStructureLogoError', err);
            throw err;
        });
};

export const setGlobalStructure = ({ commit }, { id }) => {
    return ApolloClient.mutate({
        mutation: GLOBAL_FILTER_STRUCTURE,
        variables: {
            id: id,
        }
    }).then((res) => {
        commit('setGlobalStructureSuccess', res.data.globalFilterStructure)
        return res.data;
    }).catch((err) => {
        throw err;
    })
}

export const getGlobalStructureFromSession = ({ commit }) => {
    return ApolloClient.query({
        query: GLOBAL_FILTER_STRUCTURE_FROM_SESSION,
    }).then((res) => {
        commit('setGlobalStructureSuccess', res.data.globalStructureFromSession)
        return res.data;
    }).catch((err) => {
        throw err;
    })
}

export const resetGlobalStructure = ({commit}) => {
    return ApolloClient.mutate({
        mutation: RESET_GLOBAL_STRUCTURE,
    }).then((res) => {
        commit('setGlobalStructureSuccess', res.data.resetGlobalStructure)
        return res.data;
    }).catch((err) => {
        throw err;
    })
}

export default {
    getStructureList,
    getStructure,
    putStructure,
    postStructure,
    deleteStructure,
    setGlobalStructure,
    getGlobalStructureFromSession,
    resetGlobalStructure,
    postStructureLogo
};