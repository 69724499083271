<script>
import { Radar } from 'vue-chartjs'

export default {
  extends: Radar,
  props: {
    labels: {
      type: Array,
      default: null
    },
    data: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      chartData: {
        labels: this.labels,
        datasets: [
          {
            label: 'Radar Chart',
            borderWidth: 1,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            data: this.data
          }
        ]
      },
      options: {
        responsive: true,
        scale: {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 100,
            stepSize: 50
          }
        },
        legend: {
          display: false
        }
      }
    }
  },
  watch: {
    data: function() {
      this.renderRadarChart();
    }
  },
  methods: {
    renderRadarChart() {
      this.chartData.datasets[0].data = this.data;
      this.renderChart(this.chartData, this.options);
    },
    downloadImage(){
      return this.$refs.canvas.toDataURL("image/jpg");
    }
  },
  mounted () {
    this.renderRadarChart(this.chartData, this.options);
  }
}
</script>