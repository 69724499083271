export default {
    carePlanPrestation: {
        id: null,
        evaluationId: '',
        name: '',
        typeAide: [],
        schedule: '',
        recurrency: [],
        time: '',
        observation: []
    },
    carePlanPrestations: [],
    commentaires: {},
    loading: false,
    error: null,
    total: 0
}
