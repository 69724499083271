<template>
  <div class="bo--container">
    <template>
      <div class="container">
        <loader :loader="loading"/>
        <div>
          <bo-card :title="isEdit ? '&lt; Modifier la question' : '&lt; Créer une nouvelle question'">
            <template slot="title">
              <h1 class="bo-card--title">
                <a href="#" @click.prevent="cancel">&lt; </a> {{isEdit ? "Modifier la question" : "Créer une nouvelle question"}}
              </h1>
            </template>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <form-multi-select v-model="placeRelated"
                             :options="placeRelatedList"
                             label="Lieux de vie *"
                             :error="!$v.placeRelated.required && $v.placeRelated.$error ? 'Un lieux de vie est obligatoire.' : null"
                             :disabled="false"
                             :login-input="true">
                  <template v-slot:first>
                    <option value="" selected disabled></option>
                  </template>
                </form-multi-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <form-textarea v-model="openingStatement"
                             label="Texte détaillé *"
                             :error="!$v.openingStatement.required && $v.openingStatement.$error ? 'Le texte détaillé de la question est obligatoire.' : null"
                             :disabled="false"
                             :rows="1"
                             :login-input="true">
                </form-textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                  <form-select v-model="item"
                              :options="itemList"
                              label="Item *"
                              :error="!$v.item.required && $v.item.$error ? 'Un item est obligatoire.' : null"
                              :disabled="false"
                              :login-input="true">
                    <template v-slot:first>
                      <option value="" selected disabled></option>
                    </template>
                  </form-select>
              </div>
              <div class="col-sm-12 col-md-6">
                  <form-select v-model="subItem"
                              :options="subItemList"
                              label="Sous-item"
                              :error="!$v.subItem.required && $v.subItem.$error ? 'Un Sous-item est obligatoire.' : null"
                              :disabled="false"
                              :login-input="true">
                    <template v-slot:first>
                      <option value="" selected disabled></option>
                    </template>
                  </form-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <form-multi-select v-model="itemPeriod"
                             :options="itemPeriodList"
                             label="Période *"
                             :error="!$v.itemPeriod.required && $v.itemPeriod.$error ? 'Une période est obligatoire.' : null"
                             :disabled="false"
                             :login-input="true">
                </form-multi-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 mb-2">
                - Réponse
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <form-textarea v-model="stchS"
                             label="Réponse S *"
                             :error="!$v.stchS.required && $v.stchS.$error ? 'La réponse S est obligatoire.' : null"
                             :disabled="false"
                             :rows="1"
                             :login-input="true">
                </form-textarea>
              </div>
              <div class="col-sm-12 col-md-4 mt-2">
                <form-input v-model="stchStime" label="Réponse S temps de soin associé *"
                        :login-input="true">
                    <!--<template v-slot:error v-if="$v.stchStime.$error">
                        <small v-if="!$v.stchStime.required && $v.stchStime.$error" class="input--error">le temps de soin est obligatoire.</small>
                        <small v-if="!$v.stchStime.integer && $v.stchStime.$error" class="input--error">le temps de soin doit être une valeur numérique.</small>
                    </template>-->
                </form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <form-textarea v-model="stchT"
                             label="Réponse T *"
                             :error="!$v.stchT.required && $v.stchT.$error ? 'La réponse T est obligatoire.' : null"
                             :disabled="false"
                             :rows="1"
                             :login-input="true">
                </form-textarea>
              </div>
              <div class="col-sm-12 col-md-4 mt-2">
                <form-input v-model="stchTtime" label="Réponse T temps de soin associé *"
                        :login-input="true">
                    <!--<template v-slot:error v-if="$v.stchTtime.$error">
                        <small v-if="!$v.stchTtime.required && $v.stchTtime.$error" class="input--error">le temps de soin est obligatoire.</small>
                        <small v-if="!$v.stchTtime.integer && $v.stchTtime.$error" class="input--error">le temps de soin doit être une valeur numérique.</small>
                    </template>-->
                </form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <form-textarea v-model="stchC"
                             label="Réponse C *"
                             :error="!$v.stchC.required && $v.stchC.$error ? 'La réponse C est obligatoire.' : null"
                             :disabled="false"
                             :rows="1"
                             :login-input="true">
                </form-textarea>
              </div>
              <div class="col-sm-12 col-md-4 mt-2">
                <form-input v-model="stchCtime" label="Réponse C temps de soin associé *"
                        :login-input="true">
                    <!--<template v-slot:error v-if="$v.stchCtime.$error">
                        <small v-if="!$v.stchCtime.required && $v.stchCtime.$error" class="input--error">le temps de soin est obligatoire.</small>
                        <small v-if="!$v.stchCtime.integer && $v.stchCtime.$error" class="input--error">le temps de soin doit être une valeur numérique.</small>
                    </template>-->
                </form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <form-textarea v-model="stchH"
                             label="Réponse H *"
                             :error="!$v.stchH.required && $v.stchH.$error ? 'La réponse H est obligatoire.' : null"
                             :disabled="false"
                             :rows="1"
                             :login-input="true">
                </form-textarea>
              </div>
              <div class="col-sm-12 col-md-4 mt-2">
                <form-input v-model="stchHtime" label="Réponse H temps de soin associé *"
                        :login-input="true">
                    <!--<template v-slot:error v-if="$v.stchHtime.$error">
                        <small v-if="!$v.stchHtime.required && $v.stchHtime.$error" class="input--error">le temps de soin est obligatoire.</small>
                        <small v-if="!$v.stchHtime.integer && $v.stchHtime.$error" class="input--error">le temps de soin doit être une valeur numérique.</small>
                    </template>-->
                </form-input>
              </div>
            </div>
            <template slot="footer">
              <button class="btn btn-primary" @click.prevent="submit" :disabled="saveLoading"><font-awesome-icon v-show="saveLoading" :icon="['fas', 'spinner']" spin/> Enregistrer</button>
              <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
            </template>
          </bo-card>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

import { mapState, mapActions, mapMutations } from "vuex";
import { required } from 'vuelidate/lib/validators'
import FormTextarea from "@/components/Form/Textarea";
import FormInput from "@/components/Form/Input";
import FormMultiSelect from "@/components/Form/MultiSelect";
import FormSelect from "@/components/Form/Select";

export default {
  name: "BOAggirQuestionForm",
  components: {
    FormTextarea,
    FormInput,
    FormMultiSelect,
    FormSelect
  },
  data() {
    return {
      bo: {
        active: true
      },
      saveLoading: false
    };
  },
  validations() {
    let dataReturn = {
      placeRelated: {
        required
      },
      openingStatement: {
        required
      },
      item: {
        required
      },
      subItem: {
        /*required*/
      },
      itemPeriod: {
        required
      },
      stchS: {
        required
      },
      /*stchStime: {
        required,
        integer
      },*/
      stchT: {
        required
      },
      /*stchTtime: {
        required,
        integer
      },*/
      stchC: {
        required
      },
      /*stchCtime: {
        required,
        integer
      },*/
      stchH: {
        required
      },
      /*stchHtime: {
        required,
        integer
      }*/
    };
    return dataReturn;
  },
  computed: {
    ...mapState('AggirQuestion', ['aggirQuestion', 'error', 'loading', 'placeRelatedList', 'itemList', 'itemPeriodList']),
    placeRelated:{
      get(){
        return this.aggirQuestion.placeRelated;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "placeRelated",
          value: value
        });
      }
    },
    openingStatement:{
      get(){
        return this.aggirQuestion.openingStatement;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "openingStatement",
          value: value
        });
      }
    },
    item:{
      get(){
        return this.aggirQuestion.item;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "item",
          value: value
        });
      }
    },
    subItem:{
      get(){
        return this.aggirQuestion.subItem;
      },
      set(value){
        if(!value){
          return;
        }
        this.setAggirQuestionProperty({
          name: "subItem",
          value: value
        });
      }
    },
    itemPeriod:{
      get(){
        return this.aggirQuestion.itemPeriod;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "itemPeriod",
          value: value
        });
      }
    },
    itemStch:{
      get(){
        return this.aggirQuestion.itemStch;
      }
    },
    stchS: {
      get(){
        return this.aggirQuestion.stchS;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "stchS",
          value: value
        });
        this.setAggirQuestionItemStch({index: 's', property: 'text',value: value});
      }
    },
    stchStime: {
      get(){
        return this.aggirQuestion.stchStime;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "stchStime",
          value: value
        });
        this.setAggirQuestionItemStch({index: 's', property: 'time', value: value});
      }
    },
    stchT: {
      get(){
        return this.aggirQuestion.stchT;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "stchT",
          value: value
        });
        this.setAggirQuestionItemStch({index: 't', property: 'text', value: value});
      }
    },
    stchTtime: {
      get(){
        return this.aggirQuestion.stchTtime;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "stchTtime",
          value: value
        });
        this.setAggirQuestionItemStch({index: 't', property: 'time', value: value});
      }
    },
    stchC: {
      get(){
        return this.aggirQuestion.stchC;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "stchC",
          value: value
        });
        this.setAggirQuestionItemStch({index: 'c', property: 'text', value: value});
      }
    },
    stchCtime: {
      get(){
        return this.aggirQuestion.stchCtime;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "stchCtime",
          value: value
        });
        this.setAggirQuestionItemStch({index: 'c', property: 'time', value: value});
      }
    },
    stchH: {
      get(){
        return this.aggirQuestion.stchH;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "stchH",
          value: value
        });
        this.setAggirQuestionItemStch({index: 'h', property: 'text', value: value});
      }
    },
    stchHtime: {
      get(){
        return this.aggirQuestion.stchHtime;
      },
      set(value){
        this.setAggirQuestionProperty({
          name: "stchHtime",
          value: value
        });
        this.setAggirQuestionItemStch({index: 'h', property: 'time', value: value});
      }
    },
    isEdit() {
      return (this.$route.params.id !== undefined && this.$route.params.id !== null)
    },
    subItemList() {
      const item = this.item
      const selectedItem = this.itemList.find(e => e.value == item);
      return selectedItem ? selectedItem.subItems : []
    }
  },
  mounted: async function() {
    this.setInitAggirQuestion();
    if (this.isEdit) {
      await this.getAggirQuestion({ id: this.$route.params.id })
    }
  },
  methods: {
    ...mapMutations('AggirQuestion',['setAggirQuestionProperty', 'setInitAggirQuestion', 'setAggirQuestionItemStch', 'setAggirQuestionRules']),
    ...mapActions('AggirQuestion', ['postAggirQuestion', 'putAggirQuestion', 'getAggirQuestion']),
    async submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.$confirm('Êtes-vous sur de vouloir réaliser une modification ? Les données modifiées vont impacter l\'application évaluation !' ).then(() => {
        this.afterConfirm();
      }).catch(() => {});
    },
    afterConfirm(){
      this.saveLoading = true;
      if (this.isEdit) {
        this.putAggirQuestion().then((res) => {
          this.backToList();
          return res;
        }).catch((err) => {
          this.saveLoading = false;
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          throw err;
        });
      } else {
        this.postAggirQuestion().then((res) => {
          this.backToList();
          return res;
        }).catch((err) => {
          this.saveLoading = false;
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          throw err;
        });
      }
    },
    backToList(){
      this.setInitAggirQuestion();
      this.saveLoading = false;
      this.$notify.success(this.isEdit ? "La question a été modifiée" : "La question a été créée")
      this.$router.push({name: 'aggir-question-list'});
    },
    cancel() {
      return this.$router.push({name:'aggir-question-list'});
    }
  }
};
</script>