import gql from 'graphql-tag'

export const SAVE_ADDITIONAL_INFORMATION = gql`
    mutation saveAdditionalInformation(
        $evaluationId: Int!,
        $additionalInformation: String!,
    ) {
        saveAdditionalInformation(
            evaluationId: $evaluationId,
            additionalInformation: $additionalInformation,
        ) {
            additionalInfos
        }
    }
`
