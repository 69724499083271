<template>
  <custom-modal :active="active" :id="'custom-modal-materials'" @close="close">
    <div class="eval-modal-title">
      <img src="@/assets/icon-plein-ajout.svg" alt />
      <h1>AUTONOMIE et MATERIEL</h1>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <p class="eval-modal-subtitle">SUGGESTIONS</p>
        <input
            v-model="suggestSearch"
            type="text"
            class="form-control mb-1"
            placeholder="Recherche..."/>
        <div class="eval-autonomie-modal-ps">
          <perfect-scrollbar>
            <div
              class="eval-autonomie-item eval-form--check"
              v-for="(item) in suggestedMaterialList"
              :key="`suggestion-${item.id}`">
              <div class="id-check--option">

                <div class="col-sm-10">
                  <input
                    class="id-check--input"
                    :id="`id-autonomie-a-${item.id}`"
                    type="checkbox"
                    :checked="item.existing || item.required"
                    @change="updateMaterial(item.id, 'suggested' ,!(item.suggested && (item.existing || item.required) ))"/>
                  <label class="ml-0" :for="`id-autonomie-a-${item.id}`">
                    <span class="id-check--indicator">{{item.material.name}}</span>
                  </label>
                </div>
                <button class="eval-modal--infos" @click.prevent="displayMaterialInfo(item)">
                  <img src="@/assets/bouton-secondaire-question-mark.svg" alt />
                </button>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
      <div class="col-sm-12 col-md-4">
        <p class="eval-modal-subtitle">MATERIEL A PREVOIR</p>
        <input
            v-model="requiredSearch"
            type="text"
            class="form-control mb-1"
            placeholder="Recherche..."/>
        <div class="eval-autonomie-modal-ps">
          <perfect-scrollbar>
            <div
              class="eval-autonomie-item eval-form--check"
              v-for="(item) in requiredMaterialList"
              :key="`required-${item.id}`">
              <div class="id-check--option">
                <div class="col-sm-10">
                  <input
                      class="id-check--input"
                      :id="`id-autonomie-b-${item.id}`"
                      type="checkbox"
                      :checked="item.required"
                      @change="updateMaterial(item.id, 'required' ,!(item.required) )"/>
                  <label class="ml-0" :for="`id-autonomie-b-${item.id}`">
                    <span class="id-check--indicator">{{item.name}}</span>
                  </label>
                </div>
                <button class="eval-modal--infos" @click.prevent="displayMaterialInfo(item)">
                  <img src="@/assets/bouton-secondaire-question-mark.svg" alt />
                </button>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
      <div class="col-sm-12 col-md-4">
        <p class="eval-modal-subtitle">EXISTANT</p>
        <input
            v-model="existingSearch"
            type="text"
            class="form-control mb-1"
            placeholder="Recherche..."/>
        <div class="eval-autonomie-modal-ps">
          <perfect-scrollbar>
            <div
              class="eval-autonomie-item eval-form--check"
              v-for="(item) in existingMaterialList"
              :key="`existing-${item.id}`">
              <div class="id-check--option">
                <div class="col-sm-10">
                  <input
                      class="id-check--input"
                      :id="`id-autonomie-c-${item.id}`"
                      type="checkbox"
                      :checked="item.existing"
                      @change="updateMaterial(item.id, 'existing' ,!(item.existing))"/>
                  <label class="ml-0" :for="`id-autonomie-c-${item.id}`">
                    <span class="id-check--indicator">{{item.name}}</span>
                  </label>
                </div>
                <button class="eval-modal--infos" @click.prevent="displayMaterialInfo(item)">
                  <img src="@/assets/bouton-secondaire-question-mark.svg" alt />
                </button>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
    <div class="row mt-4 " id="material-infos">
      <div class="col-sm-12 col-md-8" v-if="selectMaterial">
        <h3>{{selectMaterial.name}}</h3>
        <p>{{selectMaterial.description}}</p>
      </div>
      <div class="col-sm-12 col-md-4"  v-if="selectMaterial">
        <img v-if="selectMaterial.photoUrl" :src="getPhotoUrl(selectMaterial)" alt />
      </div>
    </div>
    <div class="d-flex justify-content-center mb-3 mt-5">
      <button class="btn btn-primary" @click.prevent="updateMaterials">Valider</button>
    </div>
  </custom-modal>
</template>
<script>
import {mapActions, mapState} from "vuex";
import {API_URL} from "@/components/Util/EnvVariable";

export default {
  name: "AutonomieModal",
  props: {
    active: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectMaterial: null,
      suggestSearch: '',
      requiredSearch: '',
      existingSearch: ''
    };
  },
  methods: {
    ...mapActions("Material", ["getMaterialList", "deleteAggirQuestion"]),
    ...mapActions("Evaluation", ["postAssignMaterial",'postAssignMaterials']),
    close() {
      this.$emit("close");
    },
    updateMaterials(){
      this.postAssignMaterials();
      this.close()
    },
    updateMaterial(id, type, state) {
      let material = {
        id: id,
        material:{
          id
        },
        existing: false,
        required: false,
        suggested: false
      }
      if(type === "required") {
        material.required = state;
      } else if(type === "existing") {
        material.existing = state;
      } else if(type === "suggested") {
        material.suggested = true;
        if(state) {
          material.required = true;
        } else {
          material.required = false;
          material.existing = false;
        }
      }
      this.postAssignMaterial({material: material});
      this.clearSearchBars();
    },
    displayMaterialInfo(item) {
      this.selectMaterial = item;
      document.getElementById('custom-modal-materials').scroll({
        top: this.materialInfoOffsetTop(),
        behavior: 'smooth'
      })
    },
    clearSearchBars() {
      this.suggestSearch = '';
      this.requiredSearch = '';
      this.existingSearch = '';
    },
    materialInfoOffsetTop() {
      const div = document.getElementById('material-infos');
      if(!div) {
        return;
      }
      const divInfos = div.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return divInfos.top + scrollTop;
    },
    getPhotoUrl(material){
      return API_URL+material.photoUrl;
    }
  },
  mounted () {
    this.getMaterialList({limit: 0});
  },
  computed: {
    ...mapState("Evaluation", ["evaluation"]),
    ...mapState("Material", ["materials"]),
    suggestedMaterialList() {
      let materials = [];
      if(!this.evaluation.materials) {
        return materials;
      }

      for(let link of this.evaluation.materials) {
        //TODO case sensitive
        if(this.suggestSearch !== ''
            && link.material.name.toUpperCase().localeCompare(this.suggestSearch.toUpperCase(), undefined, { sensitivity: 'base' }) === -1
        ) {
          continue;
        }
        if(link.suggested) {
          const suggestedMaterial = this.materials ? this.materials.find(mat => mat.id === link.material.id) : null;
          if(suggestedMaterial) {
            if(link.required || link.existing) {
              materials = [{
                ...suggestedMaterial,
                ...link
              },...materials]
            } else {
              materials = [...materials, {
                ...suggestedMaterial,
                ...link
              }]
            }
          }
        }
      }

      return materials;
    },
    requiredMaterialList() {
      let materials = [];
      for(let material of this.materials) {
        //TODO case sensitive
        if(this.requiredSearch !== ''
            && material.name.toUpperCase().localeCompare(this.requiredSearch.toUpperCase(), undefined, { sensitivity: 'base' }) === -1
        ) {
          continue;
        }
        const neededMaterial = this.evaluation.materials ? this.evaluation.materials.find(link => link.material.id === material.id) : null;

        if(neededMaterial && neededMaterial.required) {
          materials = [{
            ...material,
            required:  neededMaterial.required ? neededMaterial.required : false,
            existing:  neededMaterial.existing ? neededMaterial.existing : false
          }, ...materials]
        } else {
          materials = [...materials, {
            ...material,
            required:  neededMaterial && neededMaterial.required ? neededMaterial.required : false,
            existing:  neededMaterial && neededMaterial.existing ? neededMaterial.existing : false
          }]
        }
      }
      return materials;
    },

    existingMaterialList() {
      let materials = [];
      for(let material of this.materials) {
        //TODO case sensitive
        if(this.existingSearch !== ''
            && material.name.toUpperCase().localeCompare(this.existingSearch.toUpperCase(), undefined, { sensitivity: 'base' }) === -1
        ) {
          continue;
        }
        const neededMaterial = this.evaluation.materials ? this.evaluation.materials.find(link => link.material.id === material.id) : null;
        if(neededMaterial && neededMaterial.existing) {
          materials = [{
            ...material,
            required:  !!neededMaterial,
            existing:  neededMaterial ? neededMaterial.existing : false
          }, ...materials]
        } else {
          materials = [...materials, {
            ...material,
            required:  neededMaterial && !!neededMaterial,
            existing:  neededMaterial && neededMaterial ? neededMaterial.existing : false
          }]
        }
      }
      return materials;
    }
  }
};
</script>