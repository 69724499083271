import gql from 'graphql-tag'

export const GET_AGENCY = gql`
    query agency(
        $id: Int!,
    ) {
        agency(
            id: $id
        ) {
            id,
            apologicCode,
            companyName,
            legalForm,
            implementationName,
            address,
            status,
            postalCode,
            city,
            phoneNumber,
            fax,
            capital,
            rcs,
            siret,
            tvaintercom,
            agsnum,
            agqnumbis,
            certifnf,
            autorisationNum,
            finess,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            },
            structure{
                id,
                name
            }
        }
    }
`
