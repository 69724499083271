import gql from 'graphql-tag'

export const SAVE_AGENCY = gql`
    mutation saveAgency(
        $id: Int!,
        $structureId: Int!,
        $apologicCode: Int,
        $companyName: String!,
        $legalForm: String!,
        $status: Boolean!,
        $implementationName: String,
        $address: String!,
        $postalCode: String!,
        $city: String!,
        $phoneNumber: String,
        $fax: String,
        $capital: String,
        $rcs: String,
        $siret: String!,
        $tvaintercom: String,
        $agsnum: String,
        $agqnumbis: String,
        $certifnf: String,
        $autorisationNum: String,
        $finess: String,
    ) {
        saveAgency(
            id: $id,
            structureId: $structureId,
            apologicCode: $apologicCode,
            companyName: $companyName,
            legalForm: $legalForm,
            status: $status,
            implementationName: $implementationName,
            address: $address,
            postalCode: $postalCode,
            city: $city,
            phoneNumber: $phoneNumber,
            fax: $fax,
            capital: $capital,
            rcs: $rcs,
            siret: $siret,
            tvaintercom: $tvaintercom,
            agsnum: $agsnum,
            agqnumbis: $agqnumbis,
            certifnf: $certifnf,
            autorisationNum: $autorisationNum,
            finess: $finess
        ) {
            id,
            structureId,
            structure {
                id,
                name
            }
            apologicCode,
            companyName,
            legalForm,
            implementationName,
            address,
            status,
            postalCode,
            city,
            phoneNumber,
            fax,
            capital,
            rcs,
            siret,
            tvaintercom,
            agsnum,
            agqnumbis,
            certifnf,
            autorisationNum,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            },
        }
    }
`
