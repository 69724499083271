import gql from 'graphql-tag'

//TODO refactor
export const SAVE_CLIENT_CONTACT = gql`
    mutation saveClientContact(
        $id: Int!,
        $clientId: Int!,
        $lastName: String!,
        $firstName: String!,
        $titre: String!,
        $typeErp: String!,
        $phone: String!,
        $address: String!,
        $city: String!,
        $postalCode: String!,
        $type: String!,
        $isReferent : Boolean!
    ) {
        saveClientContact(
            id: $id,
            clientId: $clientId,
            lastName: $lastName,
            firstName: $firstName,
            titre: $titre,
            typeErp: $typeErp,
            phone: $phone,
            address: $address,
            city: $city,
            postalCode: $postalCode,
            type: $type,
            isReferent: $isReferent
        ) {
            contactList {
                id,
                lastName,
                firstName,
                titre,
                type,
                phone,
                address,
                city,
                postalCode,
                typeErp,
                erpId
            },
            familyContact {
                id,
                lastName,
                firstName,
                titre,
                type,
                phone,
                address,
                city,
                postalCode,
                typeErp,
                erpId
            },
             medicalContact {
                id,
                lastName,
                firstName,
                titre,
                type,
                phone,
                address,
                city,
                postalCode,
                typeErp,
                erpId
            },
            referent {
                id
            }
        }
    }
`
