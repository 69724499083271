import gql from 'graphql-tag'

export const GET_USER = gql`
    query user(
        $id: Int!,
    ) {
        user(
            id: $id
        ) {
            agencies {
                id,
                companyName
            },
            structure {
                id,
                name
            },
            id,
            email,
            lastName,
            firstName,
            birthName,
            birthDate,
            effectiveDate,
            type,
            address,
            addressComplement,
            city,
            cp,
            portablePhoneNumber,
            portableProfesionnalPhoneNumber
            homePhoneNumber,
            homeProfesionnalPhoneNumber,
            personalEmail,
            profesionnalEmail,
            manager {
                id,
                lastName,
                firstName,
                email
            },
            role {
                id,
                label,
                realLabel
            },
            roleId,
            intervenantType,
            status,
            hasPassword
        }
    }
`

export const GET_ROLE_LIST = gql`
    {
        roleList {
            id
            label
            realLabel
        }
    }
`
