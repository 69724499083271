export default {
    general: {
        sg01: {
            questionName: 'sg01',
            questionType: 'question',
            response: null
        },
        sg02: {
            questionName: 'sg02',
            questionType: 'question',
            response: null
        },
        sg03: {
            questionName: 'sg03',
            questionType: 'question',
            response: null
        },
        sg04: {
            questionName: 'sg04',
            questionType: 'question',
            response: null
        },
        sg05: {
            questionName: 'sg05',
            questionType: 'question',
            response: null
        },
        sg06: {
            questionName: 'sg06',
            questionType: 'question',
            response: null
        },
        sg07: {
            questionName: 'sg07',
            questionType: 'question',
            response: null
        }
    },
    sense: {
        sos01: {
            questionName: 'sos01',
            questionType: 'question',
            response: null
        },
        sos02: {
            questionName: 'sos02',
            questionType: 'question',
            response: null
        },
        sos03: {
            questionName: 'sos03',
            questionType: 'question',
            response: null
        },
        sos04: {
            questionName: 'sos04',
            questionType: 'question',
            response: null
        },
        sos05: {
            questionName: 'sos05',
            questionType: 'question',
            response: null
        },
        sos06: {
            questionName: 'sos06',
            questionType: 'question',
            response: null
        },
        sos07: {
            questionName: 'sos07',
            questionType: 'question',
            response: null
        },
        sos08: {
            questionName: 'sos08',
            questionType: 'question',
            response: null
        }
    },
    nutrition: {
        sn01size: {
            questionName: 'sn01size',
            questionType: 'question',
            response: null
        },
        sn01weight: {
            questionName: 'sn01weight',
            questionType: 'question',
            response: null
        },
        sn02: {
            questionName: 'sn02',
            questionType: 'question',
            response: null
        },
        sn03: {
            questionName: 'sn03',
            questionType: 'question',
            response: null
        },
        sn04: {
            questionName: 'sn04',
            questionType: 'question',
            response: null
        },
        sn05: {
            questionName: 'sn05',
            questionType: 'question',
            response: null
        },
        sn06: {
            questionName: 'sn06',
            questionType: 'question',
            response: null
        },
        sn07: {
            questionName: 'sn07',
            questionType: 'question',
            response: null
        },
        sn08: {
            questionName: 'sn08',
            questionType: 'question',
            response: null
        },
        sn09: {
            questionName: 'sn09',
            questionType: 'question',
            response: null
        },
        sn10: {
            questionName: 'sn10',
            questionType: 'question',
            response: null
        },
        sn11: {
            questionName: 'sn11',
            questionType: 'question',
            response: null
        },
        sn12: {
            questionName: 'sn12',
            questionType: 'question',
            response: null
        },
        sn13: {
            questionName: 'sn13',
            questionType: 'question',
            response: null
        },
        sn14: {
            questionName: 'sn14',
            questionType: 'question',
            response: null
        },
        sn15: {
            questionName: 'sn15',
            questionType: 'question',
            response: null
        },
        sn16: {
            questionName: 'sn16',
            questionType: 'question',
            response: null
        },
        sn17: {
            questionName: 'sn17',
            questionType: 'question',
            response: null
        },
    },
    breathing: {
        src01: {
            questionName: 'src01',
            questionType: 'question',
            response: null
        },
        src02: {
            questionName: 'src02',
            questionType: 'question',
            response: null
        },
        src03: {
            questionName: 'src03',
            questionType: 'question',
            response: null
        },
        src04: {
            questionName: 'src04',
            questionType: 'question',
            response: null
        },
        src05: {
            questionName: 'src05',
            questionType: 'question',
            response: null
        },
        src06: {
            questionName: 'src06',
            questionType: 'question',
            response: null
        },
        src07: {
            questionName: 'src07',
            questionType: 'question',
            response: null
        },
        src08: {
            questionName: 'src08',
            questionType: 'question',
            response: null
        }
    },
    elimination: {
        sfe01: {
            questionName: 'sfe01',
            questionType: 'question',
            response: null
        },
        sfe02: {
            questionName: 'sfe02',
            questionType: 'question',
            response: null
        },
        sfe03: {
            questionName: 'sfe03',
            questionType: 'question',
            response: null
        },
        sfe03b: {
            questionName: 'sfe03b',
            questionType: 'question',
            response: null
        },
        sfe04: {
            questionName: 'sfe04',
            questionType: 'question',
            response: null
        },
        sfe05: {
            questionName: 'sfe05',
            questionType: 'question',
            response: null
        },
        sfe06: {
            questionName: 'sfe06',
            questionType: 'question',
            response: null
        },
        sfe07: {
            questionName: 'sfe07',
            questionType: 'question',
            response: null
        },
        sfe08: {
            questionName: 'sfe08',
            questionType: 'question',
            response: null
        },
    },
    motor: {
        sfm01: {
            questionName: 'sfm01',
            questionType: 'question',
            response: null
        },
        sfm02: {
            questionName: 'sfm02',
            questionType: 'question',
            response: null
        },
        sfm03: {
            questionName: 'sfm03',
            questionType: 'question',
            response: null
        },
        sfm04: {
            questionName: 'sfm04',
            questionType: 'question',
            response: null
        },
        sfm05: {
            questionName: 'sfm05',
            questionType: 'question',
            response: null
        },
        sfm06: {
            questionName: 'sfm06',
            questionType: 'question',
            response: null
        },
        sfm07: {
            questionName: 'sfm07',
            questionType: 'question',
            response: null
        },
        sfm08: {
            questionName: 'sfm08',
            questionType: 'question',
            response: null
        },
        sfm101: { //test balance 1
            questionName: 'sfm101',
            questionType: 'question',
            response: null
        },
        sfm102: { //test balance 2
            questionName: 'sfm102',
            questionType: 'question',
            response: null
        },
        sfm103: { //test balance 3
            questionName: 'sfm103',
            questionType: 'question',
            response: null
        },
        sfm11: {
            questionName: 'sfm11',
            questionType: 'question',
            response: null
        }
    },
    skin: {
        spt01: {
            questionName: 'spt01',
            questionType: 'question',
            response: null
        },
        spt02: {
            questionName: 'spt02',
            questionType: 'question',
            response: null
        },
        spt03: {
            questionName: 'spt03',
            questionType: 'question',
            response: null
        },
        spt04: {
            questionName: 'spt04',
            questionType: 'question',
            response: null
        },
        spt05: {
            questionName: 'spt05',
            questionType: 'question',
            response: null
        },
    },
    pain:{
        sd01: {
            questionName: 'sd01',
            questionType: 'question',
            response: null
        },
        sd02: {
            questionName: 'sd02',
            questionType: 'question',
            response: null
        },
        sd03: {
            questionName: 'sd03',
            questionType: 'question',
            response: null
        },
        sd04: {
            questionName: 'sd04',
            questionType: 'question',
            response: null
        },
        sd05: {
            questionName: 'sd05',
            questionType: 'question',
            response: null
        },
        sd06: {
            questionName: 'sd06',
            questionType: 'question',
            response: null
        },
    },
    brain: {
        smd02: {
            questionName: 'smd02',
            questionType: 'question',
            response: null
        },
        smd03: {
            questionName: 'smd03',
            questionType: 'question',
            response: null
        },
        smd04: {
            questionName: 'smd04',
            questionType: 'question',
            response: null
        },
        smd05: {
            questionName: 'smd05',
            questionType: 'question',
            response: null
        },
        smd06: {
            questionName: 'smd06',
            questionType: 'question',
            response: null
        },
        smd07: {
            questionName: 'smd07',
            questionType: 'question',
            response: null
        },
    },
    treatment: {
        stsm01: {
            questionName: 'stsm01',
            questionType: 'question',
            response: null
        },
        /*stsm01_bis: {
            questionName: 'stsm01_bis',
            questionType: 'question',
            response: null
        },*/
        stsm02: {
            questionName: 'stsm02',
            questionType: 'question',
            response: null
        },
        stsm03: {
            questionName: 'stsm03',
            questionType: 'question',
            response: null
        },
        stsm04: {
            questionName: 'stsm04',
            questionType: 'question',
            response: null
        },
        stsm05: {
            questionName: 'stsm05',
            questionType: 'question',
            response: null
        },
        stsm06: {
            questionName: 'stsm06',
            questionType: 'question',
            response: null
        },
        stsm07: {
            questionName: 'stsm07',
            questionType: 'question',
            response: null
        },
        stsm08: {
            questionName: 'stsm08',
            questionType: 'question',
            response: null
        },
        stsm09: {
            questionName: 'stsm09',
            questionType: 'question',
            response: null
        },
        stsm10: {
            questionName: 'stsm10',
            questionType: 'question',
            response: null
        },
        stsm11: {
            questionName: 'stsm11',
            questionType: 'question',
            response: null
        },
    }
}