<template>
  <div class="pdv-action-card">
    <button class="btn pdv-action-card--btn-close p-0">
      <img src="@/assets/bouton-secondaire-close.svg" alt @click="removeHabit"/>
    </button>
    <div class="pdv-action-card--wrap mt-3">
      <div class="pdv-action-card--title">
        <span style="font-size: 16px;font-weight: bold;text-decoration: underline;">
          {{ title }}
        </span>
      </div>
      <div class="pdv-action-card--content">
        <div class="justify-content-start">
          <span class="eval-card--form-select-label">Période :</span>
          <span style="font-size: 13px">{{ period }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import TimePicker from "@/components/Form/TimePicker";

export default {
  name: "PDVActionCard",
  components: {
    /*TimePicker,*/
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    period: {
      type: String,
      default: null
    },
    place: {
      type: String,
      default: null
    },
    validatedTime: {
      type: String,
      default: null
    },
    habitId: {
      type: Number,
      default: null
    }
  },
  computed: {
    img() {
      if (this.icon) {
        var src = this.icon;
      } else {
        src = "pdv-cat.svg";
      }
      return require(`@/assets/${src}`);
    },
  },
  methods: {
    changeTime(time) {
      this.$emit('changeTime', {id: this.habitId, time: time})
    },
    removeHabit(){
      this.$emit('remove', this.habitId);
    }
  }
};
</script>