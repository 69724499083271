import gql from 'graphql-tag'

export const EVALUATION_LIST = gql`
    query evaluationList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!
    ) {
        evaluationList(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                id,
                evaluationDate,
                startingDate,
                endingDate,
                createdAt,
                aggirPercentage,
                createdBy{
                    id,
                    firstName,
                    lastName
                },
                updatedAt,
                updatedBy{
                    id,
                    firstName,
                    lastName
                }
                structure{
                    id,
                    name
                },
                client{
                    id,
                    firstname,
                    lastname
                },
                evaluateBy{
                    id,
                    firstName,
                    lastName
                },
                answerList {
                    id,
                    answerStch
                },
                heuresSemaine,
                heuresDimanche
            },
            total
        }
    }
`
