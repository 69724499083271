import Vue from 'vue'
import BoCard from './BO/BoCard'
import FormInput from './Form/Input'
import FormSelect from './Form/Select'
import FormMultiSelect from './Form/MultiSelect'
import FormTimePicker from './Form/TimePicker'
import FormToggle from './Form/Toggle'
import Dropdown from './Form/Dropdown'
import Loader from './Util/Loader'
import CustomModal from './Util/Modal'
import FormTextarea from './Form/Textarea'

[BoCard, FormInput, FormTextarea, FormSelect, FormMultiSelect, FormToggle, Dropdown, Loader, CustomModal, FormTimePicker].forEach(element => Vue.component(element.name, element))