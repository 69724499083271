<template>
  <auth-wrap class="login">
    <div class="login--content">
      <h2 class="login--title-one">Bienvenue sur OBY</h2>
      <h3 class="login--title-two"> Reinitialisation de votre mot de passe</h3>
      <form class="login--form">
          <el-alert
              class="mb-3"
              :title="warning"
              type="warning alert"
              show-icon
              :closable="false">
          </el-alert>
        <div class="mt-auto">
          <div class="p-2 form-group custom-form custom-form--input input--login" v-if="loginError">
            <slot name="error">
              <small class="login--error">{{ loginError }}</small>
            </slot>
          </div>
          <div class="p-2 custom-form--input active-value-label input--login">
            <form-input
                :error="errors.emailEmpty || errors.email ? `l'email est obligatoire` : null"
                label="Entrez votre mail"
                :login-input="true"
                v-model="email"
                type="email"
                :required="true"
                @input="clearEmailError"
            />
          </div>
        </div>
        <div class="mt-auto d-flex justify-content-center">
          <button class="btn btn-primary btn-lg"
                  style="min-width: 187px;"
                  @click.prevent="submitForm()">
            <font-awesome-icon v-show="loading" :icon="['fas', 'spinner']" spin/> Modifier mon mot de passe </button>
        </div>
      </form>
    </div>
  </auth-wrap>
</template>
<script>
import {mapActions, mapState} from "vuex";
export default {
  name: "ForgotPassword",
  data() {
    return {
      warning: 'Saisissez l\'adresse e-mail que vous utilisez habituellement pour vous connecter et cliquez sur "Modifier mon mot de passe".',
      email: '',
      errors: {
        emailEmpty: false,
      },
      loginError: null,
      loading: false,
    };
  },
  computed: {
    ...mapState('User', ['error','connectedUser']),
    userNotEmpty() {
      return this.email !== null && this.email !== "";
    },
    disabled() {
      return !this.userNotEmpty || this.loading;
    },
  },
  methods: {
    ...mapActions('User', ['sendResetPasswordLinkOnForgot']),
    clearEmailError() {
      this.errors.emailEmpty = false
    },
    submitForm() {
      if (this.email === '') {
        this.errors.emailEmpty = true
      }else {
        this.loading = true;
        this.sendResetPasswordLinkOnForgot({email: this.email});
        this.loading = false;
      }
    }
  }
};
</script>