import gql from 'graphql-tag'

export const SAVE_HEURES_SEMAINE_DIMANCHE = gql`
    mutation updateHeuresSemaineDimanche(
        $evaluationId: Int!,
        $heuresSemaine: Int!,
        $heuresDimanche: Int!
    ) {
        updateHeuresSemaineDimanche(
            evaluationId: $evaluationId,
            heuresSemaine: $heuresSemaine,
            heuresDimanche: $heuresDimanche
        ) {
            id,
            heuresSemaine,
            heuresDimanche
        }
    }
`
