import {client as ApolloClient} from '@/api/Apollo';
import { NOTIFICATION } from '@/api/GraphQLQueries/bo/notification/notification'

export const getNotification = ({ commit }) => {
    return ApolloClient.query({
        query: NOTIFICATION,
    }).then((res) => {
        commit('setNotification', res.data.notification);
        commit('setNotificationContractUser', res.data.userFinishedContract);
        commit('setNotificationSupportClient', res.data.clientFinishedSupport);
        return res.data;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
};

export default {
    getNotification,
};