<template>
  <eval-wrap class="theme-green">
    <template v-slot:title>
      <div class="d-flex align-items-center nav-sante">
        <div>
          <nav class="nav soin-nav">
            <div class="soin-nav-item" v-for="(item, index) in coinComponentToSHow" :key="`nav-bar-${index}`">
              <input v-if="!getActiveNavOptionsActivated.includes(item.key)"
                     type="checkbox"
                     :id="`nav-link-${index}`"
                     name="soin-nav"
                     :value="item.key"
                     v-model="navActivated"/>
              <input v-else
                     type="checkbox"
                     :id="`nav-link-${index}`"
                     name="soin-nav"
                     @click.prevent="scrollTo(item.key)"
                     :checked="true"/>
              <label :for="`nav-link-${index}`" class="btn">{{item.key}}</label>
            </div>
          </nav>
        </div>
      </div>
    </template>
    <div class="container soin-container">
      <div class="soin-content">
        <div class="soin-content--list" id="soin-content-list">

          <template v-for="(item, index) in coinComponentToSHow">
            <div v-if="navActivated.find((c) => c === item.key)"
                 :id="item.key"
                 :key="`component-${index}`">
              <component :ref="item.key"
                         :is="item.component"
                         :id="item.key"
                          @active="active => toggleActiveComponent(item.key, active)"></component>
            </div>
          </template>

        </div>
      </div>
    </div>
  </eval-wrap>
</template>

<script>
import General from "./General";
import Nutrition from "./Nutrition";
import Sense from "./Sense";
import Breathing from "./Breathing";
import Elimination from "./Elimination";
import Motor from "./Motor";
import Skin from "./Skin";
import Pain from "./Pain";
import Brain from "./Brain";
import Treatment from "./Treatment";
import {mapActions, mapGetters, mapMutations} from "vuex";
//import { Carousel, Slide } from "vue-carousel";

export default {
  name: "Soin",
  components: {
    /*Carousel,
    Slide,*/
    General,
    Nutrition,
    Sense,
    Breathing,
    Elimination,
    Motor,
    Skin,
    Pain,
    Brain,
    Treatment,
  },
  computed: {
    ...mapGetters('Evaluation', ['getActiveNavOptionsActivated']),
    navActivated: {
      get(){
        return this.getActiveNavOptionsActivated.concat(this.navOptionsActivated).reduce((acc, n) => {
          if(acc.includes(n)) {
            return acc;
          }
          return [...acc, n]
        }, []);
      },
      set(value){
        this.navOptionsActivated = this.getActiveNavOptionsActivated.concat(value);
      }
    },
    navOptionNames() {
      return this.navOptions.map((n) => n.split(" ").join("-").toLowerCase());
    }
  },
  data() {
    return {
      coinComponentToSHow: [
        {component: 'General', key: 'Général'},
        {component: 'Nutrition', key: 'Nutrition'},
        {component: 'Sense', key: 'Organes des sens'},
        {component: 'Breathing', key: 'Respiration'},
        {component: 'Elimination', key: 'Élimination'},
        {component: 'Motor', key: 'Motricité'},
        {component: 'Skin', key: 'Peau'},
        {component: 'Pain', key: 'Douleur'},
        {component: 'Brain', key: 'Cognition'},
        {component: 'Treatment', key: 'Traitement'},
      ],
      soinResponsive: [
        [768, 2],
        [960, 3],
      ],
      navOptionsActivated: [],
      nextLabel: `<button class="btn soin-carousel--next" @click.prevent="clickButton">
                <img src="${require("@/assets/chevron-right.svg")}" alt />
              </button>`,
      prevLabel: `<button class="btn soin-carousel--next" @click.prevent="clickButton">
                <img src="${require("@/assets/chevron-left.svg")}" alt />
              </button>`,
      soinNutritionActive: true
    };
  },
  methods: {
    ...mapActions("Evaluation", ["getTreatment"]),
    ...mapActions("AggirQuestion", ["getAggirQuestionList"]),
    ...mapMutations("Evaluation", ["setInitTreatment"]),
    toggleActiveComponent(key, active){
      if(active === false){
        return;
      }
      //permit to display none others components
      Object.keys(this.$refs).forEach((keyComponent) => {
        if(keyComponent === key){
          return;
        }
        this.$refs[keyComponent].forEach((component) => {
            component.active = false;
        });
      });
    },
    scrollTo(key){
      let elementByKey = document.getElementById(key);
      let elementToScroll = document.getElementById('soin-content-list');
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      elementToScroll.scroll({
        top: 0,
        left: elementByKey ? elementByKey.offsetLeft : 0,
        behavior: 'smooth'
      });
      this.$refs[key].forEach((component) => {
        component.setActiveElement();
      });
    }
  },
  mounted() {
    this.setInitTreatment();
    this.getTreatment(this.$route.params.evaluationId);
    this.getAggirQuestionList({limit: 0});
  }
};
</script>
