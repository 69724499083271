import gql from 'graphql-tag'

export const ASSIGN_MATERIALS = gql`
    mutation assignMaterials(
        $evaluationId: Int!,
        $materials: [String!]!,
    ) {
        assignMaterials(
            evaluationId: $evaluationId,
            materials: $materials
        ) {
            id,
            materials{
              existing,
              required,
              suggested,
              material{
                id,
                name
              }
            }
        }
    }
`
