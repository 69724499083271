import gql from 'graphql-tag'

export const GET_STRUCTURE = gql`
    query structure(
        $id: Int!,
    ) {
        structure(
            id: $id
        ) {
            id,
            name,
            erpId,
            address,
            status,
            logo,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            },
        }
    }
`
