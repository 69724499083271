import gql from 'graphql-tag'

export const GET_CARE_PLAN_PRESTATION= gql`
    query carePlanPrestation(
        $id: Int!,
    ) {
        carePlanPrestation(
            id: $id
        ) {
            id,
            name,
            typeAide,
            name,
            typeAide,
            schedule,
            recurrency,
            time,
            observation
        }
    }
`
