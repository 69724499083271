<template>
  <b-modal id="redirect"
           :no-close-on-backdrop="true"
           :centered="true"
           :visible="true"
           size="xl">
    <template v-if="validated" v-slot:modal-title>
      Devis Signé
    </template>
    <template v-else-if="error" v-slot:modal-title>
      Devis non signé
    </template>

    <div v-if="validated">
      Le devis à été signé, vous allez être redirigé sur la page de devis dans {{redirectSecond}} seconde(s) ...
    </div>
    <div v-else-if="error">
      Il y a eu une erreur lors de la signature du devis.
      Veuillez rééseiller dans quelques secondes.
      Si le problème persiste, consultez le service d'administration.
    </div>

    <template v-slot:modal-footer>
      <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="backToDevis">Retour</b-button>
    </template>
  </b-modal>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
  name: 'DevisRedirect',
  computed: {
    ...mapState('Evaluation', ['evaluation']),
    validated(){
      return true;
      //return this.$route.params.status === 'validated';
    },
    error(){
      return this.$route.params.status === 'error';
    },
  },
  data: () => ({
    backSetTimeOut: null,
    redirectSecond: 10
  }),
  methods: {
    ...mapActions('Contract', ['sendToSign', 'closeSign']),
    redirectOnDevis() {
      if (this.backSetTimeOut) {
        clearTimeout(this.backSetTimeOut);
      }
      this.backSetTimeOut = setTimeout(() => {
        this.redirectSecond -= 1;
        if (this.redirectSecond > 0) {
          return this.redirectOnDevis();
        }
        this.backToDevis();
      }, 1000);
    },
    backToDevis() {
      if (this.backSetTimeOut) {
        clearTimeout(this.backSetTimeOut);
      }
      this.$router.push({
        name: 'devis',
        params: this.$route.params
      });
    },
    closedSign() {
      this.closeSign({id: this.$route.params.contractId}).then((data) => {
        this.evaluation.contract.status = data.status;
        this.loadingSign = false;
      }).catch((err) => {
        console.error(err);
        this.$notify.error('Erreur lors de la tentative de clôture de la signature.');
        this.loadingSign = false;
      });
    }
  },
  created() {
    if(!this.$route.params.status || !this.$route.params.contractId){
      this.$router.push({
        name: 'devis',
        params: this.$route.params
      });
    }
    if(this.validated){
      this.closedSign();
      this.redirectOnDevis();
    }
  }
}
</script>