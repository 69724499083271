<template>
  <tr >
    <td>{{result.name}}</td>
    <td><span class="aggirgrid--check-item" :class="result.noHelp === 'oui' ? (result.noHelp ? 'checked' : '') : ''" ></span></td>
    <td><span class="aggirgrid--check-item" :class="result.partialHelp === 'oui' ? (result.partialHelp ? 'checked' : '') : ''" ></span></td>
    <td><span class="aggirgrid--check-item" :class="result.totalHelp === 'oui' ? (result.totalHelp ? 'checked' : '') : ''" ></span></td>
  </tr>
</template>
<script>

export default {
  name: "CrAutonomyResultEntry",
  data() {
    return {
    }
  },
  props: {
    result: {
      type: Object
    }
  },
  methods: {
  },
  mounted() {
  },
  computed: {
  }
};
</script>