<template>
  <div class="id--section">
    <div class="id--section-title">
      <h1>ÉLÉMENTS DE LA DEMANDE</h1>
    </div>
    <div class="id--section-content">
      <div class="row">
        <div class="col-4">
          <form-date-picker
            v-model="demandDate"
            label="Date de la demande *"
            :login-input="true"
            @change="updateEvaluation('demandDate')"
          />
        </div>
        <div class="col-4">
          <form-date-picker
            v-model="evaluationDateDay"
            label="Date du RDV d'évaluation *"
            :login-input="true"
            @change="updateEvaluation('evaluationDate')"
          />
        </div>
        <div class="col-4">
          <time-picker
            v-model="evaluationDateTime"
            label="Heure du RDV d'évaluation *"
            :login-input="true"
            @change="updateEvaluation('evaluationDate')"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-for="q in questions" :key="q.id">
        <div
          class="id-check--group"
          v-if="(q.type === 'radio' || q.type === 'checkbox') && toShow(q)"
        >
          <eval-form-check
            v-model="answers[q.id]"
            v-if="q.type === 'radio'"
            :title="q.title"
            :options="q.responses"
            @change="changeCheck(q.id)"
          />
          <eval-form-check-alt
            v-model="answers[q.id]"
            v-if="q.type === 'checkbox'"
            :subtitle="q.title"
            inputType="checkbox"
            :options="q.responses"
            @change="changeCheck(q.id, $event)"
          />
        </div>
        <form-textarea
          v-model="texts[q.id]"
          v-if="q.type === 'text' && q.id !== 6 && toShow(q)"
          :label="q.title"
          :rows="3"
          class="w-100"
          :login-input="false"
          :disabled="false"
          @blur="changeCheck(q.id)"
        />
        <div v-if="q.id === 6 && toShow(q)">
          <p class="id-check--title">
            COORDONNÉES DU TIERS PRÉSENT LORS DE L'ÉVALUATION OU DU CONTACT
            PRINCIPAL
          </p>
          <button
            class="btn"
            style="margin-bottom: 24px"
            v-if="!texts[q.id]"
            @click.prevent="addContact = true"
          >
            <img src="@/assets/bouton-primaire-add.svg" alt />
          </button>
          <div class="evaluationContact" v-if="relatedContact">
            <div>
              <p>{{ evaluationContact.name }}</p>
              <p>{{ evaluationContact.phone }}</p>
            </div>

            <button class="btn" @click.prevent="removeContact">
              <img src="@/assets/cross-sign.svg" alt />
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <form-textarea
          v-model="demandComment"
          label="Commentaires de l’évaluateur sur le contexte de la demande"
          :rows="3"
          class="w-100"
          :login-input="false"
          :disabled="false"
          @blur="changeCommentaryValue()"
        />
      </div>
    </div>

    <custom-modal :active="addContact" @close="addContact = false">
      <div class="add-act-modal">
        <h1 class="cuisine-modal--title">Ajouter un contact présent</h1>
        <div class="row">
          <div class="col-md-12 col-lg-4 text-center">
            <label>FAMILIAL</label>
            <eval-contact-form-card type="family" />
          </div>
          <div class="col-md-12 col-lg-4 text-center">
            <label>MÉDICAL</label>
            <eval-contact-form-card type="medical" class="theme-green" />
          </div>
          <div class="col-md-12 col-lg-4 text-center">
            <label>AUTRES AIDANTS</label>
            <eval-contact-form-card type="other" class="theme-red" />
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button class="btn btn-primary" @click.prevent="addPresentContact">
            Valider
          </button>
          <button
            class="btn btn-outline-primary ml-4"
            @click.prevent="addContact = false"
          >
            Annuler
          </button>
        </div>
      </div>
    </custom-modal>
  </div>
</template>
<script>
import FormDatePicker from "@/components/Form/DatePicker";
import TimePicker from "@/components/Form/TimePicker";
import EvalFormCheck from "@/components/Eval/FormCheck";
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";
import FormTextarea from "@/components/Form/Textarea";
import EvalContactFormCard from "@/components/Eval/EvalContactFormCard";
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
  data: () => ({
    texts: {},
    answers: {},
    demandDate: moment(new Date()).format("YYYY-MM-DD"),
    evaluationDateDay: moment(new Date()).format("YYYY-MM-DD"),
    evaluationDateTime: "00:00",
    expressionPers: null,
    expressionTiers: null,
    capacityExpressionPers: "oui",
    hasTiers: "oui",
    addContact: false,
    relatedContact: null,
  }),
  components: {
    FormDatePicker,
    TimePicker,
    EvalFormCheck,
    FormTextarea,
    EvalFormCheckAlt,
    EvalContactFormCard,
  },
  computed: {
    ...mapState("Evaluation", ["evaluation", "generalQuestions"]),
    ...mapState("Client", ["client", "contact"]),
    questions() {
      const commentOriginQuestion = this.generalQuestions.find(
        (e) => e.id === 65
      );
      let questions = this.generalQuestions.filter((e) => {
        return e.category === "Element demandé";
      });
      const commentOriginQuestionIndex = questions.findIndex(
        (e) => e.id === 65
      );

      if (commentOriginQuestionIndex !== -1 && commentOriginQuestion) {
        questions.splice(commentOriginQuestionIndex, 1);
        const prevQuestionIndex = questions.findIndex((e) => e.id === 2);
        questions = [
          ...questions.slice(0, prevQuestionIndex + 1),
          commentOriginQuestion,
          ...questions.slice(prevQuestionIndex + 1),
        ];
      }
      return questions;
    },
    evaluationContact() {
      return {
        name:
          this.relatedContact && this.relatedContact.lastName
            ? (this.relatedContact.firstName
                ? this.relatedContact.firstName
                : "") +
              " " +
              this.relatedContact.lastName
            : "",
        phone:
          this.relatedContact && this.relatedContact.phone
            ? this.relatedContact.phone
            : "",
      };
    },
    demandComment: {
      get() {
        return this.evaluation.evaluatorComments
          ? this.evaluation.evaluatorComments.demandComment
          : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.demandComment = value;
      },
    },
  },
  async mounted() {
    await this.getEvaluation({ id: this.$route.params.evaluationId });
    const v = this.evaluation;
    if (v.generalQuestionsAnswers) {
      let answers = {};
      let texts = {};
      v.generalQuestionsAnswers.forEach((e) => {
        if (e.generalQuestion.type == "radio") {
          answers[e.generalQuestion.id] = e.answers
            ? e.generalQuestion.responses[e.answers[0]]
            : null;
        }
        if (e.generalQuestion.type == "checkbox") {
          let answer = [];
          if (e.answers) {
            e.answers.forEach((t) => {
              answer.push(e.generalQuestion.responses[t]);
            });
          }
          answers[e.generalQuestion.id] = answer;
        }
        texts[e.generalQuestion.id] = e.text;
      });
      this.answers = answers;
      this.texts = texts;
    }
    if (v.demandDate) {
      this.demandDate = new Date(v.demandDate);
    }
    if (v.evaluationDate) {
      this.evaluationDateDay = new Date(v.evaluationDate);
      this.evaluationDateTime = v.evaluationDate.substr(11, 5);
    }
    this.getRelatedContact();
  },
  methods: {
    ...mapActions("Evaluation", [
      "getEvaluation",
      "putEvaluation",
      "getGeneralQuestions",
      "postGeneralQuestionAnswer",
    ]),
    ...mapActions("Client", ["postClientContact"]),
    ...mapMutations("Client", ["setInitContact"]),
    ...mapActions("Contact", ["getContact"]),
    ...mapMutations("Evaluation", [
      "setGeneralAnswerSuccess",
      "setEvaluationProperty",
    ]),
    changeCheck(questionId, selectedOptions) {
      let question = this.questions.find((e) => e.id == questionId);
      let generalAnswer = this.evaluation.generalQuestionsAnswers.find(
        (e) => e.generalQuestion.id == questionId
      );
      if (!question) return;
      let answers = null;
      if (question.type === "radio") {
        answers = [question.responses.indexOf(this.answers[questionId])];
      }
      if (question.type === "checkbox") {
        if (questionId === 1 && selectedOptions.length > 0) {
          const lastElementIndex = selectedOptions.length - 1;
          const lastElement = selectedOptions[lastElementIndex];
          this.answers[questionId] = [lastElement];
        }
        if (questionId === 1 && selectedOptions.length === 0) {
          this.answers[questionId] = [];
        }
        answers = [];
        this.answers[questionId].forEach((e) => {
          answers.push(question.responses.indexOf(e));
        });
      }
      const text = question.type === "text" ? this.texts[questionId] : "";
      this.setGeneralAnswerSuccess({
        id: generalAnswer ? generalAnswer.id : 0,
        questionId: questionId,
        answers: answers,
        text: text,
      });
      this.postGeneralQuestionAnswer();
    },
    updateEvaluation(type) {
      if (type === "demandDate") {
        this.setEvaluationProperty({
          name: "demandDate",
          value: moment(this.demandDate).format("YYYY-MM-DD HH:mm:ss"),
        });
      }
      if (type === "evaluationDate") {
        let day = this.evaluationDateDay
          ? moment(this.evaluationDateDay).format("YYYY-MM-DD ")
          : moment().format("YYYY-MM-DD ");
        let minute = this.evaluationDateTime
          ? this.evaluationDateTime
          : "00:00";
        this.setEvaluationProperty({
          name: "evaluationDate",
          value: day + minute + ":00",
        });
      }
      this.putEvaluation();
    },
    toShow(question) {
      if (
        question.id !== 6 &&
        question.id !== 7 &&
        question.id !== 9 &&
        question.id !== 65
      ) {
        return true;
      }
      if (question.id === 6 || question.id === 7) {
        if (this.answers[5] === "Oui") {
          return true;
        }
      }
      if (question.id === 9) {
        if (this.answers[8] === "Oui") {
          return true;
        }
      }
      if (question.id === 65) {
        if (this.answers[2] && this.answers[2].length > 0) {
          return true;
        }
      }
      return false;
    },
    getRelatedContact() {
      if (!parseInt(this.texts[6])) {
        return;
      }
      const contactId = parseInt(this.texts[6]);
      this.getContact({ id: contactId }).then((res) => {
        this.relatedContact = res.contact;
      });
    },
    removeContact() {
      this.texts[6] = null;
      this.changeCheck(6);
      this.relatedContact = null;
    },
    addPresentContact() {
      const contact = this.contact;

      if (this.isInputed(contact.family)) {
        this.submitPostContact(contact.family);
      }
      if (this.isInputed(contact.medical)) {
        this.submitPostContact(contact.medical);
      }
      if (this.isInputed(contact.other)) {
        this.submitPostContact(contact.other);
      }
      this.setInitContact();
      this.addContact = false;
    },
    isInputed(contact) {
      return contact.lastName && contact.phone;
    },
    submitPostContact(contact) {
      this.postClientContact({ contact: contact })
        .then((res) => {
          this.$notify.success("L'information a été sauvegardée");
          const newContactId =
            res.saveClientContact.contactList[
              res.saveClientContact.contactList.length - 1
            ].id;
          this.texts[6] = newContactId;
          this.changeCheck(6);
          this.relatedContact = this.client.contactList.find(
            (contact) => contact.id === newContactId
          );
          return res;
        })
        .catch((err) => {
          this.$notify.error(
            "Erreur de sauvegarde, vérifiez les informations saisies"
          );
          throw err;
        });
    },
    changeCommentaryValue() {
      this.putEvaluation();
    },
  },
};
</script>
