import gql from 'graphql-tag'

export const SAVE_TREATMENT = gql`
    mutation saveTreatment(
        $evaluationId: Int!,
        $questionName: String!,
        $questionType: String!,
        $response: String!
    ) {
        saveTreatment(
            evaluationId: $evaluationId,
            questionName: $questionName,
            questionType: $questionType,
            response: $response
        ) {
            id,
            questionType,
            questionName,
            response
        }
    }
`;
