<template>
  <div class="alert-item cursor-pointer" :class="(seen ? 'read ' : 'unread ') + (isSuper ? 'super' : '')">
    <div class="d-flex justify-content-between col-md-12 alert-item__title mb-2">
      <div>{{convertTime(data.createdAt)}}</div>
      <i v-if="seen" class="el-icon-delete" @click="$emit('remove', data.id)"></i>
      <i v-else class="el-icon-close" @click="$emit('read', data.id)"></i>
    </div>
    <div>
      <div class="col-md-12" v-if="data.type==1">
        <span class="bold click-to-go" @click="goToIntervenant(data.intervenant.id)">{{data.intervenantName}}</span> a passé plus de temps que prévu chez <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span>
      </div>
      <div class="col-md-12" v-if="data.type==2">
        <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span> a été signalé avec une dégradation de santé entre {{today}} et {{yesterday}}
      </div>
      <div class="col-md-12" v-if="data.type==3">
        <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span> a été signalé avec une dégradation de santé 3 fois ces 7 derniers jours
      </div>
      <div class="col-md-12" v-if="data.type==4">
        <span class="bold click-to-go" @click="goToIntervenant(data.intervenant.id)">{{data.intervenantName}}</span> a passé moins de temps que prévu chez <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span>
      </div>
      <div class="col-md-12" v-if="data.type==5">
        <span class="bold click-to-go" @click="goToIntervenant(data.intervenant.id)">{{data.intervenantName}}</span> a laissé un signalement au sujet de <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span>: "<span class="font-italic">{{data.additionalInfos}}</span>"
      </div>
      <div class="col-md-12" v-if="data.type==6">
        <span class="bold click-to-go" @click="goToIntervenant(data.intervenant.id)">{{data.intervenantName}}</span> a signalé son impossibilité de badger pour son intervention chez <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span>: "<span class="font-italic">{{data.additionalInfos}}</span>"
      </div>
      <div class="col-md-12" v-if="data.type==7">
        <span class="bold click-to-go" @click="goToIntervenant(data.intervenant.id)">{{data.intervenantName}}</span> a signalé l'absence de <span class="bold click-to-go" @click="goToClient(data.client.id)">{{data.clientName}}</span> pour son intervention: "<span class="font-italic">{{data.additionalInfos}}</span>"
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name:"BoAlertItem",
  props:{
      active:{
        type: Boolean,
        default: false
      },
      seen:{
        type: Boolean,
        default: false
      },
      isSuper:{
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default: () => {}
      }
  },
  computed: {
    yesterday() {
      return moment().subtract(1, 'days').format("DD/MM/YYYY");
    },
    today() {
      return moment().format("DD/MM/YYYY");
    }
  },
  methods: {
    convertTime(createdAt) {
      const now = moment(new Date());
      const createdTime = moment(createdAt);
      const hours = now.diff(createdTime, 'hours') || 0;
      return 'il y a ' + hours + ' heure' + (hours>1 ? 's' : '');
    },
    goToClient(clientId){
      if(!clientId){
        return;
      }
      this.$router.push({
        name: 'client-form',
        params:{
          id: clientId
        }
      });
      this.$emit('close');
    },
    goToIntervenant(intervenantId){
      if(!intervenantId){
        return;
      }
      this.$router.push({
        name: 'user-form',
        params:{
          id: intervenantId
        }
      });
      this.$emit('close');
    }
  },
}
</script>
<style scoped>
  .click-to-go:hover{
    text-decoration: underline;
  }
</style>