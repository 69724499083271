let options = {
    cache: 'no-cache',
    credentials: 'include',
    headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
}

export async function post(url, body){
    options.method = "post";
    options.body = body;
    return fetch(url, options)
}

export async function postUpload(url, body){
    return fetch(url, {method: 'post', body: body})
}

export async function get(url){
    options.method = "get";
    options.body = null;
    return fetch(url, options)
}
