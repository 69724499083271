<template>
  <custom-modal :active="active" @close="close">
    <div class="eval-modal-title">
      <img src="@/assets/icon-plein-contacts.svg" alt />
      <h1>ENTOURAGE</h1>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-4 text-center">
        <label>FAMILIAL</label>
        <eval-contact-form-card type="family" ref="family" />
      </div>
      <div class="col-md-12 col-lg-4 text-center">
        <label>MÉDICAL</label>
        <eval-contact-form-card
          type="medical"
          class="theme-green"
          ref="medical"
        />
      </div>
      <div class="col-md-12 col-lg-4 text-center">
        <label>AUTRES AIDANTS</label>
        <eval-contact-form-card type="other" class="theme-red" ref="other" />
      </div>
    </div>

    <div class="d-flex justify-content-center my-3">
      <button class="btn btn-primary" @click="validate">Valider</button>
    </div>
    <div class="custom-modal--section eval-contact-card--section">
      <div class="row class">
        <div
          class="col-sm-12 col-md-4"
          v-for="contact in [
            ...client.contactList,
            ...client.medicalContact,
            ...client.familyContact,
          ]"
          :key="contact.id"
        >
          <eval-contact-card
            :contact="contact"
            :class="styleClass(contact.type)"
            @remove="removeContact"
            @update="updateContact"
          />
        </div>
      </div>
    </div>
  </custom-modal>
</template>
<script>
import EvalContactCard from "@/components/Eval/EvalContactCard";
import EvalContactFormCard from "@/components/Eval/EvalContactFormCard";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "ContactsModal",
  components: {
    EvalContactCard,
    EvalContactFormCard,
  },
  props: {
    active: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState("Client", ["client", "contact"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("Client", ["postClientContact"]),
    ...mapMutations("Client", ["setInitContact"]),
    submitPostContact(contact) {
      if (this.clientAlreadyExist(contact)) {
        this.$notify.error("Le contact est déjà existant");
        return;
      }
      this.postClientContact({ contact: contact })
        .then((res) => {
          console.log(res);
          this.$notify.success("Le contact a été modifié");
          return res;
        })
        .catch((err) => {
          this.$notify.error(
            "Erreur de sauvegarde, vérifiez les informations saisies"
          );
          throw err;
        });
    },
    isInputted(contact) {
      return (
        contact.lastName ||
        contact.phone ||
        contact.firstName ||
        contact.postalCode ||
        contact.titre ||
        contact.city
      );
    },
    validate() {
      let canBeSubmitted = true;
      if (
        this.isInputted(this.contact.family) &&
        this.$refs.family.$v.$invalid
      ) {
        this.$refs.family.$v.$touch();
        canBeSubmitted = false;
      }
      if (
        this.isInputted(this.contact.medical) &&
        this.$refs.medical.$v.$invalid
      ) {
        this.$refs.medical.$v.$touch();
        canBeSubmitted = false;
      }
      if (this.isInputted(this.contact.other) && this.$refs.other.$v.$invalid) {
        this.$refs.other.$v.$touch();
        canBeSubmitted = false;
      }
      if (!canBeSubmitted) return;
      if (this.isValid(this.contact.family)) {
        this.submitPostContact(this.contact.family);
      }
      if (this.isValid(this.contact.medical)) {
        this.submitPostContact(this.contact.medical);
      }
      if (this.isValid(this.contact.other)) {
        this.submitPostContact(this.contact.other);
      }
      this.resetForms();
    },
    resetForms() {
      this.setInitContact();
      this.$refs.family.$v.$reset();
      this.$refs.medical.$v.$reset();
      this.$refs.other.$v.$reset();
    },
    isValid(contact) {
      return contact.lastName || contact.phone;
    },
    clientAlreadyExist(newContact) {
      return this.client.contactList.some(
        (contact) =>
          contact.firstName.toUpperCase() ===
            newContact.firstName.toUpperCase() &&
          contact.lastName.toUpperCase() === newContact.lastName.toUpperCase()
      );
    },
    removeContact(contactId) {
      this.$emit("remove", contactId);
    },
    updateContact(contact) {
      this.$emit("update", contact);
    },
    close() {
      this.resetForms();
      this.$emit("close");
    },
    styleClass(type) {
      if (type === "family") {
        return "";
      } else if (type === "med") {
        return "theme-green";
      } else {
        return "theme-red";
      }
    },
  },
};
</script>
