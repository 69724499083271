<template>
  <div class="bo--tarifications row mx-0 justify-content-center">
    <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
      <div class="d-flex align-items-center">
        <h1 class="bo--agence-title">Liste des tarifications</h1>
        <span class="bo--agence-title-notification info">Pas de synchronisation</span>
        <span class="bo--agence-title-count">{{total}} {{total>1 ? 'éléments' : 'élément'}}</span>
      </div>
      <div class="col-md-12">
        <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
      </div>
      <Grid ref="grid"
            id="TarificationList"
            :columns="headerColumns"
            :dataList="tarificationList"
            :sortColumn="sortColumn"
            :sortDirection="sortDirection"
            :currentPage="currentPage"
            :loading="loading"
            :total="total"
            :canEditOrDelete="isUserRoleOperationManager"
            :canDelete="false"
            @updateCurrentPage="limitCo => limit = limitCo"
            @dataLoading="dataLoading"
            @editComponent="editTarification"
            @confirmRemove="confirmRemove"
      />
    </div>
    <b-modal id="archive" hide-footer title="Archive" :centered="true">
        <template v-slot:modal-title>
          Êtes-vous sur de vouloir archiver cette tarification ?
        </template>
        <b-button class="mt-3" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('archive')">Annuler</b-button>
        <b-button class="mt-2" variant="btn offset-6 col-3 btn-outline-danger" @click.prevent="removeTarification">Archiver</b-button>
      </b-modal>
  </div>
</template>

<script>
  import Grid from "@/components/Grid/Grid";
  import {MixinStructureHeader} from "@/components/Util/MixinHeader";
  import {mapState, mapActions, mapGetters} from 'vuex'

  export default {
    name: "TarificationList",
    mixins: [MixinStructureHeader()],
    components: {
      Grid
    },
    data() {
      return {
        filterColumns: [
          'Structure',
          'Prestation',
          'Prix',
          'Type'
        ],
        columns: [
          {name: "Structure", value: 'structureName', column: 'structure.id', sortable: true},
          {name: "Prestation", value: 'prestationName', column: 'prestation.name', sortable: true},
          {name: "Type", value: 'tarifType', column: 'tarif_type', sortable: true},
          {name: "Prix", value: 'price', column: 'price', sortable: true},
        ],
        sortColumn: 'price',
        sortDirection: 'ASC',
        limit: 10,
        currentPage: 1,
        removeableTarification: null,
      };
    },
    computed: {
      ...mapState("Tarification", ["loading", "tarifications", "total", "error"]),
      ...mapState('User', ['connectedUser']),
      ...mapGetters("Notification", ["getNotificationByInterface"]),
      ...mapGetters("User", ["isUserRoleOperationManager"]),
      //headerColumns(){},headerColumns is set in mixin MixinStructureHeader
      synchronized(){
        return this.getNotificationByInterface["Tarification"] && this.getNotificationByInterface["Tarification"].type === "success";
      },
      tarificationList() {
        return this.tarifications.map(function (item) {
          return {
            ...item,
            structureName: item.structure? item.structure.name : null,
            prestationName: item.prestation ? item.prestation.name : null,
          }
        });
      }
    },
    mounted: function () {
      this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : 10;
      if (this.tarifications.length == 0) {
        this.dataLoading({limit: this.limit, offset: this.limit * (this.currentPage -1)});
      }
    },
    methods: {
      ...mapActions("Tarification", ["getTarificationList", "deleteTarification"]),
      dataLoading(data) {
        this.getTarificationList(data);
      },
      refresh() {
        this.currentPage = 1;
        this.sortColumn = "price";
        this.sortDirection = "ASC";
        this.getTarificationList({
          limit: this.limit,
          offset: this.limit * (this.currentPage -1),
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        });

        this.$refs.grid.refreshFilter();
      },
      editTarification(id) {
        this.$router.push({name: 'tarification-form', params: {id: id}});
      },
      createTarification() {
        this.$router.push({name: 'tarification-form'});
      },
      confirmRemove(tarification) {
        this.removeableTarification = tarification;
        this.$bvModal.show('archive');
      },
      async removeTarification() {
        this.$bvModal.hide('archive');
        await this.deleteTarification({id: this.removeableTarification.id});
        if (this.error === null) {
          this.$notify.success("La tarification a été archivée");
        } else {
          this.$notify.success("Impossible d'archiver la tarification");
        }
        this.removeableTarification = null;
      }
    }
  };
</script>