<template>
  <div class="goal-selection">
    <p class="font-weight-bold" @click="active = !active">
      Voir la liste de choix <span>({{listLength}} selectionné(s))</span> <img src="@/assets/chevron-up.svg" alt :style="{
        transform: !active?'rotate(180deg)':'',
        transition: 'transform .2s ease'
      }"/>
    </p>
    <div>
      <eval-form-check-alt
          inputType="checkbox"
          subtitle=""
          :options="[]">
        <template slot="content">
          <div class="id-check--option" v-for="(option, index) in relatedChoices"
               :key="`${option.value}`">
            <input class="id-check--input"
                   type="checkbox"
                   :id="`${inputData.item}-${field}-${index}`"
                   :value="option.value"
                   v-model="selectedValues"
            />
            <label :for="`${inputData.item}-${field}-${index}`">
              <span class="id-check--indicator">{{option.label}}</span>
            </label>
          </div>
        </template>
      </eval-form-check-alt>
      <div class="goal-selection--add" v-if="active">
        <input
            type="text"
            placeholder="Ajouter une option"
            v-model="choiceToAdd"
        />
        <button class="btn btn-primary" @click="addOption">
          Ajouter
        </button>
      </div>

    </div>
  </div>
</template>
<script>
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";

export default {
  name: "GoalsSelection",
  components: {
    EvalFormCheckAlt
  },
  data() {
    return {
      active: false,
      choiceToAdd: ""
    }
  },
  props: {
    inputData: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: null,
    },
    field: {
      type: String,
      required: true
    }
  },
  created() {
  },
  methods: {
    addOption() {
      if(this.field === 'goal') {
        const value = "r" + (this.inputData.goal.choices.length + 1);
        this.inputData.goal.choices.push({value: value, label: this.choiceToAdd});
        this.selectedValues = [...this.inputData.goal.selected, value];
      } else {
        const value = "r" + (this.inputData.actions.choices.length + 1);
        this.inputData.actions.choices.push({value: value, label: this.choiceToAdd});
        this.selectedValues = [...this.inputData.actions.selected, value];
      }
      this.choiceToAdd = "";
    }
  },
  computed: {
    selectedValues: {
      get() {
        if(this.field === 'goal') {
          return this.inputData.goal.selected;
        }
        return this.inputData.actions.selected;
      },
      set(value) {
        value = value.map((el) => el.value ? el.value : el);
        this.$emit('updateGoals', {
          ...this.inputData,
          goal: this.field === 'goal' ? {
            ...this.inputData.goal,
            selected: value
          } : this.inputData.goal,
          actions: this.field === 'actions' ? {
            ...this.inputData.actions,
            selected: value
          } : this.inputData.actions
        });
      }
    },
    listLength() {
      return this.field === 'goal' ? (this.inputData.goal.selected ? this.inputData.goal.selected.length : 0 ) : (this.inputData.actions.selected ? this.inputData.actions.selected.length : 0);
    },
    relatedChoices() {
      if(!this.active){
        if(this.field === 'goal') {
          return this.inputData.goal.selected.map((el) => {
            return this.inputData.goal.choices.find((goal) => goal.value === el);
          }).sort((a,b) => parseInt(a.value[1]) > parseInt(b.value[1]))
        } else {
          return this.inputData.actions.selected.map((el) => {
            return this.inputData.actions.choices.find((action) => action.value === el);
          }).sort((a,b) => parseInt(a.value[1]) > parseInt(b.value[1]))
        }
      }
      return this.field === 'goal' ? this.inputData.goal.choices : this.inputData.actions.choices;
    }
  }
};
</script>