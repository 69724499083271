import gql from 'graphql-tag'

export const SAVE_EVALUATION_HAS_ACT = gql`
    mutation saveEvaluationHasAct(
        $id: Int,
        $evaluationId: Int!,
        $actId: Int!,
        $questionId: Int,
        $caregiver: String,
        $period: String!,
        $place: String!,
        $active: Boolean!,
        $addedManually: Boolean!
        $validatedTime: Int,
        $weekDays: [String!]
    ) {
        saveEvaluationHasAct(
            id: $id,
            evaluationId: $evaluationId,
            actId: $actId,
            questionId: $questionId,
            caregiver: $caregiver,
            period: $period,
            place: $place,
            active: $active,
            addedManually: $addedManually
            validatedTime: $validatedTime,
            weekDays: $weekDays
        ) {
            id,
            estimatedTime,
            validatedTimeIntFormat,
            validatedTimeStringFormat,
            weekDays,
            aggirQuestion {
                id,
                item
            }
        }
    }
`
