import gql from 'graphql-tag'

export const DELETE_AGENCY = gql`
    mutation deleteAgency(
        $id: Int!,
    ) {
        deleteAgency(
            id: $id
        )
    }
`
