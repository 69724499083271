export default {
    setPrestationProperty(state, property) {
        if(!state.prestation[property.name]){
            state.prestation[property.name] = "";
        }
        state.prestation[property.name] = property.value;
    },
    setPrestationLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setPrestationSaveLoading(state) {
        state.error = null;
    },
    setPrestationList(state, prestations) {
        state.prestations = prestations;
        state.loading = false;
        state.error = null;
    },
    setTotalPrestationList(state, total) {
        state.total = total;
    },
    setPrestationSuccess(state, prestation) {
        state.prestation = {
            ...prestation,
            recurrency: prestation.recurrency ? prestation.recurrency : [],
            structureId: prestation.structure ? prestation.structure.id : null
        };
        state.loading = false;
        state.error = null;
    },
    setInitPrestation(state) {
        state.prestation = {
            structure: {},
            structureId: '',
            name: '',
            nameType: '',
            schedule: '',
            recurrency: [],
            time: null,
            price: null,
            tva: null,
            description: ''
        };
        state.error = null;
      },
    setPrestationError(state, error) {
        state.loading = false;
        state.error = error;
    },
    setPostPrestationSuccess(state, prestation) {
        state.prestations.push(prestation);
        state.error = null;
        state.total = state.total + 1
    },
    setPutPrestationSuccess(state, prestation) {
        state.prestations.splice(
            state.prestations.findIndex(prestationSearch => prestationSearch.id === prestation.id),
            1,
            prestation
        );
        state.error = null;
    },
    setDeletePrestationSuccess(state, id) {
        state.prestations.splice(
            state.prestations.findIndex(prestation => prestation.id === id),
            1
        );
        state.loading = false;
        state.error = null;
        state.total = state.total - 1
    }
}