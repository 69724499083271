export default {
    agency: {
        structureId: '',
        structure: {},
        structureName: '',
        apologicCode: null,
        companyName: '',
        legalForm: '',
        status: false,
        implementationName: '',
        address: '',
        postalCode: '',
        city: '',
        phoneNumber: '',
        fax: '',
        capital: '',
        rcs: '',
        siret: '',
        tvaintercom: '',
        agsnum: '',
        agqnumbis: '',
        certifnf: '',
        autorisationNum: '',
        finess: ''
    },
    agencies: [],
    loading: false,
    error: null,
    total: 0
}
