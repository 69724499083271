export default {
    cancelPromise: null,
    client: {
        photo: null,
        document: null,
        structure: {},
        structureId: '',
        agency: {},
        agencyId: '',
        statusSync: '',
        folderNumber: '',
        lastname: '',
        firstname: '',
        birthName: '',
        birthCity: '',
        nationality: '',
        email: '',
        civility: '',
        address: '',
        city: '',
        postalCode: '',
        sector: '',
        housingAccess: '',
        birthDate: '',
        age: null,
        socialSecurityNumber: '',
        digicode: '',
        sitePicture: '',
        homePhoneNumber: '',
        portablePhoneNumber: '',
        girNumber: '',
        missing: '',
        familyStatus: '',
        csp: '',
        protectiveMeasure: '',
        additionalInformations: '',
        keyPosition: '',
        familyModuleActive: false,
        qrCode: '',
        evaluationNumber: '',
        $interfaceSyncrhonisation: false,
        contactList: [],
        familyContact: [],
        medicalContact: [],
    },
    clients: [],
    loading: false,
    error: null,
    total: 0,
    baseURL: '',
    typesLieux: [
        {value: 'Lieu', text: 'Lieu'},
        {value: 'Autre document', text: 'Autre document'}
    ],
    typesDocument: [
        {value: 'Carte Vitale', text: 'Carte Vitale'},
        {value: 'Document d’identité', text: 'Document d’identité'},
        {value: 'RIB', text: 'RIB'},
        {value: 'Notification PEC', text: 'Notification PEC'},
        {value: 'Autre document', text: 'Autre document'}
    ],
    accesTypeList: [
        {value: 'Lieux', text: 'Lieux'},
        {value: 'Autre document', text: 'Autre document'}
    ],
    contact: {
        family: {
            lastName: '',
            firstName: '',
            titre: '',
            typeErp: '',
            phone: '',
            address: '',
            city: '',
            postalCode: '',
            type: 'family',
            isReferent: false
        },
        medical: {
            lastName: '',
            firstName: '',
            titre: '',
            typeErp: '',
            phone: '',
            address: '',
            city: '',
            postalCode: '',
            type: 'med',
            isReferent: false
        },
        other: {
            lastName: '',
            firstName: '',
            titre: '',
            typeErp: '',
            phone: '',
            address: '',
            city: '',
            postalCode: '',
            type: 'other',
            isReferent: false
        }
    }
}
