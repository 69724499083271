export const MixinStructureHeader = function() {
    return {
        beforeCreate() {
            let headerColumn = () => {
                let index = this.columns.findIndex((column) => column.name === "Structure");
                if (index === -1) {
                    return this.columns;
                }
                if(this.$store.getters["Filter/getFilterStructure"].length === 0){
                    return this.columns;
                }
                this.columns[index].list = this.$store.getters["Filter/getFilterStructure"];
                if(index === 0){
                    this.columns[index].class = "pl-2";
                }
                return this.columns;
            }
            if(this.$store.state.Structure.globalStructure){
                headerColumn = () => {
                    return this.columns;
                }
            }
            this.$options.computed["headerColumns"] = headerColumn;
        }
    }
};

export default {
    MixinStructureHeader
};
