export default {
    getMaterials(state){
        return state.materials;
    },
    getMaterialsByIds: (state) => (ids) => {
        return state.materials.reduce((materials, material) => {
            if(ids.includes(material.id)){
                materials.push(material)
            }
            return materials;
        }, []);
    }
};