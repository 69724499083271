<template>
  <div class="goal-selection">
    <p class="font-weight-bold cursor-pointer" @click="active = !active">
      Voir la liste de choix <span>({{listLength}} selectionné(s))</span> <img src="@/assets/chevron-up.svg" alt :style="{
        transform: !active?'rotate(180deg)':'',
        transition: 'transform .2s ease'
      }"/>
    </p>
    <div>
      <eval-form-check-alt
          inputType="checkbox"
          subtitle=""
          :options="[]">
        <template slot="content">
          <div class="id-check--option" v-for="(option, index) in relatedChoices"
               :key="option.value">
            <input class="id-check--input"
                   type="checkbox"
                   :id="`${inputData.item}-${index}`"
                   :value="option.value"
                   v-model="affectation"
            />
            <label :for="`${inputData.item}-${index}`">
              <span class="id-check--indicator">{{option.text}}</span>
            </label>
          </div>
        </template>
      </eval-form-check-alt>

      <div class="goal-selection--add" v-if="active">
        <input
            type="text"
            placeholder="Ajouter une option"
            v-model="affectationToAdd"
        />
        <button class="btn btn-primary" @click="addAffectation">
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";

export default {
  name: "GoalsAffectation",
  components: {
    EvalFormCheckAlt
  },
  props: {
    inputData: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: null,
    }
  },
  created() {
  },
  data() {
    return {
      active: false,
      affectationToAdd: ""
    };
  },
  methods: {
    addAffectation() {
      const value = "c" + (this.inputData.affectation.choices.length + 1);
      this.inputData.affectation.choices.push({value: value, text: this.affectationToAdd});
      this.affectation = [...this.inputData.affectation.selected, value];
      this.affectationToAdd = "";
    }
  },
  computed: {
    affectation: {
      get() {
        return this.inputData.affectation && this.inputData.affectation.selected ? this.inputData.affectation.selected : "";
      },
      set(value) {
        value = value.map((el) => el.value ? el.value : el);
        this.inputData.affectation.selected = value;
        this.$emit('updateAffectation', {
          ...this.inputData
        });
      }
    },
    relatedChoices() {
      if(!this.active){
        return this.inputData.affectation ? this.inputData.affectation.selected.map((el) => {
          return this.inputData.affectation.choices.find((affectation) => affectation.value === el);
        }).sort((a,b) => parseInt(a.value[1]) > parseInt(b.value[1])) : [];
      }
      return this.inputData.affectation ? this.inputData.affectation.choices : [];
    },
    listLength() {
      return this.inputData.affectation && this.inputData.affectation.selected ? this.inputData.affectation.selected.length : 0;
    },
  }
};
</script>