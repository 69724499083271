var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group custom-form custom-form--input",class:{
    'required' : _vm.required, 
    'disabled':_vm.disabled, 
    'has-bg': _vm.bgIcon != null, 
    'has-label': _vm.label!=null,
    'has-error': _vm.hasError,
    'active-focus-label': _vm.activeFocusLabel,
    'active-value-label': _vm.activeValueLabel,
    'input--login': _vm.loginInput,
  }},[(_vm.label)?_c('label',{attrs:{"for":_vm.givenId}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.val),expression:"val"}],staticClass:"form-control",class:{ customClass: _vm.customClass,'form-control-sm' : _vm.size=='sm', 'form-control-lg':_vm.size=='lg',},style:(_vm.background),attrs:{"id":_vm.givenId,"name":_vm.name,"aria-describedby":_vm.helpId,"required":_vm.required,"disabled":_vm.disabled,"placeholder":_vm.placeholder},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.val=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(_vm.showFirstOption)?_vm._t("first",function(){return [_c('option',{attrs:{"value":""}},[_vm._v("Choisissez un élément de la liste ...")])]}):_vm._e(),_vm._t("default"),_vm._l((_vm.options),function(option,index){return _c('option',{key:`option-${index}`,domProps:{"value":option[_vm.idValue]}},[_vm._v(_vm._s(option[_vm.idText]))])})],2),(_vm.error && _vm.loginInput)?[_c('img',{staticClass:"input--error-icon",attrs:{"src":require("@/assets/error.svg"),"alt":""}})]:_vm._e(),_vm._t("error",function(){return [_c('small',{staticClass:"input--error",attrs:{"id":_vm.helpId}},[_vm._v(_vm._s(_vm.error))])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }