<template>
  <div class="planning--table-wrap">
    <table class="table planning--table">
      <thead>
      <tr>
        <th></th>
        <template v-for="(week,index) in weeks">
          <th :key="`th-${index}`" colspan="7">
            <div class="cell cell--week">Semaine {{week}}</div>
          </th>
        </template>
      </tr>
      <tr>
        <th></th>
        <template v-for="(week,index) in weeks">
          <th v-for="(day,i) in weekDays" :key="`th-${index}-${week}-${i}`" :class="i === 6 && index === 0 ? 'separate-border' : ''">
            <div class="cell cell--day" >{{day.label}}</div>
          </th>
        </template>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(hour, index) in hours" :key="`td-hour-${index}`">
        <td class="hour-tag" rowspan="2" v-if="index%2===0">
          <span>{{hour.label}}</span>
        </td>
        <template v-for="(week,index) in weeks">
          <td @drop.prevent="drop(week,day.realLabel,hour.value)"
              @dragover.prevent
              v-for="(day,i) in weekDays"
              :key="`td-${index}-${week}-${i}`"
              :class="i === 6 && index === 0 ? 'separate-border' : ''">
            <div class="planning-card--wrap">
              <planning-item draggable="true"
                             @dragstart="dragstart"
                             @click="clickedPrestation = item; showDetails = true;"
                             :class="{'multiple': findCarePlanPrestation(week,day.realLabel,hour.value).length > 1}"
                             v-for="(item, index) in findCarePlanPrestation(week,day.realLabel,hour.value)"
                             :info="item"
                             :place="index"
                             :day="day.realLabel"
                             :week="week"
                             :hour="hour.value"
                             :key="`td-${item.id + index}`"></planning-item>
            </div>
          </td>
        </template>
      </tr>
      </tbody>
    </table>
    <validated-time-act v-if="isEditable && calculateValidatedTime" :tempValide="calculateValidatedTime"/>    <planning-modal v-if="itemModalActive !== null" :show="true" :isEditable="isEditable" @close="itemModalActive = null" :items="activeItems"></planning-modal>

    <care-plan-prestation-details-modal
          v-if="clickedPrestation"
          :active="showDetails"
          :carePlanPrestation="clickedPrestation"
          @close="closePrestationPlanDetailsModal" />
  </div>
</template>
<script>
import PlanningItem from "@/components/Eval/PlanningItem";
import ValidatedTimeAct from "@/components/Eval/ValidatedTimeAct";
import {mapActions, mapGetters, mapState} from "vuex";
import PlanningModal from "@/components/Eval/PlanningModal";
import CarePlanPrestationDetailsModal from "@/components/Eval/CarePlanPrestationDetailsModal";

export default {
  name: "ProjetDeViePlanning",
  components: {
    PlanningItem,
    ValidatedTimeAct,
    PlanningModal,
    CarePlanPrestationDetailsModal
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      itemModalActive: null,
      weeks: 2,
      weekDays: [
        { day: 1, label: "L", value: "Lun", realLabel: "Lundi" },
        { day: 2, label: "M", value: "Mar", realLabel: "Mardi" },
        { day: 3, label: "M", value: "Mer", realLabel: "Mercredi" },
        { day: 4, label: "J", value: "Jeu", realLabel: "Jeudi" },
        { day: 5, label: "V", value: "Ven", realLabel: "Vendredi" },
        { day: 6, label: "S", value: "Sam", realLabel: "Samedi" },
        { day: 7, label: "D", value: "Dim", realLabel: "Dimanche" },
      ],
      hours: [
        { hour: 1, label: "lever", value: "Lever" },
        { hour: 2, label: "lever", value: "Lever " },
        { hour: 3, label: "matin", value: "Matin" },
        { hour: 4, label: "matin", value: "Matin " },
        { hour: 5, label: "midi", value: "Midi" },
        { hour: 6, label: "midi", value: "Midi " },
        { hour: 7, label: "après-midi", value: "Après-midi" },
        { hour: 8, label: "après-midi", value: "Après-midi " },
        { hour: 9, label: "soirée", value: "Soir" },
        { hour: 10, label: "soirée", value: "Soir " },
        { hour: 11, label: "nuit", value: "Nuit" },
        { hour: 12, label: "nuit", value: "Nuit " },
      ],
      elementOnDrag: null,
      dragStartDay: null,
      dragStartWeek: null,
      dragStartHour: null,
      clickedPrestation: null,
      showDetails: false,
    };
  },
  mounted() {
    this.getCarePlanPrestationList({filters: '{"column" : "evaluationId", "val" : ' + this.evaluation.id + '}'});
  },
  computed: {
    ...mapState('Prestation', ['prestationsAct']),
    ...mapState('Evaluation', ['evaluation']),
    ...mapState("CarePlanPrestation", ["carePlanPrestations", "carePlanPrestation"]),
    ...mapGetters('Evaluation', ['getAvqActListPlace', 'getAivqActListPlace']),
    activeItems() {
      return this.itemModalActive
    },
    retrieveCarePlanPrestations() {
      return this.carePlanPrestations
    },
    calculateValidatedTime() {
      let total = 0;
      this.carePlanPrestations.map(prestation => {
        let prestationDays = JSON.parse(prestation.recurrency[0]);
        let daysSem1 = prestationDays['Semaine 1'];
        let daysSem2 = prestationDays['Semaine 2'];
        let meanTime = ((prestation.time * daysSem1.length) + (prestation.time * daysSem2.length)) / 2;
        total +=  Math.ceil(meanTime);
      });
      return total;
    }
  },
  methods: {
    ...mapActions('CarePlanPrestation', ['getCarePlanPrestationList', 'postCarePlanPrestation']),
    // drag
    dragstart(info) {
      if(!this.isEditable) {
        return;
      }
      this.elementOnDrag = info.id;
      this.dragStartDay = info.day;
      this.dragStartWeek = info.week;
      this.dragStartHour = info.hour;
    },
    drop(week, day, hour) {
      if(!this.isEditable) {
        return;
      }
      var plans = this.elementOnDrag
      //limit is 2. if there are more drags on a zone that has more than 2, plan won't change
      var limitReached = this.findCarePlanPrestation(week, day, hour) ? this.findCarePlanPrestation(week, day, hour).length >= 2 : null;
      let alreadyBooked = this.findCarePlanPrestation(week, day, hour) ? this.findCarePlanPrestation(week, day, hour).includes(plans) : null;

      if (!plans || limitReached || alreadyBooked) return false;

      let prevWeek = 'Semaine '+this.dragStartWeek;
      let newWeek = 'Semaine '+week;
      let recurency = JSON.parse(plans.recurrency[0]);

      if(prevWeek == newWeek) {
        recurency[prevWeek] = recurency[prevWeek].filter(day => {
          return day != this.dragStartDay.toLowerCase();
        });
        recurency[prevWeek].push(day.toLowerCase());
      }else if(recurency[prevWeek].length > 1) {
        recurency[prevWeek] = recurency[prevWeek].filter(day => {
          return day != this.dragStartDay.toLowerCase();
        });
        recurency[newWeek].push(day.toLowerCase());
      }else {
        recurency[newWeek].push(day.toLowerCase());
      }

      plans.recurrency[0] = JSON.stringify(recurency);

      let schedule = plans.schedule;
      if(this.dragStartHour.trim() != hour.trim()) {
        schedule = hour.trim().toLowerCase();
      }


      this.$confirm('Êtes vous sûr de vouloir modifier la prestation "'+plans.name+'" ?').then(() => {
          this.carePlanPrestation.id = plans.id;
          this.carePlanPrestation.evaluationId = this.evaluation.id;
          this.carePlanPrestation.name = schedule;
          this.carePlanPrestation.typeAide = plans.typeAide;
          this.carePlanPrestation.schedule = schedule;
          this.carePlanPrestation.recurrency = plans.recurrency;
          this.carePlanPrestation.time = plans.time;
          this.carePlanPrestation.observation = plans.observation;

          this.postCarePlanPrestation().then((res) => {
            this.$notify.success('La prestation a été enregistré');
            this.getCarePlanPrestationList({filters: '{"column" : "evaluationId", "val" : ' + this.evaluation.id + '}'});
            return res;
          }).catch((err) => {
            this.$notify.error("Erreur de sauvegarde: " +err)
            throw err;
          });
      }).catch(() => {
      });
      
      this.elementOnDrag = null;
    },
    findCarePlanPrestation(week, day, hour) {
      return this.retrieveCarePlanPrestations.filter(carePlan => {
        let recurency = JSON.parse(carePlan.recurrency[0]);
        let theWeek = 'Semaine ' + week;
        return recurency[theWeek].includes(day.toLowerCase()) && carePlan.schedule == hour.toLowerCase();
      });
    },
    closePrestationPlanDetailsModal() {
      this.showDetails = false;
    }
  },
};
</script>