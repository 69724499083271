export default {
    setContractProperty(state, property) {
        if(!state.contract[property.name]){
            state.contract[property.name] = "";
        }
        state.contract[property.name] = property.value;
    },
    setContractLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setContractList(state, contracts) {
        state.contracts = contracts;
        state.loading = false;
        state.error = null;
    },
    setTotalContractList(state, total) {
        state.total = total
    },
    setContractError(state, error) {
        state.loading = false
        state.error = error
    },
    setContractSuccess(state, contract) {
        state.contract = {
            ...contract,
            agencyId: contract.agency ? contract.agency.id : null,
            clientId: contract.client ? contract.client.id : null
        }
        state.loading = false
        state.error = null
    },
    setInitContract(state) {
        state.contract =  {
            contractId: null,
            agencyId: null,
            agency: {},
            clientId: null,
            client: {},
            dateStart: null,
            dateEnd: null,
            priceByMonth: "",
            nbHourByMonth: "",
            discount: null
        }
    },
    setPrestationsContractSuccess(state, prestations) {
        state.prestations = prestations;
        state.loading = false;
        state.error = null;
    },
}