export default {
    setCarePlanPrestationProperty(state, property) {
        if(!state.carePlanPrestation[property.name]){
            state.carePlanPrestation[property.name] = "";
        }
        state.carePlanPrestation[property.name] = property.value;
    },
    setCarePlanPrestation(state, carePlanPrestation) {
        state.carePlanPrestation = carePlanPrestation;
    },
    initCarePlanPrestation(state) {
        state.carePlanPrestation = {
            id: null,
            evaluationId: '',
            name: '',
            typeAide: [],
            schedule: '',
            recurrency: [],
            time: '',
            observation: []
        };
        state.commentaires = {};
    },
    setCarePlanPrestationLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setCarePlanPrestationList(state, carePlanPrestations) {
        let arrangedPrestations = [];

        let leverPrestation = carePlanPrestations.filter((elt) => {
            return elt.name == 'lever';
        });
        leverPrestation.map((elt, index) => {
            elt.name = elt.name + ' ' + (index + 1);
            arrangedPrestations.push(elt);
        });

        let matinPrestation = carePlanPrestations.filter((elt) => {
            return elt.name == 'matin';
        });
        matinPrestation.map((elt, index) => {
            elt.name = elt.name + ' ' + (index + 1);
            arrangedPrestations.push(elt);
        });
        
        let midiPrestation = carePlanPrestations.filter((elt) => {
            return elt.name == 'midi';
        });
        midiPrestation.map((elt, index) => {
            elt.name = elt.name + ' ' + (index + 1);
            arrangedPrestations.push(elt);
        });

        let apresMidiPrestation = carePlanPrestations.filter((elt) => {
            return elt.name == 'après-midi';
        });
        apresMidiPrestation.map((elt, index) => {
            elt.name = elt.name + ' ' + (index + 1);
            arrangedPrestations.push(elt);
        });
        
        let soirPrestation = carePlanPrestations.filter((elt) => {
            return elt.name == 'soir';
        });
        soirPrestation.map((elt, index) => {
            elt.name = elt.name + ' ' + (index + 1);
            arrangedPrestations.push(elt);
        });

        let nuitPrestation = carePlanPrestations.filter((elt) => {
            return elt.name == 'nuit';
        });
        nuitPrestation.map((elt, index) => {
            elt.name = elt.name + ' ' + (index + 1);
            arrangedPrestations.push(elt);
        });

        state.carePlanPrestations = arrangedPrestations.length > 0 ? arrangedPrestations : carePlanPrestations;
        state.loading = false;
        state.error = null;
    },
    setTotalCarePlanPrestationList(state, total) {
        state.total = total;
    },
    setCarePlanPrestationError(state, error) {
        state.loading = false;
        state.error = error;
    },
    setCarePlanPrestationSaveLoading(state) {
        state.error = null;
    },
    setPostCarePlanPrestationSuccess(state, carePlanPrestation) {
        if(state.carePlanPrestation.id) {
            let theCarePlan = state.carePlanPrestations.filter(carePlan => {
                return carePlan.id == state.carePlanPrestation.id
            });
            let planIndex = state.carePlanPrestations.indexOf(theCarePlan[0]);
            state.carePlanPrestations[planIndex] = carePlanPrestation;
            state.carePlanPrestations.push('');
            state.carePlanPrestations.pop();
        }else{
            state.carePlanPrestations.push(carePlanPrestation);
        }
        state.carePlanPrestation = {
            id: null,
            evaluationId: '',
            name: '',
            typeAide: [],
            schedule: '',
            recurrency: [],
            time: '',
            observation: []
        };
        state.commentaires = {};
        state.total = state.total + 1
        state.error = null;
    },
    setDeleteCarePlanPrestationSuccess(state, id) {
        state.carePlanPrestations.splice(
            state.carePlanPrestations.findIndex(prestation => prestation.id === id),
            1
        );
        state.total -= 1;
        state.loading = false;
        state.error = null;
    },
}