import gql from 'graphql-tag'

export const SAVE_MATERIAL = gql`
    mutation saveMaterial(
        $id: Int,
        $name: String!,
        $description: String!,
        $status: Boolean!,
    ) {
        saveMaterial(
            id: $id,
            name: $name,
            description: $description,
            status: $status,
        ) {
            id,
            name,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            },
        }
    }
`
