<template>
  <div class="navbar">
    <img class="avatar" src="@/assets/avatar.png" alt />
    <img class="logo cursor-pointer" src="@/assets/logo.png" alt @click.prevent="gotToHome"/>
    <div class="eval--header-btn-wrap">
      <a class="btn" @click.prevent="gotToHome">
        <img src="@/assets/bouton-primaire-home.svg" />
      </a>
      <router-link v-if="$route.name !== 'cr' && $route.name !== 'devis'" :to="{name:'cr'}" class="btn" data-prefix="2">
        <img src="@/assets/bouton-primaire-cr.svg" />
      </router-link>
      <router-link v-else :to="{name:'project-life'}" class="btn" data-prefix="2">
        <img src="@/assets/bouton-primaire-q.svg" />
      </router-link>
      <router-link :to="{name:'client-list'}" class="btn" data-prefix="2">
        <img src="@/assets/bouton-primaire-switch.svg" />
      </router-link>
      <a class="btn" @click.prevent="$bvModal.show('quit-evaluation')">
        <img src="@/assets/bouton-primaire-power-off.svg" />
      </a>
    </div>
  </div>
</template>
<script>
  import {LOCAL_STORAGE_EVALUATION_ID} from "@/components/Util/EnvVariable";
  import {mapGetters} from "vuex";

  export default {
    computed: {
      ...mapGetters('Evaluation', ['calculateTime', 'getAggirPercentage']),
      hasLoader(){
        return this.$store.state.User.loading
            || this.$store.state.Prestation.loading
            || this.$store.state.Agency.loading
            || this.$store.state.Structure.loading
            || this.$store.state.Notification.loading
            || this.$store.state.Tarification.loading
            || this.$store.state.Contract.loading
            || this.$store.state.Filter.loading
            || this.$store.state.Client.loading
            || this.$store.state.Evaluation.loading
            || this.$store.state.Act.loading
            || this.$store.state.AggirQuestion.loading
            || this.$store.state.Material.loading
            || this.$store.state.Contact.loading;
      }
    },
    methods: {
      gotToHome() {
        window.sessionStorage.removeItem(LOCAL_STORAGE_EVALUATION_ID);
        this.$router.push({name: "accueil"});
      }
    }
  }
</script>