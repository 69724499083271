<script>
import { Pie } from "vue-chartjs";
export default {
  extends: Pie,
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.renderChart(this.data, {borderWidth: "10px"});
  }
};
</script>