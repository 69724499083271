import gql from 'graphql-tag'

export const SAVE_HABIT_EVALUATION = gql`
    mutation saveHabitEvaluation(
        $id: Int,
        $evaluationId: Int,
        $description: String,
        $period: String!,
        $place: String!,
        $validatedTime: String,
    ) {
        saveHabitEvaluation(
            id: $id,
            evaluationId: $evaluationId,
            description: $description,
            period: $period,
            place: $place
            validatedTime: $validatedTime,
        ) {
            id,
            description,
            period,
            place,
            validatedTime
        }
    }
`
