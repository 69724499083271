import {client as ApolloClient} from '@/api/Apollo';
import { PRESTATION_LIST } from '@/api/GraphQLQueries/bo/prestation/list-query'
import { GET_PRESTATION } from '@/api/GraphQLQueries/bo/prestation/get-prestation-query'
import { SAVE_PRESTATION } from '@/api/GraphQLQueries/bo/prestation/save-prestation-mutation'
import { DELETE_PRESTATION } from '@/api/GraphQLQueries/bo/prestation/delete-prestation-mutation'

export const getPrestationList = ({ commit }, {
    filters = '{}',
    sortColumn = 'name',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setPrestationLoading');
    return ApolloClient.query({
        query: PRESTATION_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setPrestationList', res.data.prestationList.items);
        commit('setTotalPrestationList', res.data.prestationList.total);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setPrestationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getPrestation = ({ commit }, { id }) => {
    commit('setPrestationLoading');
    return ApolloClient.query({
        query: GET_PRESTATION,
        variables: {
            id: id
        }
    }).then((res) => {
        if (res.errors) {
            console.error(res.errors);
            commit('setPrestationError', res.errors);
            throw res.errors;
        }
        commit('setPrestationSuccess', res.data.prestation);
        return res.data;
    })
};

export const postPrestation = ({ commit, state }) => {
    commit('setPrestationSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_PRESTATION,
        variables: {
            ...state.prestation,
            id: 0
        }
    }).then((res) => {
        commit('setPostPrestationSuccess', res.data.savePrestation);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setPrestationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const putPrestation = ({ commit, state }) => {
    commit('setPrestationSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_PRESTATION,
        variables: state.prestation
    }).then((res) => {
        commit('setPutPrestationSuccess', res.data.savePrestation);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setPrestationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const deletePrestation = ({ commit }, { id }) => {
    commit('setPrestationLoading');
    return ApolloClient.mutate({
        mutation: DELETE_PRESTATION,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setDeletePrestationSuccess', res.data.deletePrestation);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setPrestationError', err.response ? err.response.data : err);
        throw err;
    })
};


export default {
    getPrestationList,
    getPrestation,
    putPrestation,
    postPrestation,
    deletePrestation,
};