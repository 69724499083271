import gql from 'graphql-tag'

export const VALIDATE_RISKS = gql`
    mutation validateRisks(
        $evaluationId: Int!,
        $checklist: [Int!]!
    ) {
        validateRisks(
            evaluationId: $evaluationId,
            checklist: $checklist
        ) {
            id
            risks{
                id,
                risk{
                    id,
                    name,
                }
                checked
            },
        }
    }
`
