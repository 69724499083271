import gql from 'graphql-tag'

export const GET_INTERVENTION = gql`
    query intervention(
        $id: Int!,
    ) {
        intervention(
            id: $id
        ) {
            id,
            dateStart,
            dateEnd,
            openingDate,
            client{
                id,
                lastname
                firstname
            },
            prestation{
                id,
                name
            },
            intervenant{
                id,
                firstName,
                lastName
            },
            additionalComments,
            familyComments,
            sendHour,
            status,
            hasNoBadge,
            hasNoBadgeDescription,
            createdAt,
            signalements {
              id,
              subSignalementId
              signalement {
                type
                actionList
              },
              description
            }
        }
    }
`
