import gql from 'graphql-tag'

export const SAVE_AGGIR_QUESTION = gql`
    mutation saveAggirQuestion(
        $id: Int!,
        $placeRelated: [String!]!,
        $item: String!,
        $subItem: String,
        $openingStatement: String!,
        $itemPeriod: [String!]!,
        $itemStch: String!,
        $timeType: String
    ) {
        saveAggirQuestion(
            id: $id,
            placeRelated: $placeRelated,
            item: $item,
            subItem: $subItem,
            openingStatement: $openingStatement,
            itemPeriod: $itemPeriod,
            itemStch: $itemStch,
            timeType: $timeType
        ) {
            id,
            placeRelated,
            item,
            subItem,
            openingStatement,
            itemPeriod,
            itemStch,
            timeType,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            }
        }
    }
`
