<template>
  <div class="bo--container">
    <template>
      <div class="container">
        <loader :loader="loading"/>
        <div>
          <bo-card :title="isEdit ? '&lt; Modifier le matériel' : '&lt; Créer le matériel'">
            <template slot="title">
              <h1 class="bo-card--title" >
                <a href="#" @click.prevent="cancel">&lt; </a> {{isEdit ? 'Modifier le matériel' : "Créer un nouveau matériel"}}
              </h1>
            </template>
            <div class="row">
              <div class="col-sm-9 col-md-9">
                  <form-input v-model="name"
                          label="Nom du matériel *"
                          :error="!$v.name.required && $v.name.$error ? 'Le nom de le matériel est obligatoire.' : null"
                          :login-input="true"
                  />
              </div>
              <div class="col-sm-3 col-md-3">
                <form-toggle append="Actif" prepend="Non actif" v-model="status" name="toggle"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <form-input v-model="ref"
                          label="Ref"
                          :login-input="true"
                          :disabled="true"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <form-textarea v-model="description"
                             label="Description *"
                             :error="!$v.description.required && $v.description.$error ? 'Le description de la question est obligatoire.' : null"
                             :disabled="false"
                             :rows="3"
                             :login-input="true">
                </form-textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                  <document-simple
                      v-if="isEdit"
                      title="Photo associée"
                      v-model="photoUrl"
                      :imageUrl="material.photoUrl ? basePhotoPath + material.photoUrl + '?' + Date.now(): ''"
                      mention="50px*50px min"
                      @input="uploadPhoto"/>
                  <p class="text-danger" v-if="!photoUrl && isSubmitPhoto">* La photo est obligatoire</p>
              </div>
              <div class="col-sm-12 col-md-6">
                  <video-simple
                      v-if="isEdit"
                      title="Vidéo associé"
                      v-model="videoUrl"
                      :videoUrl="material.videoUrl ? baseVideoPath + material.videoUrl + '?' + Date.now(): ''"
                      mention="50px*50px min"
                      @input="uploadVideo"/>
                  <p class="text-danger" v-if="!videoUrl && isSubmitVideo">* La video est obligatoire</p>
              </div>
            </div>
            <template slot="footer">
              <button class="btn btn-primary" @click.prevent="submit" :disabled="saveLoading"><font-awesome-icon v-show="saveLoading" :icon="['fas', 'spinner']" spin/> Enregistrer</button>
              <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
            </template>
          </bo-card>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

import {mapState, mapActions, mapMutations} from "vuex";
import DocumentSimple from "@/components/Form/DocumentSimple";
import VideoSimple from "@/components/Form/VideoSimple";
import { required } from 'vuelidate/lib/validators'
import {API_URL} from "@/components/Util/EnvVariable";

export default {
  name: "BOMaterialForm",
  components: { DocumentSimple, VideoSimple },
  data() {
    return {
      bo: {
        active: true
      },
      saveLoading: false,
      photoUrl: null,
      isSubmitPhoto: false,
      videoUrl: null,
      isSubmitVideo: false,
    };
  },
  validations() {
    let dataReturn = {
      name: {
        required
      },
      description: {
        required
      },
    };
    return dataReturn;
  },
  computed: {
    ...mapState('Material', ['material', 'error', 'loading']),
    ...mapState('Act', ['acts']),
    ...mapState('User', ['connectedUser']),
    ref:{
      get(){
        return this.material.ref;
      },
      set(){}
    },
    name:{
      get(){
        return this.material.name;
      },
      set(value){
        this.setMaterialProperty({
          name: "name",
          value: value
        });
      }
    },
    description:{
      get(){
        return this.material.description;
      },
      set(value){
        this.setMaterialProperty({
          name: "description",
          value: value
        });
      }
    },
    status:{
      get(){
        return this.material.status;
      },
      set(value){
        this.setMaterialProperty({
          name: "status",
          value: value
        });
      }
    },
    isEdit() {
      return (this.$route.params.id !== undefined && this.$route.params.id !== null)
    },
    basePhotoPath() {
      return API_URL;
    },
    baseVideoPath() {
      return API_URL;
    }
  },
  mounted: async function() {
    this.setInitMaterial();
    if (this.isEdit) {
      this.getMaterial({ id: this.$route.params.id })
      .catch((err) => {
        if (err) {
          this.$notify.error("Impossible d'accéder à cette material")
          this.$router.push({name: 'material-list'});
        }
      })
    }
  },
  methods: {
    ...mapMutations('Material',['setMaterialProperty', 'setInitMaterial']),
    ...mapActions('Material', ['postMaterial', 'putMaterial', 'getMaterial', 'postMaterialPhoto', 'postMaterialVideo']),
    ...mapActions('Structure', ['getStructureList', 'getStructure']),
    async submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      if (this.isEdit) {
        this.$confirm('Êtes-vous sur de vouloir réaliser une modification ?').then(() => {
          this.afterConfirm();
        }).catch(() => {
        });
      } else {
        this.afterConfirm();
      }
    },
    afterConfirm(){
      this.saveLoading = true;
      if (this.isEdit) {
        this.putMaterial().then((res) => {
          this.backToList();
          return res;
        }).catch((err) => {
          this.saveLoading = false;
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          throw err;
        });
      } else {
        this.postMaterial().then((res) => {
          this.backToList();
          return res;
        }).catch((err) => {
          this.saveLoading = false;
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          throw err;
        });
      }
    },
    backToList(){
      this.setInitMaterial();
      this.saveLoading = false;
      this.$notify.success(this.isEdit ? "Le matériel a été modifiée" : "Le matériel a été créée")
      this.$router.push({name: 'material-list'});
    },
    cancel() {
      return this.$router.push({name:'material-list'});
    },
    uploadPhoto() {
      if (!this.photoUrl) {
        return;
      }
      let formData = new FormData();
      formData.append('photo', this.photoUrl);
      this.setMaterialProperty({name: "photo", value: formData});
      this.postMaterialPhoto();
    },
    uploadVideo() {
      if (!this.videoUrl) {
        return;
      }
      let formData = new FormData();
      formData.append('video', this.videoUrl);
      this.setMaterialProperty({name: "video", value: formData});
      this.postMaterialVideo();
    }
  }
};
</script>