import gql from 'graphql-tag'

export const RESET_GLOBAL_STRUCTURE = gql`
    mutation resetGlobalStructure {
        resetGlobalStructure {
            id,
            name,
            erpId,
            address,
            status,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            },
        }
    }
`
