import gql from 'graphql-tag'

export const SAVE_PRESTATION = gql`
    mutation savePrestation(
        $id: Int!,
        $name: String!,
        $nameType: String!,
        $description: String!,
        $schedule: String!,
        $recurrency: [String!]!,
        $time: Int!,
        $price: Float!,
        $tva: Float!,
    ) {
        savePrestation(
            id: $id,
            name: $name,
            nameType: $nameType,
            description: $description,
            schedule: $schedule,
            recurrency: $recurrency,
            time: $time,
            price: $price,
            tva: $tva
        ) {
            id,
            structure {
                id,
                name
            },
            name,
            nameType,
            description,
            schedule,
            recurrency,
            time,
            price,
            tva
        }
    }
`
