import {client as ApolloClient} from '@/api/Apollo';
import { TYPE_AIDE_LIST } from '@/api/GraphQLQueries/evaluation/type-aide/list-query'

export const getTypeAideList = ({ commit }, {
    filters = '{}',
    sortColumn = 'id',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setTypeAideLoading');
    return ApolloClient.query({
        query: TYPE_AIDE_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setTypeAideList', res.data.typeAideList.items);
        commit('setTotalTypeAideList', res.data.typeAideList.total);
    }).catch((err) => {
        commit('setTypeAideError', err.response ? err.response.data : err);
        throw err;
    })
};

export default { getTypeAideList }