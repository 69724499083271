<template>
  <div class="row">
    <div class="col-sm-12 col-md-6 cr--context-chart">
      <label>Autonomie des activités corporelles et mentales</label>
      <RadarChart ref="chartAVQ"
                  title="Autonomie des activités corporelles et mentales"
                  :labels="['Cohérence', 'Dépl. Exterieurs', 'Dépl. Interieurs', 'Transferts', 'Elimination', 'ALimentation', 'Habillage', 'Toilette', 'Alerter', 'Orientation']"
                  :data="firstChartData"/>
    </div>
    <div class="col-sm-12 col-md-6 cr--context-chart">
      <label>Autonomie des activités domestiques et sociales</label>
      <RadarChart ref="chartAIVQ"
                  title="Autonomie des activités domestiques et sociales"
                  :labels="['Gestion', 'Ménage', 'Cuisine', 'Activités de temps libre', 'Transports', 'Suivi du traitement', 'Achats']"
                  :data="secondChartData"/>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import RadarChart from "@/components/Eval/RadarChart";

export default {
  name: "CR_Autonomy",
  components: {
    RadarChart
  },
  methods: {
    exportChart() {
      const data = {};
      if (this.$refs.chartAVQ) {
        data.chartAVQImage = this.$refs.chartAVQ.downloadImage();
      }
      if (this.$refs.chartAIVQ) {
        data.chartAIVQImage = this.$refs.chartAIVQ.downloadImage();
      }
      return data;
    }
  },
  computed: {
    ...mapState("Evaluation", ["evaluation"]),
    firstChartData() {
      const coherence = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 23) : null;
      const ext = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 16) : null;
      const int = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 2) : null;
      const transferts = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 1) : null;
      const elimination = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 5) : null;
      const alim = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 11) : null;
      const habillage = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 7) : null;
      const toilette = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 3) : null;
      const alert = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 15) : null;
      const orientation = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 21) : null;

      return [
        coherence ? (coherence.itemScore === "A" ? 100 : (coherence.itemScore === "B" ? 50 : 0)) : 0,
        ext ? (ext.itemScore === "A" ? 100 : (ext.itemScore === "B" ? 50 : 0)) : 0,
        int ? (int.itemScore === "A" ? 100 : (int.itemScore === "B" ? 50 : 0)) : 0,
        transferts ? (transferts.itemScore === "A" ? 100 : (transferts.itemScore === "B" ? 50 : 0)) : 0,
        elimination ? (elimination.itemScore === "A" ? 100 : (elimination.itemScore === "B" ? 50 : 0)) : 0,
        alim ? (alim.itemScore === "A" ? 100 : (alim.itemScore === "B" ? 50 : 0)) : 0,
        habillage ? (habillage.itemScore === "A" ? 100 : (habillage.itemScore === "B" ? 50 : 0)) : 0,
        toilette ? (toilette.itemScore === "A" ? 100 : (toilette.itemScore === "B" ? 50 : 0)) : 0,
        alert ? (alert.itemScore === "A" ? 100 : (alert.itemScore === "B" ? 50 : 0)) : 0,
        orientation ? (orientation.itemScore === "A" ? 100 : (orientation.itemScore === "B" ? 50 : 0)) : 0
      ];
    },
    secondChartData() {
      const gestion = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 20) : null;
      const menage = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 14) : null;
      const cuisine = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 10) : null;
      const activity = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 18) : null;
      const transport = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 17) : null;
      const suivi = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 13) : null;
      const achats = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === 19) : null;

      return [
        gestion ? (gestion.itemScore === "A" ? 100 : (gestion.itemScore === "B" ? 50 : 0)) : 0,
        menage ? (menage.itemScore === "A" ? 100 : (menage.itemScore === "B" ? 50 : 0)) : 0,
        cuisine ? (cuisine.itemScore === "A" ? 100 : (cuisine.itemScore === "B" ? 50 : 0)) : 0,
        activity ? (activity.itemScore === "A" ? 100 : (activity.itemScore === "B" ? 50 : 0)) : 0,
        transport ? (transport.itemScore === "A" ? 100 : (transport.itemScore === "B" ? 50 : 0)) : 0,
        suivi ? (suivi.itemScore === "A" ? 100 : (suivi.itemScore === "B" ? 50 : 0)) : 0,
        achats ? (achats.itemScore === "A" ? 100 : (achats.itemScore === "B" ? 50 : 0)) : 0
      ];
    },
  }
}
</script>