import gql from 'graphql-tag'

export const SEEN_ALERT = gql`
    mutation seenAlert(
        $id: Int!,
    ) {
        seenAlert(
            id: $id
        ) {
            id,
            type,
            intervenantName,
            clientName,
            hasSeen,
            createdAt
        }
    }
`
