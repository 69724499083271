export default {
    getNotificationByInterface(state) {
        return state.notification.reduce((tab, notif) => {
            if (!tab[notif.titre]) {
                tab[notif.titre] = [];
            }
            tab[notif.titre].push(notif);
            return tab;
        }, {});
    }
};