<template>
  <custom-modal :active="active" @close="close" class="prestation-details-modal">
    <div class="eval-modal-title">
      <h2>Résumé de la prestation</h2>
    </div>
    <div class="eval-modal-content">
        <div class="informations">
          <div class="second-group">
            <small>
              <img src="/img/icon-plein-info.116bf32c.svg" alt="">
              Durée de la prestation: <span>{{carePlanPrestation.time}} min</span>
            </small>
            <small>Période: <span>{{carePlanPrestation.schedule}}</span></small>
          </div>
        </div>
        <div class="datagrid">
            <table class="table datagrid-table">
                <thead>
                    <tr>
                    <th></th>
                    <th style="width: 22%;">modalité(s) Aggir</th>
                    <th>type d'aide</th>
                    <th colspan="4">commentaires</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in gridItems" :key="index">
                        <td>{{item.name}}</td>
                        <td>
                            <div class="aggirgrid-stch">
                                <div class="aggirgrid-stch--item">
                                    <template v-if="item.resA">
                                    <span
                                        class="aggirgrid--letter-item blank"
                                        :class="{'yellow': item.resA==='B','red':item.resA==='C'}">{{item.resA}}</span>
                                    </template>
                                    <div v-else class="aggirgrid--blank">-</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span v-for="(typeAide, i) in item.typesAides" :key="i">
                                {{typeAide}},
                            </span>
                        </td>
                        <td>{{item.comment}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="action-buttons">
        <a href="#" @click.prevent="updatePrestation()">
            <i class="fa fa-pencil"></i>
          <span>Modifier la prestation</span>
        </a>
        <a href="#" @click.prevent="deletePrestation()">
          <span>Supprimer</span>
        </a>
    </div>
  </custom-modal>
</template>
<script>
import {mapActions, mapState} from "vuex";
export default {
  name: "CarePlanPrestationDetailsModal",
  props: {
    active: {
      type: Boolean,
    },
    carePlanPrestation: {
        type: Object,
    },
  },
  data() {
    return {

    };
  },
  mounted() {
    this.getAggirQuestionList({limit: 0});
  },
  computed: {
    ...mapState("AggirQuestion", ["aggirQuestions"]),
    ...mapState("Evaluation", ["evaluation"]),
    gridItems() {
      const gridItems = [];
      const prestationTypesAides = JSON.parse(this.carePlanPrestation.typeAide);
      const prestationComments = JSON.parse(this.carePlanPrestation.observation);

      for (let question of this.aggirQuestions) {
        let scores = this.getQuestionScores(question);
        
        if(prestationTypesAides[question.id] || prestationComments[question.id]) {
            const item = {
            id: question.id,
            item: question.item,
            name: question.subItem ? question.item + ' - ' + question.subItem : question.item,
            resA: scores ? scores.resA : null,
            resB: scores ? scores.resB : null,
            loading: false,
            typesAides: prestationTypesAides[question.id],
            comment: prestationComments[question.id]
            }
            gridItems.push(item);
        }
      }

      return gridItems;
    }
  },
  methods: {
    ...mapActions('AggirQuestion', ['getAggirQuestionList']),
    ...mapActions('CarePlanPrestation', ['deleteCarePlanPrestation']),
    close() {
      this.$emit("close");
    },
    getQuestionScores(question) {
      let resA = null;
      let resB = null;
      let subItemCount = this.aggirQuestions.filter(q => q.item === question.item).length;
      subItemCount = subItemCount === 0 ? 1 : subItemCount;

      const answer = this.evaluation.answerList.find(answer => answer.question.id === question.id);

      if (!answer) {
        return {
            id: question.id,
            resA: resA,
            resB: resB,
            total: subItemCount
        }
      }

      return {
        id: question.id,
        resA: answer.subItemScore,
        resB: answer.itemScore,
        total: subItemCount
      }

    },
    updatePrestation() {
      this.$router.push({
        name: 'autonomy',
        params:{
          carePlanPrestation: this.carePlanPrestation.id
        }
      });
    },
    deletePrestation() {
        this.$confirm('Êtes vous sûr de vouloir supprimer la prestation "'+this.carePlanPrestation.name+'" ?').then(() => {
            this.deleteCarePlanPrestation({id: this.carePlanPrestation.id}).then((res) => {
                this.$notify.success('la prestation "'+this.carePlanPrestation.name+'" a été supprimée avec succès');
                this.close();
                res;
            }).catch((error) => {
                this.$notify.error('Impossible de supprimer la prestation "'+this.carePlanPrestation.name+'": '+error);
            });
        }).catch(() => {});
    }
  },
};
</script>