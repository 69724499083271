import {client as ApolloClient} from '@/api/Apollo';
import { INTERVENTION_LIST } from '@/api/GraphQLQueries/bo/intervention/list-query'
import { GET_INTERVENTION } from '@/api/GraphQLQueries/bo/intervention/get-intervention-query'
import { GET_INTERVENTION_CHART_DATA_BY_SIGNALEMENT } from '@/api/GraphQLQueries/bo/intervention/get-intervention-chart-data-by-signalement-query'
import { GET_INTERVENTION_RATIO_CHART_DATA_BY_HOURS } from '@/api/GraphQLQueries/bo/intervention/get-intervention-ratio-chart-data-by-hours-query'

export const getInterventionList = ({ commit }, {
    filters = '{}',
    sortColumn = 'createdAt',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setInterventionLoading');
    return ApolloClient.query({
        query: INTERVENTION_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setInterventionList', res.data.interventionList.items);
        commit('setTotalInterventionList', res.data.interventionList.total);
    }).catch((err) => {
        console.error(err);
        commit('setInterventionError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getInterventionChartDataBySignalement = ({ commit }, {
    intervenantId = '',
    agencyId = '',
    structureId = ''
}) => {
    commit('setInterventionLoading');
    return ApolloClient.query({
        query: GET_INTERVENTION_CHART_DATA_BY_SIGNALEMENT,
        variables: {
            intervenantId: intervenantId,
            agencyId: agencyId,
            structureId: structureId
        }
    }).then((res) => {
        commit('setInterventionChartDataBySignalement', res.data.interventionChartDataBySignalement);
    }).catch((err) => {
        commit('setInterventionError');
        console.error(err);
        throw err;
    })
};

export const getInterventionRatioChartDataByHours = ({ commit }, {
    intervenantId = '',
    agencyId = '',
    structureId = '',
    gir = '',
}) => {
    commit('setInterventionRatioChartDataByHoursLoading');
    return ApolloClient.query({
        query: GET_INTERVENTION_RATIO_CHART_DATA_BY_HOURS,
        variables: {
            intervenantId: intervenantId,
            agencyId: agencyId,
            structureId: structureId,
            gir: gir
        }
    }).then((res) => {
        commit('setInterventionRatioChartDataByHours', res.data.interventionRatioChartDataByHours);
    }).catch((err) => {
        commit('setInterventionError');
        console.error(err);
        throw err;
    })
};

export const getIntervention = ({ commit }, { id }) => {
    commit('setInterventionLoading');
    return ApolloClient.query({
        query: GET_INTERVENTION,
        variables: {
            id: id
        }
    }).then((res) => {
        if (res.errors) {
            console.error(res.errors);
            commit('setInterventionError', res.errors ? res.errors : null);
            throw res.errors;
        }
        commit('setInterventionSuccess', res.data.intervention);
        return res.data;
    })
};


export default {
    getInterventionList,
    getIntervention,
    getInterventionChartDataBySignalement,
    getInterventionRatioChartDataByHours
};
