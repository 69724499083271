<template>
  <div class="pdv-card">
    <div class="pdv-card--content">
      <h6 class="pdv-card--title">{{ title }}</h6>
      <slot/>
    </div>
    <div class="pdv-card--footer">
      <div class="pdv-card--footer-cal eval-form--check">
        <div class="id-check--option" v-for="(day, index) in days" :key="index">
          <input
              class="id-check--input"
              :id="`${id}-${index}`"
              :name="id"
              type="checkbox"
              :value="day"
              :checked="matchedDays(day)"
              @click="applyDay(day)"/>
          <label :for="`${id}-${index}`">
            <span class="id-check--indicator">{{ day }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import EvalFormCheck from "@/components/Eval/FormCheck";
export default {
  name: "PDVCard",
  props: {
    title: {
      type: String,
      default: null,
    },
    acts: {
      type: Array,
      default: null
    }
  },
  components: {
    // EvalFormCheck,
  },
  computed: {
    id() {
      var chars =
              "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()_-+={}[]><.,?/~|",
          id = [];
      for (let index = 0; index < 5; index++) {
        var randomIndex = Number(Math.random() * chars.length).toFixed(0);
        id.push(chars[randomIndex]);
      }
      id = id.join("");

      return id;
    },
  },
  data() {
    return {
      selectedDays: [],
      days: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
      val: [],
      isClicking: false
    };
  },
  methods: {
    async applyDay(day) {
      this.selectedDays = this.acts[0].weekDays ? this.acts[0].weekDays : []
      let index = this.selectedDays.indexOf(day);
      if (index > -1) {
        this.selectedDays.splice(index, 1)
      } else {
        this.selectedDays.push(day)
      }
      let actsIds = this.acts.map((act) => {
        return act.id
      })

      await this.$emit("applyDay", {selectedDays: this.selectedDays, ids: actsIds, period: this.acts[0].period})
    },
    matchedDays(day) {
      if (this.acts[0].weekDays && this.acts[0].weekDays.length > 0) {
          return this.acts[0].weekDays.includes(day)
      }
    }
  }
};
</script>