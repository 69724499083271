import Vue from "vue";
import VueRouter from "vue-router";

import Login from '@/views/Auth/Login'
import forgotPassword from '@/views/Auth/ForgotPassword'
import resetPassword from '@/views/Auth/ResetPassword'

import BO from './BO';
import Evaluation from './Evaluation';

Vue.use(VueRouter);

const routes = [
    {
        path: "*",
        redirect: {name: "login"}
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        alias: "/evaluation-login"
    },
    {
        path: "/forgotPassword",
        name: "forgotPassword",
        component: forgotPassword,
    },
    {
        path: "/resetPassword/:id",
        name: "resetPassword",
        component: resetPassword,
    },
    ...BO,
    ...Evaluation
];

const router = new VueRouter({
    //mode: "history",
    routes
});

export default router;
