<template>
    <div class="bo--agence row mx-0 justify-content-center">
        <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
            <div class="d-flex align-items-center">
                <h1 class="bo--agence-title">Liste du personnel</h1>
                <span class="bo--agence-title-notification error" v-if="!synchronized">Non synchronisé</span>
                <span class="bo--agence-title-notification success" v-else>Synchronisé</span>
                <span class="bo--agence-title-count">{{total}} {{total>1 ? 'éléments' : 'élément'}}</span>
            </div>
            <div class="col-md-12">
                <button class="float-right btn btn-primary mt-1 ml-1" @click="createUser" v-if="canUserCreateUser"> Créer un membre du personnel</button>
                <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
            </div>
            <Grid id="UserList"
                  :columns="headerColumns"
                  :dataList="userList"
                  :sortColumn="sortColumn"
                  :sortDirection="sortDirection"
                  :currentPage="currentPage"
                  :loading="loading"
                  :total="total"
                  :canEditOrDelete="isUserRoleOperationManagerOrAreaManager"
                  :canOnlyDeleteUser="!isUserRoleOperationManagerOrAreaManager"
                  @updateCurrentPage="limitCo => limit = limitCo"
                  @dataLoading="dataLoading"
                  @editComponent="editUser"
                  @confirmRemove="confirmRemove"
                  ref="grid"
            />
        </div>
        <b-modal id="archive" hide-footer title="Archive" :centered="true">
            <template v-slot:modal-title>
                Êtes-vous sur de vouloir supprimer ce personnel ?
            </template>
            <b-button class="mt-3" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('archive')">Annuler</b-button>
            <b-button class="mt-2" variant="btn offset-5 col-4 btn-outline-danger" @click.prevent="removeUser">Supprimer</b-button>
        </b-modal>
    </div>
</template>

<script>
    import Grid from "@/components/Grid/Grid";
    import {MixinStructureHeader} from "@/components/Util/MixinHeader";
    import {mapState, mapActions, mapGetters} from 'vuex';
    import moment from 'moment';
    import {AREA_MANAGER, OPERATION_MANAGER} from "@/components/Util/roleConstants";

    export default {
        name: "UserList",
        mixins: [MixinStructureHeader()],
        components: {
            Grid
        },
        data() {
            return {
                filterColumns: [
                    'Email',
                    'Agence',
                    'Structure',
                    'Nom',
                    'Prénom',
                    // 'Dossier',
                    'Ville',
                    'Secteur',
                    'Téléphone'
                ],
                columns: [
                    {name: "Structure", value: 'name', column: 'structure.id', sortable: true},
                    {name: "Agence", value: 'companyName', column: 'agency.company_name', sortable: true},
                    {name: "Email", value: 'email', column: 'email', sortable: true, width: '180px'},
                    {name: "Nom", value: 'lastName', column: 'lastName', sortable: true},
                    {name: "Prénom", value: 'firstName', column: 'firstName', sortable: true},
                    // {name: "Date de création", value: 'createdDate', column: 'created_at', sortable: false},
                    // {name: "Créateur", value: 'createdUser', column: 'created_by', sortable: false},
                    // {name: "Dossier", value: 'fileNumber', column: 'fileNumber', sortable: true},
                    {name: "Ville", value: 'city', column: 'city', sortable: true},
                    {name: "Secteur", value: 'sector', column: 'sector', sortable: true},
                    {name: "Téléphone", value: 'portableProfesionnalPhoneNumber', column: 'portableProfesionnalPhoneNumber', sortable: true},
                    {name: "Contrat", value: 'contract', column: 'contractEnd', sortable: true},
                    {name: "Tmp. Mensuel", value: 'contractHourByMonth', column: 'contractHourByMonth', sortable: true},
                    {name: "Statut", value: 'status', column: 'status', sortable: true, list: [{label: 'Tous', value: ""}, {label: 'Actif', value: 1}, {label: 'Inactif', value: 0}]}
                ],
                sortColumn: 'lastName',
                sortDirection: 'ASC',
                limit: 100,
                currentPage: 1,
                removableUser: null,
            };
        },
        computed: {
            ...mapState('User', ['loading', 'users', 'total', 'error', 'connectedUser']),
            ...mapGetters('User', ['isUserRoleOperationManagerOrAreaManager']),
            ...mapGetters('Notification', ['getNotificationByInterface']),
            //headerColumns(){},headerColumns is set in mixin MixinStructureHeader
            synchronized() {
                return this.getNotificationByInterface.Personnel && this.getNotificationByInterface.Personnel.reduce((value, line) => {
                    if (line.type !== "success") {
                        value = false;
                    }
                    return value;
                }, true);
            },
            tdStyle() {
                //return `width:${(100 / this.columns.length).toFixed(1)}%`;
                return `width:110px`;
            },
            canUserCreateUser() {
              return this.connectedUser.role === OPERATION_MANAGER || this.connectedUser.role === AREA_MANAGER
            },
            userList() {
                return this.users.map(function (item) {
                    return {
                        ...item,
                        companyName: item.agencies && item.agencies.length > 0 ? item.agencies.reduce((text, c) => {
                          if (!c.companyName) {
                            return text;
                          }
                          if (!text || text === "") {
                            return c.companyName;
                          }
                          text = `${text} - ${c.companyName}`;
                          return text;
                        }, null) : null,
                        name: item.structure ? item.structure.name : null,
                        createdUser: item.createdBy ? item.createdBy.firstName + ' ' + item.createdBy.lastName : null,
                        updatedUser: item.updatedBy ? item.updatedBy.firstName + ' ' + item.updatedBy.lastName : null,
                        createdDate: item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : null,
                        updatedDate: item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : null,
                        contract: item.contractType ? `${item.contractType}${(item.contractEnd?` - ${moment(item.contractEnd).format('DD/MM/YYYY')}`:'')}`: null,
                        status: item.status ? 'Actif' : 'Inactif'
                    }
                });
            }
        },
        mounted: function () {
            this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : 10;
            if (this.users.length === 0) {
                this.dataLoading({limit: this.limit, offset: this.limit * (this.currentPage -1)});
            }
        },
        methods: {
            ...mapActions("User", ["getUserList", "deleteUser"]),
            dataLoading(data) {
                this.getUserList(data);
            },

            refresh() {
                this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : this.limit;
                this.currentPage = 1;
                this.sortColumn = "lastName";
                this.sortDirection = "ASC";
                this.getUserList({
                    limit: this.limit,
                    offset: this.limit * (this.currentPage -1),
                    sortColumn: this.sortColumn,
                    sortDirection: this.sortDirection,
                });
                this.$refs.grid.refreshFilter();
            },
            editUser(id) {
                this.$router.push({name: 'user-form', params: {id: id}});
            },
            createUser() {
              this.$router.push({name: 'user-form'});
            },
            confirmRemove(user) {
                this.removableUser = user;
                this.$bvModal.show('archive');
            },
            async removeUser() {
                this.$bvModal.hide('archive');
                await this.deleteUser({id: this.removableUser.id});
                if (this.error === null) {
                    this.$notify.success("Le personnel a été archivé")
                } else {
                    this.$notify.error("Impossible de supprimer le personnel")
                }
                this.removeableAgency = null;
            }
        }
    };
</script>