import gql from 'graphql-tag'

export const FILTER_STRUCTURE_LIST = gql`
    query filterStructureList {
            filterStructureList {
                id,
                name,
                address,
                status,
                createdAt,
                createdBy{
                    id,
                    firstName,
                    lastName
                },
                updatedAt,
                updatedBy{
                    id,
                    firstName,
                    lastName
                }
            }
        }
`;
