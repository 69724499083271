<template>
  <custom-modal :active="show" @close="close" :class="items ? 'planning-modal' : ''">
    <template>
      <div class="d-flex">
        <div class="planning-item--title">
          <h2 class="mb-4">Prestation</h2>
          <div class="planning-item--timing">
            <clock-icon />
            <h6 class="mb-0 mr-2 text-uppercase" v-if="!editMode">{{items[0].period}}</h6>
            <form-select
                         v-model="activePeriod"
                         v-if="editMode"
                         :options="periodList"
                         :login-input="true"
                         :disabled="false">
              <template v-slot:first>
                <option value="" selected></option>
              </template>
            </form-select>
            <span v-if="items[0].caregiver === 'BVIDF'">- {{ formatedDate(sumModalActTime()) }}</span>
          </div>
        </div>
        <div class="ml-auto">
          <div class="planning-item--tag" :class="{'style-2': listEntourage.includes(items[0].caregiver), 'style-3': listAAD.includes(items[0].caregiver)}">{{items[0].caregiver}}</div>
        </div>
      </div>
      <hr />
      <div class="row align-items-center mx-0">
        <div class="col-sm-4 col-md-3 planning-item--detail date" :class="{'style-2': listEntourage.includes(items[0].caregiver), 'style-3': listAAD.includes(items[0].caregiver)}">
          <calendar-icon size="3x" />
          <div class>
            <span class="font-weight-bold text-uppercase h6 mb-0" v-if="!editMode">{{ items['daySelected'] }}</span>
            <form-select
                v-model="activeDay"
                v-if="editMode"
                :options="dayList"
                :login-input="true"
                :disabled="false">
              <template v-slot:first>
                <option value="" selected></option>
              </template>
            </form-select>
          </div>
        </div>
        <div class="col-sm-4 col-md-3 planning-item--detail">
          <map-pin-icon />
          <span>{{ placesStr }}</span>
        </div>
      </div>
      <div class="row align-items-center mx-0">
        <ul class="pl-0 list-acts">
          <li v-for="(item, index) in items" :key="`${index}-${item.act.id}`">
            <clipboard-icon style="opacity: 0.2"/>
            <span class="ml-2">{{ item.caregiver === 'BVIDF' ? item.act.actName + ' - ' + item.validatedTimeStringFormat : item.act.actName }}</span>
          </li>
        </ul>
      </div>

      <div class="d-flex justify-content-center my-3" v-if="isEditable">
        <button class="btn btn-primary" v-if="!editMode" @click.prevent="editPrestation">Modifier</button>
        <button class="btn btn-primary" v-if="editMode" @click.prevent="savePrestation">Valider</button>
      </div>
    </template>
  </custom-modal>
</template>
<script>
import CustomModal from "@/components/Util/Modal";
import {mapActions, mapMutations} from "vuex";

import {
  ClockIcon,
  MapPinIcon,
  CalendarIcon,
  ClipboardIcon,
} from "vue-feather-icons";
import {dateFormat} from "@/components/Util/UtilFunction";

export default {
  components: {
    CustomModal,
    ClockIcon,
    CalendarIcon,
    MapPinIcon,
    ClipboardIcon,
  },
  props: {
    show: {
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    placesStr(){
      return this.items.reduce((places, act) => {
        if(places.includes(act.place)){
          return places;
        }
        places.push(act.place);
        return places;
      }, []).reduce((val, place) => {
        if(val === ''){
          return place;
        }
        return `${val}, ${place}`;
      }, '');
    },
  },
  data() {
    return {
      form: {},
      editable: false,
      periodOptions: [
        { hour: 1, label: "matin" },
        { hour: 2, label: "matin" },
        { hour: 3, label: "après-midi" },
        { hour: 4, label: "après-midi" },
        { hour: 5, label: "soirée" },
        { hour: 6, label: "soirée" },
        { hour: 7, label: "nuit" },
        { hour: 8, label: "nuit" },
      ],
      placeOptions: [
        { label: "Cuisine" },
        { label: "Salon" },
        { label: "Salle de bain" },
        { label: "Chambre" },
        { label: "Extérieur" },
      ],
      periodList: [
        {text: "Lever", value: "Lever"},
        {text: "Matin", value: "Matin"},
        {text: "Midi", value: "Midi"},
        {text: "Après-midi", value: "Après-midi"},
        {text: "Soirée", value: "Soirée"},
        {text: "Nuit", value: "Nuit"}
      ],
      dayList: [
        {text: "Lundi", value: "Lun"},
        {text: "Mardi", value: "Mar"},
        {text: "Mercredi", value: "Mer"},
        {text: "Jeudi", value: "Jeu"},
        {text: "Vendredi", value: "Ven"},
        {text: "Samedi", value: "Sam"},
        {text: "Dimanche", value: "Dim"}
      ],
      listEntourage: ["Conjoint", "Famille", "Voisin"],
      listAAD: ["Infirmier", "Emploi direct", "Autre SAAD"],
      editMode: false,
      activePeriod: null,
      activeDay: null
    };
  },
  methods: {
    ...mapActions('Evaluation', ['applyDaysOnActs']),
    ...mapMutations('Evaluation', ['setApplyDaySuccess']),
    close() {
      this.editable = false;
      this.$emit("close");
    },
    formatedDate(val) {
      return dateFormat(val, true)
    },
    sumModalActTime() {
      let sum = 0;
      this.items.forEach((act) => {
        sum += act.validatedTimeIntFormat
      })
      return sum
    },
    editPrestation() {
      this.editMode = true;
    },
    savePrestation() {
      const planIds = this.items.map((plan) => plan.id);
      const weekDays = [...this.items[0].weekDays];
      weekDays.splice(this.items[0].weekDays.indexOf(this.dayList.find((el) => el.text === this.items['daySelected']).value), 1);
      weekDays.push(this.activeDay);

      this.setApplyDaySuccess({ids: planIds, selectedDays: weekDays, period: this.activePeriod});
      this.applyDaysOnActs({ids: planIds, selectedDays: weekDays, period: this.activePeriod}).then(() => {
        this.$notify.success("Prestation modifiée")
      }).catch((err) => {
        this.$notify.error("Erreur lors de la modification de la prestation");
        throw err;
      });
      this.editMode = false;
      this.close();
    }
  },
  mounted() {
    this.activePeriod = this.items[0].period;
    this.activeDay = this.dayList.find((el) => el.text === this.items['daySelected']).value;
  }
};
</script>