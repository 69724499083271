import moment from "moment";

export const DEVIS_STATUS = 'devis';
export const CONTRACT_STATUS = 'contract';
export default {
    cancelPromise: new Map(),
    evaluation: {
        id: null,
        structureId: null,
        structure: {},
        agencyId: null,
        agency: {},
        structureName: '',
        clientId: '',
        evaluateBy: {},
        evaluateById: '',
        demandDate: moment(new Date()).format('YYYY-MM-DD'),
        evaluationDate: moment(new Date()).format('YYYY-MM-DD'),
        startingDate: moment(new Date()).format('YYYY-MM-DD'),
        endingDate: moment(new Date()).format('YYYY-MM-DD'),
        additionalInfos: '',
        habits: [],
        risks: [],
        acts: [],
        carePlanPrestations : [],
        acts_ids: [],
        materials_ids: [],
        answerList: [],
        evaluationHasActList: [],
        evaluationHasPrestationList: [],
        aggirPercentage: 0,
        evaluatorComments: "",
        weekDays: [],
        contract: {},
        gir: 0,
        seanceDetails: null,
        heuresSemaine: null,
        heuresDimanche: null
    },
    evaluations: [],
    generalQuestions: [],
    generalAnswer: {},
    aggirAnswer: {},
    loading: false,
    error: null,
    habits: [],

    aivqTimeSum: null,
    aivqPrestationCost: null,
    aivqPriceWeek: null,
    aivqAddedRow: [],

    avqTimeSum: null,
    avqPrestationCost: null,
    avqPriceWeek: null,
    avqAddedRow: [],

    total: 0,
    risk: {},

    defautSeanceOrder: [
        {
            period: "Lever",
            acts: []
        },
        {
            period: "Matin",
            acts: []
        },
        {
            period: "Midi",
            acts: []
        },
        {
            period: "Après-midi",
            acts: []
        },
        {
            period: "Soirée",
            acts: []
        },
        {
            period: "Nuit",
            acts: []
        }
    ],
    heuresSem: 0,
    heuresDim: 0
}
