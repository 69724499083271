<template>
  <div class="container pdv--container offre-container">
    
    <div class="header">
      <h1>Liste des prestations validées</h1>
      <span>{{paginateData.total}} {{paginateData.total > 1 ? "prestations validées" : "prestation validée"}}</span>
    </div>

    <div class="search-bar">
      <i class="fa fa-search"></i>
      <input type="search" v-model="keyword" placeholder="Rechercher" @change="searchByName()">
    </div>

    <div class="prestation-grid">
      <table>
        <tbody>
          <tr v-for="(prestation, index) in paginateData.prestations"
              :key="index" :class="checkedPrestations.includes(prestation.id) ? 'selected' : ''">
            <td>
              <div>
                <span class="aggirgrid--check-item" :class="checkedPrestations.includes(prestation.id) ? 'blue' : ''" @click="selectPrestation(prestation.id, prestation.name)"></span>
              </div>
            </td>

            <td>
              <b>{{prestation.name}}</b>
            </td>

            <td>
              <span>Temps estimé: </span>
              <span>{{calculTempsEstime(prestation, aggirgridItems)}} min</span>
            </td>

            <td>
              <span>Temps validé: </span>
              <span>{{prestation.time}} min</span>
            </td>

            <td class="actions-cell">
              <div v-show="!checkedPrestations.includes(prestation.id)">
                <button class="edit-button" @click="updatePrestation(prestation.id)"><i class="fa fa-pencil"></i></button>
                <button class="delete-button" @click="deletePrestation(prestation.id, prestation.name, prestation)"><i class="fa fa-trash"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <span v-show="paginateData.total == 0">Aucune prestation trouvée</span>

    <div class="footer" v-show="paginateData.total > 0">
      <div class="pagination-cardinals">
        <span>{{paginateData.from + 1}} - {{paginateData.to + 1}} sur {{paginateData.total}}</span>
      </div>
      <div class="delete-button">
        <button v-show="checkedPrestations.length > 0" @click="deletePrestation()">Supprimer la sélection</button>
      </div>
      <div class="pagination-nav">
        <span>Afficher</span>
        <input type="number" v-model="limit" min=1 :max="paginateData.total">
        <span>par page</span>
        <button :disabled="page<=1" @click="page-=1"><i class="fa fa-arrow-left"></i></button>
        <button :disabled="page==Math.ceil(paginateData.total/limit)" @click="page+=1"><i class="fa fa-arrow-right"></i></button>
      </div>
    </div>


    <div class="row group-by-period" v-for="(row, key) in dataRows" :key="key">
      <div class="col-sm-12 col-lg-4">
        <PDVCard v-if="row.avqActs"
                 :title="row.period"
                 class="mt-3"
                 :acts="row.avqActs"
                 @applyDay="postActDay">
          <template v-for="(acts, index) in getAvqActListPlace">
            <PDVCardItem
                :key="index"
                :title="index"
                :lieu="index"
                :acts="acts"
                :indexCheck="row.period"
                @changeTime="changeEvalHasAct"/>
          </template>
          <p class="mt-5">
            <span class="lieu" style="font-size: 16px;font-weight: bold;">Total / Prestation :
              {{ dateFormat(row.avqActs.reduce((nb, item) => nb += item.validatedTimeIntFormat, 0)) }}</span>
          </p>
          <p>
            <span class="lieu" style="font-size: 16px;font-weight: bold;">Total / Semaine :
              {{ dateFormat(row.avqActs.reduce((nb, item) => nb += item.validatedTimeIntFormat, 0)
                  * (row.avqActs[0].weekDays ? row.avqActs[0].weekDays.length : 0)) }}
            </span>
          </p>
        </PDVCard>
      </div>

      <div class="col-sm-12 col-lg-4">
        <PDVCard v-if="row.aivqActs"
                 :title="row.period"
                 class="mt-3"
                 :acts="row.aivqActs"
                 @applyDay="postActDay">
          <template v-for="(acts, index) in getAivqActListPlace">
            <PDVCardItem
                :key="index"
                :title="index"
                :lieu="index"
                :acts="acts"
                :indexCheck="row.period"
                @changeTime="changeEvalHasAct"/>
          </template>
          <p class="mt-5">
            <span class="lieu" style="font-size: 16px;font-weight: bold;">Total / Prestation :
              {{ dateFormat(row.aivqActs.reduce((nb, item) => nb += item.validatedTimeIntFormat, 0)) }}</span>
          </p>
          <p>
            <span class="lieu" style="font-size: 16px;font-weight: bold;">Total / Semaine :
              {{ dateFormat(row.aivqActs.reduce((nb, item) => nb += item.validatedTimeIntFormat, 0)
                  * (row.aivqActs[0].weekDays ? row.aivqActs[0].weekDays.length : 0)) }}
            </span>
          </p>
        </PDVCard>
      </div>

      <div class="col-sm-12 col-lg-2">
        <PDVActionCard v-for="habit in row.habbits"
                       :key="habit.id"
                       :habitId="habit.id"
                       :title="habit.description"
                       :period="habit.period"
                       :place="habit.place"
                       :validatedTime="habit.validatedTime"
                       @changeTime="changeHabit"
                       @remove="removeHabit"/>
      </div>

      <div class="col-sm-12 col-lg-2 font-weight-bold pdv-card mt-3">
        <h6 class="pdv-card--title text-break text-center" style="font-size: 18px;">{{row.totalTime}} /sem</h6>
      </div>
    </div>
  </div>
</template>
<script>
import PDVCard from "@/components/Eval/PDVCard";
import PDVActionCard from "@/components/Eval/PDVActionCard";
import PDVCardItem from "@/components/Eval/PDVCardItem";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {dateFormat} from "@/components/Util/UtilFunction";
import {TEMPS_SCORE, ORD_DAYS} from "@/components/Eval/CalculTempsEstime";

export default {
  name: "ProjetDeVieOffre",
  components: {
    PDVCard,
    PDVActionCard,
    PDVCardItem,
  },
  data() {
    return {
      showOffrePage: false,
      keyword: '',
      checkedPrestations: [],
      checkedPrestationsNames: {},
      limit: 5,
      page: 1,
      dataPrestations: null,
      selectedPrestations: []
    };
  },
  props: {
    structureId: {
      type: Number,
      default: null
    }
  },
  mounted() {
    if(this.evaluation.risks) {
      if(this.evaluation.risks.filter(item => item.checked).length === 0) {
        this.showOffrePage = true
      }
    }
    this.getCarePlanPrestationList({filters: '{"column" : "evaluationId", "val" : ' + this.evaluation.id + '}'});
  },
  computed: {
    ...mapGetters('Evaluation', ['getActEvaluationFromId', 'getHabitEvaluationFromId', 'getValidatedTimeSum', 'getAvqActList', 'getAvqActListPlace', 'getAivqActList', 'getAivqActListPlace', 'getEstimatedTimeSumByPeriod', 'getValidatedTimeSumByPeriod']),
    ...mapState('Evaluation', ['evaluation']),
    ...mapState('Prestation', ['prestations']),
    ...mapState('Act', ['acts']),
    ...mapState("CarePlanPrestation", ["carePlanPrestations", "total"]),
    ...mapState("AggirQuestion", ["aggirQuestions"]),
    periods() {
      const avqPeriods = Object.keys(this.getAvqActList);
      const aivqPeriods = Object.keys(this.getAivqActList);
      const habitPeriods = this.evaluation.habits ? this.evaluation.habits.map(item => {return item.period}) : [];
      let periods = avqPeriods.concat(aivqPeriods.concat(habitPeriods));
      const order = ['Lever', 'Matin', 'Midi', 'Après-midi', 'Soirée', 'Nuit'].filter(item=>periods.indexOf(item)>-1);
      periods = order.concat(periods);
      return periods.filter(function (item, index) {
        return periods.indexOf(item) === index;
      });
    },
    dataRows() {
      return this.periods.map(item=>{
        return {
          period: item,
          avqActs: this.getAvqActList[item],
          aivqActs: this.getAivqActList[item],
          habbits: this.evaluation.habits ? this.evaluation.habits.filter(h=>h.period === item) : [],
          totalTime: this.getValidatedTimeSumByPeriod(item)
        }
      })
    },
    paginateData() {
      let indexMin = this.limit * this.page - this.limit;
      let min = indexMin;
      let max = this.limit * this.page - 1;
      let indexMax = 0;
      if(this.dataPrestations) {
        indexMax = (max <= this.dataPrestations.length - 1) ? max : this.dataPrestations.length -1;
      }else{
        indexMax = (max <= this.total - 1) ? max : this.total -1;
      }
      let resData = [];
      while(min <= indexMax) {
        this.dataPrestations ? resData.push(this.dataPrestations[min]) : resData.push(this.carePlanPrestations[min]);
        min += 1;
      }
      return {
        from: indexMin,
        to: indexMax,
        prestations: resData,
        total: this.dataPrestations ? this.dataPrestations.length : this.total
      }
    },
    aggirgridItems() {
      const gridItems = [];

      for (let question of this.aggirQuestions) {
        let scores = this.getQuestionScores(question);

        const item = {
          id: question.id,
          resA: scores ? scores.resA : null,
          resB: scores ? scores.resB : null,
        }
        gridItems.push(item);
      }

      return gridItems;
    },
  },
  methods: {
    ...mapActions('Evaluation', ['saveHabitEvaluation', 'applyDaysOnActs', 'postAssignHabit', 'updateHeuresSemaineDimanche']),
    ...mapMutations('Evaluation', ['setApplyDaySuccess', 'setRemoveHabit', 'setHeureSem', 'setHeureDim']),
    ...mapActions('CarePlanPrestation', ['getCarePlanPrestationList', 'deleteCarePlanPrestation']),
    dateFormat,
    groupBy(array, key, cate, twice = false) {
      return array.reduce((acts, val) => {
        if (twice) {
          val.forEach((v) => {
            if (v.act.category === cate && v.active) {
              (acts[v[key]] = acts[v[key]] || []).push(v);
            }
          })
          return acts
        } else {
          if (val.act.category === cate && val.active) {
            (acts[val[key]] = acts[val[key]] || []).push(val);
            return acts
          } else {
            return acts
          }
        }
      }, {});
    },
    actsIsActive(el) {
      return !!el.active
    },
    changeEvalHasAct(act) {
      let link = this.getActEvaluationFromId(act.id);
      if(!act.time){
        act.time = '00:00';
      }
      let tab = act.time.split(':');
      let hour = parseInt(tab[0]);
      let minutes = parseInt(tab[1]);
      link.validatedTimeIntFormat =  hour * 60 + minutes;
      link.validatedTimeStringFormat = act.time;
      link.evaluationId = this.$route.params.evaluationId;
      link.actId = link.act.id;
      this.postEvaluationHasAct({link: link}).then(
          //this.$notify.success("Durée enregistrée avec succès.")
      ).catch(() => {
        this.$notify.error("Impossible d'enregistrer la durée.")
      })
    },
    changeHabit(habit) {
      let evalHabit = this.getHabitEvaluationFromId(habit.id)
      evalHabit.validatedTime = habit.time
      this.saveHabitEvaluation({habit: evalHabit}).then(
          //this.$notify.success("Fréquence enregistrée avec succès.")
      ).catch(() => {
        this.$notify.error("Impossible d'enregistrer l'action.")
      })
    },
    removeHabit(id) {
      this.setRemoveHabit(id);
      this.postAssignHabit().then(() => {
        this.$notify.success('Habitude supprimée.');
      }).catch(() => {
        this.$notify.error("La suppression a échouée.");
      });
    },
    postActDay(acts) {
      this.setApplyDaySuccess({ids: acts.ids, selectedDays: acts.selectedDays, period: acts.period})
      this.applyDaysOnActs({ids: acts.ids, selectedDays: acts.selectedDays, period: acts.period}).then(
        //this.$notify.success("Jour sélectionné enregistré avec succès.")
      )
    },
    close() {
      this.showOffrePage = false;
    },
    showRisksModal() {
      this.showOffrePage = false;
      this.$parent.showRisksModal();
    },
    searchByName() {
      let res = [];
      if(this.keyword.trim().length > 0) {
        let key = this.keyword.toLowerCase();
        res = this.carePlanPrestations.filter((prestation) => {
          let prestName = prestation.name.toLowerCase();
          return prestName.includes(key) || key.includes(prestName);
        });
      }else{
        res = this.carePlanPrestations;
      }
      this.dataPrestations = res;
    },
    selectPrestation(prestationId, prestationName) {
      if(this.checkedPrestations.includes(prestationId)) {
        this.checkedPrestations = this.checkedPrestations.filter(elt => {
          return elt != prestationId;
        });
        delete this.checkedPrestationsNames[prestationId];
      }else{
        this.checkedPrestations.push(prestationId);
        this.checkedPrestationsNames[prestationId] = prestationName;
      }
      this.carePlanPrestations.map(carePlan => {
        if(this.checkedPrestations.includes(carePlan.id)) {
          this.selectedPrestations.push(carePlan);
        }
      });
    },
    deletePrestation(prestationId = null, prestationName = null, prestation = null) {
      if(prestationId) {
        this.$confirm('Êtes vous sûr de vouloir supprimer la prestation "'+prestationName.toUpperCase()+'" ?').then(() => {
            this.deleteCarePlanPrestation({id: prestationId}).then((res) => {
              this.$notify.success('la prestation "'+prestationName.toUpperCase()+'" a été supprimée avec succès');
              this.removeDeletedPrestationTime(prestation);
              res;
            }).catch((error) => {
              this.$notify.error('Impossible de supprimer la prestation "'+prestationName.toUpperCase()+'": '+error);
            });
        }).catch(() => {});
      }else{
        let listNames = '"';
        this.carePlanPrestations.map((elt) => {
          listNames += this.checkedPrestations.includes(elt.id) ? ' ,'+elt.name.toUpperCase() : '';
        });
        listNames += '"';
        this.$confirm('Êtes vous sûr de vouloir supprimer les prestations '+listNames+'?').then(() => {
            
            let deletionList = this.checkedPrestations;
            for(let i=0; i<deletionList.length; i++) {
              this.deleteCarePlanPrestation({id: deletionList[i]}).then((res) => {
                this.checkedPrestations = this.checkedPrestations.filter(elt => {
                  return elt != deletionList[i];
                });
                this.$notify.success('les prestations "'+this.checkedPrestationsNames[deletionList[i]].toUpperCase()+'" a été supprimée avec succès');
                this.removeDeletedPrestationTime(this.selectedPrestations[i]);
                res;
              }).catch((error) => {
                this.$notify.error('Impossible de supprimer la prestation "'+this.checkedPrestationsNames[deletionList[i]].toUpperCase()+'": '+error);
              });
            }
        }).catch(() => {});
      }
    },
    updatePrestation(prestationId) {
      this.$router.push({
        name: 'autonomy',
        params:{
          carePlanPrestation: prestationId
        }
      });
    },
    getQuestionScores(question) {
      let resA = null;
      let resB = null;
      let subItemCount = this.aggirQuestions.filter(q => q.item === question.item).length;
      subItemCount = subItemCount === 0 ? 1 : subItemCount;

      const answer = this.evaluation.answerList.find(answer => answer.question.id === question.id);

      if (!answer) {
        return {
            id: question.id,
            resA: resA,
            resB: resB,
            total: subItemCount
        }
      }

      return {
        id: question.id,
        resA: answer.subItemScore,
        resB: answer.itemScore,
        total: subItemCount
      }

    },
    calculTempsEstime(prestation, gridItems) {
      let tempEstimcalc = 0;
      let prestTypeAide = JSON.parse(prestation.typeAide);
      if(gridItems.length > 0) {
        Object.keys(prestTypeAide).map(key => {
            let gridElt = gridItems.filter(gridItem => {
              return gridItem.id == key;
            });
            let gridEltScore = gridElt[0].resA;
            if(gridEltScore && gridEltScore !== 'A') {
              tempEstimcalc += TEMPS_SCORE[key][gridEltScore];
            }
        });
      }

      return Math.ceil(tempEstimcalc);
    },
    removeDeletedPrestationTime(prestation) {
      let schedule = JSON.parse(prestation.recurrency);
      let data = schedule;
      let fiveDays = 0;
      let holDays = 0;
      Object.keys(data).map(week => {
        data[week].map(day => {
          fiveDays = ORD_DAYS.includes(day) ? fiveDays+1 : fiveDays;
          holDays = day === 'dimanche' ? holDays +1 : holDays;
        });
      });

      let hs = Math.ceil(prestation.time * fiveDays * 2.166);
      let hd = Math.ceil(prestation.time * holDays * 2.166);

      this.setHeureDim(-1 * hd);
      this.setHeureSem(-1 * hs);

      this.updateHeuresSemaineDimanche().then((result) => {
        this.$notify.success('Le temps de prestation de l\'évaluation a été mis à jour');
        result;
      }).catch((err) => {
        console.error(err);
        this.$notify.error("Erreur de sauvegarde du temps estimé");
      });
    }
  }
};
</script>