<template>
  <div class="bo--agence row mx-0 justify-content-center">
    <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
      <div class="d-flex align-items-center">
        <h1 class="bo--agence-title">Liste des interventions</h1>
        <span class="bo--agence-title-notification info">Pas de synchronisation</span>
        <span class="bo--agence-title-count">{{total}} {{total>1 ? 'éléments' : 'élément'}}</span>
      </div>
      <div class="col-md-12">
        <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
      </div>
      <Grid ref="grid"
            id="InterventionList"
            :columns="headerColumns"
            :dataList="interventionList"
            :sortColumn="sortColumn"
            :sortDirection="sortDirection"
            :currentPage="currentPage"
            :loading="loading"
            :total="total"
            :canEditOrDelete="false"
            @updateCurrentPage="limitCo => limit = limitCo"
            @dataLoading="dataLoading"
            @editComponent="editIntervention"
            @changeHeaderAutocomplete="filterManager"
      />
    </div>
  </div>
</template>

<script>
  import Grid from "@/components/Grid/Grid";
  import {MixinStructureHeader} from "@/components/Util/MixinHeader";
  import {mapState, mapActions, mapGetters} from 'vuex';
  import moment from 'moment';

  export default {
    name: "InterventionList",
    mixins: [MixinStructureHeader()],
    components: {
      Grid
    },
    data() {
      return {
        columns: [
          {name: 'Date', value: 'createdDate', column: 'createdAt', sortable: true},
          {name: 'Client', value: 'clientName', column: 'client', sortable: true},
          {name: 'Salarié', value: 'intervenantName', column: 'intervenant', sortable: true},
          {name: 'Heure d\'entrée prévue', value: 'dateStart', column: 'dateStart', sortable: true},
          {name: 'Heure de sortie prévue', value: 'dateEnd', column: 'dateEnd', sortable: true},
          {name: 'Date de clôture de l\'intervention', value: 'sendHour', column: 'send_hour', sortable: true},
          {name: 'Calcul du temps d’heure prévue', value: 'diffStartEnd', column: 'diffStartEnd', sortable: false},
          {name: 'Commentaires de la famille', value: 'familyComments', column: 'family_comments', sortable: true},
          {name: 'Problème de badge', value: 'hasNoBadge', column: 'has_no_badge', sortable: false},
          {name: 'Statut', value: 'status', column: 'status', sortable: true, list: [{label: 'Tous', value: ""}, {label: 'Créé', value: 0}, {label: 'Commencé', value: 1}, {label: 'Fini', value: 2}]},
          {name: 'Client absent', value: 'hasAbsence', column: 'has_absence', filterOnly: true, type: 'select', list: [{label: 'Tous', value: ""}, {label: 'Vrai', value: 1}, {label: 'Faux', value: 0}]},
          {name: 'Défaut de badgeage', value: 'hasNoBadge', column: 'has_no_badge', filterOnly: true, type: 'select', list: [{label: 'Tous', value: ""}, {label: 'Vrai', value: 1}, {label: 'Faux', value: 0}]},
          {name: 'Signalement', value: 'signalement_type', column: 'signalement_type', filterOnly: true, type: 'multi-select', list: [
              'Elimination - Urinaire',
              'Elimination - Selles',
              'Comportement',
              'Alimentation',
              'Respiratoire',
              'Peau',
              'Déplacements',
              'Douleurs',
              'Orientation',
              'Environnement',
            ]
          }
        ],
        statusLabel: ['Créé', 'Commencé', 'Fini'],
        sortColumn: 'createdAt',
        sortDirection: 'ASC',
        limit: 10,
        currentPage: 1,
        removeableIntervention: null,
        userQuery: ''
      };
    },
    computed: {
      ...mapState("Intervention", ["loading", "interventions", "total", "error"]),
      ...mapState("User", ["users", 'connectedUser']),
      ...mapGetters('User', ['isUserRoleOperationManager']),
      //headerColumns(){},headerColumns is set in mixin MixinStructureHeader
      tdStyle() {
        //return `width:${(100 / this.columns.length).toFixed(1)}%`;
        return `width:110px`;
      },
      interventionList() {
        const statusLabel = this.statusLabel;
        return this.interventions.map(function (item) {
          let diffStartEnd = null;
          if (item.dateStart && item.dateEnd) {
            diffStartEnd = moment.utc(moment(item.dateEnd).diff(moment(item.dateStart))).format("HH:mm");
          }
          return {
            ...item,
            clientName: item.client ? item.client.firstname + ' ' + item.client.lastname : null,
            intervenantName: item.intervenant ? item.intervenant.firstName + ' ' + item.intervenant.lastName : null,
            dateStart: item.dateStart ? moment(item.dateStart).utcOffset('+0000').format('DD/MM/YYYY HH:mm') : null,
            dateEnd: item.dateEnd ? moment(item.dateEnd).utcOffset('+0000').format('DD/MM/YYYY HH:mm') : null,
            sendHour: item.sendHour ? moment(item.sendHour).utcOffset('+0000').format('DD/MM/YYYY HH:mm') : null,
            diffStartEnd: diffStartEnd,
            createdDate: item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : null,
            hasNoBadge: item.hasNoBadge ? 'Oui' : 'Non',
            status: statusLabel[item.status]
          }
        });
      },
    },
    mounted: function () {
      this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : 10;
      if (this.interventions.length === 0) {
        this.dataLoading({limit: this.limit, offset: this.limit * (this.currentPage -1)});
      }
    },
    methods: {
      ...mapActions("Intervention", ["getInterventionList"]),
      ...mapActions("User", ["getUserList"]),
      filterManager(q) {
          this.userQuery = q;
      },
      dataLoading(data) {
        this.getInterventionList(data);
      },
      refresh() {
        this.currentPage = 1;
        this.sortColumn = "createdAt";
        this.sortDirection = "ASC";
        this.getInterventionList({
          limit: this.limit,
          offset: this.limit * (this.currentPage -1),
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        });

        this.$refs.grid.refreshFilter();
      },
      editIntervention(id) {
        this.$router.push({name: 'intervention-form', params: {id: id}});
      }
    }
  };
</script>