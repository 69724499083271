export default {
    typeAide: {
        id: '',
        name: '',
    },
    typeAides: [],
    loading: false,
    error: null,
    total: 0
}
