import {client as ApolloClient} from '@/api/Apollo';
import { ALERTS } from '@/api/GraphQLQueries/bo/alert/alerts-query'
import { SEEN_ALERT } from '@/api/GraphQLQueries/bo/alert/seen-alert-mutation'
import { DELETE_ALERT } from '@/api/GraphQLQueries/bo/alert/delete-alert-mutation'

export const getAlerts = ({ commit }, {
    filters = '{}',
    sortColumn = '',
    sortDirection = '',
    limit = 100,
    offset = 0 }) => {
    return ApolloClient.query({
        query: ALERTS,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setAlerts', res.data.alerts.items);
        return res.data;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
};

export const putSeenAlert = ({ commit }, {id}) => {
    return ApolloClient.mutate({
        mutation: SEEN_ALERT,
        variables: {id: id}
    }).then((res) => {
        commit('setPutSeenAlertSuccess', res.data.seenAlert);
        return res.data;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
};

export const deleteAlert = ({ commit }, { id }) => {
    return ApolloClient.mutate({
        mutation: DELETE_ALERT,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setDeleteAlertSuccess', res.data.deleteAlert);
        return res.data;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
};

export default {
    getAlerts,
    putSeenAlert,
    deleteAlert
};