<template>
  <div class="aggirgrid-stch aggirgrid-stch-td">
    <div class="aggirgrid-stch--item" @click="validateAnswer('s')">
      <span class="aggirgrid--check-item" :class="answerS ? (answerS ? 'checked' : '') : ''" ></span>
    </div>
    <div class="aggirgrid-stch--item" @click="validateAnswer('t')">
      <span class="aggirgrid--check-item" :class="answerT ? (answerT ? 'checked' : '') : ''"></span>
    </div>
    <div class="aggirgrid-stch--item" @click="validateAnswer('c')">
      <span class="aggirgrid--check-item" :class="answerC ? (answerC ? 'checked' : '') : ''"></span>
    </div>
    <div class="aggirgrid-stch--item" @click="validateAnswer('h')">
      <span class="aggirgrid--check-item" :class="answerH ? (answerH ? 'checked' : '') : ''"></span>
    </div>
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "GridAnswerStch",
  data() {
    return {
      answerStch: {
        s: null,
        t: null,
        c: null,
        h: null
      }
    }
  },
  props: {
    id: {
      type: Number
    }
  },
  methods: {
    ...mapMutations("Evaluation", ["setEvaluationAnswerProperty"]),
    validateAnswer(key) {

      if(this.answerStch[key] === null) {
        switch (key) {
          case 's':
            this.answerStch[key] = this.answerS;
            break;
          case 't':
            this.answerStch[key] = this.answerT;
            break;
          case 'c':
            this.answerStch[key] = this.answerC;
            break;
          default:
            this.answerStch[key] = this.answerH;
            break;
        }
      }
      this.answerStch[key] = !this.answerStch[key];



      const answerSend = {
        id: this.answerId,
        questionId: this.id,
        answerStch: JSON.stringify({
          s: this.answerStch.s === null ? this.answerS : this.answerStch.s,
          t: this.answerStch.t === null ? this.answerT : this.answerStch.t,
          c: this.answerStch.c === null ? this.answerC : this.answerStch.c,
          h: this.answerStch.h === null ? this.answerH : this.answerStch.h
        })
      }

      this.setEvaluationAnswerProperty(answerSend);
      this.$emit('validateAnswer', answerSend);
    }
  },
  computed: {
    ...mapState("Evaluation", ["evaluation"]),
    answerId: {
      get() {
        const answer = this.evaluation.answerList.find((answer) => answer.question.id === this.id);
        return answer ? answer.id : 0
      }, set() {

      }
    },
    answerS: {
      get() {
        const answer = this.evaluation.answerList.find((answer) => answer.question.id === this.id);
        return answer ? JSON.parse(answer.answerStch).s : null
      },
      set(value) {
        this.answerStch.s = value;
      }
    },
    answerC: {
      get() {
        const answer = this.evaluation.answerList.find((answer) => answer.question.id === this.id);
        return answer ? JSON.parse(answer.answerStch).c : null
      },
      set(value) {
        this.answerStch.c = value;
      }
    },
    answerT: {
      get() {
        const answer = this.evaluation.answerList.find((answer) => answer.question.id === this.id);
        return answer ? JSON.parse(answer.answerStch).t : null
      },
      set(value) {
        this.answerStch.t = value;
      }
    },
    answerH: {
      get() {
        const answer = this.evaluation.answerList.find((answer) => answer.question.id === this.id);
        return answer ? JSON.parse(answer.answerStch).h : null
      },
      set(value) {
        this.answerStch.h = value;
      }
    }
  }
};
</script>