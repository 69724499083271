import gql from 'graphql-tag'

export const GET_USER_WITH_FORGOTTEN_PASSWORD = gql`
    query getUserWithForgottenPassword(
        $id: String!,
    ) {
        userWithForgottenPassword(
            id: $id
        ) {
            id
        }
    }
`
