import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from "apollo-link-error";
import { InMemoryCache } from 'apollo-cache-inmemory';
//import { DedupLink } from "apollo-link-dedup";
import {API_URL} from "@/components/Util/EnvVariable";

//const dedupLink = new DedupLink();

let errorLink = onError(({ graphQLErrors }) => {
    console.error("graphQLErrors", graphQLErrors);
});

const httpLink = createHttpLink({
    uri: `${API_URL}/graphql`,
    credentials: 'include'
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Mode: 'no-cors'
        }
    }
});

export let client = new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            errorPolicy: 'ignore',
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    }
});

export const setErrorHandler  = (router) => {
    errorLink = onError(({ graphQLErrors, networkError }) => {
        if(networkError && networkError.response && networkError.response.status === 401) {
            console.info('redirect on login page => ', (graphQLErrors && graphQLErrors[0] ? graphQLErrors[0].message : null));
            router.push({name: "login"});
        }
    });

    client = new ApolloClient({
        link: authLink.concat(errorLink).concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                errorPolicy: 'ignore',
                fetchPolicy: 'no-cache',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        }
    });
};

export default {
    client,
    setErrorHandler
};