import {gql} from "apollo-boost";

export default {
    getConnectedUser: gql`
      {
        connectedUser{
            id
            email
            lastName
            firstName
            role {
                id
                label
            }
            structure {
              id
              name
            }
        }
      }
    `,
}