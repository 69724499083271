import gql from 'graphql-tag'

export const DELETE_RISK = gql`
    mutation deleteRisk(
        $evaluationId: Int!,
        $riskId: Int!
    ) {
        deleteRisk(
            evaluationId: $evaluationId,
            riskId : $riskId
        ) {
            id,
            risks{
                id,
                risk{
                    id,
                    name,
                }
                checked
            }
        }
    }
`
