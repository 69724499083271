import gql from 'graphql-tag'

export const STRUCTURE_LIST = gql`
    query structureList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!
    ) {
        structureList(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                id,
                name,
                address,
                status,
                createdAt,
                createdBy{
                    id,
                    firstName,
                    lastName
                },
                updatedAt,
                updatedBy{
                    id,
                    firstName,
                    lastName
                },
                agencies{
                    id,
                    companyName
                }
            },
            total
        }
    }
`
