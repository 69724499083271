export default {
    setAgencyProperty(state, property) {
        if(!state.agency[property.name]){
            state.agency[property.name] = "";
        }
        state.agency[property.name] = property.value;
    },
    setAgencyLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setAgencySaveLoading(state) {
        state.error = null;
    },
    setAgencyList(state, agencies) {
        state.agencies = agencies
        state.loading = false;
        state.error = null;
    },
    setTotalAgencyList(state, total) {
        state.total = total;
    },
    setAgencySuccess(state, agency) {
        state.agency = {
            ...agency,
            structureId: agency && agency.structure ? agency.structure.id : null,
            structureName: agency && agency.structure ? agency.structure.name : null
        };
        state.loading = false;
        state.error = null;
    },
    setInitAgency(state) {
        state.agency = {
            structureId: '',
            structureName: '',
            apologicCode: null,
            companyName: '',
            legalForm: '',
            status: false,
            implementationName: '',
            address: '',
            postalCode: '',
            city: '',
            phoneNumber: '',
            fax: '',
            capital: '',
            rcs: '',
            siret: '',
            tvaintercom: '',
            agsnum: '',
            agqnumbis: '',
            certifnf: '',
            autorisationNum: '',
            finess: ''
        };
        state.error = null;
      },
    setAgencyError(state, error) {
        state.loading = false;
        state.error = error;
    },
    setPostAgencySuccess(state, agency) {
        state.agencies.push(agency);
        state.error = null;
        state.total = state.total + 1
    },
    setPutAgencySuccess(state, agency) {
        state.agencies.splice(
            state.agencies.findIndex(agencySearch => agencySearch.id === agency.id),
            1,
            agency
        );
        state.error = null;
    },
    setDeleteAgencySuccess(state, id) {
        state.agencies.splice(
            state.agencies.findIndex(agency => agency.id == id),
            1
        );
        state.loading = false;
        state.error = null;
        state.total = state.total - 1
    },
}