export default {
    setMaterialLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setMaterialList(state, materials) {
        state.materials = materials
        state.loading = false;
        state.error = null;
    },
    setTotalMaterialList(state, total) {
        state.total = total;
    },
    setMaterialError(state, error) {
        state.loading = false;
        state.error = error;
    },
    setMaterialProperty(state, property) {
        if(!state.material[property.name]){
            state.material[property.name] = "";
        }
        state.material[property.name] = property.value;
    },
    setMaterialSaveLoading(state) {
        state.error = null;
    },
    setMaterialSuccess(state, material) {
        if (state.materials.findIndex(materialSearch => materialSearch.id === material.id) > -1) {
            state.materials.splice(
                state.materials.findIndex(materialSearch => materialSearch.id === material.id),
                1,
                material
            );
        }
        state.material = {
            ...material,
            actIds: material.acts ? material.acts.map(item=>item.act.id) : []
        };
        state.loading = false;
        state.error = null;
    },
    setInitMaterial(state) {
        state.material = {
            ref: '',
            name: '',
            acts: [],
            actIds:[],
            description: '',
            status: false,
            photoUrl: '',
            videoUrl: ''
        };
        state.error = null;
    },
    setPostMaterial(state) {
        state.error = null;
    },
    setPostMaterialSuccess(state, material) {
        state.materials.push(material);
        state.error = null;
        state.total = state.total + 1
    },
    setPutMaterialSuccess(state, material) {
        state.materials.splice(
            state.materials.findIndex(materialSearch => materialSearch.id === material.id),
            1,
            material
        );
        state.error = null;
    },
    setPostMaterialPhoto(state) {
        state.error = null;
    },
    setPostMaterialPhotoSuccess(state) {
        state.error = null;
    },
    setPostMaterialPhotoError(state, error) {
        state.error = error;
    },
    setPostMaterialVideo(state) {
        state.error = null;
    },
    setPostMaterialVideoSuccess(state) {
        state.error = null;
    },
    setPostMaterialVideoError(state, error) {
        state.error = error;
    },
    setDeleteMaterialSuccess(state, id) {
        state.materials.splice(
            state.materials.findIndex(material => material.id == id),
            1
        );
        state.loading = false;
        state.error = null;
        state.total = state.total - 1
    },
}