import gql from 'graphql-tag'

export const DELETE_MATERIAL = gql`
    mutation deleteMaterial(
        $id: Int!,
    ) {
        deleteMaterial(
            id: $id
        )
    }
`