<template>
  <div class="soin-item">
    <div class="soin-item--title" @click.prevent="toggleActive">
      <h1>Cognition, Mémoire, Dépression</h1>
      <button class="btn" :class="{'inverted': !active}">
        <img src="@/assets/chevron-up.svg" alt />
      </button>
    </div>
    <div class="soin-item--content">
      <eval-card-check
          :showContentSlot="false"
          :showMainSlot="false"
          :checked="smd03 !== null && smd03 !== ''"
          title="Je vais vous donner 3 mots à retenir et je vous les redemanderai tout à l'heure. (Citron, Clé, Ballon)"
          v-if="active"
          v-slot:main/>

      <eval-card-check
          :showContentSlot="true"
          :checked="smd02 !== null && smd02 !== ''"
          title="Voici un cercle, qui représente une horloge. Pouvez-vous y écrire les heures (seules 12-3-6-9 ok), puis dessiner 11 heures 10 (pas besoin de préciser si petite ou grande aiguille)"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="smd02" name id>
                  <option value="r1">Dessin réussi</option>
                  <option value="r2">Dessin non réussi</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="true"
          :checked="smd03 !== null && smd03.length > 0"
          title="Pouvez-vous me redonner les 3 mots que je vous ai cité tout à l'heure ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form">
              <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[]">
                <template slot="content">
                  <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label:'Citron'},
                      {value:'r2', label:'Clé'},
                      {value:'r3', label:'Ballon'}]"
                       :key="`smd03-${index}`">
                    <input class="id-check--input"
                           :id="`smd03-option-${index}`"
                           type="checkbox"
                           :value="option.value"
                           v-model="smd03"/>
                    <label :for="`smd03-option-${index}`">
                      <span class="id-check--indicator">{{option.label}}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showMainSlot="true"
          :has-check="false"
          class="theme-red"
          title="Interprétation mini cog">
        <template v-slot:main>
          {{ brainRisk }}
        </template>
      </eval-card-check>

      <eval-card-check
          :showMainSlot="true"
          :has-check="false"
          class="theme-red"
          title="Résultat du test de dépistage cognitif du programme ICOPE.">
        <template v-slot:main>
          {{ ICOPECognition }}
        </template>
      </eval-card-check>

      <eval-card-check
          :showContentSlot="true"
          :checked="smd04 !== null && smd04 !== ''"
          title="Vous sentez-vous découragé et triste ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="smd04" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
        <eval-card-check
            :showContentSlot="true"
            :checked="smd05 !== null && smd05 !== ''"
            title="Avez-vous le sentiment que votre vie est vide, que vous avez perdu l'intérêt ou le plaisir de faire les choses ?"
            v-if="active">
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="smd05" name id>
                    <option value="r1">Oui</option>
                    <option value="r2">Non</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>

      <eval-card-check
          :showMainSlot="true"
          :has-check="false"
          class="theme-red"
          title="Résultat du test de dépistage des symptômes dépressifs ICOPE.">
        <template v-slot:main>
          {{ RSMD05 }}
        </template>
      </eval-card-check>

        <eval-card-check
            :showContentSlot="true"
            :checked="smd06 !== null && smd06 !== ''"
            title="Êtes-vous heureux la plupart du temps ?"
            v-if="active">
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="smd06" name id>
                    <option value="r1">Oui</option>
                    <option value="r2">Non</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="smd07 !== null && smd07 !== ''"
          title="Avez-vous l'impression que votre situation est désespérée ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="smd07" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
          :showMainSlot="true"
          :has-check="false"
          class="theme-red"
          title="Résultat du test mini GDS (Gériatric Depressive Syndrome).">
        <template v-slot:main>
          {{ gds }}
        </template>
      </eval-card-check>

    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
  import SubQuestionExtend from "@/views/Evaluation/Soin/SubQuestionExtend";
  import {miniCogCalcul, miniGdsCalcul, getICOPECognition, getRSMD05} from "@/components/Eval/SoinCalcul";
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";

  export default {
    extends: SubQuestionExtend,
    components: {
      EvalFormCheckAlt
    },
    methods: {
      ...mapMutations('Evaluation', ['setTreatmentProperty']),
      ...mapActions('Evaluation', ['saveTreatment']),
    },
    computed: {
      ...mapState('Evaluation', ['brain']),
      ...mapGetters('AggirQuestion', [
        'getScoreQuestionAggirOrientationTemps',
        'getScoreQuestionAggirOrientationEspace',
      ]),
      brainRisk(){
        const miniCog = miniCogCalcul(this.getPeopleStr, this.smd03, this.smd02);
        if(miniCog) {
          return miniCog.interpretation;
        }
        return 'Veuillez compléter toutes les informations pour avoir une interprétation des résultats.';
      },
      ICOPECognition(){
        if(!this.smd03){
          return 'Veuillez compléter toutes les informations pour avoir une interprétation des résultats.';
        }
        return getICOPECognition(this.smd03,
            this.getScoreQuestionAggirOrientationTemps,
            this.getScoreQuestionAggirOrientationEspace);
      },
      RSMD05(){
        return getRSMD05(this.smd04, this.smd05);
      },
      gds(){
        return miniGdsCalcul(this.getPeopleStr, this.smd04, this.smd05, this.smd06, this.smd07).interpretation;
      },
      smd02: {
        get() {
          return this.brain.smd02.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'smd02',
            value
          });
          this.saveTreatment({id: "brain", name: "smd02", evaluationId: this.$route.params.evaluationId});
        }
      },
      smd03: {
        get() {
          return this.brain.smd03.response ? this.brain.smd03.response.split(',') : [];
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'smd03',
            value: value.join(',')
          });
          this.saveTreatment({id: "brain", name: "smd03", evaluationId: this.$route.params.evaluationId});
        }
      },
      smd04: {
        get() {
          return this.brain.smd04.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'smd04',
            value
          });
          this.saveTreatment({id: "brain", name: "smd04", evaluationId: this.$route.params.evaluationId});
        }
      },
      smd05: {
        get() {
          return this.brain.smd05.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'smd05',
            value
          });
          this.saveTreatment({id: "brain", name: "smd05", evaluationId: this.$route.params.evaluationId});
        }
      },
      smd06: {
        get() {
          return this.brain.smd06.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'smd06',
            value
          });
          this.saveTreatment({id: "brain", name: "smd06", evaluationId: this.$route.params.evaluationId});
        }
      },
      smd07: {
        get() {
          return this.brain.smd07.response;
        },
        set(value) {
          this.setTreatmentProperty({
            name: 'smd07',
            value
          });
          this.saveTreatment({id: "brain", name: "smd07", evaluationId: this.$route.params.evaluationId});
        }
      },
    }
  }
</script>