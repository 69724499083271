<script>
import {mapActions, mapState} from "vuex";
import SubQuestionExtend from "@/views/Evaluation/Soin/SubQuestionExtend";
import {formatCivility} from "@/components/Util/UtilFunction";

export default {
    name: "CR_Component",
    extends: SubQuestionExtend,
    computed: {
      ...mapState("Evaluation", ["evaluation"]),
      getPeopleStr(){
        return `${this.getCivilityStr} ${this.client.firstname} ${this.client.lastname}`;
      }
    },
    methods: {
        formatCivility,
        ...mapActions("Evaluation", ["putEvaluation"]),
        getQuestionAnswers(questionId) {
            const answer = this.evaluation.generalQuestionsAnswers ? this.evaluation.generalQuestionsAnswers.find(el => el.generalQuestion.id === questionId) : null;

            let response = {
                list: [this.DEFAULT_VALUE],
                responsesIds: null
            };
            if (!answer) {
                return response;
            }
            switch (answer.generalQuestion.type) {
                case 'radio': {
                    const ans = answer.generalQuestion.responses ? answer.generalQuestion.responses[answer.answers[0]] : this.DEFAULT_VALUE;
                    response = {
                        list: ans ? [ans] : response.list,
                        responsesIds: answer.answers ? answer.answers : null
                    }
                    break;
                }
                case 'checkbox': {
                    if (answer.answers) {
                        const answers = answer.answers.map(ans => answer.generalQuestion.responses[ans]);
                        response = answers ? answers : response;
                        response = {
                            list: answers ? answers : response.list,
                            responsesIds: answer.answers ? answer.answers : null
                        }
                    }
                    break;
                }
                default: //type text
                    response.list = answer.text ? [answer.text] : response.list;
                    break;
            }
            return response;
        },
        calculateCategoryAverage(concernedQuestionId) {
            let sum = 0;
            let nbAdded = 0;
            for (let answer of this.evaluation.answerList) {
                if (concernedQuestionId.includes(answer.question.id) && answer.itemScore) {
                    switch (answer.itemScore) {
                        case 'A':
                            sum += 100;
                            nbAdded++;
                            break;
                        case 'B':
                            sum += 50;
                            nbAdded++;
                            break;
                        case 'C':
                            nbAdded++;
                            break;
                        default:
                            break;
                    }
                }
            }
            if (nbAdded === 0) {
                return {
                    result: "en attente de réponses",
                    total: nbAdded === concernedQuestionId.length ? '' : `(incomplet ${nbAdded}/${concernedQuestionId.length})`
                }
            }

            const average = Math.floor(sum / nbAdded);
            let res = 'bon';

            if (average <= (100 / 3)) {
                res = 'mauvais';
            } else if (average <= (200 / 3)) {
                res = 'moyen';
            }

            return {
                result: res,
                total: nbAdded === concernedQuestionId.length ? '' : `(incomplet ${nbAdded}/${concernedQuestionId.length})`
            }
        },
        getAggirAnswerScore(questionId) {
            const answer = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === questionId) : null;
            if (answer) {
                return answer.itemScore
            }
            return null;
        },
        changeCommentaryValue() {
            this.putEvaluation();
        },
        stringifyCheckboxValues(answer, values) {
            const answers = answer.split(',').reduce((acc, res) => {
                if(values[res]) {
                  return [...acc, values[res]];
                }
                return acc;
            }, []);
            return answers.join(", ");
        }
    }
}

</script>