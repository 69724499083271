import gql from 'graphql-tag'

export const DELETE_TARIFICATION = gql`
    mutation deleteTarification(
        $id: Int!
    ) {
        deleteTarification(
            id: $id
        )
    }
`
