import {client as ApolloClient} from '@/api/Apollo';
import { AGENCY_LIST } from '@/api/GraphQLQueries/bo/agency/list-query'
import { GET_AGENCY } from '@/api/GraphQLQueries/bo/agency/get-agency-query'
import { SAVE_AGENCY } from '@/api/GraphQLQueries/bo/agency/save-agency-mutation'
import { DELETE_AGENCY } from '@/api/GraphQLQueries/bo/agency/delete-agency-mutation'

export const getAgencyList = ({ commit }, {
    filters = '{}',
    sortColumn = 'company_name',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setAgencyLoading');
    return ApolloClient.query({
        query: AGENCY_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setAgencyList', res.data.agencyList.items);
        commit('setTotalAgencyList', res.data.agencyList.total);
    }).catch((err) => {
        console.error(err);
        commit('setAgencyError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getAgency = ({ commit }, { id }) => {
    commit('setAgencyLoading');
    return ApolloClient.query({
        query: GET_AGENCY,
        variables: {
            id: id
        }
    }).then((res) => {
        if (res.errors) {
            console.error(res.errors);
            commit('setAgencyError', res.errors ? res.errors : null);
            throw res.errors;
        }
        commit('setAgencySuccess', res.data.agency);
        return res.data;
    })
};

export const postAgency = ({ commit, state }) => {
    commit('setAgencySaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_AGENCY,
        variables: {
            ...state.agency,
            id: 0
        }
    }).then((res) => {
        commit('setPostAgencySuccess', res.data.saveAgency);
        res.data;
    }).catch((err) => {
        console.error(err);
        commit('setAgencyError', err.response ? err.response.data : err);
        throw err;
    })
};

export const putAgency = ({ commit, state }) => {
    commit('setAgencySaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_AGENCY,
        variables: state.agency
    }).then((res) => {
        commit('setPutAgencySuccess', res.data.saveAgency);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setAgencyError', err.response ? err.response.data : err);
        throw err;
    })
};

export const deleteAgency = ({ commit }, { id }) => {
    commit('setAgencyLoading');
    return ApolloClient.mutate({
        mutation: DELETE_AGENCY,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setDeleteAgencySuccess', res.data.deleteAgency);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setAgencyError', err.response ? err.response.data : err);
        throw err;
    })
};

export default {
    getAgencyList,
    getAgency,
    putAgency,
    postAgency,
    deleteAgency
};