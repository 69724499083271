import gql from 'graphql-tag';

export const APPLY_DAYS_ON_ACTS = gql`
    mutation applyDayOnActs(
        $ids: [Int!]!,
        $selectedDays: [String!]!,
        $period: String!
    ) {
        applyDayOnActs(
            ids: $ids,
            selectedDays: $selectedDays,
            period: $period
        )
    }
`
