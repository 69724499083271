export default {
    getClient(state){
        return state.client;
    },
    getCivilityStr(state){
        if(!state.client.civility){
            return "";
        }
        return state.client.civility && state.client.civility === 'male' ? 'Monsieur' : 'Madame';
    }
};