import gql from 'graphql-tag'

export const GET_CONTRACT = gql`
    query contract(
        $id: Int!,
    ) {
        contract(
            id: $id
        ) {
            id,
            dateStart,
            dateEnd,
            erpId,
            statusErp,
            discount,
            priceByMonth,
            nbHourByMonth,
            client {
                id,
                lastname,
                firstname
            },
            agency {
                id,
                companyName,
                structure {
                    id,
                    name
                }
            },
            prestationList{
              id,
              acteLabel,
              acteDescription,
              nbHour,
              tva,
              priceByHourHT,
              priceByHourTTC,
              totalByMonthHT,
              totalByMonthTTC,
              schedule,
              recurrency,
              prestation{
                id
              }
            }
        }
    }
`
