import gql from 'graphql-tag'

export const SAVE_CONTRACT = gql`
    mutation saveContract(
        $id: Int,
        $evaluationId: Int!,
        $aivqDetails: String!,
        $avqDetails: String!,
        $totalAIVQMinutes: Float!,
        $totalAIVQCost: Float!,
        $totalAVQMinutes: Float!,
        $totalAVQCost: Float!,
        $pecDetails: String!,
        $displayPeriod: String!,
        $status: String,
        $description: String
    ) {
        saveContract(
            id: $id,
            evaluationId: $evaluationId,
            aivqDetails: $aivqDetails,
            avqDetails: $avqDetails,
            totalAIVQMinutes: $totalAIVQMinutes,
            totalAIVQCost: $totalAIVQCost,
            totalAVQMinutes: $totalAVQMinutes,
            totalAVQCost: $totalAVQCost,
            pecDetails: $pecDetails,
            displayPeriod: $displayPeriod,
            status: $status,
            description: $description
        ) {
            id
        }
    }
`;
