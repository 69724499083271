export default {
    contract: {
        contractId: null,
        agencyId: null,
        agency: {},
        clientId: null,
        client: {},
        dateStart: null,
        dateEnd: null,
        priceByMonth: "",
        nbHourByMonth: "",
        discount: null
    },
    prestations: [],
    contracts: [],
    loading: false,
    error: null,
    total: 0
}