export default {
    setContactLoading(state) {
        state.loading = true;
        state.error = false;
    },
    setContactSuccess(state, contact) {
        state.contact = {
            ...contact
        };
        state.loading = false;
        state.error = null;
    },
    setContactError(state, error) {
        state.loading = false;
        state.error = error;
    }
}