import gql from 'graphql-tag'

export const SAVE_GENERAL_QUESTION_ANSWER = gql`
    mutation saveGeneralQuestionAnswer(
        $evaluationId: Int!,
        $questionId: Int!,
        $answers: [Int!],
        $text: String,
    ) {
        saveGeneralQuestionAnswer(
            evaluationId: $evaluationId,
            questionId: $questionId,
            answers: $answers,
            text: $text
        ) {
            id,
            generalQuestionsAnswers {
                id,
                generalQuestion{
                    id,
                    responses,
                    type,
                    title,
                },
                answers,
                text
            },
        }
    }
`
