<template>
    <div class="bo--agence row mx-0 justify-content-center">
        <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
            <div class="d-flex align-items-center mb-5">
                <h1 class="bo--agence-title">Notification</h1>
            </div>

            <el-collapse accordion>
                <el-collapse-item v-for="(notificaion, title) in getNotificationByInterface" :key="`notif-${title}`" :name="title">
                    <template slot="title">
                        <p class="ml-2 font-weight-bold"><span class="mr-2">{{title}} interface </span> <font-awesome-icon :class="getIcon(notificaion)" :icon="['fas', getIcon(notificaion)]" /> </p>
                    </template>
                    <div v-for="(notif, index) in notificaion" :key="`notif-${title}-${index}`" :class="[notif.type, 'ml-2 pl-2 mt-2 mb-2']">
                      {{formatDate(notif.date)}} : {{notif.message}}
                    </div>
                </el-collapse-item>
            </el-collapse>

        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from "vuex";
    import Moment from "moment";

    export default {
        name: "NotificationView",
        computed:{
            ...mapGetters("Notification", ["getNotificationByInterface"]),
        },
        methods:{
            ...mapActions("Notification", ["getNotification"]),
            formatDate(date){
                return Moment(date).format("DD/MM/DDDD HH:mm");
            },
            getIcon(notification){
                let type = "success";
                for(let i = 0; i < notification.length; i++){
                    if(notification[i].type === "error"){
                        type = "error";
                        break;
                    }
                    if(notification[i].type === "warning"){
                        type = "warning";
                        break;
                    }
                }
                switch (type) {
                    case "error":
                        return "exclamation-error";
                    case "warning":
                        return "exclamation-triangle";
                    case "success":
                        return "check-circle";
                }
            }
        },
        created(){
            this.getNotification();
        }
    }
</script>
<style lang="scss">
    .check-circle,
    .success{
        color: #00800078;
    }
    .exclamation-error,
    .error{
        color: red;
    }
    .exclamation-triangle,
    .warning{
        color: orange;
    }
</style>