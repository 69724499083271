<template>
  <custom-modal :active="active" @close="close" class="planification-modal">
    <div class="eval-modal-title">
      <h2>Commentaire pour l'acte {{questionAggir ? questionAggir.name : ''}}</h2>
    </div>
    <div class="eval-modal-content">
        <textarea :value="this.commentaires[this.questionAggir.id]" @input="event => comment = event.target.value" cols="30" rows="5" placeholder="Ecrire un commentaire"></textarea>
    </div>
    <div class="save-prestation">
        <a href="#" @click.prevent="saveComment()">
          <span>Enregistrer</span>
        </a>
    </div>
  </custom-modal>
</template>
<script>
import {mapState} from "vuex";
export default {
  name: "CommentaireModal",
  data: () => ({
    comment: null
  }),
  props: {
    active: {
      type: Boolean,
    },
    questionAggir: {
        type: Object,
    }
  },
  computed: {
    ...mapState("CarePlanPrestation", ["commentaires"]),
    retrieveComment() {
        return this.questionAggir && this.commentaires[this.questionAggir.id] ? this.commentaires[this.questionAggir.id] : '';
    },
    questionAggirId() {
      return this.questionAggir ? this.questionAggir.id : 0;
    }
  },
  methods: {
    saveComment(){
      this.commentaires[this.questionAggirId] = this.comment
      this.comment = null
      this.close()
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>