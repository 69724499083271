<template>
  <div class="card-wrap">
    <div class="d-flex my-2 align-items-center">
      <h6 slot="title" class="mb-0 card-title" v-if="title">{{title}}</h6>
      <span class="post-card-title ml-2">
        <slot name="post-title" />
      </span>
    </div>
    <div class="card custom-card" :class="[cardClass]">
      <div class="card-body" :class="[cardBodyClass]">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Card",
  props: {
    cardClass: {
      type: String,
      default: null
    },
    cardBodyClass: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  }
};
</script>