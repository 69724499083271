import {client as ApolloClient} from '@/api/Apollo';
import { TARIFICATION_LIST } from '@/api/GraphQLQueries/bo/tarification/list-query'
import { GET_TARIFICATION } from '@/api/GraphQLQueries/bo/tarification/get-tarification-query'
import { SAVE_TARIFICATION } from '@/api/GraphQLQueries/bo/tarification/save-tarification-mutation'
import { DELETE_TARIFICATION } from '@/api/GraphQLQueries/bo/tarification/delete-tarification-mutation'

export const getTarificationList = ({ commit }, {
    filters = '{}',
    sortColumn = 'price',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setTarificationLoading');
    return ApolloClient.query({
        query: TARIFICATION_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setTarificationList', res.data.tarificationList.items);
        commit('setTotalTarificationList', res.data.tarificationList.total);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setTarificationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getTarification = ({ commit }, { id }) => {
    commit('setTarificationLoading');
    return ApolloClient.query({
        query: GET_TARIFICATION,
        variables: {
            id: id
        }
    }).then((res) => {
        if (res.errors) {
            console.error(res.errors);
            commit('setTarificationError', res.errors);
            throw res.errors;
        }
        commit('setTarificationSuccess', res.data.tarification);
        return res.data;
    })
};

export const postTarification = ({ commit, state }) => {
    commit('setTarificationSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_TARIFICATION,
        variables: {
            ...state.tarification,
            id: 0
        }
    }).then((res) => {
        commit('setPostTarificationSuccess', res.data.saveTarification);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setTarificationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const putTarification = ({ commit, state }) => {
    commit('setTarificationSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_TARIFICATION,
        variables: state.tarification
    }).then((res) => {
        commit('setPutTarificationSuccess', res.data.saveTarification);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setTarificationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const deleteTarification = ({ commit }, { id }) => {
    commit('setTarificationLoading');
    return ApolloClient.mutate({
        mutation: DELETE_TARIFICATION,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setDeleteTarificationSuccess', res.data.deleteTarification);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setTarificationError', err.response ? err.response.data : err);
        throw err;
    })
};

export default {
    getTarificationList,
    getTarification,
    putTarification,
    postTarification,
    deleteTarification
};