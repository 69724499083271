<template>
    <div class="bo--agence row mx-0 justify-content-center">
        <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
            <div class="d-flex align-items-center">
                <h1 class="bo--agence-title">Liste des contrats</h1>
                <span class="bo--agence-title-notification error" v-if="!synchronized">Non synchronisé</span>
                <span class="bo--agence-title-notification success" v-else>Synchronisé</span>
                <span class="bo--agence-title-count">{{total}} {{total>1 ? 'éléments' : 'élément'}}</span>
            </div>
            <div class="col-md-12">
                <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
            </div>
            <Grid ref="grid"
                  id="ContractList"
                  :columns="headerColumns"
                  :dataList="contractList"
                  :sortColumn="sortColumn"
                  :sortDirection="sortDirection"
                  :currentPage="currentPage"
                  :loading="loading"
                  :total="total"
                  :readOnly="readOnly"
                  :autocomplete="autocomplete"
                  @updateCurrentPage="limitCo => limit = limitCo"
                  @dataLoading="dataLoading"
                  @editComponent="editContract"
            />
        </div>
    </div>
</template>

<script>
    import Grid from "@/components/Grid/Grid";
    import {MixinStructureHeader} from "@/components/Util/MixinHeader";
    import {mapState, mapActions, mapGetters} from 'vuex';
    import moment from 'moment';

    export default {
        name: "ContractList",
        mixins: [MixinStructureHeader()],
        components: {
            Grid
        },
        data() {
            return {
                columns: [
                    {name: "Structure", value: 'structure', column: 'structure.id', sortable: true},
                    {name: "Agence", value: 'agency', column: 'agency.company_name', sortable: true},
                    {name: "Client", value: 'client', column: 'client.firstname', sortable: true},
                    {name: "Date de début", value: 'dateStart', column: 'dateStart', sortable: true},
                    {name: "Date de fin", value: 'dateEnd', column: 'dateEnd', sortable: true},
                    {name: "Nb heure", value: 'nbHourByMonth', column: 'nbHourByMonth', sortable: true},
                    {name: "Prix", value: 'priceByMonth', column: 'nbHourByMonth', sortable: true},
                    /*{name: "Date de modification", value: 'updatedDate', column: 'updatedAt', sortable: true},
                    {name: "Modifié par", value: 'updatedUser', column: 'updatedBy', sortable: false},*/
                    {name: "Statut", value: 'statusErp', column: 'statusErp', sortable: true, list: [
                            {label: 'Tous', value: ""},
                            {label: 'Devis envoyé', value: "Devis envoyé"},
                            {label: 'Contrat validé', value: "Contrat envoyé"},
                            {label: 'Contrat envoyé', value: "Contrat validé"},
                            {label: 'Devis en Création', value: "Devis en Création"},
                            {label: 'Contrat en Création', value: "Contrat en Création"}
                        ]}
                ],
                sortColumn: '',
                sortDirection: 'ASC',
                limit: 10,
                currentPage: 1,
                readOnly: true,
                autocomplete: false
            };
        },
        computed: {
            // ...mapState("Client", ["loading", "agencies", "total", "error"]),
            ...mapState("Contract", ["loading", "contracts", "total", "error"]),
            ...mapGetters("Notification", ["getNotificationByInterface"]),
            //headerColumns(){},headerColumns is set in mixin MixinStructureHeader
            synchronized() {
                return this.getNotificationByInterface.Contract && this.getNotificationByInterface.Contract.reduce((value, line) => {
                    if (line.type !== "success") {
                        value = false;
                    }
                    return value;
                }, true);
            },
            tdStyle() {
                //return `width:${(100 / this.columns.length).toFixed(1)}%`;
                return `width:110px`;
            },
            contractList() {
                return this.contracts.map(function (item) {
                    return {
                        ...item,
                        structure: item.agency.structure ? item.agency.structure.name : "",
                        agency: item.agency.companyName,
                        client: item.client ? item.client.firstname + ' ' + item.client.lastname : null,
                        updatedUser: item.updatedBy ? item.updatedBy.firstName + ' ' + item.updatedBy.lastName : null,
                        createdDate: item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : null,
                        updatedDate: item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : null,
                        dateStart: item.dateStart ? moment(item.dateStart).format('DD/MM/YYYY'): null,
                        dateEnd: item.dateEnd ? moment(item.dateEnd).format('DD/MM/YYYY'): null,
                        status: item.status ? 'Actif' : 'Inactif'
                    }
                });
            }
        },
        mounted: function () {
            this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : 10;
            if (this.contracts.length == 0) {
                this.dataLoading({limit: this.limit, offset: this.limit * (this.currentPage -1)});
            }
        },
        methods: {
            ...mapActions("Contract", ["getContractList"]),
            // ...mapActions("Client", ["getUserList"]),
            filterManager(q) {
                this.userQuery = q;
            },
            dataLoading(data) {
                this.getContractList(data);
            },
            refresh() {
                this.currentPage = 1;
                this.sortColumn = "";
                this.sortDirection = "ASC";
                this.getContractList({
                    limit: this.limit,
                    offset: this.limit * (this.currentPage -1),
                    sortColumn: this.sortColumn,
                    sortDirection: this.sortDirection,
                });

                this.$refs.grid.refreshFilter();
            },
            editContract(id) {
                this.$router.push({name: 'contract-form', params: {id: id}});
            }
        }
    };
</script>