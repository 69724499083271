<template>
  <b-modal id="sign" :no-close-on-backdrop="true" :centered="true" size="xl">
    <loader :loader="loadingSign"/>
    <template v-slot:modal-title>
      Signer le devis
    </template>

    <iframe v-if="url" width="100%" height="100%" style="min-height: 500px;" :src="url"></iframe>

    <template v-slot:modal-footer>
      <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('sign')">Retour</b-button>
      <!--<b-button class="mt-2" variant="btn col-3 btn-primary" @click.prevent="signProcessEnd">Terminer</b-button>-->
    </template>
    <!--<script src="https://app.universign.com/sig/embed.js"></script>-->
  </b-modal>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Sign",
  props: {
    contractId: Number
  },
  components: {},
  data: () => ({
    loadingSign: true,
    url: null
  }),
  computed: {
    ...mapState('Evaluation', ['evaluation']),
  },
  methods: {
    ...mapActions('Contract', ['sendToSign', 'closeSign']),
    signDevis(){
      this.$bvModal.show('sign');
      this.loadingSign = true;
      this.sendToSign({id: this.contractId}).then((data) => {
        this.url = data.url;
        this.loadingSign = false;
      }).catch((err) => {
        console.error(err);
        this.$bvModal.hide('sign');
        this.$emit('end');
        this.$notify.error('Erreur lors de la tentative de demande de signature.');
        this.loadingSign = false;
        throw err;
      });
    },
    /*signProcessEnd(){
      this.loadingSign = true;
      this.closeSign({id: this.contractId}).then((data) => {
        this.evaluation.contract.status = data.status;
        this.$bvModal.hide('sign');
        this.$emit('end');
        this.loadingSign = false;
      }).catch((err) => {
        console.error(err);
        this.$notify.error('Erreur lors de la tentative de clôture de la signature.');
        this.loadingSign = false;
      });
    }*/
  }
}
</script>