<template>
  <eval-wrap class="id-page" title="Projet de vie">
    <template v-slot:title>
      <div class="d-flex align-items-center">
        <nav class="nav eval--nav pdv--nav" ref="pdv--nav">
          <router-link class="nav-link" :to="{name:'project-life'}">Offre</router-link>
          <router-link class="nav-link" :to="{name:'planning'}">Planning</router-link>
        </nav>
      </div>
    </template>
    <transition name="fade" mode="out-in">
      <router-view v-if="evaluation.structureId" :structureId="evaluation.structureId"/>
    </transition>
  </eval-wrap>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ProjetDeVie",
  data() {
    return {
      links: ["project-life", "planning"],
      width:null,
    };
  },
  computed: {
    ...mapState('Evaluation', ['evaluation']),
    style() {
      var s
      if(this.routeName === 'project-life'){
        s = 20
      } else if(this.routeName === 'planning'){
        s = 102
      }
      return `transform:translateX(${s}px)`;
    },
    routeName() {
      return this.$route.name
    },
  },
  methods: {
    ...mapActions('Evaluation', ['getEvaluation']),
  },
};
</script>