import {client as ApolloClient} from '@/api/Apollo';
import {post} from '@/api/Fetch';
import UserSecurityQuery from '@/api/GraphQLQueries/bo/user/userSecurityQuery';
import {USER_LIST} from "@/api/GraphQLQueries/bo/user/userListQuery";
import {GET_USER, GET_ROLE_LIST} from "@/api/GraphQLQueries/bo/user/get-user-query";
import {SAVE_USER} from "@/api/GraphQLQueries/bo/user/save-user-mutation";
import {DELETE_USER} from "@/api/GraphQLQueries/bo/user/delete-user-mutation";
import {EDIT_USER_PASSWORD} from "@/api/GraphQLQueries/bo/user/edit-user-password-query";
import {API_URL} from "@/components/Util/EnvVariable";
import {SEND_RESET_PASSWORD_LINK} from "@/api/GraphQLQueries/bo/user/send-reset-password-link";
import router from "@/router";
import {GET_USER_WITH_FORGOTTEN_PASSWORD} from "@/api/GraphQLQueries/bo/user/get-user-with-forgotten-password";
import {UPDATE_USER} from "@/api/GraphQLQueries/bo/user/update-user-mutation";

export const getUserList = ({ commit }, {
    filters = '{}',
    sortColumn = 'lastName',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setUserLoading');
    return ApolloClient.query({
        query: USER_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then(res => {
        commit('setUserList', res.data.userList.items)
        commit('setTotalUserList', res.data.userList.total)
        return res.data
    }).catch((err) => {
        console.error(err);
        commit('setUserError', err.response ? err.response.data : null)
        throw err
    })
}

export const getUser = ({ commit }, { id }) => {
    commit('setUserLoading');
    return ApolloClient.query({
        query: GET_USER,
        variables: {
            id: id
        }
    }).then((res) => {
        if (res.errors) {
            console.error(res.errors);
            commit('setUserError', res.errors);
            throw res.errors
        }
        commit('setUserSuccess', res.data.user);
        return res.data
    })
};

export const getUserWithForgottenPassword = ({ commit }, { id }) => {
    commit('setUserLoading');
    return ApolloClient.query({
        query: GET_USER_WITH_FORGOTTEN_PASSWORD,
        variables: {
            id: id
        }
    }).then((res) => {
        if (res.errors) {
            console.error(res.errors);
            commit('setUserError', res.errors);
            throw res.errors
        }
        commit('setUserSuccess', res.data.userWithForgottenPassword);
        return res.data
    })
};

export const getRoleList = ({ commit }) => {
    return ApolloClient.query({
        query: GET_ROLE_LIST
    }).then((res) => {
        commit('setRoleList', res.data.roleList);
        return res.data
    }).catch((err) => {
        console.error(err);
        commit('setUserError', err.response ? err.response.data : null);
        throw err
    })
};

export const postUser = ({ commit, state }) => {
    commit('setUserSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_USER,
        variables: {
            ...state.user,
            id: Math.floor(Math.random() * 100)
        }
    }).then((res) => {
        commit('setPostUserSuccess', res.data.saveUser);
        return res.data
    }).catch((err) => {

        console.error(err);
        commit('setUserError', err.response ? err.response.data : null);
        throw err
    })
};

export const sendResetPasswordLinkOnForgot = ({ commit } , { email }) => {
    commit('setUserLoading');
    ApolloClient.mutate({
        mutation: SEND_RESET_PASSWORD_LINK,
        variables: {
            email: email
        }
    }).then(() => {
        window.alert(['Un mail de réinitialisation vous a été envoyé ! Si vous ne recevez rien dans la minute, vérifiez dans vos courriers indésirable.']);
        router.push('/login')
    }).catch((err) => {
        console.error(err)
        commit('setUserError', err.response ? err.response.data : null);
        throw err
    })
};

export const sendResetPasswordLinkOnCreate = ({ commit } , { email }) => {
    commit('setUserLoading');
    ApolloClient.mutate({
        mutation: SEND_RESET_PASSWORD_LINK,
        variables: {
            email: email
        }
    }).then(() => {
        commit('setSendUserPasswordSuccess');
    }).catch((err) => {
        console.error(err)
        commit('setUserError', err.response ? err.response.data : null);
        throw err
    })
};


export const putUser = ({ commit, state }) => {
    commit('setUserSaveLoading');
    return ApolloClient.mutate({
        mutation: UPDATE_USER,
        variables: state.user
    }).then((res) => {
        commit('setPutUserSuccess', res.data.updateUser);
        return res.data
    }).catch((err) => {
        commit('setUserError', err.response ? err.response.data : null);
        throw err
    })
};

export const deleteUser = ({ commit }, { id }) => {
    commit('setUserLoading');
    ApolloClient.mutate({
        mutation: DELETE_USER,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setDeleteUserSuccess', res.data.deleteUser);
        return res.data
    }).catch((err) => {
        console.error(err)
        commit('setUserError', err.response ? err.response.data : null);
        throw err
    })
};

export const editUserPassword = ({commit, state}, {password}) => {
    ApolloClient.mutate({
        mutation: EDIT_USER_PASSWORD,
        variables: {
            id: state.user.id,
            password: password
        }
    }).then((res) => {
        commit('setEditUserPasswordSuccess', res.data.editUserPassword)
        return res.data
    }).catch((err) => {
        commit('setEditUserPasswordError', err.response ? err.response.data : null)
        throw err
    })
}

export default {

    getUserList,
    getUser,
    getUserWithForgottenPassword,
    postUser,
    putUser,
    deleteUser,
    getRoleList,
    editUserPassword,
    sendResetPasswordLinkOnCreate,
    sendResetPasswordLinkOnForgot,

    loginUser({ commit }, {email, password}) {
        const options = {
            cache: 'no-cache',
            credentials: 'include',
            method: "post",
            body: JSON.stringify({
                email,
                password
            })
        }

        return fetch(`${API_URL}/login`, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 404) {
                        throw "error";
                    }
                    if (response.status === 401) {
                        throw "error";
                    }
                }
                return response
            })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    document.cookie = 'token=' + data.token
                }
                commit("setConnectedUser", data)
            })
            .catch((err) => {
                console.error(err);
                commit("setConnectedUser", null);
                throw err;
            });
    },

    logout({ commit }){
        return post(`${API_URL}/logout`).then((res) => {
            commit("setConnectedUser", null);
            return res;
        }).catch((err) => {
            console.error(err);
            commit("setConnectedUser", null);
            throw err;
        });
    },

    getUserConnected({commit}){
        return ApolloClient.query({query: UserSecurityQuery.getConnectedUser}).then((res) => {
            commit("setConnectedUser", res.data.connectedUser);
        }).catch((err) => {
            console.error(err);
            throw "User not connected";
        });
    }
};