<template>
    <div class="container pt-4">
      <h5 class="eval-item--title">Contexte de la demande</h5>
      <div class="eval-item--card cr--context">
        <div class="cr--context--part">
          <div class="row">
            <div class="col-sm-12 col-md-4 cr_entry">
              <p>Date de la demande: </p>
              <p class="response">{{this.evaluation.demandDate ? evaluationDates.demandDate : DEFAULT_VALUE}}</p>
            </div>
            <div class="col-sm-12 col-md-8 cr_entry">
              <p>Date et heure du RDV d'évaluation :</p>
              <p class="response">{{this.evaluation.evaluationDate ? evaluationDates.evaluationDate : DEFAULT_VALUE}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-4 cr_entry">
              <p>Nature de la demande :</p>
              <p class="response">{{demandNature}}</p>
            </div>
            <div class="col-sm-12 col-md-4 cr_entry">
              <p>Évenement ou situation de rupture :</p>
              <p class="response">{{ruptureEvent}}</p>
            </div>
            <div class="col-sm-12 col-md-4 cr_entry">
              <p>Origine de la demande :</p>
              <p class="response">{{demandOrigin}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 cr_entry">
              <p>Précisions sur l'origine de l'orientation vers nos services :</p>
              <p class="response">{{demandOriginComment}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 cr_entry">
              <p>Autres demandes en cours :</p>
              <p class="response">{{otherDemand}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 cr_entry">
              <p>Personne présente lors de l'évaluation :</p>
              <p class="response">{{peoplePresent}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 cr_entry">
              <p>Recueil de l'expression  de la personne :</p>
              <p class="response">{{clientExpression}}</p>
            </div>
          </div>
          <div class="row" v-if="tiersExpression.display">
            <div class="col-sm-12 cr_entry">
              <p>Recueil du commentaire  du tiers présent :</p>
              <p class="response">{{tiersExpression.resp}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p class="mb-0">Commentaires de l’évaluateur sur le contexte de la demande :</p>
              <form-textarea
                  v-model="demandComment"
                  :rows="3"
                  class="w-100"
                  :login-input="true"
                  :disabled="false"
                  @blur="changeCommentaryValue()" />
            </div>
          </div>
        </div>

        <p class="font-weight-bold">Description du plan d'aide en cours</p>
        <div class="row">
          <div class="col-md-6 col-sm-12 cr_entry">
            <p>Plan d’aide en  cours :</p>
            <p class="response">Aide légale: {{PEC01}} / Aide extra légale: {{PEC02}}</p>
          </div>
          <div class="col-md-6 col-sm-12 cr_entry">
            <p>Droits ouverts :</p>
            <p class="response">du: {{PEC05}}, au: {{PEC06}}</p>
            <p class="response"></p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Organisme financeur :</p>
            <p class="response">{{PEC03}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Numéro de dossier:</p>
            <p class="response">{{PEC04}}</p>
          </div>
          <div class="col-md-6 col-sm-12 cr_entry">
            <p>GIR retenu :</p>
            <p class="response">{{PEC07}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <table class="table pec-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Mode</th>
                  <th>H/Mois</th>
                  <th>Taux Horaire</th>
                  <th>% de participation Usager</th>
                  <th>Montant mensuel</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Heures semaine:</td>
                  <td>{{planMode}}</td>
                  <td>{{pecValues.HMoisSemaine}}</td>
                  <td>{{pecValues.tauxSemaine}}</td>
                  <td>{{pecValues.participationUsager}}</td>
                  <td>{{pecValues.monthlySemaine}}</td>
                </tr>

                <tr>
                  <td>Heures dimanche/férié:</td>
                  <td>{{planMode}}</td>
                  <td>{{pecValues.HMoisDF}}</td>
                  <td>{{pecValues.tauxDF}}</td>
                  <td>{{pecValues.participationUsager}}</td>
                  <td>{{pecValues.monthlyDF}}</td>
                </tr>

                <tr>
                  <td>Total:</td>
                  <td>{{planMode}}</td>
                  <td>{{pecValues.HMoisTotal}}</td>
                  <td>{{pecValues.tauxTotal}}</td>
                  <td>{{pecValues.participationUsager}}</td>
                  <td>{{pecValues.monthlyTotal}}</td>
                </tr>

                <tr>
                  <td>Autres éléments du plan d’aide:</td>
                  <td colspan="5">{{pecValues.planNote}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">Commentaires de l’évaluateur sur la prise en charge (préciser si le médecin traitant est informé de la démarche) :</p>
            <form-textarea
                v-model="pecComment"
                :rows="3"
                class="w-100"
                :login-input="true"
                :disabled="false"
                @blur="changeCommentaryValue()" />
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import {mapState} from "vuex";
import moment from "moment";
import CRComponent from "./CRComponent";

export default {
  name: "CR_Demand",
  extends: CRComponent,
  data: () => ({
    tableItem: [{
      "name": "Prestataire",
      "questionsIds": [46,47,48,49,50,51]
    },{
      "name": "Mandataire",
      "questionsIds": [52, 53, 54, 55, 56, 57]
    },{
      "name": "Gré à gré",
      "questionsIds": [58, 59, 60, 61, 62, 63]
    }
    ]
  }),
  created() {
    this.DEFAULT_VALUE = '--';
  },
  methods: {
    exportSynthesis() {
      return {
        demandDate: this.evaluation.demandDate ? this.evaluationDates.demandDate : this.DEFAULT_VALUE,
        evaluationDate: this.evaluation.evaluationDate ? this.evaluationDates.evaluationDate : this.DEFAULT_VALUE,
        demandNature: this.demandNature,
        ruptureEvent: this.ruptureEvent,
        demandOrigin: this.demandOrigin,
        demandOriginComment: this.demandOriginComment,
        otherDemand: this.otherDemand,
        peoplePresent: this.peoplePresent,
        clientExpression: this.clientExpression,
        tiersExpression: this.tiersExpression.display ? this.tiersExpression.resp : "Pas de tiers présent",
        PEC01: this.PEC01,
        PEC02: this.PEC02,
        PEC03: this.PEC03,
        PEC04: this.PEC04,
        PEC05: this.PEC05,
        PEC06: this.PEC06,
        PEC07: this.PEC07,
        demandComment: this.demandComment,
        planMode: this.planMode,
        HMoisSemaine: this.pecValues.HMoisSemaine,
        tauxSemaine: this.pecValues.tauxSemaine,
        monthlySemaine: this.pecValues.monthlySemaine,
        HMoisDF: this.pecValues.HMoisDF,
        tauxDF: this.pecValues.tauxDF,
        monthlyDF: this.pecValues.monthlyDF,
        HMoisTotal: this.pecValues.HMoisTotal,
        tauxTotal: this.pecValues.tauxTotal,
        monthlyTotal: this.pecValues.monthlyTotal,
        participationUsager: this.pecValues.participationUsager,
        planNote: this.pecValues.planNote,
        pecComment: this.pecComment
      }
    },
    toShowTable(question) {
      const cond = this.answers[45];
      if(!cond) return false;
      if (question === "Prestataire") {
        if(cond.includes("Prestataire")) return true;
      } else if (question === "Mandataire") {
        if(cond.includes("Mandataire")) return true;
      } else if (question === "Gré à gré") {
        if(cond.includes("Gré à gré")) return true;
      }
    }
  },
  computed: {
    ...mapState("Evaluation", ["evaluation", "generalQuestions"]),
    ...mapState("Client", ["client"]),
    evaluationDates() {
      return {
        demandDate: this.evaluation.demandDate ? moment(this.evaluation.demandDate).format("DD/MM/YYYY") : null,
        evaluationDate: this.evaluation.evaluationDate ? moment.utc(this.evaluation.evaluationDate).local(true).format("DD/MM/YYYY à HH:mm") : null
      }
    },
    demandNature() {
      return this.getQuestionAnswers(1).list.join(', ');
    },
    ruptureEvent() {
      return this.getQuestionAnswers(3).list.join(', ');
    },
    demandOrigin() {
      return this.getQuestionAnswers(2).list.join(', ');
    },
    demandOriginComment() {
      return this.getQuestionAnswers(65).list.join(', ');
    },
    otherDemand() {
      return this.getQuestionAnswers(4).list.join(', ');
    },
    peoplePresent() {
      const cond = this.getQuestionAnswers(5).list;
      if (cond && cond.includes('Non')) {
        return "Pas de tiers présent lors de l'évaluation"
      }
      const contactId = this.getQuestionAnswers(6).list.join(', ').trim();
      let isReferent = '';
      if (this.client.referent) {
        if (this.client.referent.id == contactId) {
          isReferent = ', personne référente';
        }
      }
      const contact = this.client.contactList.find(e=>e.id == contactId);
      return contact ? contact.firstName + ' ' + contact.lastName + ', ' + contact.phone + isReferent : this.DEFAULT_VALUE;
    },
    clientExpression() {
      const cond = this.getQuestionAnswers(8).list;
      if (cond && cond.includes('Non')) {
        return "La personne n’a pas eu la capacité de s’exprimer sur sa demande et son projet de vie"
      }
      return this.getQuestionAnswers(9).list.join(', ');
    },
    tiersExpression() {
      const cond = this.getQuestionAnswers(5).list;
      if (cond && cond.includes('Oui')) {
        return {
          resp: this.getQuestionAnswers(7).list.join(', '),
          display: true
        }
      }
      return {
        resp: 'le tiers présent ne s’est pas exprimé.',
        display: true
      }
    },
    PEC01() {
      const pec01 = this.getQuestionAnswers(37).list.join(', ');
      return pec01 ? pec01 : "";
    },
    PEC02() {
      const pec02 = this.getQuestionAnswers(38).list.join(', ');
      return pec02 ? pec02 : "";
    },
    PEC03() {
      const pec03 = this.getQuestionAnswers(39).list.join(', ');
      return pec03 ? pec03 : "";
    },
    PEC04() {
      const pec04 = this.getQuestionAnswers(40).list.join(', ');
      return pec04 ? pec04 : "";
    },
    PEC05() {
      const pec05 = this.getQuestionAnswers(41).list.join(', ');
      return moment(pec05).isValid() ? moment(pec05).format("DD/MM/YYYY") : this.DEFAULT_VALUE;
    },
    PEC06() {
      const pec06 = this.getQuestionAnswers(42).list.join(', ');
      return moment(pec06).isValid() ? moment(pec06).format("DD/MM/YYYY") : this.DEFAULT_VALUE;
    },
    PEC07() {
      return this.getQuestionAnswers(43).list.join(', ');
    },
    demandComment: {
      get() {
        return this.evaluation.evaluatorComments ? this.evaluation.evaluatorComments.demandComment : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.demandComment = value;
      }
    },
    pecComment: {
      get() {
        return this.evaluation.evaluatorComments ? this.evaluation.evaluatorComments.pecComment : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.pecComment = value;
      }
    },
    planMode() {
      return this.tableQuestions.map(e=>e.name).join(' / ');
    },
    pecValues() {
      let HMoisSemaine = '', HMoisDF = '', HMoisTotal = 0;
      let tauxSemaine = '', tauxDF = '', tauxTotal = 0;
      let monthlySemaine = '', monthlyDF = '', monthlyTotal = 0;

      const answers = this.answers;
      this.tableQuestions.forEach(q=>{
        HMoisSemaine += q.name + ': ' + (answers[q.questions[0].id] ? answers[q.questions[0].id] : this.DEFAULT_VALUE) + '  ';
        tauxSemaine += q.name + ': ' + (answers[q.questions[1].id] ? answers[q.questions[1].id] : this.DEFAULT_VALUE) + '  ';
        monthlySemaine += q.name + ': ' + (answers[q.questions[2].id] ? answers[q.questions[2].id] : this.DEFAULT_VALUE) + '  ';

        HMoisDF += q.name + ': ' + (answers[q.questions[3].id] ? answers[q.questions[3].id] : this.DEFAULT_VALUE) + '  ';
        tauxDF += q.name + ': ' + (answers[q.questions[4].id] ? answers[q.questions[4].id] : this.DEFAULT_VALUE)+ '  ';
        monthlyDF += q.name + ': ' + (answers[q.questions[5].id] ? answers[q.questions[5].id] : this.DEFAULT_VALUE) + '  ';

        HMoisTotal += (parseFloat(answers[q.questions[0].id]) || 0) +  (parseFloat(answers[q.questions[3].id]) || 0);
        tauxTotal += (parseFloat(answers[q.questions[1].id]) || 0) +  (parseFloat(answers[q.questions[4].id]) || 0);
        monthlyTotal += (parseFloat(answers[q.questions[2].id]) || 0) +  (parseFloat(answers[q.questions[5].id]) || 0);
      })
      return {
        HMoisSemaine: HMoisSemaine,
        tauxSemaine: tauxSemaine,
        monthlySemaine: monthlySemaine,
        HMoisDF: HMoisDF,
        tauxDF: tauxDF,
        monthlyDF: monthlyDF,
        HMoisTotal: HMoisTotal,
        tauxTotal: tauxTotal,
        monthlyTotal: monthlyTotal,
        participationUsager: answers[44] ? answers[44] : '',
        planNote: answers[64] ? answers[64] : ''
      }
    },
    tableQuestions() {
      const items = this.tableItem;
      const tableQuestions = [];
      for(let i = 0; i < items.length; i++) {
        const questions = this.generalQuestions.filter(e => items[i].questionsIds.includes(e.id))
        if(!questions || questions.length === 0) {
          return [];
        }
        if(this.toShowTable(items[i].name)) {
          tableQuestions.push({
            ...items[i],
            questions: questions
          })
        }
      }
      return tableQuestions;
    },
    answers() {
      const v = this.evaluation
      var answers = {}
      if (v.generalQuestionsAnswers) {
        v.generalQuestionsAnswers.forEach(e => {
          if (e.generalQuestion.type==='checkbox') {
            let answer = [];
            if (e.answers) {
              e.answers.forEach(t=> {
                answer.push(e.generalQuestion.responses[t]);
              })
            }
            answers[e.generalQuestion.id] = answer
          } else {
            answers[e.generalQuestion.id] = e.text
          }
        })
      }
      return answers;
    }
  }
};
</script>