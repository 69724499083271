import gql from 'graphql-tag'

export const SAVE_DEFAULT_AGGIR_ANSWERS = gql`
    mutation saveDefaultAggirAnswers(
        $evaluationId: Int!,
        $questionList: String!,
        $aggirPercentage: Int!
    ) {
        saveDefaultAggirAnswers(
            evaluationId: $evaluationId,
            questionList: $questionList,
            aggirPercentage: $aggirPercentage
        ) {
            items {
                answerStch,
                question {
                    id,
                    item
                },
                itemScore,
                subItemScore
            },
            total
        }
    }
`
