import {client as ApolloClient} from "@/api/Apollo";
import {FILTER_STRUCTURE_LIST} from "@/api/GraphQLQueries/bo/structure/filter-list-query";

export const getFilterStructureList = ({ commit }) => {
    return ApolloClient.query({
        query: FILTER_STRUCTURE_LIST,
    }).then((res) => {
        if(!res.data || !res.data.filterStructureList){
            throw "Structure list not found";
        }
        commit('setFilterStructures', res.data.filterStructureList);
        return res.data.filterStructureList;
    }).catch((err) => {
        console.error(err);
        throw err;
    })
};

export default {
    getFilterStructureList
}