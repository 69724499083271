import gql from "graphql-tag";

export const USER_LIST = gql`
    query userList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!

    ) {
        userList(
            filters: $filters
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                agencies {
                    id
                    companyName
                }
                structure {
                    id
                    name
                }
                id,
                email,
                lastName,
                firstName,
                city,
                cp,
                portablePhoneNumber,
                portableProfesionnalPhoneNumber
                sector,
                status,
                createdAt,
                createdBy {
                    id, 
                    email,
                    lastName,
                    firstName
                },
                contractEnd,
                contractType,
                contractHourByMonth
            },
            total
        }
    }
`

