<template>
    <el-pagination
          layout="total, sizes, prev, pager, next, ->"
          :pager-count="11"
          :total="total"
          :background="true"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="limit"
          :current-page.sync="page"
          @size-change="changePageSize"
          @current-change="changePage"
        />
</template>
<script>
export default {
    name: "Pagination",
    data: () => ({
        page: 1
    }),
    props:{
        total: {
            type: Number,
            default:()  => 0
        },
        limit: {
            type: Number,
            default:()  => 100
        },
        currentPage: {
            type: Number,
            default:()  => 1
        }
    },
    methods:{
        changePageSize(size) {
            let data = {offset: 0, limit: size};
            this.$emit('changePage', data);
        },
        changePage(page) {
            let offset = (page - 1) * this.limit;
            let data = {offset: offset, limit: this.limit};
            this.$emit('changePage', data);
        },
        resetPage() {
            this.page = 1;
            let data = {offset: 0, limit: this.limit};
            this.$emit('changePage', data);
        }
    }
};
</script>
