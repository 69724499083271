import gql from 'graphql-tag'

export const TYPE_AIDE_LIST = gql`
    query typeAideList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!
    ) {
        typeAideList(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                id,
                name
            },
            total
        }
    }
`
