import gql from 'graphql-tag'

export const GET_AGGIR_QUESTION = gql`
    query aggirQuestion(
        $id: Int!,
    ) {
        aggirQuestion(
            id: $id
        ) {
            id,
            placeRelated,
            item,
            subItem,
            openingStatement,
            itemPeriod,
            itemStch,
            timeType,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            }
        }
    }
`
