import gql from 'graphql-tag'

export const SAVE_SEANCE_DETAILS = gql`
    mutation saveSeanceDetails(
        $evaluationId: Int!,
        $seanceDetails: String!
    ) {
        saveSeanceDetails(
            evaluationId: $evaluationId,
            seanceDetails: $seanceDetails
        ) {
            id,
            seanceDetails
        }
    }
`
