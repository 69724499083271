import gql from 'graphql-tag'

export const CLIENT_LIST = gql`
    query clientList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!
    ) {
        clientList(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                id,
                erpId
                statusSync
                folderNumber
                lastname
                firstname
                birthName
                civility
                address
                city
                postalCode
                sector
                housingAccess
                birthDate
                age
                socialSecurityNumber
                digicode
                howOpenDoor
                sitePicture
                homePhoneNumber
                portablePhoneNumber
                girNumber
                missing
                familyStatus
                csp
                protectiveMeasure
                additionalInformations
                reviewDate,
                reviewer{
                    id,
                    firstName,
                    lastName
                },
                createdAt,
                createdBy{
                    id,
                    firstName,
                    lastName
                },
                updatedAt,
                updatedBy{
                    id,
                    firstName,
                    lastName
                },
                structure{
                    id,
                    name
                }
                agency{
                    id,
                    companyName,
                    structure {
                        id
                        name
                    }
                },
                status,
                placePicture,
                clientSupportList {
                    clientPart,
                    financerPart,
                    dateEnd
                },
                evaluationNumber
            },
            total
        }
    }
`
