export default {
    setAlerts(state, alerts) {
        state.alerts = alerts;
    },
    setPutSeenAlertSuccess(state, alert) {
        state.alerts.splice(
            state.alerts.findIndex(alertSearch => alertSearch.id === alert.id),
            1,
            alert
        );
    },
    setDeleteAlertSuccess(state, id) {
        state.alerts.splice(
            state.alerts.findIndex(alert => alert.id == id),
            1
        );
    },
}