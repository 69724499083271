import {client as ApolloClient} from '@/api/Apollo';
import { CARE_PLAN_PRESTATION_LIST } from '@/api/GraphQLQueries/evaluation/care-plan-prestation/list-query';
import { SAVE_CARE_PLAN_PRESTATION } from '@/api/GraphQLQueries/evaluation/care-plan-prestation/save-care-plan-prestation';
import { DELETE_CARE_PLAN_PRESTATION } from '@/api/GraphQLQueries/evaluation/care-plan-prestation/delete-care-plan-prestation';
import {GET_CARE_PLAN_PRESTATION} from "@/api/GraphQLQueries/evaluation/care-plan-prestation/get-care-plan-prestation";

export const getCarePlanPrestationList = ({ commit }, {
    filters = '{}',
    sortColumn = 'care_plan_prestation.id',
    sortDirection = 'ASC',
    limit = 10,
    offset = 0
}) => {
    commit('setCarePlanPrestationLoading');
    return ApolloClient.query({
        query: CARE_PLAN_PRESTATION_LIST,
        variables: {
            filters: filters,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
            limit: limit,
            offset: offset
        }
    }).then((res) => {
        commit('setCarePlanPrestationList', res.data.carePlanPrestationList.items);
        commit('setTotalCarePlanPrestationList', res.data.carePlanPrestationList.total);
    }).catch((err) => {
        commit('setCarePlanPrestationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const postCarePlanPrestation = ({ commit, state }) => {
    commit('setCarePlanPrestationSaveLoading');
    return ApolloClient.mutate({
        mutation: SAVE_CARE_PLAN_PRESTATION,
        variables: {
            ...state.carePlanPrestation
        }
    }).then((res) => {
        commit('setPostCarePlanPrestationSuccess', res.data.saveCarePlanPrestation);
        return res.data;
    }).catch((err) => {
        console.error(JSON.stringify(err));
        commit('setCarePlanPrestationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const deleteCarePlanPrestation =  ({ commit }, { id }) => {
    commit('setCarePlanPrestationLoading');
    return ApolloClient.mutate({
        mutation: DELETE_CARE_PLAN_PRESTATION,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setDeleteCarePlanPrestationSuccess', res.data.deleteCarePlanPrestation);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setCarePlanPrestationError', err.response ? err.response.data : err);
        throw err;
    })
};

export const getCarePlanPrestation =  ({ commit }, { id }) => {
    commit('setCarePlanPrestationLoading');
    return ApolloClient.query({
        query: GET_CARE_PLAN_PRESTATION,
        variables: {
            id: id
        }
    }).then((res) => {
        commit('setCarePlanPrestation', res.data.carePlanPrestation);
        return res.data;
    }).catch((err) => {
        console.error(err);
        commit('setCarePlanPrestationError', err.response ? err.response.data : err);
        throw err;
    })
};



export default { getCarePlanPrestationList, postCarePlanPrestation, deleteCarePlanPrestation, getCarePlanPrestation }