<template>
  <custom-modal :active="active" @close="close" class="prestation-confirm-modal">
    <div class="eval-modal-content">
        <span class="aggirgrid--check-item blue"></span>
        <span>{{updated ? 'La prestation a bien été mise à jour' : 'Prestation enregistrée'}}</span>
    </div>
    <div class="action-buttons">
        <a href="#" @click.prevent="listPrestations()">
          <span>Afficher la liste des prestations</span>
        </a>
        <a href="#" @click.prevent="showPlanning()">
          <span>Afficher le planning</span>
        </a>
        <a href="#" @click.prevent="reloadView()">
          <span>Nouvelle Prestation</span>
        </a>
    </div>
  </custom-modal>
</template>
<script>
export default {
  name: "SavePrestationConfirmModal",
  props: {
    active: {
      type: Boolean,
    },
    updated: {
      type: Boolean,
    }
  },
  data() {
    return {
      
    };
  },
  methods: {
    close() {
      this.$router.go(0);
      this.$emit("close");
    },
    listPrestations() {
      this.$router.push({name: "project-life"});
    },
    showPlanning() {
      this.$router.push({name: "planning"});
    },
    reloadView() {
      if(this.updated) {
        this.$router.push({name: "autonomy"});
        this.close();
      }else{
        this.$router.go(0);
      }
    }
  },
};
</script>