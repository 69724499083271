export const TYPE_AIDE_SCORE = {
    'Inciter / surveiller': 'B',
    'Faire faire': 'B',
    'Laisser faire, refaire': 'C',
    'Aide partielle': 'B',
    'Tout faire': 'C',
  };

  export const TEMPS_SCORE = {
    1: {'B': 5, 'C': 10},
    2: {'B': 2.5, 'C': 10},
    3: {'B': 5, 'C': 10},
    4: {'B': 5, 'C': 10},
    5: {'B': 5, 'C': 7.5},
    6: {'B': 5, 'C': 7.5},
    7: {'B': 5, 'C': 7.5},
    8: {'B': 5, 'C': 7.5},
    9: {'B': 5, 'C': 7.5},
    10: {'B': 5, 'C': 7.5},
    11: {'B': 5, 'C': 7.5},
    12: {'B': 10, 'C': 15},
    13: {'B': 2.5, 'C': 5},
    14: {'B': 30, 'C': 60},
    15: {'B': 5, 'C': 10},
    16: {'B': 10, 'C': 15},
    17: {'B': 0, 'C': 0},
    18: {'B': 10, 'C': 20},
    19: {'B': 15, 'C': 30},
    20: {'B': 15, 'C': 15},
    21: {'B': 5, 'C': 10},
    22: {'B': 5, 'C': 10},
    23: {'B': 5, 'C': 10},
    24: {'B': 10, 'C': 20},
  };

export const ORD_DAYS = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];