<template>
  <div class="bo--container">
    <template>
      <div class="container">
        <loader :loader="loading"/>
        <div>
          <bo-card>
            <template slot="title">
              <h1 class="bo-card--title" v-if="isUserRoleOperationManagerOrAreaManager">
                <a href="#" @click.prevent="cancel">&lt; </a> {{isEdit ? 'Modifier la prestation' : "Créer une prestation"}}
              </h1>
              <h1 class="bo-card--title" v-else>
                <a href="#" @click.prevent="cancel">&lt; </a> Voir la prestation
              </h1>
            </template>
            <div class="row">
              <div v-if="prestation.erpId" class="col-sm-12 col-md-6">
                <form-input :value="prestation.erpId"
                            label="Code Apologic"
                            :login-input="true"
                            :disabled="true"/>
              </div>
              <div v-if="prestation.erpId" class="col-sm-12 col-md-6">
                <form-input :value="prestation.erpTypeId"
                            label="Code famille Apologic"
                            :login-input="true"
                            :disabled="true"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                  <form-input v-model="name"
                              label="Nom de la prestation *"
                              :error="!$v.name.required && $v.name.$error ? 'Le nom est obligatoire.' : null"
                              :login-input="true"
                              :disabled="!isUserRoleOperationManagerOrAreaManager"/>
              </div>
              <div class="col-sm-12 col-md-6">
                <form-input v-model="nameType"
                            label="Type de la prestation *"
                            :error="!$v.nameType.required && $v.nameType.$error ? 'Le type de prestation est obligatoire.' : null"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManagerOrAreaManager"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <form-input v-model="price"
                            label="Prix *"
                            :error="$v.price.$error ? 'le prix est obligatoire' : null"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManagerOrAreaManager">
                  <templaete v-slot:error>
                    <small v-if="!$v.price.required && $v.price.$error" class="input--error">le prix est obligatoire</small>
                    <small v-if="!$v.price.numeric && $v.price.$error" class="input--error">le prix doit être numérique</small>
                  </templaete>
                </form-input>
              </div>
              <div class="col-sm-12 col-md-6">
                <form-input v-model="tva"
                            label="TVA *"
                            :error="$v.tva.$error ? 'la TVA est obligatoire' : null"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManagerOrAreaManager">
                  <templaete v-slot:error>
                    <small v-if="!$v.tva.required && $v.tva.$error" class="input--error">le prix est obligatoire</small>
                    <small v-if="!$v.tva.numeric && $v.tva.$error" class="input--error">le prix doit être numérique</small>
                  </templaete>
                </form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <form-time-picker v-model="schedule"
                                  label="Horaire *"
                                  :error="!$v.schedule.required && $v.schedule.$error ? 'l\'horaire est obligatoire' : null"
                                  :login-input="true"
                                  :disabled="!isUserRoleOperationManagerOrAreaManager"/>
              </div>
              <div class="col-sm-12 col-md-6">
                <label>Récurrence *</label>
                <div :class="[$v.recurrency.$error?'err':'','row']">
                  <div class="col-12">
                    <el-checkbox-group v-model="recurrency" :disabled="!isUserRoleOperationManagerOrAreaManager">
                      <el-checkbox label="Lundi"></el-checkbox>
                      <el-checkbox label="Mardi"></el-checkbox>
                      <el-checkbox label="Mercredi"></el-checkbox>
                      <el-checkbox label="Jeudi"></el-checkbox>
                      <el-checkbox label="Vendredi"></el-checkbox>
                      <el-checkbox label="Samedi"></el-checkbox>
                      <el-checkbox label="Dimanche"></el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="col-12">
                    <span class="err" v-if="!$v.recurrency.required && $v.recurrency.$error">Saisir au moins un jour</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                  <form-input v-model="time"
                              label="Durée (Heure) *"
                              :error="$v.time.$error ? 'La durée est obligatoire' : null"
                              :login-input="true"
                              :disabled="!isUserRoleOperationManagerOrAreaManager">
                    <templaete v-slot:error>
                      <small v-if="!$v.time.required && $v.time.$error" class="input--error">la durée est obligatoire</small>
                      <small v-if="!$v.time.numeric && $v.time.$error" class="input--error">la durée doit être numérique</small>
                    </templaete>
                  </form-input>
              </div>
              <div class="col-sm-12 col-md-6">
                  <form-input v-model="price"
                              label="Prix *"
                              :error="$v.price.$error ? 'Le prix est obligatoire' : null"
                              form
                              :login-input="true"
                              :disabled="!isUserRoleOperationManagerOrAreaManager">
                    <templaete v-slot:error>
                      <small v-if="!$v.price.required && $v.price.$error" class="input--error">le prix est obligatoire</small>
                      <small v-if="!$v.price.numeric && $v.price.$error" class="input--error">le prix doit être numérique</small>
                    </templaete>
                  </form-input>
              </div>
              <div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                <GridLight
                        label="Liste des tarifs"
                        :columns="tarifColumn"
                        :dataList="tarifList"
                        @cliked="goToTarification"/>
              </div>
            </div>
            <template slot="footer">
              <button v-if="isUserRoleOperationManagerOrAreaManager" class="btn btn-primary" @click.prevent="submit" :disabled="saveLoading"><font-awesome-icon v-show="saveLoading" :icon="['fas', 'spinner']" spin/> Enregistrer</button>
              <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
            </template>
          </bo-card>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

  import {mapState, mapActions, mapMutations, mapGetters} from "vuex";
import { required, numeric, decimal } from 'vuelidate/lib/validators'
import FormTimePicker from "@/components/Form/TimePicker";
import GridLight from "@/components/Grid/GridLight";

export default {
  name: "BOPrestationForm",
  components: {FormTimePicker, GridLight},
  data() {
    return {
      bo: {
        active: true
      },
      tarifColumn: [
        {name: "Nom", value: 'label', clickable: true},
        {name: "Structure", value: 'structureName'},
        {name: "Type", value: 'tarifType'},
        {name: "Prix", value: 'price'}
      ],
      saveLoading: false
    };
  },
  validations() {
    let dataReturn = {
      structureId: {
        /*required*/
      },
      name: {
        required
      },
      nameType: {
        required
      },
      schedule: {
        required
      },
      recurrency: {
        required
      },
      time: {
        required,
        numeric
      },
      price: {
        required,
        decimal
      },
      tva: {
        required,
        decimal
      },
    };
    return dataReturn;
  },
  computed: {
    ...mapState('Prestation', ['prestation', 'error', 'loading']),
    ...mapState('Structure', ['structures']),
    ...mapState('User', ['connectedUser']),
    ...mapGetters('User', ['isUserRoleOperationManagerOrAreaManager']),
    structureId:{
      get(){
        return this.prestation.structureId;
      },
      set(value){
        this.setPrestationProperty({
          name: "structureId",
          value: value
        });
      }
    },
    name:{
      get(){
        return this.prestation.name;
      },
      set(value){
        this.setPrestationProperty({
          name: "name",
          value: value
        });
      }
    },
    nameType:{
      get(){
        return this.prestation.nameType;
      },
      set(value){
        this.setPrestationProperty({
          name: "nameType",
          value: value
        });
      }
    },
    schedule:{
      get(){
        return this.prestation.schedule;
      },
      set(value){
        this.setPrestationProperty({
          name: "schedule",
          value: value
        });
      }
    },
    recurrency:{
      get(){
        return this.prestation.recurrency;
      },
      set(value){
        this.setPrestationProperty({
          name: "recurrency",
          value: value
        });
      }
    },
    time:{
      get(){
        return this.prestation.time;
      },
      set(value){
        this.setPrestationProperty({
          name: "time",
          value: value
        });
      }
    },
    price:{
      get(){
        return this.prestation.price;
      },
      set(value){
        this.setPrestationProperty({
          name: "price",
          value: value
        });
      }
    },
    tva:{
      get(){
        return this.prestation.tva;
      },
      set(value){
        this.setPrestationProperty({
          name: "tva",
          value: value
        });
      }
    },
    description:{
      get(){
        return this.prestation.description;
      },
      set(value){
        this.setPrestationProperty({
          name: "description",
          value: value
        });
      }
    },
    isEdit() {
      return (this.$route.params.id !== undefined && this.$route.params.id !== null)
    },
    tarifList() {
      return this.prestation.tarificationList ? this.prestation.tarificationList.map((item) => {
        return {
          ...item,
          structureName: item.structure ? item.structure.name : '',
        }
      }) : [];
    },
    structurelist() {
      let structurelist = [];
      this.structures.forEach(item => structurelist.push({value: item.id, text: item.name}));
      return structurelist;
    }
  },
  mounted: async function() {
    this.setInitPrestation();
    if (this.isEdit) {
      this.getPrestation({ id: this.$route.params.id })
      .catch((err) => {
        if (err) {
          this.$notify.error("Impossible d'accéder à cette prestation")
          this.$router.push({name: 'prestation-list'});
        }
      })
    }
    this.getStructureList({});
  },
  methods: {
    ...mapMutations('Prestation',['setPrestationProperty','setInitPrestation']),
    ...mapActions('Prestation', ['postPrestation', 'putPrestation', 'getPrestation']),
    ...mapActions('Structure', ['getStructureList']),
    async submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return; 
      }
      this.$confirm('Êtes-vous sur de vouloir réaliser une modification ? Les données modifiées dans DANEAD doivent être modifiées dans le logiciel métier !').then(() => {
        this.afterConfirm();
      }).catch(() => {});
    },
    afterConfirm(){
      this.saveLoading = true;
      if (this.isEdit) {
        this.putPrestation().then((data) => {
          this.backToList();
          return data;
        }).catch((err) => {
          this.errorSave();
          throw err;
        });
      } else {
        this.postPrestation().then((data) => {
          this.backToList();
          return data;
        }).catch((err) => {
          this.errorSave();
          throw err;
        });
      }
    },
    backToList(){
      this.setInitPrestation();
      this.saveLoading = false;
      this.$notify.success(this.isEdit ? "La prestation a été modifiée" : "La prestation a été créée")
      this.$router.push({name: 'prestation-list'});
      return;
    },
    errorSave(){
      this.saveLoading = false;
      this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
    },
    cancel() {
      this.setInitPrestation();
      return this.$router.push({name:'prestation-list'})
    },
    goToTarification(item){
      this.$router.push({name: "tarification-form", params: {id: item.id}});
    }
  }
};
</script>