import actions from './actions'
import mutations from './mutations'
import state from './state'
import stateTreatment from './State/treatment'
import stateObjectives from './State/objectivesActions'
import getters from './getters'

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state: Object.assign(state, stateTreatment, stateObjectives)
};