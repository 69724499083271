<template>
  <custom-modal :active="active" @close="close">
    <div class="eval-modal-title">
      <div></div>
      <img src="@/assets/icon-plein-risks.svg" alt />
      <h1>AUTRES RISQUES DOMESTIQUES</h1>
    </div>
    <div class="eval-risks-modal">
      <p class="eval-modal-subtitle mb-4">QUELS SONT LES RISQUES LIÉS À UN LIEU ET SES ACTIVITÉS ?</p>

      <div class="eval-risks-content">
        <div class="eval-form--check">
          <div class="row">
            <div class="col-sm-6 col-md-4" v-for="(option, index) in risks" :key="index">
              <div class="id-check--option">
                <input
                  class="id-check--input"
                  :id="`id-risks-${index}`"
                  name="risks"
                  type="checkbox"
                  :value="option.risk.id"
                  v-model="risksval"
                />
                <label class="ml-0" :for="`id-risks-${index}`">
                  <span class="id-check--indicator">{{option.risk.name}}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="eval-modal-subtitle mt-5">AJOUTER UN RISQUE</p>
          <div class="row">
            <div class="col-sm-12 col-md-4 d-flex align-items-center">
              <div class="eval-form--input">
                <input
                  type="text"
                  v-model="riskName"
                  class="form-control form-control-sm"
                  placeholder="Exemple : chute"
                />
              </div>
              <button class="btn p-0 ml-1" @click="addRisk">
                <img src="@/assets/bouton-primaire-add.svg" style="height:20px" alt />
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center my-3">
          <button class="btn btn-primary" @click="updateRiskCheck">Valider</button>
        </div>
      </div>
    </div>
  </custom-modal>
</template>
<script>
export default {
  name: "RisksModal",
  props: {
    active: {
      type: Boolean,
    },
    risks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      riskName: '',
      risksval: [],
    };
  },
  watch: {
    active() {
      this.risksval = this.risks.filter(e=>e.checked).map(item=>{return item.risk.id})
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    addRisk() {
      if (!this.riskName) { return }
      const riskName = this.riskName
      this.riskName = ''
      this.$emit("add", riskName);
    },
    updateRiskCheck() {
      if(this.riskName) {
        this.addRisk();
      }
      this.$emit("validate", this.risksval);
      this.close();
    }
  },
};
</script>