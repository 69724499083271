export default {
    intervention: {
        erpId: '',
        dateStart: '',
        dateEnd: '',
        client: {},
        clientId: '',
        prestation: {},
        prestationId: '',
        intervenant: {},
        intervenantId: '',
        clientHealth: '',
        clientMoral: '',
        additionalComments: '',
        familyComments: '',
        sendHour: '',
        photo: '',
        status: false,
        hasNoBadge: false,
        hasNoBadgeDescription: '',
        hasAbsence: false,
        signalements: []
    },
    interventions: [],
    loading: false,
    error: null,
    total: 0,
    countsFromSignalementType: [],
    hoursByMonth: []
}
