import gql from 'graphql-tag'

export const GET_GENERAL_QUESTIONS = gql`
    query generalQuestions {
        generalQuestions{
            id,
            title,
            responses,
            category,
            type
        }
    }
`
