import gql from 'graphql-tag'

export const GET_MATERIAL = gql`
    query material(
        $id: Int!,
    ) {
        material(
            id: $id
        ) {
            id,
            ref,
            name,
            description,
            status,
            photoUrl,
            videoUrl,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            }
        }
    }
`
