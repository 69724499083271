import gql from 'graphql-tag'

export const AGGIR_QUESTION_LIST = gql`
    query aggirQuestionList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!
    ) {
        aggirQuestionList(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                id,
                placeRelated,
                item,
                subItem,
                openingStatement,
                itemPeriod,
                itemStch,
                createdAt,
                createdBy{
                    id,
                    firstName,
                    lastName
                },
                updatedAt,
                updatedBy{
                    id,
                    firstName,
                    lastName
                }
            },
            total
        }
    }
`
