<template>
  <div class="eval-card card-alt">
    <button v-if="hasQuestion" class="btn eval-card--hint-btn" @click="e => $emit('question', e)">
      <img src="@/assets/bouton-secondaire-question-mark.svg" alt />
    </button>
    <div class="eval-card--wrap">
      <div class="eval-card--header">
        <h5 class="eval-card--title-alt">{{title}}</h5>
        <div class="eval-card--check-btn cursor-pointer" @click.prevent="$emit('update')" :class="{'checked':checked}" v-if="hasCheck">
          <span class="custom-check-circle"></span>
        </div>
        <div class="eval-card--remove-btn cursor-pointer" @click.prevent="$emit('remove')" v-if="hasRemove">
          <img src="@/assets/bouton-secondaire-trash.svg" alt />
        </div>
      </div>
      <div class="eval-card--content" v-if="showContentSlot">
        <slot name="content" />
      </div>
      <div class="eval-card--content-main" v-if="showMainSlot">
        <slot name="main" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EvalCard",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    body: {
      type: String,
      default: null,
    },
    hasCheck: {
      type: Boolean,
      default: true,
    },
    hasRemove: {
      type: Boolean,
      default: false,
    },
    showContentSlot: {
      type: Boolean,
      default: false,
    },
    showMainSlot: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    hasQuestion: {
      type: Boolean,
      default: false,
    }
  },
  computed: {},
  data() {
    return {};
  },
  methods: {}
};
</script>