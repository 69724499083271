<template>
  <div class="bo--container">
    <template>
      <div class="container">
        <loader :loader="loading"/>
        <div>
          <bo-card :title="isEdit ? '&lt; Modifier l’agence' : '&lt; Créer l’agence'">
            <template slot="title">
              <h1 class="bo-card--title" v-if="isUserRoleOperationManager" >
                <a href="#" @click.prevent="cancel">&lt; </a> {{isEdit ? 'Modifier l\'agence' : "Créer une agence"}}
              </h1>
              <h1 v-else class="bo-card--title">
                <a href="#" @click.prevent="cancel">&lt; </a> Voir l'agence
              </h1>
            </template>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <form-select v-model="structureId"
                             :options="structureList"
                             label="La structure *"
                             :error="!$v.structureId.required && $v.structureId.$error ? 'La structure est obligatoire.' : null"
                             :disabled="isEdit"
                             :login-input="true">
                  <template v-slot:first>
                    <option value="" selected disabled></option>
                  </template>
                </form-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <form-input v-model="apologicCode" label="Code Apologic" type="number"
                        :login-input="true"
                        :disabled="!isUserRoleOperationManager">
                    <template v-slot:error v-if="$v.apologicCode.$error">
                        <small v-if="!$v.apologicCode.integer && $v.apologicCode.$error" class="input--error">le code Apologic est une valeur numérique.</small>
                    </template>
                </form-input>
              </div>
              <div class="col-sm-12 col-md-6">
                  <form-input v-model="companyName"
                          label="Nom de l'agence *"
                          :error="!$v.companyName.required && $v.companyName.$error ? 'Le nom de l\'agence est obligatoire.' : null"
                          :login-input="true"
                          :disabled="!isUserRoleOperationManager"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                  <form-input v-model="legalForm"
                          label="Forme Juridique *"
                          :error="!$v.legalForm.required && $v.legalForm.$error ? 'la forme juridique est obligatoire' : null"
                          :login-input="true"
                          :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-12 col-md-6">
                  <form-input v-model="implementationName"
                              label="Nom d’implantation"
                              :login-input="true"
                              :disabled="!isUserRoleOperationManager"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                  <form-input
                          v-model="address"
                          label="Adresse *"
                          :error="!$v.address.required && $v.address.$error ? 'l\'adresse est obligatoire' : null"
                          :login-input="true"
                          :disabled="!isUserRoleOperationManager"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <form-input v-model="postalCode" label="Code postal *" :error="$v.postalCode.$error ? 'error' : null" :login-input="true" :disabled="!isUserRoleOperationManager">
                  <template v-slot:error v-if="$v.postalCode.$error">
                    <small v-if="!$v.postalCode.required && $v.postalCode.$error" class="input--error">le code postal est obligatoire</small>
                  </template>
                </form-input>
              </div>
              <div class="col-sm-3">
                <form-input v-model="city" label="Ville *"
                            :error="!$v.city.required && $v.city.$error ? 'la ville est obligatoire' : null"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="phoneNumber"
                            :error="!$v.phoneNumber.phone && $v.phoneNumber.$error ? 'Le format doit être XX XX XX XX XX' : null"
                            label="Téléphone"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="fax"
                            :error="!$v.fax.phone && $v.fax.$error ?'Le format doit être XX XX XX XX XX' : null"
                            label="Fax"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="capital"
                            label="Capital"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="rcs"
                            label="RCS"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="siret"
                            :error="(!$v.siret.required || !$v.siret.siret) && $v.siret.$error ? (!$v.siret.required ? 'le siret est obligatoire' : 'Le format doit être XXX XXX XXX XXXXX') : null"
                            label="Siret *"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="tvaintercom"
                            label="TVA intercom"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="agsnum"
                            label="AGS numéro"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="agqnumbis"
                            label="AGQ numéro bis"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="certifnf"
                            label="Certif NF"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="autorisationNum"
                            label="Autorisation numéro"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-input v-model="finess"
                            label="Finess"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-3">
                <form-toggle append="Actif" prepend="Inactif" v-model="status" name="toggle" :disabled="!isUserRoleOperationManager"/>
              </div>
            </div>
            <template slot="footer">
              <button v-if="isUserRoleOperationManager" class="btn btn-primary" @click.prevent="submit" :disabled="saveLoading"><font-awesome-icon v-show="saveLoading" :icon="['fas', 'spinner']" spin/> Enregistrer</button>
              <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
            </template>
          </bo-card>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

import {mapState, mapActions, mapMutations, mapGetters} from "vuex";
import { required, integer } from 'vuelidate/lib/validators'
import { helpers } from 'vuelidate/lib/validators'
const phone = helpers.regex('phone', /^((\+)33|0)[1-9](?:[\s.-]*\d{2}){4}$/);
const siret = helpers.regex('siret', /^((?:[\s.-]*\d{3}){3}(?:[\s.-]*\d{5}))$/);

export default {
  name: "BOAgencyForm",
  components: {},
  data() {
    return {
      bo: {
        active: true
      },
      saveLoading: false
    };
  },
  validations() {
    let dataReturn = {
      structureId: {
        required
      },
      apologicCode: {
        integer
      },
      companyName: {
        required
      },
      legalForm: {
        required
      },
      implementationName: {
      },
      address: {
        required
      },
      postalCode: {
        required
      },
      city: {
        required
      },
      phoneNumber: {
        phone
      },
      fax: {
        phone
      },
      capital: {
      },
      rcs: {
      },
      siret: {
        required,
        siret
      },
      tvaintercom: {
      },
      agsnum: {
      },
      agqnumbis: {
      },
      certifnf: {
      },
      autorisationNum: {
      },
      finess: {
      }
    };
    return dataReturn;
  },
  computed: {
    ...mapState('Agency', ['agency', 'error', 'loading']),
    ...mapState('Structure', ['structures']),
    ...mapState('User', ['connectedUser']),
    ...mapGetters('User', ['isUserRoleOperationManager']),
    structureId:{
      get(){
        return this.agency.structureId;
      },
      set(value){
        this.setAgencyProperty({
          name: "structureId",
          value: value
        });
      }
    },
    apologicCode:{
      get(){
        return this.agency.apologicCode;
      },
      set(value){
        if (value === "") {
          value = null;
        }
        this.setAgencyProperty({
          name: "apologicCode",
          value: value
        });
      }
    },
    companyName:{
      get(){
        return this.agency.companyName;
      },
      set(value){
        this.setAgencyProperty({
          name: "companyName",
          value: value
        });
      }
    },
    legalForm:{
      get(){
        return this.agency.legalForm;
      },
      set(value){
        this.setAgencyProperty({
          name: "legalForm",
          value: value
        });
      }
    },
    implementationName:{
      get(){
        return this.agency.implementationName;
      },
      set(value){
        this.setAgencyProperty({
          name: "implementationName",
          value: value
        });
      }
    },
    address:{
      get(){
        return this.agency.address;
      },
      set(value){
        this.setAgencyProperty({
          name: "address",
          value: value
        });
      }
    },
    postalCode:{
      get(){
        return this.agency.postalCode;
      },
      set(value){
        this.setAgencyProperty({
          name: "postalCode",
          value: value
        });
      }
    },
    city:{
      get(){
        return this.agency.city;
      },
      set(value){
        this.setAgencyProperty({
          name: "city",
          value: value
        });
      }
    },
    phoneNumber:{
      get(){
        return this.agency.phoneNumber;
      },
      set(value){
        this.setAgencyProperty({
          name: "phoneNumber",
          value: value
        });
      }
    },
    status:{
      get(){
        return this.agency.status;
      },
      set(value){
        this.setAgencyProperty({
          name: "status",
          value: value
        });
      }
    },
    fax:{
      get(){
        return this.agency.fax;
      },
      set(value){
        this.setAgencyProperty({
          name: "fax",
          value: value
        });
      }
    },
    capital:{
      get(){
        return this.agency.capital;
      },
      set(value){
        this.setAgencyProperty({
          name: "capital",
          value: value
        });
      }
    },
    rcs:{
      get(){
        return this.agency.rcs;
      },
      set(value){
        this.setAgencyProperty({
          name: "rcs",
          value: value
        });
      }
    },
    siret:{
      get(){
        return this.agency.siret;
      },
      set(value){
        this.setAgencyProperty({
          name: "siret",
          value: value
        });
      }
    },
    tvaintercom:{
      get(){
        return this.agency.tvaintercom;
      },
      set(value){
        this.setAgencyProperty({
          name: "tvaintercom",
          value: value
        });
      }
    },
    agsnum:{
      get(){
        return this.agency.agsnum;
      },
      set(value){
        this.setAgencyProperty({
          name: "agsnum",
          value: value
        });
      }
    },
    agqnumbis:{
      get(){
        return this.agency.agqnumbis;
      },
      set(value){
        this.setAgencyProperty({
          name: "agqnumbis",
          value: value
        });
      }
    },
    certifnf:{
      get(){
        return this.agency.certifnf;
      },
      set(value){
        this.setAgencyProperty({
          name: "certifnf",
          value: value
        });
      }
    },
    autorisationNum:{
      get(){
        return this.agency.autorisationNum;
      },
      set(value){
        this.setAgencyProperty({
          name: "autorisationNum",
          value: value
        });
      }
    },
    finess:{
      get(){
        return this.agency.finess;
      },
      set(value){
        this.setAgencyProperty({
          name: "finess",
          value: value
        });
      }
    },
    isEdit() {
      return (this.$route.params.id !== undefined && this.$route.params.id !== null)
    },
    structureList() {
      if(this.structures.length === 0){
        return [];
      }
      return this.structures.reduce((list, structure) => {
        list.push({value: structure.id, text: structure.name});
        return list;
      }, []);
    }
  },
  mounted: async function() {
    this.setInitAgency();
    if (this.isEdit) {
      this.getAgency({ id: this.$route.params.id })
      .catch((err) => {
        if (err) {
          this.$notify.error("Impossible d'accéder à cette agence")
          this.$router.push({name: 'agency-list'});
        }
      })
    }
    //TODO if the number af agency past 300, use filtered component and adapt function to get structure by name!
    this.getStructureList({limit: 10000});
  },
  methods: {
    ...mapMutations('Agency',['setAgencyProperty', 'setInitAgency']),
    ...mapActions('Agency', ['postAgency', 'putAgency', 'getAgency']),
    ...mapActions('Structure', ['getStructureList', 'getStructure']),
    async submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.$confirm('Êtes-vous sur de vouloir réaliser une modification ? Les données modifiées dans DANEAD doivent être modifiées dans le logiciel métier !').then(() => {
        this.afterConfirm();
      }).catch(() => {
      });
    },
    afterConfirm(){
      this.saveLoading = true;
      if (this.isEdit) {
        this.putAgency().then((res) => {
          this.backToList();
          return res;
        }).catch((err) => {
          this.saveLoading = false;
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          throw err;
        });
      } else {
        this.postAgency().then((res) => {
          this.backToList();
          return res;
        }).catch((err) => {
          this.saveLoading = false;
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          throw err;
        });
      }
    },
    backToList(){
      this.setInitAgency();
      this.saveLoading = false;
      this.$notify.success(this.isEdit ? "L'agence a été modifiée" : "L'agence a été créée")
      this.$router.push({name: 'agency-list'});
    },
    cancel() {
      return this.$router.push({name:'agency-list'});
    }
  }
};
</script>