<template>
    <div class="bo--gridLight">
        <div v-if="label" class="row">
            <div class="col">
                <a href="#" @click.prevent="show">{{label}} <font-awesome-icon :icon="['fas', viewable ? 'chevron-up' : 'chevron-down']"/></a>
            </div>
        </div>
        <div class="row" :class="[loading ? 'loading' : '','list list--bo mt-1']" v-if="viewable">
            <div class="col-12 row" v-for="e in typesList" :key="e.name + Math.random()">
                <div class="col-12 mt-1"><p>- {{e.label}}</p></div>
                <div class="col-11">
                    <template v-if="e.count>0">
                        <agile
                            :ref="e.name+'Slider'"
                            :slidesToShow="3"
                            :dots="false"
                            :navButtons="false"
                            :infinite="e.count>viewableCount">
                            <div class="slide" v-for="item in e.values" :key="item.id+item.type">
                                <card :url="API_URL + item.path" :type="item.type" :description="item.description" @preview="showPreview(API_URL + item.path, item.id)" />
                            </div>
                        </agile>
                    </template>
                </div>
                <div class="col-1 p-0 d-flex align-items-center justify-content-end">
                    <div class="align-middle">
                        <i v-if="e.count>viewableCount" class="el-icon-arrow-right text-primary h3 cursor-pointer" @click="nextSlider(e.name)"></i>
                        <i v-if="!disabled" class="el-icon-circle-plus text-primary h3 cursor-pointer" @click="$emit('clickAdd', e.name)"></i>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="preview_document" title="Preview Document" :centered="true" size="md">
            <template v-slot:modal-title>
                Aperçu
            </template>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <img :src="previewURL" width="100%">
            </div>
            <template v-slot:modal-footer>
                <b-button class="mt-2" variant="btn col-3 btn-outline-danger" v-if="documentId" @click.prevent="$emit('remove', documentId)">Supprimer</b-button>
                <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('preview_document')">Annuler</b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
    import { VueAgile } from 'vue-agile';
    import DocumentCard from './DocumentCard';
    import {API_URL} from '@/components/Util/EnvVariable';
    export default {
        name: "Grid",
        components:{
            agile: VueAgile,
            card: DocumentCard
        },
        data() {
            return {
                API_URL: API_URL,
                viewable: false,
                viewableCount: 3,
                previewURL: '',
                documentId: null
            }
        },
        props: {
            id: String,
            label: String,
            dataList: Array,
            loading: Boolean,
            disabled: {
              type: Boolean,
              default: false
            },
            viewTable: {
                type: Boolean,
                default: false
            }
        },
        mounted: function () {
            this.viewable = this.viewTable
        },
        computed:{
            typesList () {
                const lieux = this.dataList ? this.dataList.filter(item => item.mainType == 'lieux') : [];
                const documents = this.dataList ? this.dataList.filter(item => item.mainType == 'documents') : [];
                return [
                    {name: 'lieux', values: lieux, label: 'Lieux', count: lieux.length},
                    {name: 'documents', values: documents, label: 'Documents', count: documents.length},
                ];
            }
        },
        methods: {
            show() {
                if (this.viewable) {
                    this.viewable = false;
                } else {
                    this.viewable = true;
                }
            },
            nextSlider(ref) {
                if (ref == 'lieux') {
                    this.$refs.lieuxSlider[0].goToNext();
                } else {
                    this.$refs.documentsSlider[0].goToNext();
                }
            },
            showPreview(url, documentId = null) {
                this.previewURL = url;
                this.documentId = documentId;
                this.$bvModal.show('preview_document');
            }
        }
    };
</script>