<template>
  <div class="wrapper wrapper--auth">
    <img class="ai ai--logo" src="@/assets/path-3.svg" alt="">
    <img class="ai ai--bottom-shade" src="@/assets/path-4.svg" alt="">
    <img class="ai ai--illu_2" src="@/assets/illu_2@2x.png" alt="">
    <img class="ai ai--illu_1" src="@/assets/illu_1@2x.png" alt="">
    <header>
      <div class="navbar">
        <div class="navbar-brand">
          <img src="@/assets/logo-light.svg" alt />
        </div>
        <div class="ml-auto mr-5">
          <button class="btn btn-lg btn-outline-white" v-text="buttonLabel" @click="redirectToApp"></button>
        </div>
      </div>
    </header>
    <main>
      <div class="auth--content">
          <div class="auth--card">
              <img class="ai ai--card-logo" src="@/assets/Illustrations-logo.svg" alt="">
              <slot />
          </div>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: "AuthWrap",
  computed: {
    buttonLabel () {
      if (this.$route.path.includes('evaluation')) {
        return 'Accéder au back office'
      } else {
        return 'Accéder à l\'évaluation'
      }
    }
  },
  methods: {
    redirectToApp () {
      if (this.$route.path.includes('evaluation')) {
        this.$router.push('/login')
      } else {
        this.$router.push('/evaluation-login')
      }
    }
  }
};
</script>
