<template>
  <div class="container">
    <Planning :isEditable="true"/>
  </div>
</template>
<script>
import Planning from "@/components/Eval/Planning";

export default {
  name: "ProjetDeViePlanning",
  components: {
    Planning
  },
  data() {
    return {}
  },
  computed: {

  },
  methods: {

  },
};
</script>