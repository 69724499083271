export default {
    setAggirQuestionProperty(state, property) {
        if(!state.aggirQuestion[property.name]){
            state.aggirQuestion[property.name] = "";
        }
        state.aggirQuestion[property.name] = property.value;
    },
    setAggirQuestionItemStch(state, property) {
        state.aggirQuestion.itemStch[property.index][property.property] = property.value;
    },
    setAggirQuestionLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setAggirQuestionSaveLoading(state) {
        state.error = null;
    },
    setAggirQuestionList(state, aggirQuestions) {

        state.aggirQuestions = aggirQuestions;
        state.loading = false;
        state.error = null;
    },
    setTotalAggirQuestionList(state, total) {
        state.total = total;
    },
    setAggirQuestionSuccess(state, aggirQuestion) {
        const items = JSON.parse(aggirQuestion.itemStch);

        state.aggirQuestion = {
            ...aggirQuestion,
            itemStch: items,
            stchS: items.s.text,
            stchStime: items.s.time,
            stchT: items.t.text,
            stchTtime: items.t.time,
            stchC: items.c.text,
            stchCtime: items.c.time,
            stchH: items.h.text,
            stchHtime: items.h.time
        };
        state.loading = false;
        state.error = null;
    },
    setInitAggirQuestion(state) {
        state.aggirQuestion = {
            placeRelated: [],
            item: '',
            subItem: null,
            openingStatement: '',
            itemPeriod: [],
            itemStch: {
                "s" : {
                    "time" : null,
                    "text" : null
                },
                "t" : {
                    "time" : null,
                    "text" : null
                },
                "c" : {
                    "time" : null,
                    "text" : null
                },
                "h" : {
                    "time" : null,
                    "text" : null
                }
            },
            stchS: null,
            stchStime: null,
            stchT: null,
            stchTtime: null,
            stchC: null,
            stchCtime: null,
            stchH: null,
            stchHtime: null,
            ruleA: '',
            ruleB: '',
            ruleC: ''
        };
        state.error = null;
      },
    setAggirQuestionError(state, error) {
        state.loading = false;
        state.error = error;
    },
    setPostAggirQuestionSuccess(state, aggirQuestion) {
        state.aggirQuestions.push(aggirQuestion);
        state.total = state.total + 1
        state.error = null;
    },
    setPutAggirQuestionSuccess(state, aggirQuestion) {
        state.aggirQuestions.splice(
            state.aggirQuestions.findIndex(aggirQuestionSearch => aggirQuestionSearch.id === aggirQuestion.id),
            1,
            aggirQuestion
        );
        state.error = null;
    },
    setDeleteAggirQuestionSuccess(state, id) {
        state.aggirQuestions.splice(
            state.aggirQuestions.findIndex(aggirQuestion => aggirQuestion.id == id),
            1
        );
        state.loading = false;
        state.error = null;
        state.total = state.total - 1
    },
}