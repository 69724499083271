import gql from 'graphql-tag'

export const SAVE_AGGIR_ANSWER = gql`
    mutation saveAggirAnswer(
        $evaluationId: Int!,
        $questionId: Int!,
        $answerStch: String!,
        $interpretationRule: String!,
        $aggirPercentage: Int!
    ) {
        saveAggirAnswer(
            evaluationId: $evaluationId,
            questionId: $questionId,
            answerStch: $answerStch,
            interpretationRule: $interpretationRule,
            aggirPercentage: $aggirPercentage
        ) {
            id,
            answerList {
                id,
                answerStch,
                question {
                   id,
                   item
                },
                itemScore,
                subItemScore
            },
            gir
        }
    }
`
