<template>
  <div class="soin-item">
    <div class="soin-item--title" @click.prevent="toggleActive">
      <h1>Santé - Traitement suivi Médical</h1>
      <button class="btn" :class="{'inverted': !active}">
        <img src="@/assets/chevron-up.svg" alt />
      </button>
    </div>
    <div class="soin-item--content">
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm01 !== null && stsm01 !== ''"
          title="Combien de médicaments différents par jour prenez-vous ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="stsm01" name id>
                  <option value="r1">0</option>
                  <option value="r2">1</option>
                  <option value="r3">2</option>
                  <option value="r4">3</option>
                  <option value="r5">4</option>
                  <option value="r6">5</option>
                  <option value="r7">5+</option>
                  <option value="r8">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm02 !== null && stsm02.length > 0"
          title="Quand prenez-vous ces médicaments ?"
          v-if="stsm01 !== 'r1' /*&& valueStsm01 > 0*/ && active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                inputType="checkbox"
                subtitle=""
                :options="[]">
                <template slot="content">
                  <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label: 'Matin'},
                      {value:'r2', label: 'Midi'},
                      {value:'r3', label: 'Après-midi'},
                      {value:'r4', label: 'Soir'},
                      {value:'r5', label: 'Au coucher'},
                      {value:'r6', label: 'La nuit'},
                      {value:'r7', label: 'NSP'}]"
                       :key="index">
                    <input class="id-check--input"
                           :id="`stsm02-option-${index}`"
                           type="checkbox"
                           :value="option.value"
                           v-model="stsm02"/>
                    <label :for="`stsm02-option-${index}`">
                      <span class="id-check--indicator">{{option.label}}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm03 !== null && stsm03.length > 0"
          title="Préparation et dispensation des médicaments"
          v-if="stsm01 !== 'r1' /*&& valueStsm01 > 0*/ && active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                inputType="checkbox"
                subtitle=""
                :options="[]">
                <template slot="content">
                  <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label: 'Utilisation d\'un semainier'},
                      {value:'r2', label: 'Préparé par la personne elle-même'},
                      {value:'r3', label: 'Préparé par une infirmière'},
                      {value:'r4', label: 'Préparé par un membre de la famille'},
                      {value:'r5', label: 'Médicaments pris seul(e)'},
                      {value:'r6', label: 'Aide à la prise par une infirmière'},
                      {value:'r7', label: 'Aide à la prise par un membre de la famille'},
                      {value:'r8', label: 'Aide a la prise par un aidant professionnel'}]"
                       :key="index">
                    <input class="id-check--input"
                           :id="`stsm03-option-${index}`"
                           type="checkbox"
                           :value="option.value"
                           v-model="stsm03"/>
                    <label :for="`stsm03-option-${index}`">
                      <span class="id-check--indicator">{{option.label}}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm04 !== null && stsm04 !== ''"
          title="Avez vous d'autres traitements à signaler ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="stsm04" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm05 !== null && stsm05 !== ''"
          title="Si oui, pouvez vous décrire ?"
          v-if="stsm04 === 'r1' && active">
        <template v-slot:content>
          <div class="w-100">
            <div class="eval-card--form input">
              <div class="form-group eval-form eval-form--input">
                <textarea
                    v-model="stsm05"
                    class="form-control"
                    placeholder="Saisir une réponse"
                    style="width: 100%;max-width: 100%;"
                    @blur="onBlurStsm05"></textarea>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm06 !== null && stsm06 !== ''"
          title="À quelle fréquence voyez-vous votre médecin ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="stsm06" name id>
                  <option value="r1">Chaque semaine</option>
                  <option value="r2">Chaque mois</option>
                  <option value="r3">Plusieurs fois par an</option>
                  <option value="r4">Une fois par an</option>
                  <option value="r5">Jamais</option>
                  <option value="r6">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm07 !== null && stsm07 !== ''"
          title="Ce médecin, est-il votre médecin traitant déclaré ?"
          v-if="(stsm06 === 'r1' || stsm06 === 'r2' || stsm06 === 'r3' || stsm06 === 'r4') && active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="stsm07" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm08 !== null && stsm08.length > 0"
          title="Durant l'année écoulée, avez-vous consulté l'un ou plusieurs des spécialistes suivants ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                inputType="checkbox"
                subtitle=""
                :options="[]">
                <template slot="content">
                  <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label: 'Dentiste'},
                      {value:'r2', label: 'Ophtalmologue'},
                      {value:'r3', label: 'ORL'},
                      {value:'r4', label: 'Autre spécialiste'},
                      {value:'r5', label: 'NSP'}]"
                       :key="index">
                    <input class="id-check--input"
                           :id="`stsm08-option-${index}`"
                           type="checkbox"
                           :value="option.value"
                           v-model="stsm08"/>
                    <label :for="`stsm08-option-${index}`">
                      <span class="id-check--indicator">{{option.label}}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm09 !== null && stsm09 !== ''"
          title="Recevez-vous la visite d'une infirmière ou d'un service de soin infirmiers, d'une HAD ?"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="stsm09" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm10 !== null && stsm10 !== ''"
          title="Si oui, pour quel type de soin et de fréquence ?"
          v-if="stsm09 === 'r1' && active">
        <template v-slot:content>
          <div class="w-100">
            <div class="eval-card--form input">
              <div class="form-group eval-form eval-form--input">
                <textarea
                    v-model="stsm10"
                    class="form-control"
                    placeholder="Saisir une réponse"
                    style="width: 100%;max-width: 100%;"
                    @blur="onBlurStsm10"></textarea>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
          :showContentSlot="true"
          :checked="stsm11 !== null && stsm11.length > 0"
          title="Êtes-vous régulièrement suivi par"
          v-if="active">
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <eval-form-check-alt
                inputType="checkbox"
                subtitle=""
                :options="[]">
                <template slot="content">
                  <div class="id-check--option" v-for="(option, index) in
                    [{value:'r1', label: 'Pédicure'},
                      {value:'r2', label: 'Kinésithérapeute'},
                      {value:'r3', label: 'Orthophoniste'},
                      {value:'r4', label: 'Psychologue'},
                      {value:'r5', label: 'Aucun de tout cela'}]"
                       :key="index">
                    <input class="id-check--input"
                           :id="`stsm11-option-${index}`"
                           type="checkbox"
                           :value="option.value"
                           v-model="stsm11"/>
                    <label :for="`stsm11-option-${index}`">
                      <span class="id-check--indicator">{{option.label}}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>
    </div>
  </div>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import SubQuestionExtend from "@/views/Evaluation/Soin/SubQuestionExtend";
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";

export default {
  extends: SubQuestionExtend,
  components:{
    EvalFormCheckAlt
  },
  methods: {
    ...mapMutations('Evaluation', ['setTreatmentProperty']),
    ...mapActions('Evaluation', ['saveTreatment']),
    onBlurValueStsm01Bis(){
      this.saveTreatment({
        id: "treatment",
        name: "stsm01_bis",
        evaluationId: this.$route.params.evaluationId
      });
    },
    onBlurStsm05(){
      this.saveTreatment({
        id: "treatment",
        name: "stsm05",
        evaluationId: this.$route.params.evaluationId
      });
    },
    onBlurStsm10(){
      this.saveTreatment({
        id: "treatment",
        name: "stsm10",
        evaluationId: this.$route.params.evaluationId
      });
    }
  },
  computed: {
    ...mapState('Evaluation', ['treatment']),
    brainRisk(){
      /*let total = 0;
      if(this.smd03 === 'r1'){
        total += 1;
      }else if(this.smd03 === 'r2'){
        total += 2;
      }else{
        total += 3;
      }

      if(this.smd02 === 'r1'){
        total += 2;
      }*/
      return "Mémorisation des 3 mots et Evaluation du risque d'escarre : coming soon";
    },
    /*valueStsm01:{
      get() {
        if(!this.treatment.stsm01_bis.response){
          return null;
        }
        if(isNaN(this.treatment.stsm01_bis.response)){
          return null;
        }
        return this.treatment.stsm01_bis.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'stsm01_bis',
          value
        });
        this.saveTreatment({id: "treatment", name: "stsm01_bis", evaluationId: this.$route.params.evaluationId});
      }
    },*/
    stsm01: {
      get() {
        return this.treatment.stsm01.response
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'stsm01',
          value
        });
        this.saveTreatment({id: "treatment", name: "stsm01", evaluationId: this.$route.params.evaluationId});
      }
    },
    stsm02: {
      get() {
        return this.treatment.stsm02.response ? this.treatment.stsm02.response.split(',') : [];
      },
      set(value) {
        const prevValue = this.treatment.stsm02.response;
        this.setTreatmentProperty({
          name: 'stsm02',
          value: value.join(',')
        });
        if (value.join(',')!==prevValue) {
          this.saveTreatment({id: "treatment", name: "stsm02", evaluationId: this.$route.params.evaluationId});
        }
      }
    },
    stsm03: {
      get() {
        return this.treatment.stsm03.response ? this.treatment.stsm03.response.split(',') : [];
      },
      set(value) {
        const prevValue = this.treatment.stsm03.response;
        this.setTreatmentProperty({
          name: 'stsm03',
          value: value.join(',')
        });
        if (value.join(',')!==prevValue) {
          this.saveTreatment({id: "treatment", name: "stsm03", evaluationId: this.$route.params.evaluationId});
        }
      }
    },
    stsm04: {
      get() {
        return this.treatment.stsm04.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'stsm04',
          value
        });
        this.saveTreatment({id: "treatment", name: "stsm04", evaluationId: this.$route.params.evaluationId});
      }
    },
    stsm05: {
      get() {
        return this.treatment.stsm05.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'stsm05',
          value
        });
      }
    },
    stsm06: {
      get() {
        return this.treatment.stsm06.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'stsm06',
          value
        });
        this.saveTreatment({id: "treatment", name: "stsm06", evaluationId: this.$route.params.evaluationId});
      }
    },
    stsm07: {
      get() {
        return this.treatment.stsm07.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'stsm07',
          value
        });
        this.saveTreatment({id: "treatment", name: "stsm07", evaluationId: this.$route.params.evaluationId});
      }
    },
    stsm08: {
      get() {
        return this.treatment.stsm08.response ? this.treatment.stsm08.response.split(',') : [];
      },
      set(value) {
        const prevValue = this.treatment.stsm08.response;
        this.setTreatmentProperty({
          name: 'stsm08',
          value: value.join(',')
        });
        if (value.join(',')!==prevValue) {
          this.saveTreatment({id: "treatment", name: "stsm08", evaluationId: this.$route.params.evaluationId});
        }
      }
    },
    stsm09: {
      get() {
        return this.treatment.stsm09.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'stsm09',
          value
        });
        this.saveTreatment({id: "treatment", name: "stsm09", evaluationId: this.$route.params.evaluationId});
      }
    },
    stsm10: {
      get() {
        return this.treatment.stsm10.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: 'stsm10',
          value
        });
      }
    },
    stsm11: {
      get() {
        return this.treatment.stsm11.response ? this.treatment.stsm11.response.split(',') : [];
      },
      set(value) {
        const prevValue = this.treatment.stsm11.response;
        this.setTreatmentProperty({
          name: 'stsm11',
          value: value.join(',')
        });
        if (value.join(',')!==prevValue) {
          this.saveTreatment({id: "treatment", name: "stsm11", evaluationId: this.$route.params.evaluationId});
        }
      }
    },
  }
}
</script>