<template>
  <div class="soin-item">
    <div class="soin-item--title" @click.prevent="toggleActive">
      <h1>Général</h1>
      <button class="btn" :class="{ inverted: !active }">
        <img src="@/assets/chevron-up.svg" alt />
      </button>
    </div>
    <div class="soin-item--content">
      <eval-card-check
        :showContentSlot="true"
        :checked="sg01 !== null && sg01 !== ''"
        title="Recueil de la personne sur son état de santé, ses maladies ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="w-100">
            <div class="eval-card--form input">
              <div class="form-group eval-form eval-form--input">
                <textarea
                  v-model="sg01"
                  class="form-control"
                  placeholder="Saisir une réponse"
                  style="width: 100%; max-width: 100%"
                  @blur="onBlur"
                ></textarea>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
        :showContentSlot="true"
        :checked="sg02 !== null && sg02 !== ''"
        title="Par rapport aux personnes du même âge, comment jugez-vous votre état de santé ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sg02" name id>
                  <option value="r1">Equivalent</option>
                  <option value="r2">Plutôt meilleur</option>
                  <option value="r3">Plutôt moins bon</option>
                  <option value="r4">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
        :showContentSlot="true"
        :checked="sg03 !== null && sg03 !== ''"
        title="Avez-vous été hospitalisé au cours des 6 derniers mois ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sg03" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
      <eval-card-check
        :showContentSlot="true"
        :checked="sg04 !== null && sg04 !== ''"
        title="Avez-vous des signes d'une infection en cours ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sg04" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sg07 !== null && sg07 !== ''"
        title="Description des symptômes"
        v-if="active & (sg04 === 'r1')"
      >
        <template v-slot:content>
          <div class="w-100">
            <div class="eval-card--form input">
              <div class="form-group eval-form eval-form--input">
                <form-textarea
                  v-model="sg07"
                  label=""
                  :rows="1"
                  class="w-100"
                  :disabled="false"
                  :login-input="true"
                >
                  <span slot="error"></span>
                </form-textarea>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sg05 !== null && sg05.length > 0"
        title="Statut vaccinal"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form">
              <eval-form-check-alt
                inputType="checkbox"
                subtitle=""
                :options="[]"
              >
                <template slot="content">
                  <div
                    class="id-check--option"
                    v-for="(option, index) in [
                      { value: 'r1', label: 'Grippe' },
                      { value: 'r2', label: 'Covid' },
                    ]"
                    :key="index"
                  >
                    <input
                      class="id-check--input"
                      :id="`sg05-option-${index}`"
                      type="checkbox"
                      :value="option.value"
                      v-model="sg05"
                    />
                    <label :for="`sg05-option-${index}`">
                      <span class="id-check--indicator">{{
                        option.label
                      }}</span>
                    </label>
                  </div>
                </template>
              </eval-form-check-alt>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sg06 !== null && sg06 !== ''"
        title="Libellé "
        v-if="active & (sg05.includes('r1') || sg05.includes('r2'))"
      >
        <template v-slot:content>
          <div class="w-100">
            <div class="eval-card--form input">
              <div class="form-group eval-form eval-form--input">
                <form-textarea
                  v-model="sg06"
                  label=""
                  :rows="1"
                  class="w-100"
                  :login-input="true"
                >
                  <span slot="error"></span>
                </form-textarea>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState, mapActions } from "vuex";
import SubQuestionExtend from "@/views/Evaluation/Soin/SubQuestionExtend";
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";
import FormTextarea from "@/components/Form/Textarea";

export default {
  extends: SubQuestionExtend,
  components: {
    FormTextarea,
    EvalFormCheckAlt,
  },
  methods: {
    ...mapMutations("Evaluation", ["setTreatmentProperty"]),
    ...mapActions("Evaluation", ["saveTreatment"]),
    onBlur() {
      this.saveTreatment({
        id: "general",
        name: "sg01",
        evaluationId: this.$route.params.evaluationId,
      });
    },
  },
  computed: {
    ...mapState("Evaluation", ["general"]),
    sg01: {
      get() {
        return this.general.sg01.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sg01",
          value: value,
        });
      },
    },
    sg02: {
      get() {
        return this.general.sg02.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sg02",
          value: value,
        });
        this.saveTreatment({
          id: "general",
          name: "sg02",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sg03: {
      get() {
        return this.general.sg03.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sg03",
          value: value,
        });
        this.saveTreatment({
          id: "general",
          name: "sg03",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },

    sg04: {
      get() {
        return this.general.sg04.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sg04",
          value: value,
        });
        this.saveTreatment({
          id: "general",
          name: "sg04",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sg05: {
      get() {
        return this.general.sg05.response
          ? this.general.sg05.response.split(",")
          : [];
      },
      set(value) {
        const prevValue = this.general.sg05.response;
        this.setTreatmentProperty({
          name: "sg05",
          value: value.join(","),
        });
        if (!prevValue || value.join(",") !== prevValue) {
          this.saveTreatment({
            id: "general",
            name: "sg05",
            evaluationId: this.$route.params.evaluationId,
          });
        }
      },
    },
    sg06: {
      get() {
        return this.general.sg06.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sg06",
          value: value,
        });
        this.saveTreatment({
          id: "general",
          name: "sg06",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sg07: {
      get() {
        return this.general.sg07.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sg07",
          value: value,
        });
        this.saveTreatment({
          id: "general",
          name: "sg07",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
  },
};
</script>
