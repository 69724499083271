import gql from 'graphql-tag'

export const DELETE_AGGIR_QUESTION = gql`
    mutation deleteAggirQuestion(
        $id: Int!,
    ) {
        deleteAggirQuestion(
            id: $id
        )
    }
`
