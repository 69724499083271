<template>
  <div>
    <div class="id--section">
      <div class="id--section-title">
        <h1>HABITAT ET ENVIRONNEMENT DE PROXIMITÉ</h1>
      </div>
      <div class="id--section-content">
        <div class="row">
          <div class="col-12" v-for="q in questions" :key="q.id">
            <div
              class="id-check--group"
              v-if="(q.type === 'radio' || q.type === 'checkbox') && toShow(q)"
            >
              <eval-form-check
                v-model="answers[q.id]"
                v-if="q.type === 'radio'"
                :title="q.title"
                :options="q.responses"
                @change="changeCheck(q.id)"
              />
              <eval-form-check-alt
                v-model="answers[q.id]"
                v-if="q.type === 'checkbox'"
                :subtitle="q.title"
                inputType="checkbox"
                :options="q.responses"
                @change="changeCheck(q.id)"
              />
            </div>
            <form-textarea
              v-model="texts[q.id]"
              v-if="q.type === 'text'"
              v-show="toShow(q)"
              :label="q.title"
              :rows="3"
              class="w-100"
              :login-input="false"
              :disabled="false"
              @blur="changeCheck(q.id)"
            />
          </div>
          <div class="col-12">
            <form-textarea
              v-model="habitatComment"
              label="Commentaires de l'évaluateur sur l'habitat et l'environnement de proximité"
              :rows="3"
              class="w-100"
              :login-input="false"
              :disabled="false"
              @blur="changeCommentaryValue()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EvalFormCheck from "@/components/Eval/FormCheck";
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  components: {
    EvalFormCheck,
    EvalFormCheckAlt,
  },
  data: () => ({
    texts: {},
    answers: {},
    transport: null,
    environment: null,
    wc: null,
    bathRoom: null,
  }),
  computed: {
    ...mapState("Evaluation", ["evaluation", "generalQuestions"]),
    questions() {
      return this.generalQuestions.filter((e) => {
        return e.category === "Habitat";
      });
    },
    habitatComment: {
      get() {
        return this.evaluation.evaluatorComments
          ? this.evaluation.evaluatorComments.habitatComment
          : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.habitatComment = value;
      },
    },
  },
  async mounted() {
    await this.getEvaluation({ id: this.$route.params.evaluationId });
    const v = this.evaluation;
    if (v.generalQuestionsAnswers) {
      var answers = {};
      var texts = {};
      v.generalQuestionsAnswers.forEach((e) => {
        if (e.generalQuestion.type == "radio") {
          answers[e.generalQuestion.id] = e.answers
            ? e.generalQuestion.responses[e.answers[0]]
            : null;
        }
        if (e.generalQuestion.type == "checkbox") {
          let answer = [];
          if (e.answers) {
            e.answers.forEach((t) => {
              answer.push(e.generalQuestion.responses[t]);
            });
          }
          answers[e.generalQuestion.id] = answer;
        }
        texts[e.generalQuestion.id] = e.text;
      });
      this.answers = answers;
      this.texts = texts;
    }
  },
  methods: {
    ...mapActions("Evaluation", [
      "getEvaluation",
      "putEvaluation",
      "getGeneralQuestions",
      "postGeneralQuestionAnswer",
    ]),
    ...mapMutations("Evaluation", [
      "setGeneralAnswerSuccess",
      "setEvaluationProperty",
    ]),
    changeCheck(questionId) {
      let question = this.questions.find((e) => e.id == questionId);
      let generalAnswer = this.evaluation.generalQuestionsAnswers.find(
        (e) => e.generalQuestion.id == questionId
      );
      if (!question) return;
      let answers = null;
      if (question.type === "radio") {
        answers = [question.responses.indexOf(this.answers[questionId])];
      }
      if (question.type === "checkbox") {
        answers = [];
        this.answers[questionId].forEach((e) => {
          answers.push(question.responses.indexOf(e));
        });
      }
      const text = question.type === "text" ? this.texts[questionId] : "";
      this.setGeneralAnswerSuccess({
        id: generalAnswer ? generalAnswer.id : 0,
        questionId: questionId,
        answers: answers,
        text: text,
      });
      this.postGeneralQuestionAnswer();
    },
    toShow(question) {
      if (question.id === 19 || question.id === 21) {
        if (question.id === 19) {
          if (
            this.answers[18] &&
            this.answers[18] !== "absence de salle de bain"
          ) {
            return true;
          }
        }
        if (question.id === 21) {
          if (this.answers[20] && this.answers[20] !== "Pas de WC") {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    },
    changeCommentaryValue() {
      this.putEvaluation();
    },
  },
};
</script>
