<template>
    <Card :title="title" card-body-class="p-0">
        <template v-if="mention" v-slot:post-title>{{mention}}</template>
        <div class="d-flex h-100 justify-content-center align-items-center">
            <input id="upload-picture" name="photo" type="file" accept=".png,.jpeg,.jpg,.svg" @change="filesDocument"/>
            <label for="upload-picture">
                <img v-if="imageUpload && value" :src="imageUpload" width="200" :height="isLogo ? '' : '200'"/>
                <img v-else-if="imageUrl" :src="imageUrl" width="200" :height="isLogo ? '' : '200'"/>
                <img v-else width="200px" :height="isLogo ? '' : '200px'" src="@/assets/photo-add-gray.svg" alt/>
            </label>
        </div>
    </Card>
</template>
<script>
    import Card from "./Card";
    export default {
        name: "DocumentSimple",
        components: {Card},
        props:{
            title: String,
            imageUrl: String,
            mention: {
                type: String,
                default:()  => false
            },
            value: {
                type: File,
                default: () => {}
            },
            isLogo: {
                type: Boolean,
                default:()  => false
            },
        },
        data: () => ({
            image: null,
            imageUpload: null
        }),
        methods:{
            filesDocument: function(e){
                this.$emit('input', e.target.files[0]);
                let fileReader = new FileReader();
                fileReader.onload = (e) => {
                    this.imageUpload = e.target.result;
                };
                fileReader.readAsDataURL(e.target.files[0]);
            }
        }
    };
</script>