export default {
    setNotification(state, notification) {
        state.notification = notification;
    },
    setNotificationContractUser(state, userList) {
        state.notificationContractUser = userList;
    },
    setNotificationSupportClient(state, clientList) {
        state.notificationSupportClient = clientList;
    }
}