import gql from 'graphql-tag'

export const ASSIGN_RISK = gql`
    mutation assignRisk(
        $id: Int!,
        $name: String!,
    ) {
        assignRisk(
            id: $id,
            name: $name,
        ) {
            id,
            risk{
                id,
                name,
            }
            checked
        }
    }
`
